import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { IServiceBookingPageResponse } from '@ncg/data';
import { Subject, from, take, takeUntil } from 'rxjs';
import { productListAnimation } from '../../utils/animations/filter.animation';

@Component({
    selector: 'ncg-service-booking-page',
    animations: [productListAnimation],
    template: `
        <div class="content service-booking-page">
            <ncg-umb-grid [grid]="data?.grid"></ncg-umb-grid>
            <ng-container [ngSwitch]="state">
                <div *ngSwitchCase="'loading'" class="service-booking-loader">
                    <ncg-spinner-car class="car-spinner"></ncg-spinner-car>
                </div>
                <ng-container *ngSwitchCase="'success'">
                    <ng-container #vcServiceBooking></ng-container>
                </ng-container>
            </ng-container>
        </div>
    `,
    styles: [
        `
            .car-spinner {
                color: var(--configurator-accent-color); /* TODO: Consider if spinner */
                width: clamp(10rem, 12vw + 4rem, 20rem);
            }
            .service-booking-loader {
                padding: 8rem 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceBookingPageComponent implements OnInit, OnDestroy {
    static ref = 'serviceBookingPage';
    private readonly unsubscribe = new Subject<void>();
    public state: 'loading' | 'success' | 'error' = 'loading';

    @ViewChild('vcServiceBooking', { read: ViewContainerRef })
    public viewContainerServiceBooking: ViewContainerRef;

    @Input() public data: IServiceBookingPageResponse;

    constructor(private readonly cd: ChangeDetectorRef) {}

    public ngOnInit() {
        from(import('../../service-booking/service-booking.component'))
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe({
                next: ({ ServiceBookingComponent }) => {
                    this.state = 'success';
                    this.cd.markForCheck();
                    window.requestAnimationFrame(() => {
                        this.viewContainerServiceBooking.clear();
                        const component = this.viewContainerServiceBooking.createComponent(ServiceBookingComponent);
                        component.instance.data = this.data;
                        this.cd.markForCheck();
                    });
                },
                error: () => this._handleError(),
            });
    }

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private _handleError(): void {
        this.state = 'error';
        this.cd.markForCheck();
    }
}
