import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ISiteLanguage } from '@ncg/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ncg-language-selector',
    template: `
        <div class="header-language">
            <nav class="navbar" role="navigation" aria-label="Langauge selector">
                <div class="is-flex">
                    <a
                        *ngFor="let language of siteLanguages"
                        class="header-language__item navbar-item"
                        [ngClass]="{ 'is-active': isActive(languageLinks[language.languageCode]) }"
                        [href]="languageLinks[language.languageCode] ? languageLinks[language.languageCode] : language.fallbackLink.url"
                    >
                        <span>{{ language.languageTitle }}<span aria-hidden="true" class="navbar-item__active-bar"></span></span>
                    </a>
                </div>
            </nav>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
    @Input() languageLinks: Record<string, string>;
    @Input() siteLanguages: ISiteLanguage[];
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            // Is unfortunately neeeded to update UI with "is-active" class, since we do not use a routerLink in this case
            if (event instanceof NavigationEnd) {
                this.cd.markForCheck();
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public isActive(url: string): boolean {
        return this.router.isActive(url, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' });
    }
}
