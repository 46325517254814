import { HttpEventType, HttpUserEvent } from '@angular/common/http';

export interface IValidationError {
    property: string;
    error: string;
}

export class AppHttpErrorResponse implements HttpUserEvent<any> {
    type: HttpEventType.User = HttpEventType.User; // sets the httpEvent to a custom event
    validationErrors: IValidationError[] = [];

    constructor(public error: any) {}
}
