import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { SearchIconComponent } from './search-icon.component';

@NgModule({
    declarations: [SearchIconComponent],
    imports: [CommonModule, IconSpriteModule],
    exports: [SearchIconComponent],
})
export class SearchIconModule {}
