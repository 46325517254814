// Naming and values based on "breakpoints" from _variables.scss
export const breakpoints = {
    mobileXs: 321,
    mobileSmall: 374,
    mobileLarge: 414,
    tablet: 768,
    desktop: 1024,
    widescreen: 1152,
    fullhd: 1250,
    desktopLarge: 1400,
    fullwidth: 1600,
};

// Queries are reduced in size, since we do not use that many. Expand if needed
export const breakpointQueries = {
    mobile: `(max-width: ${breakpoints.mobileLarge - 1}px)`,
    mobileLarge: `(min-width: ${breakpoints.mobileLarge}px) and (max-width: ${breakpoints.tablet - 1}px)`,
    tablet: `(min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktop - 1}px)`,
    desktop: `(min-width: ${breakpoints.desktop}px)`,
};
