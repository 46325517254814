import { Pipe, PipeTransform } from '@angular/core';
import { map, of } from 'rxjs';
import { SettingsService } from '../../core/settings.service';
import { DataEntities, PriceProperty, getEntitiesSortedByPrice } from './by-price.util';

@Pipe({
    name: 'sortEntitiesByPrice',
    standalone: true,
    pure: true,
})
export class SortEntitiesByPricePipe implements PipeTransform {
    constructor(private readonly settingsService: SettingsService) {}
    transform<T extends DataEntities>(
        entities: T,
        pricePropertyWithoutCurrency: PriceProperty = 'CommercialColourOptionRetailSellingPrice',
        orderBy: 'asc' | 'desc' = 'asc'
    ) {
        return (this.settingsService.settings$ || of({ currency: 'DKK' })).pipe(
            map(({ currency }) => getEntitiesSortedByPrice(entities, pricePropertyWithoutCurrency, currency, orderBy))
        );
    }
}
