import { DataField, DataListField } from '@ncg/data';
import { isDataList } from './is-data-list.util';

export interface Resource {
    ResourceCdnUrlOriginal?: string;
    ResourceFilename?: string;
    ResourceMedia?: string;
    ResourceMediaFormat?: string;
    ResourceMediaType?: string;
    ResourcePerspective?: string;
}

export const fieldsToResource = (fields?: (DataField | DataListField)[] | null): Resource | undefined => {
    const resource: Resource = {};
    return fields?.reduce((acc, curr) => {
        if (curr.fieldTypeId?.includes('Resource')) {
            const val = isDataList(curr) ? curr.data?.[0].value : curr.data?.value;
            return { ...acc, [curr.fieldTypeId]: val };
        }
        return acc;
    }, resource);
};
