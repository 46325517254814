import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { IFacet, IFacetList } from '@ncg/data';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { filterParams } from '../../+state/products/products.actions';
import { ProductsFacade } from '../../+state/products/products.facade';
import { SettingsService } from '../../core/settings.service';
import { TrackingService } from '../../core/tracking.service';

@Component({
    selector: 'ncg-filter-facet-range',
    template: `
        <div class="filter-facet" *ngIf="facetGroup" [formGroup]="form">
            <div class="columns is-variable is-3 is-multiline is-mobile">
                <div class="column is-half">
                    <div class="field is-marginless">
                        <label for="rangeFrom" class="label is-small is-uppercase has-text-black-bis">{{
                            'filter_sidepanel.range_from' | translate
                        }}</label>
                        <div class="select is-full is-size-4">
                            <select id="rangeFrom" formControlName="from" (change)="onRangeChange()">
                                <option
                                    *ngFor="let item of fromArr; let isLast = last; trackBy: trackByFn"
                                    [value]="item.key"
                                    [disabled]="disableFromValue(item.key) || (isLast && !allowSameRangeValues)"
                                >
                                    <ng-container *ngIf="item.key === '*' || facetGroup.facetKey === 'year'; else formatNumberTmpl">
                                        <ng-container *ngIf="item.key === '*'; else keyTmpl">{{
                                            'facet_types.min_range_label' | translate
                                        }}</ng-container>
                                        <ng-template #keyTmpl>{{ item.key }}</ng-template>
                                    </ng-container>
                                    <ng-template #formatNumberTmpl>{{ item.key | number: '1.0-0' : locale }}</ng-template>
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="column is-half">
                    <div class="field is-marginless">
                        <label for="rangeTo" class="label is-small is-uppercase has-text-black-bis">{{
                            'filter_sidepanel.range_to' | translate
                        }}</label>
                        <div class="select is-full is-size-4">
                            <select id="rangeTo" formControlName="to" (change)="onRangeChange()">
                                <option *ngFor="let item of toArr; trackBy: trackByFn" [value]="item.key" [disabled]="disableToValue(item.key)">
                                    <ng-container *ngIf="item.key === '*' || facetGroup.facetKey === 'year'; else formatNumberTmpl">
                                        <ng-container *ngIf="item.key === '*'; else keyTmpl">{{
                                            'facet_types.max_range_label' | translate
                                        }}</ng-container>
                                        <ng-template #keyTmpl>{{ item.key }}</ng-template>
                                    </ng-container>
                                    <ng-template #formatNumberTmpl>{{ item.key | number: '1.0-0' : locale }}</ng-template>
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFacetRangeComponent implements OnInit, OnChanges, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    @Input() facetGroup: IFacetList;
    form = this.fb.group({
        from: '',
        to: '',
    });
    locale = '';
    get fromArr() {
        return this.facetGroup.facets ?? [];
    }
    get toArr() {
        return this.facetGroup.facets ?? [];
    }
    get allowSameRangeValues() {
        return this.facetGroup.facetKey === 'year';
    }

    constructor(
        public productsFacade: ProductsFacade,
        private readonly cd: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private readonly settingsService: SettingsService,
        private readonly trackingService: TrackingService
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit() {
        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => (this.locale = `${settings.seoLanguage}-${settings.seoCountry}`));
    }

    ngOnChanges() {
        this.setInitialValues();
    }

    setInitialValues() {
        const selectedItems = this.facetGroup.facets?.filter((item) => item.selected === true) ?? [];
        let fromValue = '';
        let toValue = '';

        switch (selectedItems.length) {
            case 1: // If only one value is selcted from BE response, then assume the user has selected same "from" and "to" value
                fromValue = selectedItems[0]?.key ?? '';
                toValue = selectedItems[0]?.key ?? '';
                break;

            case 2: {
                // Set "from" and "to "range
                const indexSelectedFrom = this.facetGroup.facetKey === 'year' ? selectedItems.length - 1 : 0;
                const indexSelectedTo = this.facetGroup.facetKey === 'year' ? 0 : selectedItems.length - 1;
                fromValue = selectedItems[indexSelectedFrom]?.key ?? '';
                toValue = selectedItems[indexSelectedTo]?.key ?? '';
                break;
            }

            default: {
                const indexFrom = this.facetGroup.facetKey === 'year' ? this.toArr.length - 1 : 0;
                const indexTo = this.facetGroup.facetKey === 'year' ? 0 : this.toArr.length - 1;
                fromValue = this.fromArr[indexFrom]?.key ?? '';
                toValue = this.toArr[indexTo]?.key ?? '';
                break;
            }
        }

        this.fromInput?.setValue(fromValue);
        this.toInput?.setValue(toValue);
    }

    disableFromValue(value?: string | null) {
        const toNumber = Number(this.toInput?.value) ?? 0;
        const fromNumber = Number(value);

        if (this.allowSameRangeValues) {
            return fromNumber > toNumber;
        } else {
            return fromNumber >= toNumber;
        }
    }

    disableToValue(value?: string | null) {
        const fromNumber = Number(this.fromInput?.value) ?? 0;
        const toNumber = this.allowSameRangeValues ? Number(value) : Number(value) - 1;
        return fromNumber > toNumber ?? 0;
    }

    onRangeChange() {
        const from = this.fromInput?.value;
        let to = this.toInput?.value;

        if (Number(from) && Number(to) && Number(from) > Number(to)) {
            to = this.toArr.length > 1 ? this.toArr[this.toArr.length - 1].key : '*';
            this.toInput?.setValue(to);
            this.cd.markForCheck();
        }

        const key = `${from}-${to}`;
        this.updateFilterQuery(key);
    }

    updateFilterQuery(facetKey?: string | null) {
        if (this.facetGroup.facetKey) {
            this.productsFacade.dispatch(
                filterParams({
                    filterParams: { [this.facetGroup.facetKey]: [facetKey] },
                    resetKey: true,
                })
            );

            if (facetKey) {
                // track only selected facet
                this.trackingService.trackFilter(facetKey, this.facetGroup.facetKey);
            }
        }
    }

    trackByFn(index: number, item: IFacet) {
        return item.key || index;
    }

    get fromInput() {
        return this.form.get('from');
    }

    get toInput() {
        return this.form.get('to');
    }
}
