/* eslint-disable rxjs-angular/prefer-takeuntil */
import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

import { dimAnimation, overlayAnimation } from './page-transition.animation';
import { PageTransitionService } from './page-transition.service';

@Component({
    selector: 'ncg-page-transition',
    template: `
        <div class="page-transition">
            <div class="page-transition__overlay" [@overlay]="animationOverlayState" (@overlay.done)="onDone($event)">
                <div class="page-transition__overlay__content">
                    <ncg-spinner id="spinner" [active]="spinnerActive"></ncg-spinner>
                </div>
            </div>
        </div>
    `,
    animations: [dimAnimation, overlayAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTransitionComponent implements OnInit {
    @HostBinding('class.is-active')
    get isActive() {
        return this.phase !== 'none';
    }

    @HostBinding('class.is-dimmed')
    get isDimmed() {
        return this.phase !== 'none' && this.phase !== 'post';
    }

    get animationOverlayState(): any {
        const ret = {
            value: this.phase,
            params: {
                durationIn: `${this.pageTransitionService.durationIn}s`,
                durationOut: `${this.pageTransitionService.durationOut}s`,
            },
        };
        return ret;
    }

    constructor(
        private readonly cd: ChangeDetectorRef,
        private router: Router,
        @Inject(PLATFORM_ID)
        private platformId: any,
        public pageTransitionService: PageTransitionService
    ) {}
    @Input() spinnerDelay = 300;

    spinnerActive = false;

    @HostBinding('class')
    phase = 'none';

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.pageTransitionService.phase$.subscribe((phase) => {
                this.phase = phase;
                if (this.phase === 'mid') {
                    setTimeout(() => {
                        if (this.phase === 'mid') {
                            this.spinnerActive = true;
                            this.cd.detectChanges();
                        }
                    }, this.spinnerDelay);
                } else {
                    this.spinnerActive = false;
                }
                this.cd.detectChanges();
            });

            this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
                firstValueFrom(this.pageTransitionService.startPost()).catch(() => undefined);
            });
        }
    }

    onDone(event: any) {
        if (event['toState'] === 'pre') {
            firstValueFrom(this.pageTransitionService.endPre()).catch(() => undefined);
        } else if (event['toState'] === 'post') {
            firstValueFrom(this.pageTransitionService.endPost()).catch(() => undefined);
        }
    }
}
