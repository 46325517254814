import { animate, state, style, transition, trigger } from '@angular/animations';

const ANIMATION_TIMINGS = '300ms cubic-bezier(0.35, 0, 0.25, 1)';

export const mobileAnimation = [
    trigger('fade', [
        state('fadeOut', style({ opacity: 0 })),
        state('fadeIn', style({ opacity: 1 })),
        transition('* => fadeIn', animate(ANIMATION_TIMINGS)),
    ]),
    trigger('slideContent', [
        state('void', style({ transform: 'translate3d({{xAxis}}, {{yAxis}}, 0)' }), {
            params: {
                xAxis: '-100%',
                yAxis: '0',
            },
        }),
        state('enter', style({ transform: 'translate3d(0, 0, 0)' })),
        state('leave', style({ transform: 'translate3d({{xAxis}}, {{yAxis}}, 0)' }), {
            params: {
                xAxis: '-100%',
                yAxis: '0',
            },
        }),
        transition(
            '* => enter',
            [
                style({
                    transform: 'translate3d({{xAxis}}, {{yAxis}}, 0)',
                }),
                animate(ANIMATION_TIMINGS),
            ],
            {
                params: {
                    xAxis: '-100%',
                    yAxis: '0',
                },
            }
        ),
        transition('* => leave', [animate(ANIMATION_TIMINGS)], {
            delay: '50ms',
        }),
    ]),
    trigger('fadeInOut', [
        state('void', style({ opacity: 0, transform: 'translate3d(-100%, 0, 0)' })),
        transition(':enter', [
            style({ opacity: 0, transform: 'translate3d(-100%, 0, 0)' }),
            animate(ANIMATION_TIMINGS, style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
        ]),
        transition(':leave', [animate(ANIMATION_TIMINGS, style({ opacity: 0, transform: 'translate3d(-100%, 0, 0)' }))]),
    ]),

    trigger('navigate', [
        state('left', style({ transform: 'translate3d(-100%, 0, 0)' })),
        state('active', style({ transform: 'translate3d(0, 0, 0)' })),
        state('right', style({ transform: 'translate3d(100%, 0, 0)' })),

        transition('active => left', animate(ANIMATION_TIMINGS)),
        transition('active => right', animate(ANIMATION_TIMINGS)),

        transition('right => active', animate(ANIMATION_TIMINGS)),
        transition('left => active', animate(ANIMATION_TIMINGS)),

        transition('* => right', animate(0)),
        transition('* => left', animate(0)),
    ]),
];
