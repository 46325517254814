import { Pipe, PipeTransform } from '@angular/core';
import { multiParamSeparator } from '../configurator-settings';

@Pipe({
    name: 'formatMultiParam',
    standalone: true,
})
export class FormatMultiParamPipe implements PipeTransform {
    transform(paramId?: string | null, queryParam?: string): string | null {
        if (!queryParam || !paramId) {
            return paramId || '';
        }
        const idsFromUrl: string[] = queryParam.split(multiParamSeparator);
        // Adds or subtracts the id based on current existence
        const idArray = idsFromUrl.includes(paramId) ? idsFromUrl.filter((id) => id !== paramId) : [...idsFromUrl, paramId];
        return idArray.join(multiParamSeparator) || null;
    }
}
