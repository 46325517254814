import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UtilsModule } from '../utils/utils.module';
import { LinkContentClassPipe } from './link-content-class.pipe';
import { LinkComponent } from './link.component';

@NgModule({
    imports: [CommonModule, RouterModule, UtilsModule],
    declarations: [LinkComponent, LinkContentClassPipe],
    exports: [LinkComponent],
})
export class LinkModule {}
