import { Injectable, OnDestroy } from '@angular/core';
import { OverlayService } from '../../overlay/overlay.service';
import { Subject } from 'rxjs';
import { IValidationError } from '../../core/app-http-error.response';
import { SimpleDialogConfig } from './dialog.interface';
import { ImpactOverlayConfig } from '../../overlay/overlay.interfaces';
import { ImpactOverlayRef } from '../../overlay/overlay-ref';

@Injectable({
    providedIn: 'root',
})
export class DialogService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    constructor(private impactOverlay: OverlayService) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    openDialogWithComponent<T = any>(component: any, dialogConfig: ImpactOverlayConfig<T>): ImpactOverlayRef {
        const config: ImpactOverlayConfig<T> & { hasBackdrop: boolean } = { hasBackdrop: true, ...dialogConfig };
        return this.impactOverlay.open(component, config);
    }

    async openDialog(dialogConfig: SimpleDialogConfig): Promise<ImpactOverlayRef> {
        const { DialogComponent } = await import('./dialog.component');
        return this.openDialogWithComponent(DialogComponent, dialogConfig);
    }

    showMessage(text: string | string[], header?: string): void {
        // SetTimeout ensures the context has been rendered before showing a dialog, which could happen when opened in an ngOnInit hook
        // https://github.com/angular/material2/issues/5268
        setTimeout(() => {
            this.openDialog({
                data: {
                    header,
                    text,
                },
            });
        });
    }

    showValidationErrors(validationErrors: IValidationError[], headerVariable: string = 'forms.dialog_validation_errors'): void {
        const message = validationErrors.map((x) => x.error).join('<br>');
        this.showMessage(validationErrors && validationErrors.length ? message : 'Error', headerVariable);
    }
}
