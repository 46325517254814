import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import { FeatureDetectionService } from '../../core/feature-detection.service';

@Directive({
    selector: '[ncgLoadIn]',
})
export class LoadInDirective implements AfterViewInit {
    @Input() loadDirection: 'left' | 'right' | 'up' | 'down' = 'up';
    @Input() loadOptions?: IntersectionObserverInit;
    @Input() loadOpacity = false;
    @Input() loadDelay?: number;
    @Input() loadDuration?: number;

    private observer: IntersectionObserver;
    private defaultOptions: IntersectionObserverInit = {
        rootMargin: '90px 0px 16% 0px',
        threshold: 0.1,
    };

    private animationClass: string[] = [];

    constructor(
        private readonly el: ElementRef,
        private readonly featureDetectionService: FeatureDetectionService
    ) {}

    ngAfterViewInit() {
        if (this.featureDetectionService.isFirstPageLoad) {
            return;
        }

        const element: HTMLElement = this.el.nativeElement;

        if (element && this.featureDetectionService.hasIntersectionObserver()) {
            this.animationClass.push(`animate-${this.loadDirection}`);
            if (this.loadOpacity) {
                this.animationClass.push('animate-opacity');
            }

            if (this.loadDelay) {
                element.style.transitionDelay = `${this.loadDelay}ms`;
            }

            if (this.loadDuration) {
                element.style.transitionDuration = `${this.loadDuration}ms`;
            }

            element.classList.add('animate-load', ...this.animationClass);

            this.observer = new IntersectionObserver(
                (entries) => {
                    for (const entry of entries) {
                        if (entry.isIntersecting) {
                            entry.target.classList.remove(...this.animationClass);
                            this.observer.unobserve(entry.target);
                            this.observer.disconnect();
                        }
                    }
                },
                { ...this.defaultOptions, ...this.loadOptions }
            );

            this.observer.observe(element);
        }
    }
}
