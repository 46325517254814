import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { ConfiguratorQueryParams } from '../../configurator/configurator';

export interface ConfiguratorRouter {
    state: ConfiguratorRouterState;
}
export interface ConfiguratorRouterState {
    url: string;
    queryParams: ConfiguratorQueryParams;
}

export class ConfiguratorRouteSerializer implements RouterStateSerializer<ConfiguratorRouterState> {
    serialize(routerState: RouterStateSnapshot): ConfiguratorRouterState {
        let route = routerState.root;

        while (route.firstChild) {
            route = route.firstChild;
        }

        const {
            url,
            root: { queryParams },
        } = routerState;

        // Only return an object including the URL and query params
        // instead of the entire snapshot
        return { url, queryParams };
    }
}
