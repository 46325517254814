import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TextSpriteIconComponent } from './text-sprite-icon.component';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

@NgModule({
    imports: [CommonModule, IconSpriteModule],
    declarations: [TextSpriteIconComponent],
    exports: [TextSpriteIconComponent],
})
export class TextSpriteIconModule {}
