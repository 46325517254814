import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '../utils/utils.module';
import { ImageUploadComponent } from './image-upload.component';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, RouterModule, UtilsModule, IconSpriteModule, TranslateModule.forChild(), FormsModule, ReactiveFormsModule],
    declarations: [ImageUploadComponent],
    exports: [ImageUploadComponent],
})
export class ImageUploadModule {}
