import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'multiParamActiveState',
    standalone: true,
})
export class MultiParamActiveStatePipe implements PipeTransform {
    // To allow for less repetition in template, we return a string, so we can save it as a variable with *ngIf
    transform(id: string, param?: string): 'active' | 'inactive' {
        return param?.includes(id) ? 'active' : 'inactive';
    }
}
