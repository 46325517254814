import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VideoSpot, IVideoItem } from '@ncg/data';
import SwiperCore, { Keyboard, Navigation, Pagination } from 'swiper';

import { SpotBaseDirective } from '../spot-base.class';

SwiperCore.use([Pagination, Navigation, Keyboard]);

@Component({
    selector: 'ncg-video-spot',
    template: `
        <div class="video-spot is-relative" *ngIf="hasSlides">
            <div class="video-spot-slider">
                <swiper
                    #swiper
                    class="video-spot-swiper"
                    [slidesPerView]="1"
                    [spaceBetween]="0"
                    [keyboard]="true"
                    [threshold]="10"
                    [pagination]="{
                        clickable: true,
                        type: 'bullets',
                        el: pagination
                    }"
                    [navigation]="{
                        prevEl: prev,
                        nextEl: next
                    }"
                >
                    <ng-template swiperSlide *ngFor="let item of items; let index = index" let-data>
                        <div class="container">
                            <div class="columns is-multiline">
                                <ncg-video
                                    *ngIf="item.video"
                                    class="column is-12-touch"
                                    [video]="item.video"
                                    [play]="data.isActive"
                                    [reset]="!data.isActive"
                                    [aspectRatioClass]="'is-ratio-16-7'"
                                ></ncg-video>
                                <ncg-video-item
                                    *ngIf="item.title || item.text || item.link"
                                    class="column is-12-touch is-4 video-item"
                                    [item]="item"
                                ></ncg-video-item>
                            </div>
                        </div>
                    </ng-template>
                </swiper>
            </div>

            <div class="video-spot__arrows">
                <div class="video-spot__arrows-container">
                    <div>
                        <div #prev class="swiper-button-prev video-spot__prev"></div>
                        <div #next class="swiper-button-next video-spot__next"></div>
                    </div>
                </div>
            </div>

            <div class="video-spot__pagination">
                <div #pagination class="columns is-variable is-2"></div>
            </div>
        </div>

        <div class="columns" *ngIf="!hasSlides">
            <ncg-video *ngIf="items[0].video" class="column" [video]="items[0].video"></ncg-video>
            <ncg-video-item
                *ngIf="items[0].title || items[0].text || items[0].link"
                class="column is-4 video-item"
                [item]="items[0]"
            ></ncg-video-item>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoSpotComponent extends SpotBaseDirective {
    static ref = 'video';
    @Input() set data(videoItems: VideoSpot) {
        this.items = videoItems.items;
        this.hasSlides = this.items.length > 1;
    }
    items: IVideoItem[];
    hasSlides: boolean;

    constructor() {
        super();
    }
}
