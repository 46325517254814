import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import {
    Data,
    DataField,
    DataListField,
    IAccessoryDto,
    ICommercialColourOptionsExteriorDto,
    ICommercialColourOptionsInteriorDto,
    ICommercialTrimDto,
    IConfigurationDto,
    IConfiguratorModelPageResponse,
    IOptionalOptionDto,
    IPartnerProductDto,
    IPowerTrainDto,
    IVariantDto,
    NcgLocationFormCategory,
} from '@ncg/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, first, firstValueFrom, Subject, take, takeUntil } from 'rxjs';
import { setStep } from '../../+state/configurator/configurator.actions';

import { ConfiguratorFacade } from '../../+state/configurator/configurator.facade';
import { AppHttpErrorResponse } from '../../core/app-http-error.response';
import { MetaService } from '../../core/meta.service';
import { SettingsService } from '../../core/settings.service';
import { FooterMinimalComponent } from '../../footer-minimal/footer-minimal.component';
import { FormModule } from '../../form/form.module';
import { FormService } from '../../form/form.service';
import { LegalModule } from '../../legal/legal.module';
import { PortalComponent } from '../../portal/portal.component';
import { slideFadeInBottom, slideInBottom } from '../../utils/animations/slide.animations';
import { DialogService } from '../../utils/dialog/dialog.service';
import { UtilsModule } from '../../utils/utils.module';
import { fieldById } from '../utils';
import { fieldsToResource } from '../utils/fields-to-resource.util';
import { TrackingService } from '../../core/tracking.service';
import { ConfiguratorFormType, ConfiguratorStep } from '../configurator';

@Component({
    selector: 'ncg-configurator-form',
    animations: [slideInBottom, slideFadeInBottom],
    template: `<div class="container configurator__container" [@slideFadeInBottom]="{ value: '', params: { distance: '25px' } }">
        <div class="has-text-centered configurator__narrow">
            <h1 class="configurator__headline">{{ translationBase + 'heading_' + type | translate }}</h1>
            <p class="configurator__body-text mt-3">{{ translationBase + 'body_text_' + type | translate }}</p>
        </div>
        <div class="configurator__narrow">
            <form [formGroup]="form" (submit)="onSubmit()">
                <div class="field" *ngIf="form.contains('location')">
                    <ncg-location-select
                        [parentForm]="form"
                        controlName="location"
                        [category]="category"
                        [allowedCategory]="category"
                        (isLocationsFound)="onLocationState($event)"
                        [isTouched]="form?.get('location')?.touched"
                    ></ncg-location-select>
                </div>

                <div class="field">
                    <div class="control is-expanded">
                        <label class="label">{{ 'forms.firstname' | translate }}*</label>
                        <input
                            formControlName="firstname"
                            name="firstname"
                            class="input"
                            [class.is-danger]="errors('firstname')"
                            type="text"
                            required
                        />
                        <p class="help is-danger" *ngIf="errors('firstname')">{{ 'forms.error_required_field' | translate }}</p>
                    </div>
                </div>

                <div class="field">
                    <div class="control is-expanded">
                        <label class="label">{{ 'forms.lastname' | translate }}*</label>
                        <input formControlName="lastname" name="lastname" class="input" [class.is-danger]="errors('lastname')" type="text" required />
                        <p class="help is-danger" *ngIf="errors('lastname')">{{ 'forms.error_required_field' | translate }}</p>
                    </div>
                </div>

                <div class="field">
                    <div class="control is-expanded">
                        <label class="label">{{ 'forms.email' | translate }}*</label>
                        <input formControlName="email" name="email" class="input" [class.is-danger]="errors('email')" type="email" required />
                        <p class="help is-danger" *ngIf="errors('email')">{{ 'forms.error_email' | translate }}</p>
                    </div>
                </div>

                <div class="field" *ngIf="form.contains('phone_mobile')">
                    <div class="control is-expanded">
                        <label class="label">{{ 'forms.mobile' | translate }}*</label>
                        <div class="phone">
                            <span class="phoneCode">
                                <input
                                    style="width: 100px; margin-right: 2px"
                                    type="country-code"
                                    autocomplete="tel-country-code"
                                    class="input"
                                    name="selectedCountryCode"
                                    formControlName="selectedCountryCode"
                                    [class.is-danger]="errors('selectedCountryCode')"
                                />
                                <p class="help is-danger" *ngIf="errors('selectedCountryCode')">{{ 'forms.error_required_field' | translate }}</p>
                            </span>
                            <span class="phoneOnly">
                                <input
                                    formControlName="phone_mobile"
                                    name="phone"
                                    class="input"
                                    [class.is-danger]="errors('phone_mobile')"
                                    type="tel-national"
                                    autocomplete="tel-national"
                                    required
                                />
                                <p class="help is-danger" *ngIf="errors('phone_mobile')">{{ 'forms.error_required_field' | translate }}</p>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <ncg-consent [parentForm]="form" [align]="'left'"></ncg-consent>
                    </div>
                </div>

                <div class="field" *ngIf="form.contains('terms')">
                    <div class="control checkbox m-0 is-block" *ngIf="configuratorFacade.pageData$ | async as pageData">
                        <label>
                            <input type="checkbox" formControlName="terms" />
                            <span class="checkmark checkmark--top-aligned"></span>
                            <button class="dialog-button consent__text consent__text--default" (click)="open($event, pageData)">
                                {{ 'configurator.form_terms_label' | translate }}
                            </button>
                        </label>
                        <p class="help is-danger" *ngIf="errors('terms')">{{ 'forms.error_required_field' | translate }}</p>
                    </div>
                </div>

                <div class="field mt-6" *ngIf="form.contains('financeType')">
                    <label class="label">{{ 'forms.financetype_interest' | translate }}</label>
                    <div class="control radio m-0 mb-5 is-block">
                        <label>
                            <input type="radio" formControlName="financeType" value="cash" />
                            <span class="checkmark"></span>
                            {{ 'forms.financetype_cash' | translate }}
                        </label>
                    </div>
                    <div class="control radio m-0 mb-5 is-block">
                        <label>
                            <input type="radio" formControlName="financeType" value="loan" />
                            <span class="checkmark"></span>
                            {{ 'forms.financetype_loan' | translate }}
                        </label>
                    </div>
                    <div class="control radio m-0 mb-5 is-block">
                        <label>
                            <input type="radio" formControlName="financeType" value="leasing" />
                            <span class="checkmark"></span>
                            {{ 'forms.financetype_leasing' | translate }}
                        </label>
                    </div>
                </div>

                <div class="notification is-primary" *ngIf="errorState">
                    <ng-container *ngIf="errorState === 'api'; else genericError">{{ 'forms.error_field' | translate }}</ng-container>
                    <ng-template #genericError>{{ 'forms.error_submit' | translate }}</ng-template>
                </div>

                <button class="visuallyhidden">{{ translationBase + 'button_' + type | translate }}</button>
            </form>
        </div>
        <ncg-portal outletId="#footer-portal">
            <ncg-footer-minimal @slideInBottom>
                <div class="footer-buttons">
                    <button class="button is-secondary" type="button" (click)="goBack()">{{ translationBase + 'button_back' | translate }}</button>
                    <!-- Because of the portal, even if the submit is inside form, it won't trigger a submit because it is moved -->
                    <button class="button is-primary" [class.is-loading]="states.processing" (click)="onSubmit()">
                        {{ translationBase + 'button_' + type | translate }}
                    </button>
                </div>
            </ncg-footer-minimal>
        </ncg-portal>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styleUrls: ['./configurator-form.component.scss'],
    imports: [CommonModule, TranslateModule, PortalComponent, FooterMinimalComponent, FormsModule, FormModule, LegalModule, UtilsModule],
})
export class ConfiguratorFormComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private isLocationsDisabled = true;
    public translationBase = 'configurator.form_';
    public form: FormGroup;
    public states = {
        submitted: false,
        processing: false,
    };
    public errorState: '' | 'api' | 'server' = '';
    public category: NcgLocationFormCategory = 'retail';

    @Input() type: ConfiguratorFormType;

    constructor(
        private readonly fb: FormBuilder,
        private readonly formService: FormService,
        private readonly cd: ChangeDetectorRef,
        private readonly metaService: MetaService,
        private readonly translateService: TranslateService,
        private readonly dialogService: DialogService,
        private readonly settingsService: SettingsService,
        private readonly trackingService: TrackingService,
        public readonly configuratorFacade: ConfiguratorFacade
    ) {}

    public ngOnInit() {
        this.form = this.fb.group(
            {
                firstname: ['', Validators.required],
                lastname: ['', Validators.required],
                email: ['', [Validators.email, Validators.required, FormService.emailValidator()]],
                consent: '',
                ...(this.type === 'to_dealer'
                    ? {
                          phone_mobile: ['', [Validators.required, FormService.countryPhoneValidator(''), FormService.numbersOnlyValidator()]],
                          selectedCountryCode: ['', [Validators.required, FormService.countryCodeValidator()]],
                          terms: ['', Validators.required],
                          location: ['', [Validators.required]],
                          financeType: 'cash',
                      }
                    : {}),
            },
            { updateOn: 'blur' }
        );
        // Call settingsService directly here
        this.settingsService
            .get()
            .pipe(first(), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                // Patch the form value inside the settings subscribe block
                this.form?.patchValue({
                    selectedCountryCode: FormService.convertCountryCodeToPhoneCode(settings.seoCountry),
                });
            });
    }

    public handleStepChange(step: ConfiguratorStep): void {
        this.configuratorFacade.dispatch(setStep({ step }));
    }

    public goBack(): void {
        this.configuratorFacade.dispatch(setStep({ step: 'summary' }));
    }

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public errors = (controlName: string) => FormService.errors(controlName, this.form);

    public onLocationState(event: any) {
        this.isLocationsDisabled = event;

        if (!this.isLocationsDisabled && this.form) {
            this.form.disable();
            this.errorState = 'server';
        }
    }

    public onSubmit(): void {
        if (this.states.processing) {
            return;
        }

        this.errorState = '';
        this.cd.markForCheck();

        if (this.form.invalid || this.form.disabled) {
            FormService.markControlsAsTouched(this.form);
            return;
        }

        this.states.processing = true;

        const origin = window.location.host.replace('www.', '');

        const { financeType, firstname, lastname, email, phone_mobile, consent, location } = this.form.value;

        const financeTypeTranslated = this.translateService.instant(`forms.financetype_${financeType}`);

        const { model$, trim$, powertrain$, variant$, exterior$, interior$, optionals$, accessories$, partnerProducts$, price$ } =
            this.configuratorFacade;

        combineLatest([
            model$,
            trim$,
            powertrain$,
            variant$,
            exterior$,
            interior$,
            optionals$,
            accessories$,
            partnerProducts$,
            price$,
            this.settingsService.get(),
        ])
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe(([model, trim, powertrain, variant, exterior, interior, optionals, accessories, partnerProducts, price, settings]) => {
                const { currency, enableLeadGeneration, mediaUrlNCG } = settings;

                // Only send to customer if lead generation is disabled or if the form is the "to_me" form.
                const sendToCustomerOnly = this.type === 'to_me' || !enableLeadGeneration;

                // Get main image for the selected exterior color.
                const exteriorColor = model?.commercialColourOptionsExterior?.[exterior?.id ?? ''];
                const exteriorColorResource = fieldsToResource(exteriorColor?.resourcePackShot?.[0]?.fields);
                const mainImageUrl = exteriorColorResource?.ResourceCdnUrlOriginal
                    ? `${mediaUrlNCG}/${exteriorColorResource?.ResourceCdnUrlOriginal}`
                    : '';

                const payload: IConfigurationDto = {
                    origin,
                    person: {
                        username: email,
                        attributes: {
                            firstname,
                            lastname,
                            email,
                            ...(phone_mobile
                                ? { phone_mobile: `${FormService.convertCountryCodeToPhoneCode(settings.seoCountry)}${phone_mobile}` }
                                : {}),
                        },
                    },

                    consent,
                    context: {
                        sendToCustomerOnly,
                        origin,
                        brand: '', // Added in BFF
                        model: model?.title ?? '',
                        url: `${this.metaService.getAbsoluteUrl()}&finalized=1`, // Add param to allow direct navigation to summary
                        payment: financeTypeTranslated,
                        dealerName: location?.name,
                        totalSellingPrice: price?.total?.priceFormatted ?? '',
                    },
                    storeCode: location?.store_code,
                    commercialTrim: [
                        this.createPayloadDataFromFields<ICommercialTrimDto>(
                            ['commercialTrimMarketingDescription', 'commercialTrimMarketingTitle', 'commercialTrimName'],
                            trim?.fields
                        ),
                    ],

                    images: {
                        key: 'configuratorCarImage',
                        value: mainImageUrl,
                    },
                    powerTrain: [
                        this.createPayloadDataFromFields<IPowerTrainDto>(
                            [
                                'powerTrainCode',
                                'powerTrainEngineType',
                                'powerTrainMarketingDescripton',
                                'powerTrainMarketingTitle',
                                'powerTrainRequiredOptions',
                                'powerTrainTransmissionModel',
                                'powerTrainTransmissionType',
                            ],
                            powertrain?.fields
                        ),
                    ],
                    variant: [
                        this.createPayloadDataFromFields<IVariantDto>(
                            [`variantAnnualOwnershipTax${currency}`, 'variantCode', `variantRetailSellingPrice${currency}`, 'variantTitle'],
                            variant?.fields
                        ),
                    ],
                    commercialColourOptionsExterior: [
                        this.createPayloadDataFromFields<ICommercialColourOptionsExteriorDto>(
                            [
                                'commercialColourOptionColourCode',
                                `commercialColourOptionLeasingSellingPricePrivateMonthly${currency}`,
                                'commercialColourOptionMarketingDescription',
                                'commercialColourOptionMarketingTitle',
                                `commercialColourOptionRetailSellingPrice${currency}`,
                                'commercialColourOptionType',
                            ],
                            exterior?.fields
                        ),
                    ],
                    commercialColourOptionsInterior: [
                        this.createPayloadDataFromFields<ICommercialColourOptionsInteriorDto>(
                            [
                                'commercialColourOptionColourCode',
                                `commercialColourOptionLeasingSellingPricePrivateMonthly${currency}`,
                                'commercialColourOptionMarketingDescription',
                                'commercialColourOptionMarketingTitle',
                                `commercialColourOptionRetailSellingPrice${currency}`,
                                'commercialColourOptionType',
                            ],
                            interior?.fields
                        ),
                    ],
                    optionalOption: optionals.map((option) =>
                        this.createPayloadDataFromFields<IOptionalOptionDto>(
                            [
                                'optionCode',
                                'optionInfoText',
                                'optionMarketingDescription',
                                'optionMarketingTitle',
                                `optionRetailSellingPrice${currency}`,
                                'optionType',
                            ],
                            option?.fields,
                            'multi'
                        )
                    ),
                    partnerProduct: partnerProducts.map((product) =>
                        this.createPayloadDataFromFields<IPartnerProductDto>(
                            [
                                'partnerProductMarketingDescription',
                                'partnerProductMarketingInfoText',
                                'partnerProductMarketingTitle',
                                `partnerProductRetailSellingPrice${currency}`,
                                `partnerProductRetailSellingPriceMonthlyFee${currency}`,
                                `partnerProductRetailSellingPriceYearlyFee${currency}`,
                            ],
                            product?.fields
                        )
                    ),
                    accessory: accessories.map((accessory) =>
                        this.createPayloadDataFromFields<IAccessoryDto>(
                            [
                                'accessoryInfoText',
                                'accessoryItemNumber',
                                'accessoryMarketingDescription',
                                'accessoryMarketingTitle',
                                'accessoryRequiredOptions',
                                `accessoryRetailSellingPrice${currency}`,
                                'accessoryType',
                            ],
                            accessory?.fields
                        )
                    ),
                };

                firstValueFrom(this.formService.submitConfiguration(payload))
                    .then(() => {
                        this.states.processing = false;
                        this.states.submitted = true;
                        this.configuratorFacade.dispatch(setStep({ step: this.type === 'to_dealer' ? 'dealer_receipt' : 'mail_receipt' }));

                        this.cd.markForCheck();
                    })

                    .catch((err: AppHttpErrorResponse) => {
                        this.states.processing = false;
                        this.errorState = 'server';

                        if (err.validationErrors.length) {
                            this.errorState = 'api';
                            FormService.markValidationErrors(err.validationErrors, this.form);
                        }

                        this.cd.markForCheck();
                    });
            });
    }

    private createPayloadDataFromFields<T>(properties: string[], fields?: (DataField | DataListField)[] | null, type: 'single' | 'multi' = 'single') {
        return properties.reduce(
            (prev, curr) => {
                const isMulti = type === 'multi';
                const id = `${curr.charAt(0).toUpperCase()}${curr.slice(1)}`;
                const key = curr.replace(/DKK|SEK|NOK|EUR/g, '');
                const fieldData = (
                    isMulti ? fieldById<DataListField>(fields as DataListField[], id) : fieldById<DataField>(fields as DataField[], id)
                )?.data;
                prev[key as keyof T] = type === 'single' ? (fieldData as Data)?.value : (fieldData as Data[])?.map((data) => data.value)[0];
                return prev;
            },
            {} as { [key in keyof T]: string }
        );
    }

    public open(event: MouseEvent, { termsBody, termsHeading }: IConfiguratorModelPageResponse): void {
        event.preventDefault();
        event.stopPropagation();

        this.dialogService
            .openDialog({
                data: {
                    header: termsHeading,
                    text: termsBody,
                    textIsSafe: true,
                    okBtn: 'dialog.button_accept',
                },
            })
            .then((dialogRef) => {
                if (!dialogRef) {
                    return;
                }
                dialogRef
                    .afterClose()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((confirmed) => {
                        if (confirmed) {
                            this.form.controls['terms'].setValue(confirmed);
                            this.cd.markForCheck();
                        }
                    });
            });
    }
}
