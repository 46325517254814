import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { setTab, softReset } from '../../../+state/configurator/configurator.actions';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { CurrencyFormatterPipe } from '../../../utils/currency.pipe';
import { DataByIdPipe } from '../../utils/data-by-id.pipe';
import { DatasByIdPipe } from '../../utils/datas-by-id.pipe';
import { AddCurrencyPipe } from '../../utils/add-currency.pipe';
import { IsEmptyObjectPipe } from '../../../utils/is-empty-object.pipe';
import { UtilsModule } from '../../../utils/utils.module';
import { configuratorTabs } from '../../configurator-settings';
import { ConfiguratorTab } from '../../configurator';

@Component({
    selector: 'ncg-configurator-summary',
    template: `
        <div class="summary configurator__narrow">
            <h2 class="configurator__headline" [ngClass]="{ 'has-text-left-touch': (configuratorFacade.step$ | async) === 'summary' }">
                {{ 'configurator.summary_heading' | translate }}
            </h2>
            <div class="lines">
                <div class="line">
                    <ng-container [ngTemplateOutlet]="lineHeader" [ngTemplateOutletContext]="{ tabName: 'trim' }"></ng-container>
                    <div class="line-row">
                        <div *ngIf="configuratorFacade.trim$ | async as trim">
                            {{ trim.fields | dataById: 'CommercialTrimName' }}
                        </div>
                        <div *ngIf="configuratorFacade.variant$ | async as variant; else zeroLine">
                            {{ variant.fields | dataById: ('VariantRetailSellingPrice' | addCurrency | async) | currency }}
                        </div>
                    </div>
                </div>

                <div class="line" *ngIf="(configuratorFacade.variant$ | async)?.fields as variantFields">
                    <ng-container [ngTemplateOutlet]="lineHeader" [ngTemplateOutletContext]="{ tabName: 'powertrain' }"></ng-container>
                    <ul *ngIf="(configuratorFacade.powertrain$ | async)?.fields as powertrainFields">
                        <ng-container *ngIf="variantFields | dataById: 'VariantElectricRange' as range">
                            <li class="powertrain-field" *ngIf="variantFields | dataById: 'VariantElectricRangeUnit' as rangeUnit">
                                {{ 'configurator.powertrain_electric_range' | translate: { range: range + ' ' + rangeUnit } }}
                            </li>
                        </ng-container>
                        <li class="powertrain-field" *ngIf="powertrainFields | dataById: 'PowerTrainTransmissionType' as transmission">
                            {{ transmission }}
                        </li>
                        <ng-container *ngIf="powertrainFields | dataById: 'PowerTrainHorsePower' as horserpower">
                            <li class="powertrain-field" *ngIf="powertrainFields | dataById: 'PowerTrainHorsePowerUnit' as horserpowerUnit">
                                {{ horserpower }} {{ horserpowerUnit }}
                            </li>
                        </ng-container>
                        <li class="powertrain-field" *ngIf="powertrainFields | dataById: 'PowerTrainDrivenWheels' as wheels">{{ wheels }}</li>
                        <ng-container *ngIf="variantFields | dataById: 'VariantFuelConsumption' as fuelConsumption">
                            <li class="powertrain-field" *ngIf="variantFields | dataById: 'VariantFuelConsumptionUnit' as fuelConsumptionUnit">
                                {{ fuelConsumption }}{{ fuelConsumptionUnit }}
                            </li>
                        </ng-container>
                        <ng-container *ngIf="variantFields | dataById: 'VariantCo2Emission' as co2">
                            <li class="powertrain-field" *ngIf="variantFields | dataById: 'VariantCo2EmissionUnit' as co2Unit">
                                {{ co2 }}{{ co2Unit }}
                            </li>
                        </ng-container>
                        <li class="powertrain-field" *ngIf="variantFields | dataById: 'VariantAnnuallyOwnershipTax' as yearlyTax">
                            {{ 'configurator.powertrain_yearly-tax' | translate: { tax: yearlyTax } }}
                        </li>
                    </ul>
                </div>

                <div class="line">
                    <ng-container [ngTemplateOutlet]="lineHeader" [ngTemplateOutletContext]="{ tabName: 'exterior' }"></ng-container>
                    <div class="line-row" *ngIf="configuratorFacade.exterior$ | async as exterior; else zeroLine">
                        <span>{{ exterior.fields | dataById: 'CommercialColourOptionMarketingTitle' }}</span>
                        <span>{{ exterior.fields | dataById: ('CommercialColourOptionRetailSellingPrice' | addCurrency | async) | currency }}</span>
                    </div>
                </div>

                <div class="line">
                    <ng-container [ngTemplateOutlet]="lineHeader" [ngTemplateOutletContext]="{ tabName: 'interior' }"></ng-container>
                    <div class="line-row" *ngIf="configuratorFacade.interior$ | async as interior; else zeroLine">
                        <span>{{ interior.fields | dataById: 'CommercialColourOptionMarketingTitle' }}</span>
                        <span>{{ interior.fields | dataById: ('CommercialColourOptionRetailSellingPrice' | addCurrency | async) | currency }}</span>
                    </div>
                </div>

                <div class="line" *ngIf="!((configuratorFacade.model$ | async)?.optionalOptions | isEmptyObject)">
                    <ng-container [ngTemplateOutlet]="lineHeader" [ngTemplateOutletContext]="{ tabName: 'optionals' }"></ng-container>
                    <ng-container *ngIf="configuratorFacade.optionals$ | async as optionals">
                        <ng-container *ngIf="optionals.length; else zeroLine">
                            <div class="line-row" *ngFor="let option of optionals">
                                <span>
                                    <span
                                        *ngFor="let description of option?.fields | datasById: 'OptionMarketingTitle'"
                                        [innerHTML]="description | safe: 'html'"
                                    ></span>
                                </span>
                                {{ (option?.fields | datasById: ('OptionRetailSellingPrice' | addCurrency | async))?.[0] || 0 | currency }}
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <div class="line" *ngIf="!((configuratorFacade.model$ | async)?.accessories | isEmptyObject)">
                    <ng-container [ngTemplateOutlet]="lineHeader" [ngTemplateOutletContext]="{ tabName: 'accessories' }"></ng-container>
                    <ng-container *ngIf="configuratorFacade.accessories$ | async as accessories">
                        <ng-container *ngIf="accessories.length; else zeroLine">
                            <div class="line-row" *ngFor="let accessory of accessories">
                                <span [innerHTML]="accessory?.fields | dataById: 'AccessoryMarketingTitle' | safe: 'html'"></span>
                                {{ accessory?.fields | dataById: ('AccessoryRetailSellingPrice' | addCurrency | async) | currency }}
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <div class="line" *ngIf="!((configuratorFacade.model$ | async)?.partnerProducts | isEmptyObject)">
                    <ng-container [ngTemplateOutlet]="lineHeader" [ngTemplateOutletContext]="{ tabName: 'partner_products' }"></ng-container>
                    <ng-container *ngIf="configuratorFacade.partnerProducts$ | async as partnerProducts">
                        <ng-container *ngIf="partnerProducts.length; else zeroLine">
                            <ng-container *ngFor="let partnerProduct of partnerProducts">
                                <div class="line-row">
                                    <span [innerHTML]="partnerProduct?.fields | dataById: 'PartnerProductMarketingTitle' | safe: 'html'"></span>
                                    <span>{{
                                        (partnerProduct?.fields | dataById: ('PartnerProductRetailSellingPrice' | addCurrency | async)) || 0
                                            | currency
                                    }}</span>
                                </div>
                                <div class="line-row">
                                    <span>
                                        <!-- Unclarified whether we need to change this layout -->
                                    </span>
                                    <span>{{
                                        'configurator.price_per_month'
                                            | translate
                                                : {
                                                      price:
                                                          (partnerProduct?.fields
                                                              | dataById: ('PartnerProductRetailSellingPriceMonthlyFee' | addCurrency | async)) || 0
                                                          | currency
                                                  }
                                    }}</span>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <p class="configurator__headline">
                {{ 'configurator.price_total' | translate }}
                <span class="configurator__text--bold">{{ (configuratorFacade.price$ | async)?.total?.priceFormatted }}</span>
            </p>

            <button class="reset-button" (click)="softReset()">
                {{ 'configurator.reset' | translate }}
                <svg-icon-sprite
                    [width]="'30px'"
                    [height]="'30px'"
                    [src]="'reset'"
                    [viewBox]="'0 0 30 30'"
                    aria-hidden="true"
                    class="icon"
                ></svg-icon-sprite>
            </button>

            <ng-template #lineHeader let-tabName="tabName">
                <div class="line-header">
                    <p class="configurator__sub-headline m-0">{{ translationBase + tabName | translate }}</p>
                    <button class="edit-button" [attr.aria-label]="'configurator.summary_edit' | translate" (click)="setTab(tabName)">
                        <svg-icon-sprite src="edit" [viewBox]="'0 0 30 30'" aria-hidden="true" class="is-flex"></svg-icon-sprite>
                    </button>
                </div>
            </ng-template>

            <ng-template #zeroLine>
                <div class="zero-line">{{ 0 | currency }}</div>
            </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styleUrls: ['./configurator-summary.component.scss'],
    imports: [
        CommonModule,
        CurrencyFormatterPipe,
        TranslateModule,
        RouterModule,
        IconSpriteModule,
        DataByIdPipe,
        DatasByIdPipe,
        CurrencyFormatterPipe,
        AddCurrencyPipe,
        IsEmptyObjectPipe,
        UtilsModule,
    ],
})
export class ConfiguratorSummaryComponent {
    public tabs = configuratorTabs;
    public translationBase = 'configurator.tab_header_';

    @Output() private readonly editClick = new EventEmitter();

    constructor(public readonly configuratorFacade: ConfiguratorFacade) {}

    public setTab(tab: ConfiguratorTab): void {
        if (typeof tab !== 'string') {
            return;
        }
        this.editClick.emit();
        this.configuratorFacade.dispatch(setTab({ tab }));
    }

    public softReset(): void {
        this.configuratorFacade.dispatch(softReset());
    }
}
