import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ILink, IMetaLink, IMetaLinksLocation } from '@ncg/data';

import { SearchDialogService } from '../../search/search-dialog.service';

@Component({
    selector: 'ncg-meta-menu-links',
    template: `
        <div class="navbar" role="navigation" aria-label="meta navigation">
            <div class="navbar-menu is-active is-flex is-shadowless">
                <!-- Show used cars amount -->
                <ncg-meta-menu-cars *ngIf="hasUsedCars" class="is-flex is-hidden-mobile-small"></ncg-meta-menu-cars>

                <!-- Show meta links from Umbraco -->
                <ng-container *ngIf="showMetaLinks">
                    <ng-container *ngFor="let metaLink of metaLinks">
                        <ng-container *ngIf="!metaLink.hideOnDesktop">
                            <ng-container *ngIf="metaLink.link.isExternal; else internalLink">
                                <a class="navbar-item has--icon external" [href]="metaLink.link.url" target="_blank" rel="noopener">
                                    <ng-container *ngTemplateOutlet="linkTmpl; context: { link: metaLink }"></ng-container>
                                    <span aria-hidden="true" class="navbar-item__active-bar"></span>
                                </a>
                            </ng-container>
                            <ng-template #internalLink>
                                <a class="navbar-item has--icon" [routerLink]="metaLink.link.url" routerLinkActive="is-active">
                                    <ng-container *ngTemplateOutlet="linkTmpl; context: { link: metaLink }"></ng-container>
                                    <span aria-hidden="true" class="navbar-item__active-bar"></span>
                                </a>
                            </ng-template>

                            <ng-template #linkTmpl let-link="link">
                                <svg-icon-sprite
                                    [src]="link.iconClass"
                                    [viewBox]="'0 0 30 30'"
                                    [width]="'30px'"
                                    [height]="'30px'"
                                    aria-hidden="true"
                                    [classes]="'navbar-item--icon'"
                                ></svg-icon-sprite>
                                <span class="navbar-item--text">{{ link.name }}</span>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ncg-dealer *ngIf="dealerLink?.url" [dealerLink]="dealerLink" class="dealer" [isPartOfNav]="true"></ncg-dealer>

                <button
                    *ngIf="isSearchEnabled"
                    type="button"
                    aria-label="Opens search dialog on desktop"
                    class="button is-clean navbar-item navbar-item__search has--icon"
                    (click)="openSearchDialog()"
                >
                    <ncg-search-icon [classes]="'navbar-item--icon'"></ncg-search-icon>
                    <span class="navbar-item--text">
                        {{ 'search.button_text' | translate }}
                        <span aria-hidden="true" class="navbar-item__active-bar"></span>
                    </span>
                </button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaMenuLinksComponent implements OnInit {
    @Input() metaLinks?: IMetaLink[] = [];
    @Input() metaLinksLocation?: IMetaLinksLocation[];
    @Input() dealerLink?: ILink;
    @Input() isSearchEnabled = false;
    @Input() hasUsedCars = false;
    showMetaLinks = true;

    constructor(private readonly searchServiceDialog: SearchDialogService) {}

    ngOnInit() {
        // Show metalinks by default, if metaLinksLocation is not defined
        if (this.metaLinksLocation) {
            this.showMetaLinks = this.metaLinksLocation.includes('InHeader');
        }
    }

    openSearchDialog(isMobile = false) {
        this.searchServiceDialog.openSearchDialog(isMobile);
    }
}
