import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IDealerPageResponse, ILink } from '@ncg/data';
import { firstValueFrom } from 'rxjs';
import { MetaService } from '../../core/meta.service';

import { SettingsService } from '../../core/settings.service';
import { LOCATION_QUERY_CATEGORY, LOCATION_QUERY_SELECTOR } from '../../form/location-select.component';

@Component({
    selector: 'ncg-dealer-page',
    template: `
        <div class="dealer-page" *ngIf="data">
            <ncg-breadcrumb [data]="data"></ncg-breadcrumb>
            <ngx-json-ld *ngIf="schema" [json]="schema"></ngx-json-ld>
            <div class="grid__row grid__theme--top-padding" *ngIf="data?.pageBannerImage?.url" ncgLoadIn>
                <ncg-banner-spot [isPageBanner]="true" [image]="data?.pageBannerImage"></ncg-banner-spot>
            </div>
            <div class="content">
                <div class="grid__row grid__theme section grid__theme--default">
                    <div class="container">
                        <div class="columns is-centered" ncgLoadIn>
                            <div
                                class="column is-10-tablet is-12-mobile"
                                [ngClass]="data.retailLocation && data.workshopLocation ? 'is-10-desktop' : 'is-8-desktop'"
                            >
                                <div class="headline-spot">
                                    <ncg-spot-content [useAsHeader]="true" [title]="data.name" [subtitle]="data.subtitle"></ncg-spot-content>
                                </div>

                                <div class="dealer-page__info" *ngIf="data.retailLocation || data.workshopLocation">
                                    <ncg-dealer-info
                                        [location]="data.retailLocation"
                                        [workshop]="data.workshopLocation"
                                        [isCenteredMobile]="true"
                                    ></ncg-dealer-info>
                                </div>
                                <div
                                    class="dealer-page__cta"
                                    *ngIf="(contactLink && data.retailLocation) || (serviceLink && data.workshopLocation?.onlineWorkshopBooking)"
                                >
                                    <div class="columns is-variable is-custom-variable is-3 is-centered has-text-centered-mobile">
                                        <div class="column is-narrow" *ngIf="serviceLink && data.workshopLocation?.onlineWorkshopBooking">
                                            <ncg-button [link]="serviceLink" [queryParams]="serviceParams"></ncg-button>
                                        </div>

                                        <div class="column is-narrow" *ngIf="contactLink && data.retailLocation">
                                            <ncg-button [link]="contactLink" [queryParams]="contactParams"></ncg-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ncg-umb-grid [grid]="data?.grid"></ncg-umb-grid>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealerPageComponent implements OnInit {
    static ref = 'dealerPage';

    @Input() data: IDealerPageResponse;
    contactLink?: ILink;
    serviceLink?: ILink;
    contactParams = {};
    serviceParams = {};
    schema: Record<string, any> = {
        '@context': 'https://schema.org',
        '@type': 'AutomotiveBusiness',
    };

    constructor(
        private readonly settingsService: SettingsService,
        private readonly cd: ChangeDetectorRef,
        private readonly metaService: MetaService
    ) {}

    ngOnInit() {
        this._setJsonLd();
        this._setLinks();
    }

    async _setLinks() {
        const settings$ = this.settingsService.get();
        const settings = await firstValueFrom(settings$);
        this.contactLink = settings.dealershipContactLink?.url ? settings.dealershipContactLink : undefined;
        this.serviceLink = settings.dealershipServiceLink?.url ? settings.dealershipServiceLink : undefined;
        if (this.contactLink && this.data.retailLocation) {
            this.contactParams = {
                [LOCATION_QUERY_SELECTOR]: this.data.retailLocation?.store_code,
                [LOCATION_QUERY_CATEGORY]: this.data.retailLocation?.category?.code,
            };
        }

        if (this.serviceLink && this.data.workshopLocation) {
            this.serviceParams = {
                [LOCATION_QUERY_SELECTOR]: this.data.workshopLocation?.store_code,
                [LOCATION_QUERY_CATEGORY]: this.data.workshopLocation?.category?.code,
            };
        }
        this.cd.markForCheck();
    }

    private _setJsonLd() {
        const currentUrl = this.metaService.getAbsoluteUrl();

        if (currentUrl) {
            this.schema.url = currentUrl;
        }

        if (this.data.pageBannerImage) {
            this.schema.image = [this.data.pageBannerImage.url];
        }
        if (this.data.retailLocation) {
            const location = this.data.retailLocation;
            const address = location.address;

            if (location.name) {
                this.schema.name = location.name;
            }

            if (location.phone) {
                this.schema.telephone = location.phone;
            }

            if (address) {
                this.schema.address = {
                    '@type': 'PostalAddress',
                    'streetAddress': address.street,
                    'addressLocality': address.city,
                    'postalCode': address.zipcode,
                    'addressCountry': address.country,
                };

                this.schema.geo = {
                    '@type': 'GeoCoordinates',
                    'latitude': address.lat,
                    'longitude': address.lng,
                };
            }
        }
    }
}
