import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { FeatureDetectionService } from './feature-detection.service';

@Injectable({
    providedIn: 'root',
})
export class ScriptLoadService {
    constructor(
        private readonly featureDetection: FeatureDetectionService,
        @Inject(DOCUMENT)
        private readonly document: Document
    ) {}

    /**
     * Simple script loader for loading script tags into dom via js.
     * Ideal for loading 3rdParty scripts (tracking, etc.).
     */
    public loadScript(src?: string, options?: LoadScriptOptions, loadOnServer: boolean = false) {
        const { type, callback, async, dataset, id, errorCallback, textContent } = options || {};

        const shouldLoad =
            (loadOnServer && this.featureDetection.isServer()) ||
            (!loadOnServer && this.featureDetection.isBrowser() && !this.featureDetection.isAudit());

        if (shouldLoad) {
            const scriptElement: HTMLScriptElement = this.document.createElement('script');

            if (id) {
                scriptElement.id = id;
            }

            if (type) {
                scriptElement.type = type;
            }

            if (async) {
                scriptElement.async = async;
            }

            if (callback) {
                scriptElement.onload = callback;
            }

            if (errorCallback) {
                scriptElement.onerror = errorCallback;
            }

            if (dataset) {
                Object.entries(dataset).forEach(([key, value]) => {
                    scriptElement.setAttribute(`data-${key}`, `${value}`);
                });
            }

            if (textContent) {
                scriptElement.textContent = textContent;
            }

            if (src) {
                scriptElement.src = src;
            }

            this.document.getElementsByTagName('head')[0].appendChild(scriptElement);
        } else if (this.featureDetection.isBrowser() && callback) {
            callback();
        }
    }

    /**
     * Checks if script is inserted in html.
     * Matches script by start of src i.e. you don't need to supply the full src including querystring.
     */
    public isScriptInserted(src: string) {
        return Boolean(this.getScriptFromHtml(src));
    }

    /**
     * Removes script from html.
     * Matches script by start of src i.e. you don't need to supply the full src including querystring.
     */
    public removeScript(src: string) {
        const script = this.getScriptFromHtml(src);
        if (script) {
            script.remove();
        }
    }

    /**
     * Gets script element from html.
     * Matches script by start of src i.e. you don't need to supply the full src including querystring.
     */
    private getScriptFromHtml(src: string) {
        return this.document.querySelector('script[src^="' + src + '"]');
    }
}

export interface LoadScriptOptions extends Partial<HTMLScriptElement> {
    callback?: () => void;
    errorCallback?: () => void;
}
