import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmbedSpot } from '@ncg/data';
import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-embed-spot',
    template: `<ncg-embed
        [embedUrl]="data.embedUrl"
        [embedCaption]="data.embedCaption"
        [thumbnail]="data.thumbnail"
        [autoplay]="data.autoplay"
    ></ncg-embed>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbedSpotComponent extends SpotBaseDirective {
    static ref = 'embed';
    @Input() data: EmbedSpot;

    constructor() {
        super();
    }
}
