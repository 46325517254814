import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { FeatureDetectionService } from './feature-detection.service';

@Injectable({
    providedIn: 'root',
})
export class ResponseCacheService {
    private readonly memoryCache: Map<string, TransferHttpResponse> = new Map();

    public readonly urlCacheRegexWhitelist: RegExp[] = [
        /api\/settings/i,
        /api\/translations/i,
        /api\/navigation/i,
        /api\/page/i,
        /api\/consent/i,
        /api\/elastic-locations/i,
        /api\/product/i,
    ];

    constructor(
        private readonly transferState: TransferState,
        private readonly featureDetection: FeatureDetectionService
    ) {}

    public get(memoryKey: string, storeKey: StateKey<TransferHttpResponse>): TransferHttpResponse | undefined {
        if (this.transferState.hasKey(storeKey) || this.memoryCache.has(memoryKey)) {
            return this.memoryCache.get(memoryKey) ?? this.transferState.get(storeKey, undefined);
        }
        return undefined;
    }

    public set(storeKey: StateKey<TransferHttpResponse>, memoryKey: string, response: HttpResponse<unknown> | HttpErrorResponse): void {
        const cachedHttpResponse: TransferHttpResponse = {
            body: response instanceof HttpResponse ? response.body : null,
            headers: this.getHeadersMap(response.headers),
            status: response.status,
            statusText: response.statusText,
            url: response.url || '',
        };
        if (this.featureDetection.isServer()) {
            this.transferState.set(storeKey, cachedHttpResponse);
        } else {
            this.memoryCache.set(memoryKey, cachedHttpResponse);
        }
    }

    public invalidate(url: string): void {
        Object.keys(this.transferState['store']).forEach((key) => (key.includes(url) ? this.transferState.remove(makeStateKey(key)) : null));
        this.memoryCache.forEach((value, key) => (key.includes(url) ? this.memoryCache.delete(key) : null));
    }

    public makeCacheKeys(method: string, url: string): [string, StateKey<TransferHttpResponse>] {
        const key = (method === 'GET' ? 'G.' : 'H.') + url;

        return [key, makeStateKey<TransferHttpResponse>(key)];
    }

    private getHeadersMap(headers: HttpHeaders): Record<string, string[]> {
        const headersMap: Record<string, string[]> = {};
        for (const key of headers.keys()) {
            const headerKeys = headers.getAll(key);
            if (headerKeys) {
                headersMap[key] = headerKeys;
            }
        }

        return headersMap;
    }
}

export interface TransferHttpResponse {
    body?: any | null;
    headers?: { [k: string]: string[] };
    status?: number;
    statusText?: string;
    url?: string;
}
