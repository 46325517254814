import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HeadlineSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-headline-spot',
    template: `
        <div class="headline-spot" [class.is-narrow]="allowNarrowContent" *ngIf="data" ncgLoadIn>
            <div class="columns is-centered">
                <div class="column" [class.is-8]="!allowNarrowContent">
                    <ncg-spot-content
                        [useAsHeader]="data.useAsHeader"
                        [background]="data.background"
                        [title]="data.title"
                        [subtitle]="data.subtitle"
                    ></ncg-spot-content>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadlineSpotComponent extends SpotBaseDirective {
    static ref = 'headline';

    @Input()
    data?: HeadlineSpot;
}
