import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    private headerHeight$ = new BehaviorSubject<number>(100);

    public updateModelPageHeader(height: number) {
        this.headerHeight$.next(height);
    }

    public getHeaderEvents(): Observable<number> {
        return this.headerHeight$.asObservable();
    }
}
