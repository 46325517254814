import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { getBuildIdQuery } from '@ncg/data';

import { SpotsConfig } from '../../../spots/spots-config';

@Component({
    selector: 'ncg-mobile-header',
    template: `
        <header class="mobile-menu__header columns is-gapless is-mobile is-vcentered">
            <div class="column is-3 mobile-menu__header__dealer">
                <ncg-dealer [isNameVisible]="true"></ncg-dealer>
            </div>
            <div class="column is-6 has-text-centered" *ngIf="spotsConfig.mobileNavigationLogo">
                <a class="logo" routerLink="/">
                    <span class="is-hidden-desktop">
                        <svg-icon-sprite [src]="'assets/images/sprite.svg' + buildIdQuery + logoName" [viewBox]="'0 0 98 50'"></svg-icon-sprite
                    ></span>
                </a>
            </div>
            <div class="column is-3 has-text-right">
                <button *ngIf="isActiveCloseButton" class="mobile-menu__close" imp-dialog-close>
                    <span class="mobile-menu__close--icon"></span>
                    <span class="mobile-menu__close--text">{{ 'navigation.mobile_close' | translate }}</span>
                </button>
            </div>
        </header>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHeaderComponent {
    public buildIdQuery = getBuildIdQuery();

    @Input()
    public isActiveCloseButton?: boolean;

    @Input()
    public logoName?: string;

    constructor(@Inject(SpotsConfig) public spotsConfig: SpotsConfig) {}
}
