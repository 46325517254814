import { Pipe, PipeTransform } from '@angular/core';
import { IImage } from '@ncg/data';

import { HdAspect } from './helpers/aspect-ratio';
import { IImageOptions, ImageUrl } from './helpers/image-helper';

interface SrcSetPipeOptions {
    retina?: boolean; // use `false` to skip 2x srcset
    clean?: boolean; // use `true` to remove defaults - provide your own imageOptions!
    widths?: number[];
    srcsetMultiplier?: number; // DEPRECATED old `srcsetMultiplier` don't use this
    imageOptions?: IImageOptions;
}

@Pipe({
    name: 'srcset',
})
export class SrcSetPipe implements PipeTransform {
    transform(src: IImage, options?: SrcSetPipeOptions) {
        const { retina = true, clean, widths, srcsetMultiplier = 0, imageOptions = {} } = options || {};

        // if clean is not used, use sensible defaults
        if (!clean) {
            if (imageOptions.height === undefined && imageOptions.heightratio === undefined) {
                imageOptions.heightratio = HdAspect;
            }
            if (imageOptions.mode === undefined) {
                imageOptions.mode = 'crop';
            }
        }

        if (widths) {
            return widths
                .map((width) => {
                    if (retina) {
                        return [
                            `${ImageUrl(src, { ...imageOptions, width })} ${width}w`,
                            `${ImageUrl(src, { ...imageOptions, width: width * 2 })} ${width * 2}w`,
                        ].join(', ');
                    }

                    return `${ImageUrl(src, { ...imageOptions, width })} ${width}w`;
                })
                .join(', ');
        }

        // auto-retina: if width is provided generate a srcset for both 1x and 2x
        if (retina && imageOptions.width) {
            return [
                `${ImageUrl(src, imageOptions)} ${imageOptions.width}w`,
                `${ImageUrl(src, { ...imageOptions, width: imageOptions.width * 2 })} ${imageOptions.width * 2}w`,
            ].join(', ');
        }

        // act like old ImageUrlPipe
        return ImageUrl(src, imageOptions, srcsetMultiplier);
    }
}
