import { Injectable } from '@angular/core';
import { IFacet, IFacetList, IFacets, IFilterParams, IFilterSearchResultViewModel, ILayoutSettings, ISelectedFacets } from '@ncg/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import stringify from 'fast-safe-stringify';
import { combineLatest, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { FeatureDetectionService } from '../../core/feature-detection.service';
import { FilterService } from '../../filter/filter.service';

import { ProductService } from '../../products/product.service';
import * as ProductsActions from './products.actions';
import { isPremiumEnabled } from './products.actions';
import { ProductsFacade } from './products.facade';

/**
 * The list of facets to be shown in the UI
 */
const listData: IFacetList[] = [
    // Make
    { facetKey: 'make.keyword', name: 'Make', type: 'buttons', facets: [], isCollapsed: false, priority: 0 },

    // Series
    { facetKey: 'series.keyword', name: 'Series', type: 'buttons', facets: [], isCollapsed: false, priority: 0 },

    // Model
    { facetKey: 'model.keyword', name: 'Model', type: 'buttons', facets: [], isCollapsed: true, priority: 0 },

    // CUSTOM
    { facetKey: 'only', name: 'Vis kun', type: 'toggles', facetGroups: [], isCollapsed: false, priority: 0 },

    // Custom: payment (isleasing/iscash)
    { facetKey: 'payment', name: 'Payment', type: 'toggles', facetGroups: [], isCollapsed: false, priority: 0 },

    // Body type
    { facetKey: 'bodytypes.keyword', name: 'Body type', type: 'buttons', facets: [], isCollapsed: true, priority: 0 },

    // Year
    { facetKey: 'year', name: 'Year', type: 'range', facets: [], isCollapsed: true, priority: 0 },

    // Propellant
    { facetKey: 'propellant.keyword', name: 'Propellant', type: 'buttons', facets: [], isCollapsed: true, priority: 0 },

    // Is leasing
    { facetKey: 'isleasing', name: 'Leasing only', type: 'toggle', facets: [], isCollapsed: true, priority: 0 },

    // Is Demo / showroom
    { facetKey: 'isdemo', name: 'demo', type: 'toggle', facets: [], isCollapsed: true, priority: 0 },

    // Is used
    { facetKey: 'isdemo', reverse: true, name: 'notdemo', type: 'toggle', facets: [], isCollapsed: true, priority: 0 },

    // Is cash
    { facetKey: 'iscash', name: 'Cash only', type: 'toggle', facets: [], isCollapsed: true, priority: 0 },

    // Is premium
    { facetKey: 'ispremium', name: 'Premium only', type: 'toggle', facets: [], isCollapsed: true, priority: 0 },

    // Price
    { facetKey: 'price', name: 'Price', type: 'range', facets: [], isCollapsed: true, priority: 0 },

    // Geartype
    { facetKey: 'geartype.keyword', name: 'Geartype', type: 'buttons', facets: [], isCollapsed: true, priority: 0 },

    // Effect
    { facetKey: 'effect', name: 'Effect (bhp)', type: 'range', facets: [], isCollapsed: true, priority: 0 },

    // Mileage
    { facetKey: 'mileage', name: 'Mileage (km)', type: 'range', facets: [], isCollapsed: true, priority: 0 },

    // DealerName
    { facetKey: 'dealername.keyword', name: 'Dealership name', type: 'buttons', facets: [], isCollapsed: true, priority: 0 },

    // Is Van (disabled as it is already in the "bodytypes.keyword" facet).
    // { facetKey: 'isvan', name: 'Is van', type: 'toggle', facets: [], isCollapsed: true , sortOrder: 0},

    // Has pictures
    { facetKey: 'haspictures', name: 'With Pictures', type: 'toggle', facets: [], isCollapsed: true, priority: 0 },
];

/**
 * List of approved selected facets, to be shown as facets "tags"
 * E.g. "make.keyword" is not added because on BAG, it should not be able to remove "BMW" make
 */
const selectedFilterWhiteList = [
    'isdemo',
    'model.keyword',
    'series.keyword',
    'bodytypes.keyword',
    'year',
    'propellant.keyword',
    'isleasing',
    'iscash',
    'ispremium',
    'price',
    'geartype.keyword',
    'effect',
    'mileage',
    'dealername.keyword',
    'q',
    'haspictures',
];

@Injectable()
export class ProductsEffects {
    private _isInitialFilter = true;

    loadProductsFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.filterParams),
            switchMap(() => {
                this.productsFacade.dispatch(ProductsActions.clearAllProducts());
                return this.productsFacade.filterParams$.pipe(
                    take(1),
                    switchMap((params) => {
                        const model = {
                            ...params,
                            from: '0',
                            take: (Number(params?.from ?? 0) + Number(params?.take ?? 0)).toString(),
                        };

                        return combineLatest([this.productService.filter(model, true), this.productsFacade.layout$]).pipe(
                            take(1),
                            switchMap(([data, layout]) => [
                                ProductsActions.loadProductsSuccess({ products: data.hits ?? [] }),
                                ...this.updateFilterStates(data, params, layout),
                            ]),
                            catchError((error: any) => of(ProductsActions.loadProductsFailure({ error })))
                        );
                    })
                );
            })
        )
    );

    loadMoreProductsFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.loadMoreProducts),
            switchMap(() =>
                this.productsFacade.filterParams$.pipe(
                    take(1),
                    switchMap((params) =>
                        this.productService.filter(params, true).pipe(
                            take(1),
                            switchMap((data) => [
                                ProductsActions.addMoreProducts({ products: data.hits ?? [] }),
                                ...this.updateFilterStates(data, params),
                            ]),
                            catchError((error: any) => of(ProductsActions.loadProductsFailure({ error })))
                        )
                    )
                )
            )
        )
    );

    /**
     * Get facets to be used in quick filter spot
     */
    loadQuickFilterFacets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.loadQuickFilterFacets),
            switchMap(() =>
                this.productService.filter({ from: '0', take: '0' }).pipe(
                    take(1),
                    switchMap((data) => [ProductsActions.loadQuickFilterfacetsSuccess({ quickFilterfacets: data?.facets })]),
                    catchError(() => of(ProductsActions.loadQuickFilterFacetsFailure({ error: 'Could not fetch facets' })))
                )
            )
        )
    );

    constructor(
        private readonly featureDetection: FeatureDetectionService,
        private readonly actions$: Actions,
        private readonly productService: ProductService,
        private readonly productsFacade: ProductsFacade,
        private readonly filterService: FilterService
    ) {}

    /**
     *
     * @param {IFilterSearchResultViewModel} data The data from the filter response
     * @param {IFilterParams } params The filter query parameters used for the http request
     * @param {IFilterPageLayout } Layout Use to determin if Makes, should be shown as a selected filter facet
     */
    updateFilterStates(data: IFilterSearchResultViewModel, params?: IFilterParams, layout?: ILayoutSettings): TypedAction<string>[] {
        // When the layout is filterVisible, it's okay to show Make, as an selected facets, to be removed
        if (layout?.pageLayout === 'filterVisible') {
            selectedFilterWhiteList.unshift('make.keyword');
        }

        const arr: ISelectedFacets[] = this.getWhiteListedSelectedFilterFacets(params);
        const facetList = this.setFacetOrder(data.facets);
        const actions: TypedAction<string>[] = [];
        actions.push(
            ProductsActions.total({ total: data.total }),
            ProductsActions.facets({ facets: data.facets }),
            ProductsActions.selectedFacets({ selectedFacets: arr }),
            ProductsActions.isFetching({ isFetching: false }),
            ProductsActions.facetList({ facetList })
        );

        // Decide if premium facet should be shown or not (configurable by CMS)
        if (data.facets && layout?.allowPremium) {
            const premiumFacet = Object.entries(data.facets).find(([k]) => k === 'ispremium');
            if (premiumFacet) {
                this.setPremiumState(premiumFacet[1]);
            }
        }

        // Only set premium texts once
        if (this._isInitialFilter) {
            actions.push(
                ProductsActions.premiumText({
                    premiumText: {
                        ...data.premiumText,
                        default: data.premiumTextDefault || '',
                    },
                })
            );
        }

        this._isInitialFilter = false;
        return actions;
    }

    /**
     * Sets the order of the facets and groups them
     *
     * @param {IFacets} facets
     */
    setFacetOrder(facets?: IFacets) {
        if (!facets) {
            return;
        }
        let facetGroups: IFacetList[] = JSON.parse(stringify(listData));

        // Merges "facets" object data into "facetGroups" array.
        for (const key in facets) {
            if (facets.hasOwnProperty(key)) {
                const values = facets[key as keyof IFacets];

                for (const facetGroup of facetGroups) {
                    if (facetGroup.facetKey === key) {
                        facetGroup.facets = values;

                        if (facetGroup.facets && facetGroup.facets.length <= 1) {
                            facetGroup.isCollapsed = true;
                        }
                    }
                }
            }
        }

        // references to custom facet groups
        const only = facetGroups.find((item) => item.facetKey === 'only');
        const payment = facetGroups.find((item) => item.facetKey === 'payment');

        // modify facet data yet aging (merge toggles into custom groups "payment" & "only").
        // + Add sort order from Umbraco in this step as well.
        // + Override isCollapsed with settings from Umbraco.
        facetGroups = facetGroups.filter((item: IFacetList) => {
            if (item.type === 'toggle') {
                // remove toggle types without an alternative option (all are either true or false).
                if (!item.facets || item.facets.length === 1) {
                    return false;
                }

                if (['iscash', 'isleasing'].includes(item.facetKey)) {
                    // move "iscash" & "isleasing" into the new CUSTOM facet "payment".
                    payment?.facetGroups?.push(item);
                    this.updateFacetWithSettings(payment);
                } else {
                    // move remaining "toggle" type facets into the new CUSTOM facet "only".
                    only?.facetGroups?.push(item);
                    this.updateFacetWithSettings(only);
                }

                // remove remaining "moved" facets (i.e. iscash, isleasing & everything that went into "only").
                return false;
            } else {
                this.updateFacetWithSettings(item);
                return true;
            }
        });

        // Do sorting on all filter options (selections under facets).
        facetGroups.forEach((facet) => {
            if (facet.facets) {
                facet.facets.sort(this.sortByPriority);
            }
        });

        // Do sorting on the facets themselves.
        facetGroups.sort(this.sortByPriority);

        return facetGroups;
    }

    setPremiumState(facets: IFacet[]) {
        if (facets.length > 1) {
            this.productsFacade.dispatch(isPremiumEnabled({ isPremiumEnabled: true }));
        } else {
            this.productsFacade.dispatch(isPremiumEnabled({ isPremiumEnabled: false }));
        }
    }

    getWhiteListedSelectedFilterFacets(filterParams?: IFilterParams): ISelectedFacets[] {
        const arr: ISelectedFacets[] = [];
        if (filterParams) {
            const modelCopy: IFilterParams = { ...filterParams };
            for (const key in modelCopy) {
                if (modelCopy.hasOwnProperty(key) && selectedFilterWhiteList.includes(key.toLowerCase())) {
                    if (Array.isArray(modelCopy[key])) {
                        modelCopy[key].forEach((element: string) => {
                            arr.push({
                                key: key.toLowerCase(),
                                value: element,
                            });
                        });
                    } else {
                        arr.push({
                            key: key.toLowerCase(),
                            value: modelCopy[key],
                        });
                    }
                } else {
                    delete modelCopy[key];
                }
            }
        }

        return arr;
    }

    private updateFacetWithSettings(facet?: IFacetList): void {
        if (!facet) {
            return;
        }
        const facetSettings = this.filterService.filterSettings[facet.facetKey];
        if (!facetSettings) {
            facet.priority = 0;
            if (facet.facets) {
                facet.facets.forEach((filter) => {
                    filter.priority = 0;
                });
            }
        } else {
            facet.priority = facetSettings.sort;
            facet.isCollapsed = facetSettings.overrideToState !== undefined ? !facetSettings.overrideToState : facet.isCollapsed;
            if (facet.facets) {
                const filterPriorities = facetSettings.options;
                if (!filterPriorities) {
                    facet.facets.forEach((filter) => {
                        filter.priority = 0;
                    });
                } else {
                    facet.facets.forEach((filter) => {
                        const filterPriority = filterPriorities[filter.key || ''];
                        filter.priority = filterPriority?.sort || 0;
                    });
                }
            }
        }
    }

    private sortByPriority(a: IFacetList | IFacet, b: IFacetList | IFacet) {
        if (a.priority < b.priority) {
            return 1;
        }
        if (b.priority < a.priority) {
            return -1;
        }
        return 0;
    }
}
