import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { CurrencyFormatterPipe } from '../utils/currency.pipe';

@Component({
    selector: 'ncg-color',
    template: `
        <button (click)="colorClick.emit()">
            <span class="color" [ngStyle]="{ background: 'linear-gradient(135deg, ' + color + ' 50%, ' + (secondaryColor || color) + ' 50%)' }">
                <svg-icon-sprite *ngIf="selected" [src]="'checkmark'" [viewBox]="'0 0 30 30'" aria-hidden="true" class="checkmark"></svg-icon-sprite>
            </span>
            <ng-content></ng-content>
        </button>
    `,
    styleUrls: ['./color.component.scss'],
    standalone: true,
    imports: [CommonModule, IconSpriteModule, CurrencyFormatterPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorComponent {
    @Input()
    public selected: boolean;
    @Input()
    public color?: string | number;
    @Input()
    public secondaryColor?: string | number;
    @Output()
    public colorClick = new EventEmitter();
}
