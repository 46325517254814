import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { ConfiguratorRouteSerializer } from '../+state/configurator/configurator-route-serializer';
import { ConfiguratorEffects } from '../+state/configurator/configurator.effects';
import { configuratorReducer } from '../+state/configurator/configurator.reducer';
import { CONFIGURATOR_FEATURE_KEY } from './configurator-settings';
@NgModule({
    imports: [
        StoreModule.forFeature(CONFIGURATOR_FEATURE_KEY, {
            configurator: configuratorReducer,
            router: routerReducer,
        }),
        EffectsModule.forFeature([ConfiguratorEffects]),
        StoreRouterConnectingModule.forRoot({
            serializer: ConfiguratorRouteSerializer,
            navigationActionTiming: 2,
        }),
    ],
})
export class ConfiguratorModule {}
