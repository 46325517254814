import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';

/** Page transition has multiple phases:
 *
 * "none": No transition is active
 *
 * "pre": Pre-transition phase before route is changed
 *
 * "mid": When pre-transition is done, but post-transiton has not been initiated
 *
 * "post": Post-transition phase after route has changed and transition will reveal the page again
 */
export type PageTransitionPhase = 'none' | 'pre' | 'mid' | 'post';

let persistedTransitionDuration = 0;

@Injectable({
    providedIn: 'root',
})
export class PageTransitionService {
    _duration = 1.05;

    set duration(value) {
        this._duration = value;
        persistedTransitionDuration = value;
    }

    get duration() {
        return this._duration;
    }

    get durationIn() {
        return this.duration * 0.4;
    }

    get durationOut() {
        return this.duration * 0.5;
    }

    private _durationMin = 0.65;

    private _phase: PageTransitionPhase = 'none';
    phase$: BehaviorSubject<PageTransitionPhase> = new BehaviorSubject(this._phase);

    get phase(): PageTransitionPhase {
        return this._phase;
    }

    set phase(value: PageTransitionPhase) {
        if (value !== this._phase) {
            this._phase = value;
            this.phase$.next(this._phase);
        }
    }

    private _safariAnimationTimeout: NodeJS.Timer;

    constructor() {
        if (persistedTransitionDuration) {
            this._duration = persistedTransitionDuration;
        }
    }

    startPre(): Observable<PageTransitionPhase> {
        // Incrementally speed up transitions when navigating through site
        // until durationMin is reached
        if (this.duration !== this._durationMin) {
            this.duration = Math.round((this.duration - 0.05) * 100) / 100;
        }

        this.phase = 'pre';

        clearTimeout(this._safariAnimationTimeout);
        this._safariAnimationTimeout = setTimeout(() => {
            this._safariAnimationTimeoutFn();
        }, this.durationIn * 1000);

        return this.phase$.pipe(
            filter((value) => value === 'mid'),
            take(1)
        );
    }

    endPre(): Observable<PageTransitionPhase> {
        if (this.phase === 'pre') {
            this.phase = 'mid';
        }
        return of(this.phase);
    }

    startPost(): Observable<PageTransitionPhase> {
        if (this.phase !== 'none') {
            // Double request animation frame, to ensure DOM has rendered in all browsers
            window.requestAnimationFrame(() => {
                window.requestAnimationFrame(() => {
                    this.phase = 'post';

                    clearTimeout(this._safariAnimationTimeout);
                    this._safariAnimationTimeout = setTimeout(() => {
                        this._safariAnimationTimeoutFn();
                    }, this.durationOut * 1000);
                });
            });

            return this.phase$.pipe(
                filter((value) => value === 'none'),
                take(1)
            );
        }
        return of(this.phase);
    }

    endPost(): Observable<PageTransitionPhase> {
        if (this.phase === 'post') {
            this.phase = 'none';
        }
        return of(this.phase);
    }

    // This handles a safari bug.
    // Animation "done" events do not always fire in safari, so we make sure to also fire it
    // via a timeout to make sure the transition completes and removes itself from the page.
    private _safariAnimationTimeoutFn() {
        // Double rAF to make sure a frame has passed and real animation done events have had
        // enough time to fire if they do not fail to
        window.requestAnimationFrame(() => {
            window.requestAnimationFrame(() => {
                if (this.phase === 'pre') {
                    lastValueFrom(this.endPre()).catch();
                } else if (this.phase === 'post') {
                    lastValueFrom(this.endPost()).catch();
                }
            });
        });
    }
}
