import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getBuildIdQuery } from '@ncg/data';

@Component({
    selector: 'ncg-logos',
    template: `
        <ng-container [ngSwitch]="key">
            <ng-container *ngSwitchCase="'hyundai'">
                <img [src]="'assets/images/logos/hyundai.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidthLg" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'nissan'">
                <img [src]="'assets/images/logos/nissan.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'mazda'">
                <img [src]="'assets/images/logos/mazda.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'fiat'">
                <img [src]="'assets/images/logos/fiat.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'fiat_professional'">
                <img
                    [src]="'assets/images/logos/fiat_professional.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthLg"
                    [alt]="defaultText"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'suzuki'">
                <img [src]="'assets/images/logos/suzuki.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'honda'">
                <img [src]="'assets/images/logos/honda.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'toyota'">
                <img [src]="'assets/images/logos/toyota.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'landrover'">
                <img [src]="'assets/images/logos/landrover.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidthLg" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'jaguar'">
                <img [src]="'assets/images/logos/jaguar.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidthLg" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'vw'">
                <img [src]="'assets/images/logos/volkswagen.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'opel'">
                <img [src]="'assets/images/logos/opel.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidthLg" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'peugeot'">
                <img [src]="'assets/images/logos/peugeot.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidthSm" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'citroen'">
                <img [src]="'assets/images/logos/citroen.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'citroën'">
                <img [src]="'assets/images/logos/citroen.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'mercedes'">
                <img [src]="'assets/images/logos/mercedes.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'renault'">
                <img [src]="'assets/images/logos/renault.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidthSm" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'volvo'">
                <img [src]="'assets/images/logos/volvo.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'alfaromeo'">
                <img [src]="'assets/images/logos/alfa-romeo.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'audi'">
                <img [src]="'assets/images/logos/audi.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidthLg" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'ford'">
                <img [src]="'assets/images/logos/ford.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidthLg" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'kia'">
                <img [src]="'assets/images/logos/kia.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidthLg" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'skoda'">
                <img [src]="'assets/images/logos/skoda.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'dacia'">
                <img [src]="'assets/images/logos/dacia.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'mitsubishi'">
                <img [src]="'assets/images/logos/mitsubishi.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'jeep'">
                <img [src]="'assets/images/logos/jeep.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'seat'">
                <img [src]="'assets/images/logos/seat.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'bmw'">
                <img [src]="'assets/images/logos/bmw.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'mini'">
                <img [src]="'assets/images/logos/mini.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidthXl" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'byd'">
                <img [src]="'assets/images/logos/byd.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidthXl" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'cupra'">
                <img [src]="'assets/images/logos/cupra.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'tesla'">
                <img [src]="'assets/images/logos/tesla.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'mg'">
                <img [src]="'assets/images/logos/mg.svg' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchCase="'maxus'">
                <img [src]="'assets/images/logos/maxus.png' + buildIdQuery" [className]="imageClass" [width]="iconWidth" [alt]="defaultText" />
            </ng-container>
            <ng-container *ngSwitchDefault>{{ defaultText }}</ng-container>
        </ng-container>
        <span class="tabs__amount" *ngIf="count || count === 0">{{ count }}</span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogosComponent {
    buildIdQuery = getBuildIdQuery();

    @Input() count?: number;
    @Input() iconWidthSm = '32';
    @Input() iconWidth = '38';
    @Input() iconWidthLg = '48';
    @Input() iconWidthXl = '64';
    @Input() imageClass = '';
    @Input() get key(): string | undefined | null {
        return this._key;
    }
    set key(val: string | undefined | null) {
        this.defaultText = val ?? '';
        this._key = val?.toLowerCase().replaceAll(' ', '') ?? '';
    }
    private _key = '';
    defaultText = '';
}
