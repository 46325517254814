import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ExpansionPanelModule } from '../expansion-panel/expansion-panel.module';

@Component({
    selector: 'ncg-accordion',
    template: `
        <ncg-expansion-panel ncgLoadIn class="accordion" [expanded]="expanded">
            <div class="accordion-title" expansion-title>
                <ng-content select="[title]"></ng-content>
            </div>
            <ng-container expansion-icon>
                <div class="svg-wrapper">
                    <svg-icon-sprite
                        src="chevron-light-down"
                        [viewBox]="'0 0 30 30'"
                        [width]="'30px'"
                        [height]="'30px'"
                        aria-hidden="true"
                        class="is-flex"
                    ></svg-icon-sprite>
                </div>
            </ng-container>
            <ng-content select="[content]"></ng-content>
        </ncg-expansion-panel>
    `,
    standalone: true,
    styles: [
        `
            .accordion {
                display: block;
                padding-bottom: 0.5rem;
                border-bottom: 1px solid var(--color-border);
            }
            .accordion-title {
                padding: 2rem 0;
            }
            .svg-wrapper {
                color: var(--color-black);
            }
        `,
    ],
    imports: [CommonModule, IconSpriteModule, ExpansionPanelModule],
})
export class AccordionComponent {
    @Input() expanded: boolean;
    title: any;
    content: any;
}
