import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';

import { CommonModule } from '@angular/common';
import { ConfiguratorSummaryComponent } from '../configurator-summary/configurator-summary.component';
import { slideFadeInBottom } from '../../../utils/animations/slide.animations';
import { setStep } from '../../../+state/configurator/configurator.actions';
import { TranslateModule } from '@ngx-translate/core';
import { ConfiguratorStep } from '../../configurator';

@Component({
    selector: 'ncg-configurator-summary-step',
    animations: [slideFadeInBottom],
    template: `<div class="container configurator__container" [@slideFadeInBottom]="{ value: '', params: { distance: '25px' } }">
        <div class="has-text-centered has-text-left-touch configurator__narrow">
            <h1 class="configurator__headline">{{ 'configurator.summary_step_heading' | translate }}</h1>
            <p class="configurator__body-text mt-3">{{ 'configurator.summary_step_body_text' | translate }}</p>
            <div class="buttons mt-5 mb-6">
                <button class="button is-secondary" (click)="handleStepChange('mail')">
                    {{ 'configurator.form_button_to_me' | translate }}
                </button>
                <button class="button is-primary" (click)="handleStepChange('dealer')">
                    {{ 'configurator.form_button_to_dealer' | translate }}
                </button>
            </div>
        </div>
        <ncg-configurator-summary></ncg-configurator-summary>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styleUrls: ['./configurator-summary-step.component.scss'],
    imports: [CommonModule, TranslateModule, ConfiguratorSummaryComponent],
})
export class ConfiguratorSummaryStepComponent {
    constructor(public readonly configuratorFacade: ConfiguratorFacade) {}

    public handleStepChange(step: ConfiguratorStep): void {
        this.configuratorFacade.dispatch(setStep({ step }));
    }
}
