import { trigger, state, style, transition, animate, group, query } from '@angular/animations';

export const dimAnimation = trigger('dim', [
    state(
        'show',
        style({
            opacity: 1,
        })
    ),
    state(
        'hide',
        style({
            opacity: 0,
        })
    ),
    transition('hide => show', animate('0.3s cubic-bezier(0.23, 0, 0.13, 1)')),
    transition('show => hide', animate('1s cubic-bezier(0.23, 0, 0.13, 1)')),
]);

export const overlayAnimation = trigger('overlay', [
    state(
        'none',
        style({
            transform: 'translateY(-100%)',
        })
    ),
    state(
        'post',
        style({
            transform: 'translateY(100%)',
        })
    ),
    state(
        'mid',
        style({
            transform: 'translateY(0%)',
        })
    ),
    transition('none => pre', [
        group([
            style({
                transform: 'translateY(-100%)',
            }),
            animate(
                '{{ durationIn }} cubic-bezier(0.23, 0, 0.13, 1)',
                style({
                    transform: 'translateY(0%)',
                })
            ),
            query('.page-transition__overlay__content', [
                style({
                    transform: 'translateY(100%)',
                }),
                animate(
                    '{{ durationIn }} cubic-bezier(0.23, 0, 0.13, 1)',
                    style({
                        transform: 'translateY(0%)',
                    })
                ),
            ]),
        ]),
    ]),
    transition('mid => post, pre => post', [
        group([
            animate(
                '{{ durationOut }} cubic-bezier(0.23, 0, 0.13, 1)',
                style({
                    transform: 'translateY(100%)',
                })
            ),
            query('.page-transition__overlay__content', [
                animate(
                    '{{ durationOut }} cubic-bezier(0.23, 0, 0.13, 1)',
                    style({
                        transform: 'translateY(-100%)',
                    })
                ),
            ]),
        ]),
    ]),
]);
