import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { INavigationResponse, SortedModelCategories } from '@ncg/data';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LazyContentAliases } from '../../$lazy-content/models/lazy-content.models';
import { FeatureDetectionService } from '../../core/feature-detection.service';
import { SettingsService } from '../../core/settings.service';
import { animateIn, animateOut } from '../../utils/animations/easing.animations';
import { menuBarAnimation } from '../../utils/animations/filter.animation';

@Component({
    selector: 'ncg-mega-models-menu',
    template: `
        <div #megaModelRef *ngIf="children && children?.length" class="mega-models mega-models__container mega-menu__container--animate">
            <div class="mega-models__nav" *ngIf="filterFacets.length > 0">
                <div class="columns is-gapless is-centered">
                    <nav class="mega-models__navbar mega-models__navbar--animate navbar">
                        <div class="navbar-start navbar-filter" #navRef>
                            <button
                                *ngFor="let facet of filterFacets"
                                class="mega-models__filter button is-filter"
                                [ngClass]="{ 'is-active-bar': currentType === facet }"
                                #btnRef
                                (click)="onFilterClick(facet, btnRef, megaModelRef)"
                            >
                                <span>{{ facet }}</span>
                            </button>
                            <div
                                [@menuBar]="{
                                    value: state,
                                    params: {
                                        scaleToPercentage: scaleToPercentage,
                                        scalePercentage: scalePercentage,
                                        fromX: fromX + 'px',
                                        toX: toX + 'px'
                                    }
                                }"
                                #activeBarRef
                                class="active-bar"
                            ></div>
                        </div>
                        <div class="navbar-end level" *ngIf="allModelsUrl">
                            <a class="button is-primary is-small is-narrow" [routerLink]="[allModelsUrl]">{{
                                'header.view_all_models' | translate
                            }}</a>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="mega-models__wrapper" [style.height]="modelsHeight + 'px'">
                <div class="container" #modelsContainerRef>
                    <div class="columns is-gapless is-multiline">
                        <div
                            #modelItem
                            class="column is-one-fifth mega-menu__item mega-menu__item--animate"
                            *ngFor="let child of visibleItems | filter: 'includeInNavigation'; trackBy: trackByMethod"
                        >
                            <a *ngIf="child" class="mega-models__link animate-scale__link" [routerLink]="[child?.url]">
                                <ncg-mega-menu-image
                                    class="mega-models__link--image"
                                    *ngIf="child?.image"
                                    [isModelsMenu]="true"
                                    [image]="child.image"
                                    [title]="child.name"
                                ></ncg-mega-menu-image>
                                <header class="mega-models__header">
                                    <ng-container *ngIf="child.isNew || child.isCampaign">
                                        <ncg-info-label [isCampaign]="child.isCampaign && showCampaignLabels" [isNew]="child.isNew"></ncg-info-label>
                                    </ng-container>
                                    <div class="mega-models__header--title" *ngIf="child?.name">{{ child?.name }}</div>
                                    <div class="mega-models__header--price" *ngIf="child?.price">
                                        {{ 'models.pricefrom' | translate: { price: child?.priceFormatted } }}
                                    </div>
                                </header>
                            </a>
                        </div>
                    </div>
                    <ng-container *ngIf="settings.getMenuSpot() | async as menuSpotData">
                        <ng-template
                            *ngIf="menuSpotData.enableMenuSpot"
                            ncgLazyComponentLoader
                            [data]="menuSpotData"
                            [alias]="menuSpotKey"
                        ></ng-template>
                    </ng-container>
                </div>
            </div>
        </div>
    `,
    animations: [menuBarAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegaModelsMenuComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() children?: INavigationResponse[];
    @Input() allModelsUrl?: string;
    @Input() isOpen?: boolean;
    @ViewChild('navRef') navRef: ElementRef;
    @ViewChild('megaModelRef') megaModelRef: ElementRef<HTMLDivElement>;
    @ViewChildren('btnRef') filterButtonItems: QueryList<ElementRef>;
    @ViewChild('modelsContainerRef') modelsContainerRef: ElementRef<HTMLDivElement>;
    @ViewChild('activeBarRef') activeBarRef: ElementRef;
    @ViewChildren('modelItem') modelItems: QueryList<ElementRef<HTMLDivElement>>;
    menuSpotKey = LazyContentAliases.MenuSpot;
    modelsHeight = 0;
    visibleItems?: INavigationResponse[];
    hasMegaMenuBeenOpen?: boolean;
    filterFacets: string[]; // An array of umbraco tags.
    currentType = '';
    state: 'initial' | 'move' = 'initial';
    prevButton?: HTMLButtonElement;
    toButton: HTMLButtonElement;
    scaleToPercentage = 0; // Size of the active button in percentage
    scalePercentage = 0; // The scaling size between active and previous button
    fromX = 0;
    toX = 0;
    direction: 'leftToRight' | 'rightToLeft' = 'leftToRight';
    showCampaignLabels = true;
    resizeObserver?: ResizeObserver;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly featureDetectionService: FeatureDetectionService,
        public readonly settings: SettingsService
    ) {}

    ngOnInit() {
        if (this.children?.length) {
            this.filterFacets = SortedModelCategories(this.children);
            this.currentType = this.filterFacets[0];
            this.visibleItems = this.children;
        }

        this.settings
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((setting) => {
                this.showCampaignLabels = setting.showCampaignLabels;
                this.cd.markForCheck();
            });
    }

    ngAfterViewInit(): void {
        if (this.featureDetectionService.isBrowser()) {
            this.initializeFilterButton();
            this.animateHeightObserver();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Only used to calculate the initial filter button width,
        // when going from mobile view to desktop view. Due to the display none property on main menu, on mobile
        if (!this.hasMegaMenuBeenOpen && changes.isOpen.currentValue) {
            this.hasMegaMenuBeenOpen = true;
            this.initializeFilterButton();
        }
    }

    initializeFilterButton() {
        const firstButton = this.filterButtonItems?.first;
        if (firstButton?.nativeElement) {
            this.toButton = firstButton.nativeElement as HTMLButtonElement;
            this.onFilterClick(this.currentType, this.toButton, undefined, true);
        }
    }

    async onFilterClick(type: string, btnElement: HTMLButtonElement, element?: HTMLElement, preventAnimation = false) {
        if (this.featureDetectionService.hasWebAnimation() && !preventAnimation) {
            await animateOut(this.modelItems.toArray());
            this.visibleItems = [];
            this.cd.detectChanges();
        }

        this.currentType = type;

        const arr = [];
        if (this.children) {
            for (const model of this.children) {
                const foundItem = model.modelCategory?.find((category) => category.name === this.currentType);
                if (foundItem) {
                    arr.push(model);
                }
            }
        }

        this.visibleItems = arr;

        if (this.navRef && btnElement) {
            this.prevButton = this.toButton;
            this.toButton = btnElement;
            this.animateActiveBar();
        }

        if (this.featureDetectionService.hasWebAnimation() && !preventAnimation) {
            this.cd.detectChanges();
            await animateIn(this.modelItems.toArray());
        }
        this.cd.markForCheck();
    }

    animateActiveBar() {
        const nav: HTMLElement = this.navRef.nativeElement;
        if (nav && this.activeBarRef.nativeElement) {
            const btnStyle = window.getComputedStyle(this.toButton);
            const btnPl = btnStyle.paddingLeft ? Number(btnStyle.paddingLeft.replace('px', '')) : 0;
            const btnPr = btnStyle.paddingRight ? Number(btnStyle.paddingRight.replace('px', '')) : 0;
            const padding = btnPl + btnPr;
            const width = this.toButton.clientWidth - padding;
            this.toX = this.toButton.offsetLeft + btnPl;
            this.scaleToPercentage = width / nav.offsetWidth;

            if (this.prevButton) {
                const prevBtnStyle = window.getComputedStyle(this.prevButton);
                const prevBtnPl = prevBtnStyle.paddingLeft ? Number(prevBtnStyle.paddingLeft.replace('px', '')) : 0;
                const prevBtnPr = prevBtnStyle.paddingRight ? Number(prevBtnStyle.paddingRight.replace('px', '')) : 0;
                const prevPadding = prevBtnPl + prevBtnPr;
                const prevWidth = this.prevButton.clientWidth - prevPadding;
                this.direction = this.prevButton.offsetLeft < this.toButton.offsetLeft ? 'leftToRight' : 'rightToLeft';

                if (this.direction === 'leftToRight') {
                    this.fromX = this.prevButton.offsetLeft + prevBtnPl;
                    this.scalePercentage = (this.toButton.offsetLeft + btnPl + width - (this.prevButton.offsetLeft + prevBtnPl)) / nav.offsetWidth;
                } else {
                    this.fromX = this.toButton.offsetLeft + btnPl;
                    this.scalePercentage =
                        (this.prevButton.offsetLeft + prevBtnPl + prevWidth - (this.toButton.offsetLeft + btnPl)) / nav.offsetWidth;
                }
            } else {
                this.fromX = 0;
                this.scalePercentage = this.toX / nav.offsetWidth + this.scaleToPercentage;
            }

            this.cd.markForCheck();
            this.state = this.state === 'initial' ? 'move' : 'initial';
        }
    }

    animateHeightObserver() {
        if (!this.featureDetectionService.supportsResizeObserver()) {
            return;
        }

        const targetNode = this.modelsContainerRef.nativeElement;

        this.resizeObserver = new ResizeObserver((entries) => {
            if (entries[0]) {
                const { height } = entries[0].contentRect;
                this.modelsHeight = Math.round(height);
                this.cd.detectChanges();
            }
        });

        this.resizeObserver.observe(targetNode);
    }

    trackByMethod(index: number, item: any) {
        return item.id || index;
    }

    ngOnDestroy() {
        this.resizeObserver?.disconnect();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
