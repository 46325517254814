import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IFacet, IFacetList, IFilterPageLayout } from '@ncg/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { filterParams } from '../../+state/products/products.actions';
import { ProductsFacade } from '../../+state/products/products.facade';
import { showAllAnimation } from '../../expansion-panel/expansion.animation';
import { TrackingService } from '../../core/tracking.service';

@Component({
    selector: 'ncg-filter-facet',
    template: `
        <div class="filter-facet">
            <div class="columns is-variable is-3 is-multiline is-mobile" [@showAllAnimation]="expandedState">
                <div class="column" [class]="columnView" *ngFor="let facet of list; trackBy: trackByFn">
                    <button
                        type="button"
                        class="button is-full is-border is-full-height"
                        [ngClass]="{
                            'is-border--active': facet.selected,
                            'is-clean is-paddingless filter-facets__button': facetGroup.facetKey === 'make.keyword',
                            'is-fetching': currentFacet?.key === facet.key && isFetching
                        }"
                        (click)="updateFilterQuery(facet)"
                        [ngSwitch]="facetGroup.facetKey"
                    >
                        <div *ngSwitchCase="'make.keyword'" class="filter-facets__tab filter-facets__tab--make">
                            <ncg-logos
                                [imageClass]="
                                    pageLayout === 'filterVisible' && !facet.selected ? 'filter-facet__image--grey' : 'filter-facet__image--normal'
                                "
                                [key]="facet.key"
                            ></ncg-logos>
                            <span>
                                <span *ngIf="(facet.key | lowercase) !== 'andre mærker'">{{ facet.key }}&nbsp;</span>
                                <span class="has-text-grey-dark">({{ facet.count }})</span>
                            </span>
                        </div>
                        <div *ngSwitchDefault class="has-text-centered">
                            <ng-container [ngSwitch]="facetGroup.facetKey | lowercase">
                                <div *ngSwitchCase="'bodytypes.keyword'">
                                    <ncg-filter-type-icons [facet]="facet"></ncg-filter-type-icons>
                                </div>
                                <div *ngSwitchCase="'series.keyword'">
                                    <span>{{ 'filter_spot.label_facet_series' | translate }} {{ facet.key }}</span>
                                    <span class="has-text-grey-dark">&nbsp;({{ facet.count }})</span>
                                </div>
                                <div *ngSwitchDefault>
                                    <span>{{ facet.key }}</span>
                                    <span class="has-text-grey-dark">&nbsp;({{ facet.count }})</span>
                                </div>
                            </ng-container>
                        </div>
                    </button>
                </div>
                <div *ngIf="showMoreButton" class="column is-full">
                    <button type="button" class="button is-link filter-facet__show-all" aria-label="show all facets" (click)="showAllFacets()">
                        {{ 'filter_sidepanel.show_all_facets' | translate }}
                    </button>
                </div>
            </div>
        </div>
    `,
    animations: [showAllAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFacetComponent implements OnInit, OnDestroy {
    @Input() set facetData(val: IFacetList) {
        this.list = val.facets ?? [];
        this.facetGroup = val;
        this.columnView =
            this.facetGroup.facetKey === 'make.keyword' && this.facetGroup.facets?.length === 3
                ? 'is-one-third'
                : this.facetGroup.facetKey === 'dealername.keyword'
                ? 'is-full'
                : 'is-half';
        this.setAmountItemsShown();
    }
    @Input() isMakeMultiSelect = true;
    currentFacet?: IFacet | null;
    columnView: string;
    facetGroup: IFacetList;
    list: IFacet[];
    maxFacetsShown = 6;
    expanded = false;
    expandedState?: 'expanded' | 'closed' = 'closed';
    showMoreButton = true;
    pageLayout?: IFilterPageLayout;
    logoClass = '';
    isFetching = false;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly productsFacade: ProductsFacade,
        private readonly cd: ChangeDetectorRef,
        private readonly trackingService: TrackingService
    ) {}

    ngOnInit() {
        this.getLayout();
        this.getIsFetching();
    }

    setAmountItemsShown() {
        if (!this.showMoreButton) {
            return;
        }

        let selectedIndex = 0;
        this.list.forEach((item, index) => {
            if (item.selected) {
                selectedIndex = index;
            }
        });

        if (this.facetGroup.facetKey === 'make.keyword') {
            this.maxFacetsShown = 8;
        }

        if (selectedIndex >= this.maxFacetsShown) {
            const sliceAmount = selectedIndex % 2 === 0 ? selectedIndex + 2 : selectedIndex + 1;
            this.list = this.list.slice(0, sliceAmount);
            this.showMoreButton = true;
        } else if (this.list.length > this.maxFacetsShown) {
            this.list = this.list.slice(0, this.maxFacetsShown);
            this.showMoreButton = true;
        } else {
            this.showMoreButton = false;
        }
    }

    updateFilterQuery(facet?: IFacet | null) {
        this.currentFacet = facet;
        this.cd.markForCheck();
        if (this.facetGroup.facetKey && facet?.key) {
            let filterModel = {};

            if (this.facetGroup.facetKey === 'make.keyword' && !this.isMakeMultiSelect) {
                filterModel = {
                    filterParams: {
                        from: '0',
                        take: '24',
                        ['make.keyword']: [facet.key],
                    },
                    resetKey: true,
                    resetFilter: true,
                };
            } else {
                const shouldResetKey = this.facetGroup.facetKey === 'make.keyword' && this.isMakeMultiSelect === false;
                const resetValue = facet.selected ? undefined : facet.key;
                filterModel = {
                    filterParams: { [this.facetGroup.facetKey]: shouldResetKey ? resetValue : [facet.key] },
                    resetKey: shouldResetKey,
                };
            }

            filterModel = this.clearModels(filterModel);
            this.productsFacade.dispatch(filterParams(filterModel));

            if (!facet.selected) {
                // track only selected facet
                this.trackingService.trackFilter(facet?.key, this.facetGroup.facetKey);
            }
        }
    }

    /**
     * Special rule: When a make is clicked, then it should reset the selected models
     */
    clearModels(filterModel: Record<string, any>) {
        const data = filterModel;

        if (this.facetGroup.facetKey === 'make.keyword') {
            data.filterParams = {
                ...data.filterParams,
                ['model.keyword']: [],
            };
        }

        return data;
    }

    getLayout() {
        this.productsFacade.layout$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.isMakeMultiSelect = data?.multiSelectMakes ?? false;
            this.pageLayout = data?.pageLayout;
        });
    }

    getIsFetching() {
        this.productsFacade.isFetching$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.isFetching = data;
            if (!this.isFetching) {
                this.currentFacet = undefined;
            }
            this.cd.markForCheck();
        });
    }

    showAllFacets() {
        this.showMoreButton = false;
        this.cd.detectChanges();
        this.list = this.facetGroup.facets ?? [];
        this.maxFacetsShown = this.facetGroup.facets?.length ?? this.maxFacetsShown;
        this.expanded = !this.expanded;
        this.expandedState = this.expanded ? 'expanded' : 'closed';
        this.cd.markForCheck();
    }

    trackByFn(index: number, item: any) {
        return item.key || index;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
