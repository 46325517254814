import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class DefaultRouteReuseStrategy implements RouteReuseStrategy {
    shouldDetach(): boolean {
        return false;
    }

    store(): void {}

    shouldAttach(): boolean {
        return false;
    }

    retrieve(): DetachedRouteHandle | null {
        return null;
    }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }
}
