import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SettingsService } from '../core/settings.service';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'ncg-footer-minimal',
    template: `
        <footer class="container" [ngClass]="{ 'grid__theme--dark': (settingsService.settings$ | async)?.brandId === 'byd' }">
            <ng-content></ng-content>
        </footer>
    `,
    styleUrls: ['./footer-minimal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule],
    standalone: true,
})
export class FooterMinimalComponent {
    constructor(public readonly settingsService: SettingsService) {}
}
