import { IImage } from '@ncg/data';

/**
 * Interface for Umbraco resize options
 *
 * @link http://imageprocessor.org/imageprocessor-web/imageprocessingmodule/resize/
 */
export interface IImageOptions {
    /**
     * Width of new image in pixels
     */
    width?: number;

    /**
     * Suggested width ratio for resizing
     * Umbraco 13: We convert to width=(height * widthratio) instead.
     */
    widthratio?: number;

    /**
     * Height of new image in pixels
     */
    height?: number;

    /**
     * Suggested height ratio for resizing
     * Umbraco 13: We convert to height=(width * heightratio) instead.
     */
    heightratio?: number;

    /**
     * Hexadecimal string value of background color to use for box padding
     */
    bgcolor?: string;

    /**
     * Fraction to crop as close to as possible
     *
     * Value: x,y
     * Example: 0.25,0.3
     */
    rxy?: string;

    /**
     * Mode of cropping, 'pad' is default in Umbraco
     */
    mode?: 'pad' | 'boxpad' | 'crop' | 'min' | 'max' | 'stretch';

    /**
     * Quality of new image in percent
     *
     * Example: "25" - 25 % quality
     * Default: "100"
     */
    quality?: string;
}

export const ImageUrl = (src: IImage, options?: IImageOptions, srcsetMultiplier = 0): string => {
    const settings: IImageOptions = options || {};

    if (!src.url) {
        return '';
    }

    if (src.focalPoint !== null && src.focalPoint?.top !== 0.5 && src.focalPoint?.left !== 0.5 && settings?.mode === 'crop') {
        settings.rxy = `${src.focalPoint.left},${src.focalPoint.top}`;
    }

    // Upscale prevention - only handle width/heightratio combination atm.
    if (src.width && settings.width && settings.heightratio && settings.width > src.width) {
        settings.width = src.width;
    }

    // After Umbraco 13 migration the image params have changed:
    // Reference: https://github.com/SixLabors/ImageSharp.Web/blob/main/src/ImageSharp.Web/Processors/ResizeWebProcessor.cs
    // "heightratio" does nothing (calculate a height instead).
    // "widthratio" does nothing (calculate a width instead).
    if (settings.heightratio && settings.width) {
        settings.height = Math.round(settings.width * settings.heightratio);
        delete settings.heightratio;
    }
    if (settings.widthratio && settings.height) {
        settings.width = Math.round(settings.height * settings.widthratio);
        delete settings.widthratio;
    }

    let queryParams = Object.entries(settings)
        .sort(([key1], [key2]) => key1.localeCompare(key2)) // Sort entries by key
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

    if (queryParams) {
        queryParams = `?${queryParams}`;
    }

    if (srcsetMultiplier) {
        return [...Array(srcsetMultiplier)]
            .map((_, i) => getSrcset(src, settings || {}, i + 1))
            .filter((x) => x)
            .join(', ');
    }

    return `${encodeURI(src.url)}${queryParams}`;
};

const getSrcset = (src: IImage, options: IImageOptions, multiplier: number): string => {
    const settings = {
        ...options,
        ...{
            ...(options.width ? { width: options.width * multiplier } : {}),
            ...(options.height ? { height: options.height * multiplier } : {}),
        },
    };

    // Upscale prevention - only handle width/heightratio combination atm.
    if (src.width && settings.width && settings.heightratio && settings.width > src.width) {
        settings.width = src.width;

        if (multiplier > 1) {
            return '';
        }
    }

    return `${ImageUrl(src, settings)} ${multiplier}x`;
};
