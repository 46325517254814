import { Component, Input } from '@angular/core';
import { BookServiceFormSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-book-service-form-spot',
    template: `
        <div class="columns is-centered" ncgLoadIn>
            <ncg-book-service-form *ngIf="data" class="column" [class.is-8]="!allowNarrowContent" [data]="data"></ncg-book-service-form>
        </div>
    `,
})
export class BookServiceFormSpotComponent extends SpotBaseDirective {
    static ref = 'bookserviceform';

    @Input() data: BookServiceFormSpot;
}
