import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IFacetList } from '@ncg/data';

@Component({
    selector: 'ncg-filter-facet-toggle',
    template: `
        <div class="filter-facet" *ngIf="list">
            <div class="columns is-variable is-3 is-multiline is-mobile">
                <div class="column is-half" *ngFor="let facet of list; trackBy: trackByFn">
                    <ncg-filter-facet-toggle-item [facetGroup]="facet"></ncg-filter-facet-toggle-item>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFacetToggleComponent {
    @Input() facetGroup?: IFacetList;

    get list(): IFacetList[] | undefined {
        return (this.facetGroup?.facetGroups as IFacetList[]) ?? undefined;
    }

    trackByFn(index: number, item: any) {
        return item.key || index;
    }
}
