import { AccordionSpotComponent } from './accordion-spot/accordion-spot.component';
import { AnchorNavigationSpotComponent } from './anchor-navigation-spot/anchor-navigation-spot.component';
import { AnchorSpotComponent } from './anchor-spot/anchor-spot.component';
import { BannerSpotComponent } from './banner-spot/banner-spot.component';
import { BookServiceFormSpotComponent } from './book-service-form/book-service-form-spot.component';
import { BydHeroSpotComponent } from './byd-hero-spot/byd-hero-spot.component';
import { CampaignsSpotComponent } from './campaigns-spot/campaigns-spot.component';
import { CarouselSpotComponent } from './carousel-spot/carousel-spot.component';
import { ContactFormSpotComponent } from './contact-form-spot/contact-form-spot.component';
import { CTASpotComponent } from './cta-spot/cta-spot.component';
import { DocModifiedSpotComponent } from './doc-modified-spot/doc-modified-spot.component';
import { DualImageSpotComponent } from './dual-image-spot/dual-image-spot.component';
import { EmbedSpotComponent } from './embed-spot/embed-spot.component';
import { EquipmentPackagesSpotComponent } from './equipment-packages-spot/equipment-packages.component';
import { ExtendedHeroSpotComponent } from './extended-hero-spot/extended-hero-spot.component';
import { FeaturedCarsSpotComponent } from './featured-cars-spot/featured-cars-spot.component';
import { FilterSpotComponent } from './filter-spot/filter-spot.component';
import { GallerySpotComponent } from './gallery-spot/gallery-spot.component';
import { HeadlineSpotComponent } from './headline-spot/headline-spot.component';
import { HeroSliderSpotComponent } from './hero-slider-spot/hero-slider-spot.component';
import { HeroSpotComponent } from './hero-spot/hero-spot.component';
import { ImageTextPickerSpotComponent } from './image-text-picker-spot/image-text-picker-spot.component';
import { LinkImageSpotComponent } from './link-image-spot/link-image-spot.component';
import { MapSpotComponent } from './map-spot/map-spot.component';
import { ModelPageNavigationSpotComponent } from './model-page-navigation-spot/model-page-navigation-spot.component';
import { ModelPricesSpotTableComponent } from './model-prices-spot/model-prices-spot-table.component';
import { ModelPricesComponent } from './model-prices-spot/model-prices-spot.component';
import { ModelViewerSpotComponent } from './model-viewer-spot/model-viewer-spot.component';
import { ModelsSpotComponent } from './models-spot/models-spot.component';
import { MotorsizeComponent } from './motorsize-spot/motorsize-spot.component';
import { NewsletterSpotComponent } from './newsletter-spot/newsletter-spot.component';
import { OfferFormSpotComponent } from './offer-form-spot/offer-form-spot.component';
import { RecentlyViewedCarsSpotComponent } from './recently-viewed-cars-spot/recently-viewed-cars-spot.component';
import { RichTextSpotComponent } from './richtext-spot/rich-text-spot.component';
import { ScriptSpotComponent } from './script-spot/script-spot.component';
import { SpecificationsComponent } from './specifications-spot/specifications-spot.component';
import { SubnavigationSpotComponent } from './subnavigation-spot/subnavigation-spot.component';
import { TabbedContentSpotComponent } from './tabbed-content-spot/tabbed-content-spot.component';
import { TabsSpotComponent } from './tabs-spot/tabs-spot.component';
import { TestDriveFormSpotComponent } from './test-drive-form-spot/test-drive-form-spot.component';
import { TimelineSpotComponent } from './timeline-spot/timeline-spot.component';
import { UnsubscribeNewsletterSpotComponent } from './unsubscribe-newsletter-spot/unsubscribe-newsletter-spot.component';
import { VideoSpotComponent } from './video-spot/video-spot.component';

export const spotComponents = [
    AccordionSpotComponent,
    AnchorNavigationSpotComponent,
    AnchorSpotComponent,
    BannerSpotComponent,
    BookServiceFormSpotComponent,
    BydHeroSpotComponent,
    CTASpotComponent,
    CampaignsSpotComponent,
    CarouselSpotComponent,
    ContactFormSpotComponent,
    DocModifiedSpotComponent,
    DualImageSpotComponent,
    EmbedSpotComponent,
    EquipmentPackagesSpotComponent,
    ExtendedHeroSpotComponent,
    FeaturedCarsSpotComponent,
    FilterSpotComponent,
    GallerySpotComponent,
    HeadlineSpotComponent,
    HeroSliderSpotComponent,
    HeroSpotComponent,
    ImageTextPickerSpotComponent,
    LinkImageSpotComponent,
    MapSpotComponent,
    ModelPageNavigationSpotComponent,
    ModelPricesComponent,
    ModelPricesSpotTableComponent,
    ModelViewerSpotComponent,
    ModelsSpotComponent,
    MotorsizeComponent,
    NewsletterSpotComponent,
    OfferFormSpotComponent,
    RecentlyViewedCarsSpotComponent,
    RichTextSpotComponent,
    ScriptSpotComponent,
    SpecificationsComponent,
    SubnavigationSpotComponent,
    TabbedContentSpotComponent,
    TabsSpotComponent,
    TestDriveFormSpotComponent,
    TimelineSpotComponent,
    UnsubscribeNewsletterSpotComponent,
    VideoSpotComponent,
];
