export const currencyFormatter = (currencyCode: string): Intl.NumberFormat => {
    switch (currencyCode) {
        case 'EUR':
            return new Intl.NumberFormat('de-DE', {
                currency: currencyCode,
                style: 'currency',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            });
        case 'SEK':
            return new Intl.NumberFormat('sv-SE', {
                currency: currencyCode,
                style: 'currency',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            });

        case 'NOK':
            return new Intl.NumberFormat('nb-NO', {
                currency: currencyCode,
                style: 'currency',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            });

        case 'DKK':
        default:
            return new Intl.NumberFormat('da-DK', {
                currency: currencyCode,
                style: 'currency',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            });
    }
};

export const formatWithCurrency = (value: number, currencyCode: string): string => {
    const formatter = currencyFormatter(currencyCode);
    return formatter.format(value);
};

export const getCurrencyForCulture = (culture: string): string => {
    switch (culture) {
        case 'et-EE':
        case 'lv-LV':
        case 'lt-LT':
        case 'fi-FI':
            return 'EUR';
        case 'sv-SE':
            return 'SEK';
        case 'no-NO':
            return 'NOK';
        case 'da-DK':
        default:
            return 'DKK';
    }
};
