import { Location } from '@angular/common';
import { ChangeDetectorRef, ComponentRef, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { LazyContentAliases, LazyContentData, LazyContentGenericComponent } from '../models/lazy-content.models';
import { getLazyComponent } from '../utils/lazy-content.util';

/**
 * Example:
 *
 * Simple usage without generating additional markup.
 *
 * <ng-template ncgLazyComponentLoader [data]="spotData"></ng-template>
 *
 * Usage with optional alias specification (if alias is already in data, then this is not needed)
 *
 * <ng-template ncgLazyComponentLoader [data]="spotData" [alias]="'lazySpotAlias'"></ng-template>
 */
@Directive({
    selector: '[ncgLazyComponentLoader]',
})
export class LazyComponentLoaderDirective implements OnInit {
    @Input()
    public data?: LazyContentData<unknown>;

    @Input()
    public alias?: LazyContentAliases;

    constructor(
        private readonly viewContainerRef: ViewContainerRef,
        private readonly location: Location,
        private readonly cd: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        const alias = this.alias || this.data?.alias;
        if (!alias) {
            return console.error('Lazy Component Error', `No alias for component on "${this.location.path()}"`);
        }
        this.insertDynamic(alias, this.data).catch((error) => console.error('Content Insert Error', error));
    }

    public async insertDynamic(alias: string, data?: LazyContentData<unknown>): Promise<void> {
        const component = await getLazyComponent(alias);
        if (!component) {
            return console.error('Lazy Component Error', `No matching component found for "${alias}" on "${this.location.path()}"`);
        }
        if (this.viewContainerRef) {
            const componentRef: ComponentRef<LazyContentGenericComponent> = this.viewContainerRef.createComponent(component as any);
            if (data) {
                componentRef.instance.data = data;
            }
            this.cd.markForCheck();
        }
    }
}
