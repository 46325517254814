import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FeatureDetectionService } from '../core/feature-detection.service';
import { ScrollStatusService } from '../core/scroll-status.service';

import { ScrollService } from '../core/scroll.service';

@Component({
    selector: 'ncg-up',
    template: `
        <button
            type="button"
            class="ncg-up"
            [ngClass]="{
                'ncg-up--hidden': (scrollStatusService.isScrollingDown$ | async) || ((scrollStatusService.scrollFraction$ | async) || 0) < 1,
                'ncg-up--in-corner': !isMobile
            }"
            title="Up"
            (click)="onClick()"
        >
            <svg-icon-sprite
                src="chevron-light-down"
                [viewBox]="'0 0 30 30'"
                [width]="'50px'"
                [height]="'50px'"
                classes="chevron--up"
            ></svg-icon-sprite>
        </button>
    `,
    styleUrls: ['./up.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpComponent implements OnInit, OnDestroy {
    private readonly isMobileMq = this.features.isBrowser() ? window.matchMedia('(max-width: 768px)') : undefined;
    public isMobile = false;
    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly features: FeatureDetectionService,
        private readonly scrollService: ScrollService,
        public readonly scrollStatusService: ScrollStatusService
    ) {}

    public ngOnDestroy() {
        this.isMobileMq?.removeEventListener('change', this.toggleEnabled);
    }

    public ngOnInit() {
        // Window size watcher
        if (this.isMobileMq) {
            this.toggleEnabled();
            this.isMobileMq.addEventListener('change', this.toggleEnabled);
        }
    }

    public onClick() {
        this.scrollService.scrollToPosition({ top: 0 });
    }

    private toggleEnabled = () => {
        this.isMobile = this.isMobileMq?.matches ?? false;
        this.cd.markForCheck();
    };
}
