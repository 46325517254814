import { Component, Input } from '@angular/core';
import { SubNavigationSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-subnavigation-spot',
    template: `
        <div class="subnavigation">
            <h1 class="subnavigation__header">
                {{ data.pageTitle }}
            </h1>

            <div class="subnavigation__grid">
                <ncg-subnavigation-item [item]="item" *ngFor="let item of data.items"></ncg-subnavigation-item>
            </div>
        </div>
    `,
})
export class SubnavigationSpotComponent extends SpotBaseDirective {
    static ref = 'subpagenavigation';

    @Input() data: SubNavigationSpot;
}
