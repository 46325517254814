import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IGridSection } from '@ncg/data';

@Component({
    selector: 'ncg-umb-section',
    template: `
        <!-- Previously had <ncg-umb-row /> wrapping here - be aware of shitty styling expecting certain node depth -->
        <div *ngIf="hasContent" [id]="'section-' + sectionIndex" class="section grid__row grid__theme" [ngClass]="sectionClasses">
            <!-- Previously had <ncg-umb-area /> wrapping here - be aware of shitty styling expecting certain node depth -->
            <div
                *ngFor="let spot of section.content; let spotIndex = index; let isFirstSpot = first"
                [id]="'section-' + sectionIndex + '-spot-' + spotIndex"
                [ngClass]="{ container: !allowNarrowContent }"
            >
                <!-- Previously had <ncg-spots /> wrapping here - be aware of shitty styling expecting certain node depth -->
                <!-- Previously had <div /> wrapping here - be aware of shitty styling expecting certain node depth -->
                <ng-template
                    ncgDynamicSpotComponent
                    [spotType]="spot.alias"
                    [allowNarrowContent]="allowNarrowContent"
                    [spotData]="spot"
                    [isFirstSpot]="isFirstSection && isFirstSpot"
                    [indexes]="{ section: sectionIndex, spot: spotIndex }"
                ></ng-template>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UmbSectionComponent implements OnInit {
    @Input()
    public section: IGridSection;

    @Input()
    public sectionIndex: number = 0;

    @Input()
    public isFirstSection: boolean = false;

    @Input()
    public allowNarrowContent?: boolean;

    public sectionClasses: string[] = [];
    public hasContent = false;

    public ngOnInit(): void {
        // Check if the section has any content.
        this.hasContent = Boolean(this.section.content.length);

        if (this.hasContent) {
            // Set class for section theme (based on the background color).
            if (this.section.theme) {
                this.sectionClasses.push(`grid__theme--${this.section.theme}`);
            }

            // Set class for section background position.
            if (this.section.settings.backgroundPosition) {
                this.sectionClasses.push(`grid__theme--${this.section.settings.backgroundPosition.toLowerCase()}`);
            }

            // Set class if theme is the same as the previous section's.
            if (this.section.isThemeSameAsPrev) {
                this.sectionClasses.push('is-same-prev');
            }

            // Get the alias of the first spot in the current section.
            const firstSpotAlias = this.section.content[0]?.alias;

            // Add classes for the first section in the grid.
            if (this.isFirstSection) {
                this.sectionClasses.push('grid__theme--is-first');

                // These spots should also have a class to remove top padding.
                const noPadding = ['hero', 'extendedhero', 'bydhero', 'bannerspot'].includes(firstSpotAlias);
                if (noPadding) {
                    this.sectionClasses.push('grid__theme--top-padding');
                }
            }

            // Remove vertical padding if section starts with cta.
            if (firstSpotAlias === 'cta') {
                this.sectionClasses.push('grid__theme--no-v-padding');
            }

            // Remove vertical padding if section only contains bydhero(s).
            if (!this.section.content.some((spot) => spot.alias !== 'bydhero')) {
                this.sectionClasses.push('grid__theme--no-v-padding');
            }
        }
    }
}
