import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
    selector: 'ncg-spinner-car',
    standalone: true,
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 83 37" @spinnerEnterLeave>
            <g>
                <g>
                    <path id="spinner-underside" fill-rule="evenodd" d="M24 21.5h37v2H24v-2Z" clip-rule="evenodd" />
                    <path
                        id="spinner-body"
                        fill-rule="evenodd"
                        d="M27.376.008A.998.998 0 0 1 27.5 0h15a1 1 0 0 1 .294.044c2.225.685 8.652 3.12 17.02 7.492 1.692.252 3.098.43 4.33.587.522.066 1.013.128 1.48.19 1.594.212 2.919.423 4.21.787 2.611.737 4.978 2.073 9.243 5.083A1 1 0 0 1 79.5 15v7.5a1 1 0 0 1-1 1H73a1 1 0 1 1 0-2h4.5v-5.98c-3.957-2.771-6.022-3.878-8.209-4.495-1.146-.323-2.352-.52-3.93-.729-.45-.06-.93-.12-1.446-.186a193.716 193.716 0 0 1-4.563-.621 1 1 0 0 1-.316-.103C50.858 5.102 44.577 2.706 42.348 2H27.566c-.683.095-1.702.389-2.958.843a68.138 68.138 0 0 0-4.216 1.722 146.766 146.766 0 0 0-7.93 3.822 1 1 0 0 1-.539.11l-5.336-.41.41 5.336a1 1 0 0 1-.372.858L4.57 15.924l.796 5.576H10.5a1 1 0 1 1 0 2h-6a1 1 0 0 1-.99-.859l-1-7a1 1 0 0 1 .365-.922l2.087-1.67-.459-5.972a1 1 0 0 1 1.074-1.074l6.215.478c1.942-1.006 4.9-2.467 7.791-3.746 1.51-.667 3.01-1.29 4.344-1.772 1.314-.476 2.532-.84 3.449-.955Z"
                        clip-rule="evenodd"
                    />
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="translate"
                        values="0,1;0,0;0,1"
                        dur="0.25s"
                        repeatCount="indefinite"
                    />
                </g>
                <path
                    id="spinner-wheels"
                    fill-rule="evenodd"
                    d="M17.5 28a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Zm0 2a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm49-2a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Zm0 2a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
                    clip-rule="evenodd"
                />
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    values="-3,0;3,0;-3,0"
                    dur="1s"
                    keySplines=".42,0,.58,1;.42,0,.58,1;"
                    calcMode="spline"
                    repeatCount="indefinite"
                />
            </g>
            <path fill-rule="evenodd" d="M.5 28.5H83v2H.5v-2Z" clip-rule="evenodd" />
            <path stroke="currentColor" stroke-dasharray="6 6" stroke-linecap="square" stroke-width="2" d="M83 35H2">
                <animate attributeName="stroke-dashoffset" values="83;0" dur="1.5s" calcMode="linear" repeatCount="indefinite" />
            </path>
        </svg>
    `,
    styles: [
        `
            /* Put on host to make it easy to overwrite from outside */
            :host {
                width: 83px;
            }
            svg {
                width: inherit;
            }
        `,
    ],
    animations: [
        trigger('spinnerEnterLeave', [
            transition(':enter', [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))]),
            transition(':leave', [animate('0.2s', style({ opacity: 0 }))]),
        ]),
    ],
})
export class SpinnerCarComponent {}
