import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UtilsModule } from '../utils/utils.module';
import { VideoComponent } from './video.component';

@NgModule({
    declarations: [VideoComponent],
    imports: [CommonModule, UtilsModule],
    exports: [VideoComponent],
})
export class VideoModule {}
