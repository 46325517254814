import { Pipe, PipeTransform } from '@angular/core';
import { IImage } from '@ncg/data';
import { IImageOptions, ImageUrl } from './helpers/image-helper';

@Pipe({
    name: 'imageUrl',
})
export class ImageUrlPipe implements PipeTransform {
    transform(src: IImage, options?: IImageOptions, srcsetMultiplier = 0): string {
        return ImageUrl(src, options, srcsetMultiplier);
    }
}
