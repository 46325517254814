import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import * as fromProducts from './products.reducer';
import * as ProductsSelectors from './products.selectors';

@Injectable()
export class ProductsFacade {
    isFetching$ = this.store$.pipe(select(ProductsSelectors.getFetching));
    allProducts$ = this.store$.pipe(select(ProductsSelectors.getAllProducts));
    filterParams$ = this.store$.pipe(select(ProductsSelectors.getFilterParams));
    layout$ = this.store$.pipe(select(ProductsSelectors.getLayout));
    total$ = this.store$.pipe(select(ProductsSelectors.getTotal));
    facets$ = this.store$.pipe(select(ProductsSelectors.getFacets));
    quickFilterFacets$ = this.store$.pipe(select(ProductsSelectors.getQuickFilterfacets));
    selectedFacets$ = this.store$.pipe(select(ProductsSelectors.getSelectedFacets));
    facetList$ = this.store$.pipe(select(ProductsSelectors.getFacetList));
    premiumText$ = this.store$.pipe(select(ProductsSelectors.getPremiumText));
    selectedPremiumText$ = this.store$.pipe(select(ProductsSelectors.getSelectedPremiumText));
    isPremiumEnabled$ = this.store$.pipe(select(ProductsSelectors.getIsPremiumEnabled));
    dropdownState$ = this.store$.pipe(select(ProductsSelectors.getDropdownState));

    constructor(private readonly store$: Store<fromProducts.ProductsPartialState>) {}

    dispatch(action: Action) {
        this.store$.dispatch(action);
    }
}
