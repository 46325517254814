import { IFacetList, IFacets, IFilterItemViewModel, IFilterParams, ILayoutSettings, ISelectedFacets } from '@ncg/data';
import { createAction, props } from '@ngrx/store';

// PRODUCTS
export const loadProductsSuccess = createAction('[Products] Load products success', props<{ products: IFilterItemViewModel[] }>());
export const loadMoreProducts = createAction('[Products] Load more products', props<{ filterParams?: IFilterParams }>());
export const addMoreProducts = createAction('[Products] Load more products success ', props<{ products: IFilterItemViewModel[] }>());
export const clearAllProducts = createAction('[Products] Remove all products');
export const loadProductsFailure = createAction('[Products] Load products error', props<{ error: any }>());
export const total = createAction('[Products] Current total amount cars', props<{ total?: number }>());
export const isFetching = createAction('[Products] Fetched products', props<{ isFetching: boolean }>());
export const filterParams = createAction(
    '[Products] Load current filter query',
    props<{ filterParams?: IFilterParams; resetFilter?: boolean; resetKey?: boolean }>()
);

// FACETS
export const facets = createAction('[Facets] Current availeble facets', props<{ facets?: IFacets }>());
export const facetList = createAction('[Facets] List of white listed facets', props<{ facetList?: IFacetList[] }>());
export const selectedFacets = createAction('[Facets] List of selected facets', props<{ selectedFacets: ISelectedFacets[] }>());

// QUICK FILTER
export const loadQuickFilterFacets = createAction('[Quick filter facet] Load Quick filter facet');
export const loadQuickFilterfacetsSuccess = createAction(
    '[Quick filter facet] Load quick filter facets success',
    props<{ quickFilterfacets?: IFacets }>()
);
export const loadQuickFilterFacetsFailure = createAction('[Quick filter facet] failure Load Quick filter facet failure', props<{ error: any }>());

// PREMIUM
export const premiumText = createAction('[Premium] All premium texts', props<{ premiumText?: Record<string, string> }>());
export const selectedPremiumText = createAction('[Premium] Selected premium text', props<{ selectedPremiumText?: string }>());
export const isPremiumEnabled = createAction('[Premium] Is premium selection enabled', props<{ isPremiumEnabled: boolean }>());

// layout ect.
export const dropdownState = createAction('[Dropdown] Current dropdown state', props<{ dropdownState: { isOpen: boolean; activeId?: number } }>());
export const layout = createAction('[Layout] Layout settings', props<{ layout?: ILayoutSettings }>());
