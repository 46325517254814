import { Pipe, PipeTransform } from '@angular/core';
import { DataEntityWithResources, DataField } from '@ncg/data';
import { fieldById } from '../../utils';

@Pipe({
    name: 'groupAccessories',
    standalone: true,
    pure: true,
})
export class GroupAccessoriesPipe implements PipeTransform {
    transform(ungroupedAccessories: (DataEntityWithResources | undefined)[] | null): { accessories: DataEntityWithResources[]; category: string }[] {
        const groups: { [key: string]: DataEntityWithResources[] } = {};
        ungroupedAccessories?.forEach((accessory) => {
            if (!accessory) {
                return;
            }
            const category: string | undefined = fieldById<DataField>(accessory.fields, 'AccessoryCategory')?.data?.value;
            if (!category) {
                return;
            }
            if (!groups[category]) {
                groups[category] = [];
            }
            groups[category].push(accessory);
        });
        return Object.entries(groups).map(([category, accessories]) => ({ category, accessories }));
    }
}
