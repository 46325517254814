import { AnimationEvent } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getBuildIdQuery, IFilterParams } from '@ncg/data';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { filterParams } from '../../+state/products/products.actions';
import { ProductsFacade } from '../../+state/products/products.facade';
import { ImpactOverlayRef } from '../../overlay/overlay-ref';
import { slideInLeft } from '../../utils/animations/slide.animations';

@Component({
    selector: 'ncg-filter-sidepanel',
    template: `
        <aside
            [id]="dialogRef?.id"
            class="filter-sidepanel"
            role="dialog"
            [@slideContent]="animationState"
            (@slideContent.start)="onAnimationStart($event)"
            (@slideContent.done)="onAnimationDone($event)"
        >
            <button aria-label="Closes filter dialog" class="button-icon is-primary filter-sidepanel__close" (click)="closeDialog()">
                <svg-icon-sprite
                    [src]="'assets/images/sprite-multi.svg' + buildIdQuery + '#close-circle'"
                    [width]="'58px'"
                    [height]="'58px'"
                    aria-hidden="true"
                ></svg-icon-sprite>
            </button>

            <div class="filter-sidepanel__content">
                <header class="filter-sidepanel__header border-bottom">
                    <h3>{{ 'filter_sidepanel.headline' | translate }}</h3>
                </header>
                <div class="filter-sidepanel__facets">
                    <ncg-filter-facets></ncg-filter-facets>
                </div>
                <div class="filter-sidepanel__bottom">
                    <div class="columns is-mobile is-variable is-4 is-vcentered">
                        <div class="column is-narrow">
                            <button type="button" class="button is-white" (click)="clearAllFilters()">
                                {{ 'filter_sidepanel.reset' | translate }}
                            </button>
                        </div>
                        <div class="column">
                            <button type="button" class="button is-full is-primary" (click)="closeDialog()" [class.is-loading]="isFetching">
                                <ng-container *ngIf="productsFacade.total$ | async as total; else noResult">
                                    {{ 'filter_sidepanel.show_results' | translate: { amount: total } }}
                                </ng-container>
                                <ng-template #noResult>{{ 'filter_sidepanel.no_result' | translate }}</ng-template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    `,
    animations: [slideInLeft],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSidepanelComponent implements OnInit, OnDestroy {
    buildIdQuery = getBuildIdQuery();
    animationState: 'void' | 'enter' | 'leave' = 'enter';
    animationStateChanged = new EventEmitter<AnimationEvent>();
    isFetching: boolean;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly cd: ChangeDetectorRef,
        public dialogRef: ImpactOverlayRef,
        public productsFacade: ProductsFacade,
        private readonly router: Router
    ) {}

    ngOnInit() {
        this.router.events.pipe(take(1), takeUntil(this.unsubscribe)).subscribe(() => {
            if (this.dialogRef?.componentInstance) {
                this.closeDialog();
            }
        });
        this.productsFacade.isFetching$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.isFetching = data;
            this.cd.markForCheck();
        });
    }

    clearAllFilters() {
        combineLatest([this.productsFacade.selectedFacets$, this.productsFacade.filterParams$])
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe(([selectedFacets, filterData]) => {
                const filters: IFilterParams | undefined = { ...filterData };
                const arr = [...new Set(selectedFacets.map((item) => item.key))];
                arr.forEach((element) => {
                    delete filters?.[element];
                });
                this.productsFacade.dispatch(filterParams({ filterParams: filters, resetFilter: true }));
            });
    }

    @HostListener('window:keyup.esc', ['$event'])
    closeDialog() {
        this.dialogRef.close();
    }

    onAnimationStart(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    onAnimationDone(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.animationState = 'leave';
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
