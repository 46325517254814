import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { setTabState } from '../../../+state/configurator/configurator.actions';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { CardComponent } from '../../../card/card.component';
import { listAnimation } from '../../../utils/animations/list.animations';
import { CurrencyFormatterPipe } from '../../../utils/currency.pipe';
import { UtilsModule } from '../../../utils/utils.module';
import { ConfiguratorImageComponent } from '../../components/configurator-image/configurator-image.component';
import { AddCurrencyPipe } from '../../utils/add-currency.pipe';
import { DataByIdPipe } from '../../utils/data-by-id.pipe';
import { FormatMultiParamPipe } from '../../utils/format-multi-param.pipe';
import { MultiParamActiveStatePipe } from '../../utils/multi-param-active-state.pipe';
import { ResourceFieldsByVariantModelPipe } from '../../utils/resource-fields-by-variant-model.pipe';
import { SortByPricePipe } from '../../utils/sort-by-price.pipe';

@Component({
    selector: 'ncg-configurator-partner-products',
    animations: [listAnimation],
    template: `
        <ng-container *ngIf="configuratorFacade.routerQueryParams$ | async as params">
            <ng-container *ngIf="configuratorFacade.model$ | async as model">
                <div class="container" *ngIf="configuratorFacade.trim$ | async as trim" [@listAnimation]="trim.partnerProductIds?.length">
                    <div class="configurator__cards">
                        <ng-container
                            *ngFor="
                                let ppId of trim.partnerProductIds
                                    | sortByPrice: model.partnerProducts : 'PartnerProductRetailSellingPrice' : 'desc'
                                    | async
                            "
                        >
                            <ncg-card
                                class="configurator__card"
                                *ngIf="model.partnerProducts?.[ppId] as partnerProduct"
                                [selected]="(ppId | multiParamActiveState: params.partner_products) === 'active'"
                                variant="checkbox"
                                contentClass="configurator__card-content"
                            >
                                <ncg-configurator-image
                                    class="mb-5"
                                    [fields]="partnerProduct.resources | resourceFieldsByVariantModel | async"
                                    sizes="300px"
                                    card-image
                                ></ncg-configurator-image>
                                <h2 class="configurator__label label" card-label>
                                    <a
                                        class="configurator__font-weight--emphasis configurator__link-cover configurator__word-break"
                                        (click)="configuratorFacade.dispatch(setTabState({ tab: 'partner_products', tabState: 'visited' }))"
                                        [routerLink]="[]"
                                        [replaceUrl]="true"
                                        [queryParams]="{ partner_products: partnerProduct.id | formatMultiParam: params.partner_products }"
                                        queryParamsHandling="merge"
                                        [innerHTML]="partnerProduct.fields | dataById: 'PartnerProductMarketingTitle' | safe: 'html'"
                                    >
                                    </a>
                                </h2>
                                <p
                                    class="configurator__text"
                                    *ngIf="partnerProduct.fields | dataById: 'PartnerProductMarketingDescription' as description"
                                >
                                    {{ description }}
                                </p>
                                <p
                                    class="configurator__text-tiny link-cover-breakout"
                                    *ngIf="partnerProduct.fields | dataById: 'PartnerProductMarketingInfoText' as infoText"
                                    [innerHTML]="infoText | safe: 'html'"
                                ></p>
                                <div class="configurator__price price">
                                    <span>{{ 'configurator.price_creation_fee' | translate }}</span>
                                    <span>{{
                                        partnerProduct.fields | dataById: ('PartnerProductRetailSellingPrice' | addCurrency | async) | currency
                                    }}</span>
                                </div>
                                <div class="configurator__leasing leasing">
                                    <span *ngIf="">{{ 'configurator.price_monthly_payment' | translate }}</span>
                                    <span>{{ 'configurator.price_monthly_payment' | translate }}</span>
                                    <span>
                                        {{
                                            'configurator.price_per_month'
                                                | translate
                                                    : {
                                                          price:
                                                              partnerProduct.fields
                                                              | dataById: ('PartnerProductRetailSellingPriceMonthlyFee' | addCurrency | async)
                                                              | currency
                                                      }
                                        }}
                                    </span>
                                </div>
                            </ncg-card>
                        </ng-container>
                    </div>
                    <div class="configurator__legal" *ngIf="configuratorFacade.variant$ | async as variant">
                        <p>
                            <strong>{{ variant.fields | dataById: 'VariantMarketingTitle' }}.</strong>
                            {{ variant.fields | dataById: 'VariantLegalText' }}<br />
                            {{ variant.fields | dataById: 'VariantLegalTextConfigurator' }}
                        </p>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styleUrls: ['./configurator-partner-products.component.scss'],
    imports: [
        CommonModule,
        CardComponent,
        DataByIdPipe,
        CurrencyFormatterPipe,
        TranslateModule,
        RouterModule,
        MultiParamActiveStatePipe,
        FormatMultiParamPipe,
        ConfiguratorImageComponent,
        AddCurrencyPipe,
        SortByPricePipe,
        UtilsModule,
        ResourceFieldsByVariantModelPipe,
    ],
})
export class ConfiguratorPartnerProductsComponent {
    public readonly setTabState = setTabState;
    constructor(public readonly configuratorFacade: ConfiguratorFacade) {}
}
