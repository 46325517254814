import { Pipe, PipeTransform } from '@angular/core';
import { DataField, DataListEntity, DataListField } from '@ncg/data';
import { firstValueFrom } from 'rxjs';
import { ConfiguratorFacade } from '../../+state/configurator/configurator.facade';
import { fieldById } from './field-by-id.util';

@Pipe({
    name: 'resourceFieldsByVariantModel',
    standalone: true,
    pure: true,
})
export class ResourceFieldsByVariantModelPipe implements PipeTransform {
    constructor(private readonly configuratorFacade: ConfiguratorFacade) {}

    public async transform(resources?: DataListEntity[] | null): Promise<DataListField[] | null | undefined> {
        if (!resources) {
            return undefined;
        }

        const currentVariantKey = await firstValueFrom(this.configuratorFacade.variant$).then(
            (variant) => fieldById<DataField>(variant?.fields, 'VariantModel')?.data?.key
        );

        if (currentVariantKey) {
            const matchingResource = resources.find((resource) => {
                const resourceHasMatchingModel = Boolean(
                    fieldById<DataListField>(resource.fields, 'ResourceModel')?.data?.find((data) => data.key === currentVariantKey)
                );
                if (resourceHasMatchingModel) {
                    return resource;
                }
            });
            if (matchingResource) {
                return matchingResource.fields;
            }
        }
        return resources[0]?.fields;
    }
}
