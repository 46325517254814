import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UtilsModule } from '../utils/utils.module';
import { ButtonComponent } from './button.component';

@NgModule({
    imports: [CommonModule, RouterModule, UtilsModule],
    declarations: [ButtonComponent],
    exports: [ButtonComponent],
})
export class ButtonModule {}
