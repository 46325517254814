import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ncg-spot-content',
    template: `
        <div class="spot-content">
            <header class="spot-content__header" *ngIf="background || title || subtitle">
                <p class="spot-content__header--background mb-6" *ngIf="background">
                    {{ background }}
                </p>
                <ng-container *ngIf="useAsHeader; else noHeader">
                    <h1 [ngClass]="checkWordLength(title)" class="spot-content__header--title" *ngIf="title">
                        <span [innerHTML]="title | usedCarsTags | async | safe: 'html'"></span>
                    </h1>
                    <ng-container *ngIf="subtitle">
                        <h2
                            class="spot-content__header--subtitle"
                            ncgInterceptLinks
                            [innerHTML]="subtitle | usedCarsTags | async | safe: 'html'"
                        ></h2>
                    </ng-container>
                </ng-container>

                <ng-template #noHeader>
                    <h2 class="spot-content__header--title" *ngIf="title">
                        <span [innerHTML]="title | usedCarsTags | async | safe: 'html'"></span>
                    </h2>
                    <div
                        class="spot-content__header--subtitle"
                        *ngIf="subtitle"
                        ncgInterceptLinks
                        [innerHTML]="subtitle | usedCarsTags | async | safe: 'html'"
                    ></div>
                </ng-template>
            </header>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpotContentComponent {
    @Input() background?: string;
    @Input() title?: string;
    @Input() subtitle?: string;
    @Input() useAsHeader?: boolean;

    checkWordLength(title: string) {
        if (title) {
            const wordArray = title.split(' ');
            const hasLongWord = wordArray.some((word) => word.length >= 11);

            if (hasLongWord) {
                return 'spot-content__header--title--has-long-word';
            }
        }

        return '';
    }
}
