import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IConfiguratorPageResponse, IConfiguratorVehicle } from '@ncg/data';
import { Subject } from 'rxjs';
import { setCategories } from '../../+state/configurator/configurator.actions';
import { ConfiguratorFacade } from '../../+state/configurator/configurator.facade';
import { productListAnimation } from '../../utils/animations/filter.animation';

@Component({
    selector: 'ncg-configurator-page',
    animations: [productListAnimation],
    template: `
        <div class="container configurator-models">
            <ncg-portal outletId="#header-portal">
                <ncg-configurator-header [showLink]="false"></ncg-configurator-header>
            </ncg-portal>
            <ncg-accordion [expanded]="true" *ngFor="let category of configuratorFacade.categories$ | async">
                <h2 class="configurator-models__headline" title>
                    {{ category.title }}
                </h2>
                <ul
                    content
                    *ngIf="configuratorFacade.routerQueryParams$ | async as params"
                    class="columns is-mobile is-multiline pb-6"
                    [@listAnimation]="category.vehicles.length"
                >
                    <li
                        class="configurator-models__list-item has-text-centered column mobile is-full-mobile is-half-tablet"
                        *ngFor="let vehicle of category.vehicles"
                    >
                        <article class="configurator-models__vehicle animate-scale__link">
                            <picture
                                *ngIf="vehicle.image as img"
                                class="configurator-models__vehicle-picture is-ratio-model animate-scale is-top-scale is-x-small-scale"
                            >
                                <source media="(min-width: 1024px)" [srcset]="img | imageUrl: { width: 523, height: 295 } : 2" />
                                <source media="(min-width: 768px)" [srcset]="img | imageUrl: { width: 442, height: 248 } : 2" />
                                <source media="(min-width: 415px)" [srcset]="img | imageUrl: { width: 737, height: 416 } : 2" />
                                <source media="(max-width: 414px)" [srcset]="img | imageUrl: { width: 384, height: 216 } : 2" />
                                <img [src]="img | imageUrl: { width: 384, height: 216 }" [alt]="img.altText" loading="lazy" ncgImageLoad />
                            </picture>
                            <div class="configurator-models__vehicle-content">
                                <h3 class="configurator-models__vehicle-name">
                                    <a
                                        class="configurator-models__vehicle-link link-cover"
                                        [routerLink]="routerLinkMap[vehicle.id].link"
                                        [queryParams]="routerLinkMap[vehicle.id].queryParams"
                                        [attr.aria-label]="('configurator.build_car_button' | translate) + ' ' + vehicle.name"
                                    >
                                        {{ vehicle.name }}
                                    </a>
                                </h3>
                                <p class="configurator-models__vehicle-price" *ngIf="vehicle.prices?.priceFormatted as price">
                                    {{ 'configurator.price_cash_from' | translate: { price: this.price } }}
                                </p>
                            </div>
                        </article>
                    </li>
                </ul>
            </ncg-accordion>
            <ncg-umb-grid [grid]="data?.grid"></ncg-umb-grid>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorPageComponent implements OnInit, OnDestroy {
    static ref = 'configuratorPage';

    private readonly unsubscribe = new Subject<void>();

    public routerLinkMap: { [id: string]: ConfiguratorRouterLink } = {};

    @Input()
    public data: IConfiguratorPageResponse;

    constructor(
        private readonly cd: ChangeDetectorRef,
        public readonly configuratorFacade: ConfiguratorFacade,
        public readonly route: ActivatedRoute
    ) {}

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public ngOnInit() {
        // Push data to our store, so everything is in the same place
        this.configuratorFacade.dispatch(setCategories({ categories: this.data.categories || [] }));
        // Build router links
        if (this.data) {
            this.routerLinkMap = Object.fromEntries(
                this.data.categories?.flatMap((category) => category.vehicles.map((vehicle) => [vehicle.id, this.createRouterLink(vehicle)])) || []
            );
        }
        this.cd.markForCheck();
    }

    private createRouterLink(vehicle: IConfiguratorVehicle): ConfiguratorRouterLink {
        const url = this.getVehicleUrlName(vehicle.name);

        if (vehicle.pimUmbrellaId) {
            return { link: [url], queryParams: { pid: vehicle.pimUmbrellaId } };
        } else if (vehicle.pimModelId) {
            return { link: [url], queryParams: { mid: vehicle.pimModelId } };
        }

        return { link: [url] };
    }

    private getVehicleUrlName(modelName: string): string {
        const substitutes = {
            ' ': '-',
            'æ': 'a',
            'ø': 'o',
            'å': 'a',
        } as const;
        return modelName
            .toLocaleLowerCase()
            .trim()
            .replace(/[ æøå]/g, (char) => substitutes[char as keyof typeof substitutes]);
    }
}

interface ConfiguratorRouterLink {
    link: string[];
    queryParams?: {
        pid?: string;
        mid?: string;
    };
}
