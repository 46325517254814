import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

const ANIMATION_TIMING_FUNCTION = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)';
const ANIMATION_DURATION = '500ms';
const ANIMATION_TIMINGS = `${ANIMATION_DURATION} ${ANIMATION_TIMING_FUNCTION}`;

export const slideInLeft = trigger('slideContent', [
    state('void', style({ transform: 'translate3d(100%, 0 , 0)' })),
    state('enter', style({ transform: 'translate3d(0, 0, 0)' })),
    state('leave', style({ transform: 'translate3d(100%, 0, 0)' })),
    transition('* => enter', [
        style({
            transform: 'scale3d(1, 1, 1) translate3d(108%, 0, 0)',
            transformOrigin: 'left center',
        }),
        animate(
            '500ms cubic-bezier(0.250, 0.460, 0.450, 0.940)',
            style({
                transform: 'scale3d(1, 1, 1) translate3d(0, 0, 0)',
                transformOrigin: 'left center',
            })
        ),
    ]),
    transition('* => leave', [
        style({ transform: 'translate3d(0, 0, 0)' }),
        animate(
            '300ms cubic-bezier(0.550, 0.085, 0.680, 0.530)',
            style({
                transform: 'translate3d(108%, 0, 0)',
            })
        ),
    ]),
]);

export const slideInRight = trigger('slideContent', [
    state('void', style({ transform: 'translate3d(-100%, 0 , 0)' })),
    state('enter', style({ transform: 'translate3d(0, 0, 0)' })),
    transition('* => enter', [
        animate(
            '500ms cubic-bezier(0.250, 0.460, 0.450, 0.940)',
            style({
                transform: 'scale3d(1, 1, 1) translate3d(0, 0, 0)',
                transformOrigin: 'left center',
            })
        ),
    ]),
    transition(':leave', [
        animate(
            '300ms cubic-bezier(0.550, 0.085, 0.680, 0.530)',
            style({
                transform: 'translate3d(-100%, 0, 0)',
            })
        ),
    ]),
]);

export const slideInBottom = trigger('slideInBottom', [
    state('void', style({ transform: 'translate3d(0, 100%, 0)' })),
    state('leave', style({ transform: 'translate3d(0, 100%, 0)' })),
    transition(':enter', [
        style({ transform: 'translate3d(0, 100%, 0)' }),
        animate(ANIMATION_TIMINGS, style({ transform: 'translate3d(0, 0, 0)' })),
        query('@*', [animateChild()], { optional: true }),
    ]),
    transition(':leave', [animate('300ms cubic-bezier(0.390, 0.575, 0.565, 1.000)', style({ opacity: 1, transform: 'translate3d(-100%, 0, 0)' }))]),
    transition('* => leave', [animate(ANIMATION_TIMINGS)], {
        delay: '50ms',
    }),
]);

export const slideFadeInBottom = trigger('slideFadeInBottom', [
    state('void', style({ transform: 'translate3d(0, {{distance}}, 0)', opacity: 0 }), { params: { distance: '100%' } }),
    state('leave', style({ transform: 'translate3d(0, {{distance}}, 0)', opacity: 0 }), { params: { distance: '100%' } }),
    transition(
        ':enter',
        [
            style({ transform: 'translate3d(0, {{distance}}, 0)', opacity: 0 }),
            animate(`250ms ${ANIMATION_TIMING_FUNCTION}`, style({ transform: 'translate3d(0, 0, 0)', opacity: 1 })),
        ],
        { params: { distance: '100%' } }
    ),
    transition(':leave', [animate(`250ms ${ANIMATION_TIMING_FUNCTION}`, style({ opacity: 1, transform: 'translate3d(-{{distance}}, 0, 0)' }))], {
        params: { distance: '100%' },
    }),
    transition('* => leave', [animate(`250ms ${ANIMATION_TIMING_FUNCTION}`)], { delay: '50ms' }),
]);

export const translateFadeIn = trigger('translateFadeIn', [
    transition(
        ':enter',
        [
            style({ transform: 'translate3d({{x}}, {{y}}, {{z}})', opacity: 0 }),
            animate(`{{durationIn}} cubic-bezier(0.390, 0.575, 0.565, 1.000)`, style({ transform: 'translate3d(0, 0, 0)', opacity: 1 })),
        ],
        { params: { x: '0px', y: '100%', z: '0px', durationIn: '250ms' } }
    ),
    transition(
        ':leave',
        [
            style({ transform: 'translate3d(0, 0, 0)', opacity: 1 }),
            animate(`{{durationOut}} cubic-bezier(0.390, 0.575, 0.565, 1.000)`, style({ transform: 'translate3d({{x}}, {{y}}, {{z}})', opacity: 0 })),
        ],
        { params: { x: '0px', y: '100%', z: '0px', durationOut: '250ms' } }
    ),
]);

// Combine with some kind of absolute positioning, so the content leaving and entering overlaps.
// It could be e.g. position: absolute, or placing both children in the same grid col and row.
export const slideInOut = [
    trigger('inOut', [
        transition(
            'void => left',
            [
                group([
                    style({ transform: 'translateX(-{{distanceIn}})', zIndex: 1 }),
                    animate(`{{durationIn}} ${ANIMATION_TIMING_FUNCTION}`, style({ zIndex: 1, transform: 'translateX(0)' })),
                    query('@*', [animateChild()], { optional: true }),
                ]),
            ],
            { params: { distanceIn: '100%', durationIn: ANIMATION_DURATION } }
        ),
        transition(
            'left => void',
            [
                style({ zIndex: 0 }),
                animate(
                    `{{durationOut}} ${ANIMATION_TIMING_FUNCTION}`,
                    style({
                        transform: 'translateX(-{{distanceOut}})',
                        zIndex: 0,
                    })
                ),
                query('@*', [animateChild()], { optional: true }),
            ],
            { params: { distanceOut: '35%', durationOut: ANIMATION_DURATION } }
        ),
        transition(
            'void => right',
            [
                group([
                    style({ transform: 'translateX({{distanceIn}})', zIndex: 1 }),
                    animate(`{{durationIn}} ${ANIMATION_TIMING_FUNCTION}`, style({ zIndex: 1, transform: 'translateX(0)' })),
                    query('@*', [animateChild()], { optional: true }),
                ]),
            ],
            { params: { distanceIn: '100%', durationIn: ANIMATION_DURATION } }
        ),
        transition(
            'right => void, still => void',
            [
                style({ zIndex: 0 }),
                animate(
                    `{{durationOut}} ${ANIMATION_TIMING_FUNCTION}`,
                    style({
                        transform: 'translateX({{distanceOut}})',
                        zIndex: 0,
                    })
                ),
                query('@*', [animateChild()], { optional: true }),
            ],
            { params: { distanceOut: '35%', durationOut: ANIMATION_DURATION } }
        ),
        transition('* => still', [
            animate(
                `${ANIMATION_DURATION} ${ANIMATION_TIMING_FUNCTION}`,
                style({
                    transform: 'translateX(0%)',
                })
            ),
        ]),
    ]),
];

export const slideDownInWithWidthAnimation = [
    trigger('slideDownInWithWidthAnimation', [
        transition(':enter', [
            style({ transform: 'translate(0, -25%)', opacity: 0, width: '0' }),
            animate(`150ms ${ANIMATION_TIMING_FUNCTION}`, style({ transform: 'translate(0, -25%)', opacity: 0, width: '*' })),
            animate(`150ms ${ANIMATION_TIMING_FUNCTION}`, style({ transform: 'translate(0, 0%)', opacity: 1, width: '*' })),
        ]),
        transition(':leave', [
            style({ transform: 'translate(0, 0)', opacity: 1, width: '*' }),
            animate(`150ms ${ANIMATION_TIMING_FUNCTION}`, style({ transform: 'translate(0, -25%)', opacity: 0, width: '*' })),
            animate(`150ms ${ANIMATION_TIMING_FUNCTION}`, style({ transform: 'translate(0, -25%)', opacity: 0, width: '0' })),
        ]),
    ]),
];

export const translateOnUpdate = [
    trigger('translateOnUpdate', [
        transition(
            '* => *',
            [
                group([
                    style({ transform: 'translate3d({{x}}, {{y}}, {{z}})' }),
                    animate(`{{duration}} ${ANIMATION_TIMING_FUNCTION}`, style({ transform: 'translate3d(0, 0, 0)' })),
                ]),
            ],
            { params: { x: '0px', y: '0px', z: '0px', duration: ANIMATION_DURATION } }
        ),
    ]),
];
