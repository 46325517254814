import { Pipe, PipeTransform } from '@angular/core';
import { DataField, DataListField } from '@ncg/data';
import { Resource, fieldsToResource } from './fields-to-resource.util';

@Pipe({
    name: 'fieldsToResource',
    standalone: true,
    pure: true,
})
export class FieldsToResourcePipe implements PipeTransform {
    transform(fields?: (DataField | DataListField)[] | null): Resource | undefined {
        return fieldsToResource(fields);
    }
}
