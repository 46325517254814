import { Pipe, PipeTransform } from '@angular/core';
import { DataEntityWithResources } from '@ncg/data';
import { Observable, map } from 'rxjs';

@Pipe({
    name: 'selectedCardsCount',
    standalone: true,
})
export class SelectedCardsCountPipe implements PipeTransform {
    transform(accessories$: Observable<(DataEntityWithResources | undefined)[]>, category: string): Observable<number> {
        return accessories$.pipe(
            map(
                (accessories) =>
                    accessories.filter((accessory) => accessory && accessory?.fields?.find((field) => field.data?.value === category)).length
            )
        );
    }
}
