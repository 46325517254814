import { Component, Input } from '@angular/core';
import { NewsletterFormSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-newsletter-spot',
    template: `
        <div class="columns is-centered" ncgLoadIn>
            <ncg-newsletter class="column" [class.is-8]="!allowNarrowContent" [data]="data" [isSidePanel]="allowNarrowContent"></ncg-newsletter>
        </div>
    `,
})
export class NewsletterSpotComponent extends SpotBaseDirective {
    static ref = 'newsletter';

    @Input() data: NewsletterFormSpot;
}
