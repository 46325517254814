import { OverlayModule as OverlayCdkModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImpDialogCloseDirective } from './dialog-directives';
import { OverlayService } from './overlay.service';

@NgModule({
    imports: [CommonModule, OverlayCdkModule],
    providers: [OverlayService],
    declarations: [ImpDialogCloseDirective],
    exports: [ImpDialogCloseDirective],
})
export class OverlayModule {}
