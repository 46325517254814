<div class="columns is-multiline filter-facets">
    <div class="column is-full border-bottom is-hidden-touch">
        <div class="filter-facets__item" [ngClass]="{ 'filter-facets__item--padding': filterPageLayout === 'filterInSidepanel' }">
            <ncg-product-search></ncg-product-search>
        </div>
    </div>
    <ng-container *ngFor="let facetGroup of facetList; trackBy: trackByFn; let last = last">
        <div class="column is-full" [class.border-bottom]="!last" *ngIf="facetGroup?.facets?.length || facetGroup.facetGroups?.length">
            <div class="filter-facets__item" [ngClass]="{ 'filter-facets__item--padding': filterPageLayout === 'filterInSidepanel' }">
                <ncg-expansion-panel [expanded]="!facetGroup.isCollapsed">
                    <h3 class="filter-facets__title" expansion-title>
                        <ng-container [ngSwitch]="facetGroup.facetKey">
                            <ng-container *ngSwitchCase="'make.keyword'">{{ 'facet_types.make_keyword' | translate }} </ng-container>
                            <ng-container *ngSwitchCase="'bodytypes.keyword'"> {{ 'facet_types.bodytypes_keyword' | translate }}</ng-container>
                            <ng-container *ngSwitchCase="'dealername.keyword'"> {{ 'facet_types.dealername' | translate }}</ng-container>
                            <ng-container *ngSwitchCase="'effect'">{{ 'facet_types.effect' | translate }}</ng-container>
                            <ng-container *ngSwitchCase="'geartype.keyword'"> {{ 'facet_types.geartype_keyword' | translate }}</ng-container>
                            <!--isvan is disabled as it is already in the "bodytypes.keyword" facet.-->
                            <!--<ng-container *ngSwitchCase="'isvan'">{{ 'facet_types.isvan' | translate }}</ng-container>-->
                            <ng-container *ngSwitchCase="'mileage'">{{ 'facet_types.mileage' | translate }} </ng-container>
                            <ng-container *ngSwitchCase="'model.keyword'">{{ 'facet_types.model_keyword' | translate }} </ng-container>
                            <ng-container *ngSwitchCase="'price'">{{ 'facet_types.price' | translate }}</ng-container>
                            <ng-container *ngSwitchCase="'propellant.keyword'">{{ 'facet_types.propellant_keyword' | translate }}</ng-container>
                            <ng-container *ngSwitchCase="'series.keyword'"> {{ 'facet_types.series_keyword' | translate }}</ng-container>
                            <ng-container *ngSwitchCase="'year'">{{ 'facet_types.year' | translate }}</ng-container>
                            <ng-container *ngSwitchCase="'only'">{{ 'facet_types.only' | translate }}</ng-container>
                            <ng-container *ngSwitchCase="'payment'">{{ 'facet_types.payment' | translate }}</ng-container>
                        </ng-container>
                        {{ facetGroup.selectedCount ? '(' + facetGroup.selectedCount + ')' : '' }}
                    </h3>
                    <img expansion-icon alt="icon" src="assets/images/down.svg" width="30" height="30" />
                    <ng-container [ngSwitch]="facetGroup.type">
                        <ng-container *ngSwitchCase="'toggles'">
                            <ncg-filter-facet-toggle [facetGroup]="facetGroup"></ncg-filter-facet-toggle>
                        </ng-container>
                        <ng-container *ngSwitchCase="'range'">
                            <ncg-filter-facet-range [facetGroup]="facetGroup"></ncg-filter-facet-range>
                        </ng-container>
                        <ng-container *ngSwitchCase="'buttons'">
                            <ncg-filter-facet [facetData]="facetGroup"></ncg-filter-facet>
                        </ng-container>
                    </ng-container>
                </ncg-expansion-panel>
            </div>
        </div>
    </ng-container>
</div>
