import { Component, Input, OnInit } from '@angular/core';
import { SubNavigationItem } from '@ncg/data';

import { HdAspect } from '../../utils/helpers/aspect-ratio';
import { ImageUrl } from '../../utils/helpers/image-helper';

@Component({
    selector: 'ncg-subnavigation-item',
    template: `
        <div class="subnavigation__item" *ngIf="item" ncgLoadIn>
            <ng-container *ngIf="item.link.isExternal; else internalImageLink">
                <a class="subnavigation__link--image" [href]="item.link.url" [target]="item.link?.target">
                    <ng-container *ngTemplateOutlet="imageTmpl"></ng-container>
                </a>
            </ng-container>

            <ng-template #internalImageLink>
                <a class="subnavigation__link--image" [routerLink]="[item.link.url]">
                    <ng-container *ngTemplateOutlet="imageTmpl"></ng-container>
                </a>
            </ng-template>

            <h2 class="subnavigation__item--header" *ngIf="item.name">
                <ng-container *ngIf="item.link.isExternal; else internalTitleLink">
                    <a [href]="item.link.url" [target]="item.link?.target">{{ item.name }} </a>
                </ng-container>

                <ng-template #internalTitleLink>
                    <a [routerLink]="[item.link.url]">{{ item.name }}</a>
                </ng-template>
            </h2>
            <p *ngIf="item.description">{{ item.description }}</p>
            <ng-container *ngIf="item.link?.isExternal; else internalLink">
                <a [href]="item.link.url" [target]="item.link?.target" class="button is-secondary">{{ 'navigation.read_more' | translate }}</a>
            </ng-container>

            <ng-template #internalLink>
                <a class="button is-secondary" [routerLink]="item.link.url">{{ 'navigation.read_more' | translate }}</a>
            </ng-template>
        </div>

        <ng-template #imageTmpl>
            <div class="is-ratio-hd subnavigation__link--image animate-mask is-x-small-mask">
                <img
                    [alt]="altText"
                    [srcset]="srcset"
                    sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 600px"
                    [src]="src"
                    loading="lazy"
                    ncgImageLoad
                />
            </div>
        </ng-template>
    `,
})
export class SubnavigationItemComponent implements OnInit {
    @Input() item: SubNavigationItem;

    src = '';
    srcset: string = '';
    altText = '';

    ngOnInit() {
        if (this.item.image) {
            this.altText = this.item.name || this.item.image.altText || '';

            const image = this.item.image;

            this.srcset = `
                ${ImageUrl(image, {
                    width: 600,
                    heightratio: HdAspect,
                    mode: 'crop',
                })} 600w,
                ${ImageUrl(image, {
                    width: 1200,
                    heightratio: HdAspect,
                    mode: 'crop',
                })} 1200w,
                ${ImageUrl(image, {
                    width: 2048,
                    heightratio: HdAspect,
                    mode: 'crop',
                })} 2048w,
            `;

            this.src = ImageUrl(image, {
                width: 600,
                heightratio: HdAspect,
                mode: 'crop',
            });
        }
    }
}
