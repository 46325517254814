import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HeadlineSpot, IImage } from '@ncg/data';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import SwiperCore, { Keyboard, Navigation, Pagination } from 'swiper';
import { SettingsService } from '../../core/settings.service';
import { SpotBaseDirective } from '../spot-base.class';
import { ILastViewedVehicle, RecentlyViewedCarsSpotService } from './recently-viewed-cars-spot.service';

SwiperCore.use([Navigation, Pagination, Keyboard]);
@Component({
    selector: 'ncg-recently-viewed-cars-spot',
    template: `<ng-container *ngIf="items.length">
        <div [ngClass]="{ 'grid__row grid__theme grid__theme--light': productId }" class="section">
            <div class="container">
                <ncg-headline-spot [data]="headlineData"></ncg-headline-spot>
                <div class="product-last-viewed">
                    <div class="section container">
                        <div class="is-relative">
                            <swiper
                                #swiper
                                class="product-last-viewed-swiper"
                                [slidesPerView]="1.1"
                                [spaceBetween]="20"
                                [grabCursor]="true"
                                [keyboard]="true"
                                [threshold]="10"
                                [simulateTouch]="items.length > amountVisibleSlides"
                                [breakpoints]="{
                                    '1024': { slidesPerView: amountVisibleSlides + 0.1 },
                                    '1600': { slidesPerView: amountVisibleSlides }
                                }"
                                [navigation]="{
                                    prevEl: prev,
                                    nextEl: next
                                }"
                            >
                                <ng-template swiperSlide *ngFor="let item of items">
                                    <a *ngIf="item.id !== productId" [routerLink]="item.url" class="product-last-viewed__link">
                                        <div class="product-last-viewed__image">
                                            <img
                                                *ngIf="item.picture"
                                                [src]="item.picture | productImage: 'small'"
                                                [srcset]="
                                                    (item.picture | productImage: 'small') +
                                                    ' 400w,' +
                                                    (item.picture | productImage: 'medium') +
                                                    ' 800w,' +
                                                    (item.picture | productImage: 'large') +
                                                    ' 1200w'
                                                "
                                                [alt]="item.title + ' ' + item.variant"
                                                loading="lazy"
                                                sizes="(min-width: 1440px) 265px, (min-width: 1024px) 210px, (min-width: 768px) 680px, (min-width: 425px) 360px, 265px"
                                            />
                                            <span *ngIf="!item.picture && fallbackImage" class="product-last-viewed__image-fallback">
                                                {{ 'product_details.gallery_placeholder' | translate }}
                                            </span>
                                            <img
                                                *ngIf="!item.picture && fallbackImage"
                                                [src]="fallbackImage | srcset: { retina: false, imageOptions: { width: 265 } }"
                                                [srcset]="fallbackImage | srcset: { widths: [210, 265, 360, 680] }"
                                                [alt]="fallbackImage.altText || ''"
                                                loading="lazy"
                                                sizes="(min-width: 1440px) 265px, (min-width: 1024px) 210px, (min-width: 768px) 680px, (min-width: 425px) 360px, 265px"
                                            />
                                        </div>
                                        <div class="product-last-viewed__body">
                                            <h3 *ngIf="item.title" class="product-last-viewed__title">{{ item.title }}</h3>
                                            <h5 *ngIf="item.variant" class="product-last-viewed__subtitle">{{ item.variant }}</h5>
                                        </div>
                                        <ng-container [ngSwitch]="item.priceType">
                                            <ng-container *ngSwitchCase="'CallForLeasing'">
                                                <div class="product-last-viewed__price">
                                                    <ng-container *ngIf="item.cashPrice">
                                                        <div class="product-last-viewed__price-primary">
                                                            {{ item.cashPrice }}
                                                            <ng-container *ngTemplateOutlet="vatText"></ng-container>
                                                        </div>
                                                        <div class="product-last-viewed__price-secondary">
                                                            {{ 'product_item.call_for_leasing' | translate }}
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="!item.cashPrice">
                                                        <div class="product-last-viewed__price-primary">
                                                            {{ 'product_item.call_for_leasing' | translate }}
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'RetailPrice'">
                                                <div class="product-last-viewed__price" *ngIf="item.cashPrice">
                                                    <div class="product-last-viewed__price-primary">
                                                        {{ item.cashPrice }}
                                                        <ng-container *ngTemplateOutlet="vatText"></ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'LeasingPrice'">
                                                <div class="product-last-viewed__price">
                                                    <ng-container *ngIf="item.cashPrice">
                                                        <div class="product-last-viewed__price-primary">
                                                            {{ item.cashPrice }}
                                                            <ng-container *ngTemplateOutlet="vatText"></ng-container>
                                                        </div>
                                                        <div *ngIf="item.leasingPrice" class="product-last-viewed__price-secondary">
                                                            {{ 'product_item.leasing_price' | translate: { price: item.leasingPrice } }}
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="!item.cashPrice && item.leasingPrice">
                                                        <div *ngIf="item.leasingPrice" class="product-last-viewed__price-secondary">
                                                            {{ 'product_item.leasing_price' | translate: { price: item.leasingPrice } }}
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'Leasing'">
                                                <div class="product-last-viewed__price">
                                                    <ng-container *ngIf="item.cashPrice">
                                                        <div class="product-last-viewed__price-primary">
                                                            {{ item.cashPrice }}
                                                            <ng-container *ngTemplateOutlet="vatText"></ng-container>
                                                        </div>
                                                        <div *ngIf="item.leasingPrice" class="product-last-viewed__price-secondary">
                                                            {{ 'product_item.leasing_price' | translate: { price: item.leasingPrice } }}
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="!item.cashPrice && item.leasingPrice">
                                                        <div class="product-last-viewed__price-primary">
                                                            {{ 'product_item.leasing_price' | translate: { price: item.leasingPrice } }}
                                                            <ng-container *ngTemplateOutlet="vatText"></ng-container>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <div class="product-last-viewed__price">
                                                    <div class="product-last-viewed__price-primary">
                                                        {{ 'product_item.call_for_price' | translate }}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </a>
                                    <ng-template #vatText>
                                        <ng-container [ngSwitch]="item.vatType">
                                            <ng-container *ngSwitchCase="'WithVat'">
                                                <div class="product-last-viewed__price-vat">
                                                    {{ 'product_item.price_incl_vat' | translate }}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'WithoutVat'">
                                                <div class="product-last-viewed__price-vat">
                                                    {{ 'product_item.price_ex_vat' | translate }}
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                            </swiper>
                            <div
                                class="product-last-viewed-swiper__arrows"
                                [class.is-hidden-tablet]="items.length <= amountVisibleSlides"
                                [style.--slides-per-view]="amountVisibleSlides"
                            >
                                <div #prev class="swiper-button-prev is-small product-last-viewed-swiper__prev"></div>
                                <div #next class="swiper-button-next is-small product-last-viewed-swiper__next"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>`,
})
export class RecentlyViewedCarsSpotComponent extends SpotBaseDirective implements OnInit, OnDestroy {
    static ref = 'recentlyviewedcars';

    private readonly unsubscribe = new Subject<void>();

    @Input()
    public productId: string;
    @Input()
    public fallbackImage: IImage | undefined;
    public items: ILastViewedVehicle[] = [];
    public headlineData: HeadlineSpot;
    public amountVisibleSlides = 4;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly recentlyViewedCars: RecentlyViewedCarsSpotService,
        private readonly translateService: TranslateService,
        private readonly settings: SettingsService
    ) {
        super();
    }

    public ngOnInit() {
        combineLatest([
            this.translateService.get('product_details.recently_viewed'),
            this.settings.get().pipe(map((value) => value.productFallbackImage)),
            this.recentlyViewedCars.lastViewedVehicles(),
        ])
            .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe))
            .subscribe(([title, fallbackImage, vehicles]) => {
                this.headlineData = {
                    alias: 'headline',
                    title,
                };

                this.fallbackImage = fallbackImage;

                // filter out current vehicle from last viewed
                // and reverse the order to show latest first
                this.items = [...vehicles.filter((x) => x.id !== this.productId)].reverse();
                this.cd.markForCheck();
            });
    }

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
