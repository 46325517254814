import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linkContentClass',
})
export class LinkContentClassPipe implements PipeTransform {
    transform(classNames: string, postfix: string): string {
        if (!classNames.length) {
            return '';
        }
        return classNames
            .split(' ')
            .map((className) => `${className}${postfix}`)
            .join(' ');
    }
}
