import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ILink } from '@ncg/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SettingsService } from '../../core/settings.service';
import { SpotsConfig } from '../../spots/spots-config';

@Component({
    selector: 'ncg-shortcuts',
    template: `
        <ng-container *ngIf="links && links.length">
            <!-- Shortcuts in mobile navigation overlay -->
            <div *ngIf="isInMobile" class="mobile-links">
                <aside class="menu">
                    <ul class="menu-list">
                        <li *ngFor="let link of links" class="mobile-links__item">
                            <ng-container *ngTemplateOutlet="shortcutLink; context: { link: link }"></ng-container>
                        </li>
                    </ul>
                </aside>
            </div>

            <!-- Shortcuts in mega menu -->
            <div *ngIf="!isInMobile" class="mega-sidemenu">
                <aside class="menu">
                    <p class="mega-sidemenu__label menu-label mega-sidemenu__label--animate">{{ 'header.shortcuts' | translate }}</p>
                    <ul class="menu-list">
                        <li *ngFor="let link of links" class="mega-sidemenu__item mega-sidemenu__item--animate">
                            <ng-container *ngTemplateOutlet="shortcutLink; context: { link: link }"></ng-container>
                        </li>
                    </ul>
                </aside>
            </div>
        </ng-container>

        <ng-template #shortcutLink let-link="link">
            <a [routerLink]="[link.url]" *ngIf="link?.url">
                <ng-container [ngSwitch]="spotsConfig.iconType">
                    <ng-container *ngSwitchCase="'light'">
                        <svg-icon-sprite
                            [src]="'chevron-light-right'"
                            [viewBox]="'0 0 30 30'"
                            [width]="'30px'"
                            [height]="'30px'"
                            aria-hidden="true"
                            [classes]="''"
                            class="shortcut__icon"
                        ></svg-icon-sprite>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <svg-icon-sprite
                            [src]="'chevron-right'"
                            [viewBox]="'0 0 30 30'"
                            [width]="'30px'"
                            [height]="'30px'"
                            aria-hidden="true"
                            [classes]="''"
                            class="shortcut__icon"
                        ></svg-icon-sprite>
                    </ng-container>
                </ng-container>
                <span class="mega-sidemenu__item--text">{{ link.name }}</span>
            </a>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortcutsComponent implements OnInit, OnDestroy {
    @Input()
    isInMobile = false;

    links: ILink[];

    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly settingsService: SettingsService,
        @Inject(SpotsConfig) public spotsConfig: SpotsConfig
    ) {}

    ngOnInit() {
        this.getShortcuts();
    }

    private getShortcuts() {
        this.settingsService
            .get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.links = settings.shortcuts;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
