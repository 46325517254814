import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IProductPageResponse } from '@ncg/data';
import { MetaService } from '../../core/meta.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

import { TrackingService } from '../../core/tracking.service';
import { ProductPageService } from './product-page.service';

@Component({
    providers: [ProductPageService],
    selector: 'ncg-product-page',
    template: `
        <div class="content" *ngIf="data?.product">
            <ncg-product-details-root ncgLoadIn [data]="data"></ncg-product-details-root>
            <ncg-umb-grid [grid]="data.grid" *ngIf="data?.grid"></ncg-umb-grid>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPageComponent implements OnInit {
    static ref = 'productPage';

    @Input()
    data: IProductPageResponse;

    constructor(
        private readonly metaService: MetaService,
        private readonly translateService: TranslateService,
        private readonly trackingService: TrackingService,
        private readonly productPageService: ProductPageService
    ) {}

    ngOnInit() {
        this._setMetaData();
        this.trackingService.trackProductPageViewed();
        this.productPageService.setProductPageData(this.data);
    }

    private async _setMetaData(): Promise<void> {
        if (!this.data) {
            return;
        }

        const { product } = this.data;

        if (product) {
            const { properties } = product;

            let price = '';

            if (product.priceType === 'CallForPrice' || product.priceType === 'CallForLeasing') {
                price = await firstValueFrom(this.translateService.get('product_details.contact_for_price'));
            } else {
                price = product.cashPrice
                    ? product.cashPrice
                    : product.leasingPrice
                    ? await firstValueFrom(this.translateService.get('product_details.leasing_price', { amount: product.leasingPrice }))
                    : await firstValueFrom(this.translateService.get('product_details.contact_for_price'));
            }

            let displayName = properties.displayName;
            if (!displayName) {
                displayName = `${properties.make || ''} ${properties.model || ''} ${properties.variant || ''}`.trim();
            }

            const interpolatableParams = {
                displayName: displayName.trim(),
                make: properties.make || '',
                model: properties.model || '',
                variant: properties.variant || '',
                color: properties.color || '',
                motor: product.motor || '',
                price: decodeURIComponent(price),
            };

            let title = '';

            if (this.data.meta.title) {
                title = this.translateService.parser.interpolate(this.data.meta.title, interpolatableParams) ?? '';
                this.metaService.setTitle(title);
            } else {
                this.metaService.setTitle(displayName.trim());
            }

            if (this.data.meta.description) {
                const description = this.translateService.parser.interpolate(this.data.meta.description, interpolatableParams);
                this.metaService.setDescription(description);
            }

            if (this.data.meta.keywords) {
                const keywords = this.translateService.parser.interpolate(this.data.meta.keywords, interpolatableParams);
                this.metaService.setKeywords(keywords);
            }

            if (properties.pictures?.length) {
                const mainImage = properties.pictures[0];
                if (mainImage) {
                    this.metaService.setOpenGraph({
                        title,
                        type: 'website',
                        image: {
                            url: mainImage,
                            width: '1600',
                            height: '1067',
                            altText: title,
                        },
                        author: this.data.dealership?.location.name || '',
                    });
                }
            }
        }
    }
}
