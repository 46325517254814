import { Pipe, PipeTransform } from '@angular/core';
import { DataEntityWithResources, DataListDataListEntityWithResources } from '@ncg/data';
import { map, of } from 'rxjs';
import { SettingsService } from '../../core/settings.service';
import { getIdsSortedByPrice, PriceProperty } from './by-price.util';

@Pipe({
    name: 'sortByPrice',
    standalone: true,
    pure: true,
})
export class SortByPricePipe implements PipeTransform {
    constructor(private readonly settingsService: SettingsService) {}
    transform(
        ids?: string[] | null,
        dataForIds?: Record<string, DataEntityWithResources> | Record<string, DataListDataListEntityWithResources>,
        pricePropertyWithoutCurrency?: PriceProperty,
        orderBy: 'asc' | 'desc' = 'asc'
    ) {
        return (this.settingsService.settings$ || of({ currency: 'DKK' })).pipe(
            map(({ currency }) =>
                getIdsSortedByPrice(ids, dataForIds, pricePropertyWithoutCurrency, currency, orderBy).map((idWithPrice) => idWithPrice.id)
            )
        );
    }
}
