import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ncg-motorsize-item-icons',
    template: `
        <div class="motorsize-item-icons" [ngSwitch]="icon">
            <ng-container *ngSwitchCase="'engine_size'">
                <figure class="motorsize-item__figure">
                    <svg-icon-sprite src="engine_size" [viewBox]="'0 0 30 30'" [width]="'48px'" [height]="'48px'"></svg-icon-sprite>
                </figure>
            </ng-container>
            <ng-container *ngSwitchCase="'engine_fuel_type'">
                <figure class="motorsize-item__figure">
                    <svg-icon-sprite src="engine_fuel_type" [viewBox]="'0 0 30 30'" [width]="'48px'" [height]="'48px'"></svg-icon-sprite>
                </figure>
            </ng-container>
            <ng-container *ngSwitchCase="'engine_co2'">
                <figure class="motorsize-item__figure">
                    <svg-icon-sprite src="engine_co2" [viewBox]="'0 0 30 30'" [width]="'48px'" [height]="'48px'"></svg-icon-sprite>
                </figure>
            </ng-container>
            <ng-container *ngSwitchCase="'engine_transmission_type'">
                <figure class="motorsize-item__figure">
                    <svg-icon-sprite src="engine_transmission_type" [viewBox]="'0 0 30 30'" [width]="'48px'" [height]="'48px'"></svg-icon-sprite>
                </figure>
            </ng-container>

            <ng-container *ngSwitchCase="'engine_transmission_gear'">
                <figure class="motorsize-item__figure">
                    <svg-icon-sprite src="engine_transmission_gear" [viewBox]="'0 0 30 30'" [width]="'48px'" [height]="'48px'"></svg-icon-sprite>
                </figure>
            </ng-container>

            <ng-container *ngSwitchCase="'engine_hp'">
                <figure class="motorsize-item__figure">
                    <svg-icon-sprite src="engine_hp" [viewBox]="'0 0 30 30'" [width]="'48px'" [height]="'48px'"></svg-icon-sprite>
                </figure>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <figure class="motorsize-item__figure">
                    <svg-icon-sprite src="engine_efficiency" [viewBox]="'0 0 30 30'" [width]="'48px'" [height]="'48px'"></svg-icon-sprite>
                </figure>
            </ng-container>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MotorsizeItemIconsComponent {
    @Input() icon: string;
}
