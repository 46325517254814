import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DataEntityWithResources } from '@ncg/data';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, combineLatest, map, takeUntil } from 'rxjs';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { AccordionComponent } from '../../../accordion/accordion.component';
import { CardComponent } from '../../../card/card.component';
import { listAnimation } from '../../../utils/animations/list.animations';
import { CurrencyFormatterPipe } from '../../../utils/currency.pipe';
import { UtilsModule } from '../../../utils/utils.module';
import { ConfiguratorImageComponent } from '../../components/configurator-image/configurator-image.component';
import { SelectedCardsCountPipe } from '../../utils/accessories-count-pipe';
import { AddCurrencyPipe } from '../../utils/add-currency.pipe';
import { DataByIdPipe } from '../../utils/data-by-id.pipe';
import { FormatMultiParamPipe } from '../../utils/format-multi-param.pipe';
import { MultiParamActiveStatePipe } from '../../utils/multi-param-active-state.pipe';
import { ResourceFieldsByVariantModelPipe } from '../../utils/resource-fields-by-variant-model.pipe';
import { SortByPricePipe } from '../../utils/sort-by-price.pipe';
import { SortEntitiesByPricePipe } from '../../utils/sort-entities-by-price.pipe';
import { GroupAccessoriesPipe } from './group-accessories.pipe';

@Component({
    selector: 'ncg-configurator-accessories',
    animations: [listAnimation],
    template: `
        <ng-container *ngIf="configuratorFacade.routerQueryParams$ | async as params">
            <div class="container" *ngIf="configuratorFacade.model$ | async as model" [@listAnimation]="model.accessoryIds?.length">
                <ncg-accordion
                    class="accordion"
                    *ngFor="
                        let group of (accessories$ | async) || []
                            | sortEntitiesByPrice: 'AccessoryRetailSellingPrice' : 'desc'
                            | async
                            | groupAccessories
                    "
                    [expanded]="openGroups"
                >
                    <h2 class="configurator__label accordion-title" title>
                        {{ group.category }}
                        <ng-container *ngIf="configuratorFacade.accessories$ | selectedCardsCount: group.category | async as count">
                            <span class="card-count" *ngIf="count > 0">({{ count }})</span>
                        </ng-container>
                    </h2>
                    <div content class="configurator__cards card-container">
                        <ncg-card
                            *ngFor="let accessory of group.accessories"
                            [selected]="(accessory.id || '' | multiParamActiveState: params.accessories) === 'active'"
                            variant="checkbox"
                            contentClass="configurator__card-content content"
                            class="configurator__card"
                        >
                            <ncg-configurator-image
                                class="mb-5"
                                [fields]="accessory.resources | resourceFieldsByVariantModel | async"
                                sizes="300px"
                                card-image
                            ></ncg-configurator-image>
                            <h3 class="configurator__label card-label" card-label>
                                <a
                                    class="configurator__font-weight--emphasis configurator__link-cover configurator__word-break"
                                    [routerLink]="[]"
                                    [replaceUrl]="true"
                                    [queryParams]="{ accessories: accessory.id | formatMultiParam: params.accessories }"
                                    queryParamsHandling="merge"
                                    [innerHTML]="accessory.fields | dataById: 'AccessoryMarketingTitle' | safe: 'html'"
                                ></a>
                            </h3>
                            <p class="configurator__text" *ngIf="accessory.fields | dataById: 'AccessoryMarketingDescription' as description">
                                {{ description }}
                            </p>
                            <p class="configurator__text-tiny" *ngIf="accessory.fields | dataById: 'AccessoryInfoText' as infoText">
                                {{ infoText }}
                            </p>
                            <div class="configurator__price price">
                                {{ accessory.fields | dataById: ('AccessoryRetailSellingPrice' | addCurrency | async) | currency }}
                            </div>
                        </ncg-card>
                    </div>
                </ncg-accordion>
                <div class="configurator__legal" *ngIf="configuratorFacade.variant$ | async as variant">
                    <p>
                        <strong>{{ variant.fields | dataById: 'VariantMarketingTitle' }}.</strong>
                        {{ variant.fields | dataById: 'VariantLegalText' }}<br />
                        {{ variant.fields | dataById: 'VariantLegalTextConfigurator' }}
                    </p>
                </div>
            </div>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styles: [
        `
            .accordion {
                display: block;
            }
            .price {
                text-align: right;
            }
            .accordion-title {
                margin-bottom: 0;
            }
            .card-count {
                font-family: var(--font-family-base);
                margin-left: 1.2rem;
                color: var(--color-grey);
                font-weight: normal;
            }
            .card-container {
                padding: 1.4rem 0;
            }
            .card-label {
                margin: 0.2rem 0 0;
            }
        `,
    ],
    imports: [
        CommonModule,
        CardComponent,
        DataByIdPipe,
        CurrencyFormatterPipe,
        TranslateModule,
        RouterModule,
        MultiParamActiveStatePipe,
        FormatMultiParamPipe,
        AccordionComponent,
        AddCurrencyPipe,
        ConfiguratorImageComponent,
        SelectedCardsCountPipe,
        UtilsModule,
        ResourceFieldsByVariantModelPipe,
        SortByPricePipe,
        GroupAccessoriesPipe,
        SortEntitiesByPricePipe,
    ],
})
export class ConfiguratorAccessoriesComponent implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    public openGroups = false; // Added to aid visual regression testing in Chromatic.
    public accessories$ = combineLatest([this.configuratorFacade.model$, this.configuratorFacade.variant$, this.configuratorFacade.trim$]).pipe(
        map(([model, variant, trim]) => {
            const ids = variant?.accessoryIds ?? trim?.accessoryIds ?? model?.accessoryIds;
            return (ids?.map((id) => model?.accessories?.[id]) ?? []).filter(this.isAccessory);
        }),
        takeUntil(this.unsubscribe)
    );

    constructor(public readonly configuratorFacade: ConfiguratorFacade) {}

    private readonly isAccessory = (item: DataEntityWithResources | undefined): item is DataEntityWithResources => !!item;

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
