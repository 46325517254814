import { animate, animateChild, group, query, stagger, style, transition, trigger } from '@angular/animations';

export const listAnimation = [
    trigger('listAnimation', [
        transition('* <=> *', [
            group([
                query(
                    ':enter',
                    [
                        style({
                            opacity: 0,
                            transform: 'translateY(40px)',
                            transformOrigin: '50% 50%',
                        }),
                        stagger(
                            '60ms',
                            animate(
                                '500ms cubic-bezier(0.390, 0.575, 0.565, 1.000)',
                                style({
                                    opacity: 1,
                                    transform: 'translateY(0)',
                                    transformOrigin: '50% 50%',
                                })
                            )
                        ),
                    ],
                    { optional: true }
                ),
                query('@*', [animateChild()], { optional: true }),
            ]),
        ]),
    ]),
];
