import { Component, Input } from '@angular/core';
import { LinkImageSpot } from '@ncg/data';
import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-link-image',
    template: `
        <div class="link-image" *ngIf="data" ncgLoadIn>
            <ng-container *ngIf="data.link; else image">
                <a class="is-text" [routerLink]="data.link.url" [attr.title]="data.link?.name">
                    <ng-container *ngTemplateOutlet="image"></ng-container>
                </a>
            </ng-container>

            <ng-template #image>
                <figure class="figure">
                    <picture class="link-image__image" *ngIf="data.image as image">
                        <source media="(min-width: 1024px)" [srcset]="image | imageUrl: { width: 1220, mode: 'crop' } : 2" />
                        <source media="(min-width: 768px)" [srcset]="image | imageUrl: { width: 1024, mode: 'crop' } : 2" />
                        <source media="(min-width: 415px)" [srcset]="image | imageUrl: { width: 767, mode: 'crop' } : 2" />
                        <source media="(max-width: 414px)" [srcset]="image | imageUrl: { width: 394, mode: 'crop' } : 2" />
                        <img [src]="image | imageUrl: { width: 1220, mode: 'crop' }" [alt]="image.altText || ''" loading="lazy" ncgImageLoad />
                    </picture>
                </figure>
            </ng-template>
        </div>
    `,
})
export class LinkImageSpotComponent extends SpotBaseDirective {
    static ref = 'linkimage';
    @Input() data: LinkImageSpot;
}
