import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { INavigationResponse } from '@ncg/data';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, take, takeUntil } from 'rxjs/operators';

import { SettingsService } from '../core/settings.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    private _navigationTreeObs$ = new ReplaySubject<INavigationResponse[]>(1);
    public currentPageId$ = new ReplaySubject<number>(1);
    public isFrontPage$ = new ReplaySubject<boolean>(1);
    public breadcrumb$ = new BehaviorSubject<
        | {
              key: string;
              value: string;
          }[]
        | undefined
    >(undefined);
    languageLinks$ = new BehaviorSubject<Record<string, string> | undefined>(undefined);

    constructor(
        private readonly settingsService: SettingsService,
        private readonly http: HttpClient
    ) {
        this.initNavigation();
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public getNavigation(): Observable<INavigationResponse[]> {
        return this._navigationTreeObs$.asObservable().pipe(take(1));
    }

    /**
     * Use this method to get second level of menu items in the array. (index = 1)
     * At the moment it's only relevant for the Honda App
     */
    public getNavigationById() {
        return combineLatest([this.currentPageId$, this._navigationTreeObs$.asObservable()]).pipe(
            distinctUntilChanged(),
            switchMap(([currentId, menu]) => {
                let foundInIndex = 0;
                for (const [menuIndex, menuItem] of menu.entries()) {
                    // Check if current page is a first level menu item, then use its index.
                    if (menuItem.id === currentId) {
                        foundInIndex = menuIndex;
                        break;
                    } else {
                        // Check if current pages is a nested item
                        const checkFirstLevelsChildren = (items: INavigationResponse[]) => {
                            for (const item of items) {
                                if (item.id === currentId) {
                                    foundInIndex = menuIndex;
                                    break;
                                }

                                checkFirstLevelsChildren(item.children ?? []);
                            }
                        };

                        checkFirstLevelsChildren(menuItem.children ?? []);
                    }
                }

                return of(menu[foundInIndex].children ?? []);
            }),
            take(1)
        );
    }

    public isFrontPage() {
        return this.isFrontPage$.asObservable();
    }

    public getDescendantsByTag(rootId: number, tagName: string, page = 1, pageSize = 10) {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) =>
                this.http.get<INavigationResponse[]>(`/api/navigation/descendantsbytag/${rootId}/${tagName}`, {
                    params: {
                        skip: (page - 1) * pageSize,
                        take: pageSize,
                        culture,
                    },
                })
            ),
            takeUntil(this.unsubscribe)
        );
    }

    private initNavigation(): void {
        this.settingsService
            .getCulture()
            .pipe(
                switchMap((culture) =>
                    this.http.get<INavigationResponse[]>('/api/navigation', {
                        params: {
                            culture,
                            levels: 10,
                        },
                    })
                ),
                takeUntil(this.unsubscribe)
            )
            .subscribe((menu) => {
                this._navigationTreeObs$.next(menu);
            });
    }
}
