import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CarModel, TrimViewModel } from '@ncg/data';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { AccordionComponent } from '../../../../accordion/accordion.component';
import { ExpansionPanelModule } from '../../../../expansion-panel/expansion-panel.module';
import { DataByIdPipe } from '../../../utils/data-by-id.pipe';
import { ListItemDirective } from '../../../../list-item/list-item.directive';

@Component({
    selector: 'ncg-configurator-trims-equipment-sidepanel',
    template: `
        <ncg-accordion *ngFor="let trim of trimsWithStandardOptions; index as i" [expanded]="trim.id === selectedTrimId">
            <h4 title class="equipment-list-heading">
                {{ 'configurator.options_title' | translate: { trim: (trim.fields | dataById: 'CommercialTrimName') } }}
            </h4>

            <ul class="list" content>
                <li ncgListItem *ngIf="i > 0" class="all-lower-equipment-list-item">
                    {{
                        'configurator.options_title'
                            | translate: { trim: (trimsWithStandardOptions?.[i - 1]?.fields | dataById: 'CommercialTrimName') }
                    }}
                </li>
                <ng-container *ngFor="let value of cleanedAndSortedTrims[i]">
                    <li ncgListItem>
                        {{ value }}
                    </li>
                </ng-container>
            </ul>
        </ncg-accordion>
    `,
    standalone: true,
    styles: [
        `
            .equipment-list-heading {
                color: var(--color-primary);
            }
            .all-lower-equipment-list-item {
                font-family: var(--font-family-base-bold);
                padding-bottom: 0.8em;
                &:before {
                    content: none;
                }
                &:after {
                    content: '\\0020+';
                    white-space: pre;
                }
            }
        `,
    ],
    imports: [CommonModule, IconSpriteModule, ExpansionPanelModule, DataByIdPipe, TranslateModule, AccordionComponent, ListItemDirective],
})
export class ConfiguratorTrimsEquipmentSidepanelComponent implements OnInit {
    @Input() model: CarModel;
    @Input() selectedTrimId: TrimViewModel['id'];
    public trimsWithStandardOptions: CarModel['trims'];
    public cleanedAndSortedTrims: string[][];

    private getTrimOptionsSorted(trimsWithStandardOptions: CarModel['trims']): any[] {
        if (!trimsWithStandardOptions) {
            return [];
        }
        return trimsWithStandardOptions.map(
            (trim) =>
                trim.standardOptionsDisplayIds
                    ?.map((trimId) => this.model.standardOptions?.[trimId].fields?.find((x) => x.fieldTypeId === 'OptionMarketingTitle')?.data?.value)
                    .sort((a, b) => String(a).localeCompare(String(b)))
        );
    }

    private removeDuplicatesBetweenArrays(arrayOfArrays: string[][]): string[][] {
        // Use a Set to store unique elements while preserving order
        const uniqueElementsSet = new Set<string>();

        // Create a new array for each inner array with unique elements
        return arrayOfArrays.map((stringArray) => {
            const uniqueArray: string[] = [];

            // Iterate through the array and add unique elements to the set and new array
            stringArray.forEach((element) => {
                if (!uniqueElementsSet.has(element)) {
                    uniqueElementsSet.add(element);
                    uniqueArray.push(element);
                }
            });

            return uniqueArray;
        });
    }

    public ngOnInit(): void {
        this.trimsWithStandardOptions = this.model.trims?.filter((x) => (x.standardOptionsDisplayIds?.length || 0) > 0);

        // This kinda breaks the elegance of the pipes... but I couldn't figure out how to integrate it without changing even more stuff.
        this.cleanedAndSortedTrims = this.removeDuplicatesBetweenArrays(this.getTrimOptionsSorted(this.trimsWithStandardOptions));
    }
}
