import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[ncgListItem]',
    standalone: true,
})
export class ListItemDirective implements AfterViewInit {
    @Input() public iconName?: 'info' | 'attention_filled'; // Add more icons as needed. Note that they need to be in the icons folder
    constructor(private readonly elementRef: ElementRef) {}
    public ngAfterViewInit(): void {
        if (!this.iconName) {
            return;
        }
        this.elementRef.nativeElement.style.setProperty('--icon-url', `url('/assets/icons/${this.iconName}.svg')`);
    }
}
