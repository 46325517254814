import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SpotsUiModule } from '../spots/spots-ui.module';
import { UmbGridComponent } from './umb-grid/umb-grid.component';
import { UmbSectionComponent } from './umb-section/umb-section.component';

@NgModule({
    imports: [CommonModule, SpotsUiModule],
    declarations: [UmbSectionComponent, UmbGridComponent],
    exports: [UmbSectionComponent, UmbGridComponent],
})
export class UmbracoGridModule {}
