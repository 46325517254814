import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { LazyContentModule } from '../$lazy-content/lazy-content.module';
import { InfoLabelModule } from '../label/info-label.module';
import { OverlayModule } from '../overlay/overlay.module';
import { SearchIconModule } from '../search-icon/search-icon.module';
import { UtilsModule } from '../utils/utils.module';
import { DealerComponent } from './dealer/dealer.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MegaMenuAlternateComponent } from './mega-menu-alternate/mega-menu-alternate.component';
import { MegaMenuImageComponent } from './mega-menu-image/mega-menu-image.component';
import { MegaMenuComponent } from './mega-menu/mega-menu.component';
import { MegaModelsMenuComponent } from './mega-models-menu/mega-models-menu.component';
import { MetaMenuCarsComponent } from './meta-menu/meta-menu-cars.component';
import { MetaMenuLinksComponent } from './meta-menu/meta-menu-links.component';
import { MetaMenuOverlayComponent } from './meta-menu/meta-menu-overlay.component';
import { MetaMenuComponent } from './meta-menu/meta-menu.component';
import { MobileHeaderLinkComponent } from './mobile-header-link/mobile-header-link.component';
import { MobileHeaderSearchComponent } from './mobile-header-search/mobile-header-search.component';
import { MobileHeaderComponent } from './mobile-menu/mobile-header/mobile-header.component';
import { MobileMenuTriggerDirective } from './mobile-menu/mobile-menu-trigger.directive';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { MobileNavListComponent } from './mobile-menu/mobile-nav-list/mobile-nav-list.component';
import { ShortcutsComponent } from './shortcuts/shortcuts.component';
import { TopMenuComponent } from './top-menu/top-menu.component';

@NgModule({
    declarations: [
        MainMenuComponent,
        MegaMenuComponent,
        MegaMenuAlternateComponent,
        MegaModelsMenuComponent,
        ShortcutsComponent,
        MetaMenuComponent,
        MobileMenuComponent,
        MegaMenuImageComponent,
        MobileMenuTriggerDirective,
        MobileHeaderComponent,
        MobileNavListComponent,
        DealerComponent,
        MetaMenuOverlayComponent,
        MetaMenuLinksComponent,
        MetaMenuCarsComponent,
        LanguageSelectorComponent,
        MobileHeaderLinkComponent,
        MobileHeaderSearchComponent,
    ],
    imports: [
        CommonModule,
        OverlayModule,
        RouterModule,
        TranslateModule.forChild(),
        BrowserAnimationsModule,
        UtilsModule,
        InfoLabelModule,
        IconSpriteModule,
        SearchIconModule,
        LazyContentModule,
        TopMenuComponent,
    ],
    exports: [
        MainMenuComponent,
        MetaMenuComponent,
        MobileMenuTriggerDirective,
        DealerComponent,
        MetaMenuLinksComponent,
        MobileHeaderLinkComponent,
        MobileHeaderSearchComponent,
    ],
})
export class NavigationModule {}
