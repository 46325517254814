import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { NcapStarsComponent } from './ncap-stars.component';

@NgModule({
    imports: [CommonModule, IconSpriteModule],
    declarations: [NcapStarsComponent],
    exports: [NcapStarsComponent],
})
export class NcapStarsModule {}
