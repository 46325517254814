import { animate, group, query, stagger, state, style, transition, trigger } from '@angular/animations';

export const showAllAnimation = trigger('showAllAnimation', [
    transition('* <=> *', [
        query(
            ':enter',
            [
                style({
                    opacity: 0,
                    transform: 'translateY(40px)',
                    transformOrigin: '50% 50%',
                }),
                stagger(
                    '20ms',
                    animate(
                        '400ms cubic-bezier(0.390, 0.575, 0.565, 1.000)',
                        style({
                            opacity: 1,
                            transform: 'translateY(0)',
                            transformOrigin: '50% 50%',
                        })
                    )
                ),
            ],
            { optional: true }
        ),
        query(':leave', animate('200ms ease-out', style({ opacity: 0 })), { optional: true }),
    ]),
]);

export const expansionAnimation = trigger('expandAnimation', [
    state(
        'closed',
        style({
            height: '{{ closedHeight }}',
        }),
        { params: { closedHeight: '0px' } }
    ),
    state(
        'expanded',
        style({
            height: '*',
        })
    ),
    transition('closed => expanded', [
        group([
            animate('400ms 0ms cubic-bezier(0.23, 0, 0.13, 1)'),
            query(
                '.expansion-panel__stagger--animate',
                [
                    style({
                        transform: 'translate3d(-100%, 0, 0)',
                    }),
                    stagger(10, [
                        animate(
                            '400ms 0ms cubic-bezier(0.23, 0, 0.13, 1)',
                            style({
                                transform: 'translate3d(0, 0, 0)',
                            })
                        ),
                    ]),
                ],
                { optional: true }
            ),
        ]),
    ]),
    transition(
        'expanded => closed',
        [
            group([
                style({
                    height: '*',
                }),
                animate(
                    '400ms 0ms cubic-bezier(0.23, 0, 0.13, 1)',
                    style({
                        height: '{{ closedHeight }}',
                    })
                ),
                query(
                    '.expansion-panel__stagger--animate',
                    [
                        style({
                            transform: 'translate3d(0, 0, 0)',
                        }),
                        stagger(-10, [
                            animate(
                                '500ms 0ms cubic-bezier(0.23, 0, 0.13, 1)',
                                style({
                                    transform: 'translate3d(-100%, 0, 0)',
                                })
                            ),
                        ]),
                    ],
                    { optional: true }
                ),
            ]),
        ],
        { params: { closedHeight: '0px' } }
    ),
]);
