import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PRODUCTS_FEATURE_KEY, productsAdapter, ProductsState } from './products.reducer';

// Lookup the 'Products' feature state managed by NgRx
export const getProductsState = createFeatureSelector<ProductsState>(PRODUCTS_FEATURE_KEY);

const { selectAll, selectEntities } = productsAdapter.getSelectors();

export const getFetching = createSelector(getProductsState, (state: ProductsState) => state.isFetching);

export const getAllProducts = createSelector(getProductsState, (state: ProductsState) => selectAll(state));

export const getProductsEntities = createSelector(getProductsState, (state: ProductsState) => selectEntities(state));

export const getFilterParams = createSelector(getProductsState, (state: ProductsState) => state.filterParams);

export const getLayout = createSelector(getProductsState, (state: ProductsState) => state.layout);

export const getTotal = createSelector(getProductsState, (state: ProductsState) => state.total);

export const getFacets = createSelector(getProductsState, (state: ProductsState) => state.facets);

export const getQuickFilterfacets = createSelector(getProductsState, (state: ProductsState) => state.quickFilterfacets);

export const getSelectedFacets = createSelector(getProductsState, (state: ProductsState) => state.selectedFacets);

export const getFacetList = createSelector(getProductsState, (state: ProductsState) => state.facetList);

export const getPremiumText = createSelector(getProductsState, (state: ProductsState) => state.premiumText);

export const getSelectedPremiumText = createSelector(getProductsState, (state: ProductsState) => state.selectedPremiumText);

export const getIsPremiumEnabled = createSelector(getProductsState, (state: ProductsState) => state.isPremiumEnabled);

export const getDropdownState = createSelector(getProductsState, (state: ProductsState) => state.dropdownState);
