import { Injectable, OnDestroy } from '@angular/core';
import { ILink } from '@ncg/data';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { SettingsService } from '../core/settings.service';
import { DialogService } from '../utils/dialog/dialog.service';
import { SearchComponent } from './search.component';

@Injectable({
    providedIn: 'root',
})
export class SearchDialogService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    quicklinksSearch: ILink[] = [];

    constructor(
        private readonly dialogService: DialogService,
        private readonly settingsService: SettingsService
    ) {
        this.getSettings();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    openSearchDialog(isMobile = false) {
        this.dialogService.openDialogWithComponent(SearchComponent, {
            fullHeight: true,
            blockScroll: true,
            hasBackdrop: true,
            data: {
                isMobile,
                quicklinksSearch: this.quicklinksSearch,
            },
            positionHorizontal: {
                placement: 'right',
            },
        });
    }

    private getSettings() {
        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.quicklinksSearch = settings.quicklinksSearch;
            });
    }
}
