import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EnergyLabelComponent } from './energy-labels.component';

@NgModule({
    declarations: [EnergyLabelComponent],
    imports: [CommonModule],
    exports: [EnergyLabelComponent],
})
export class EnergyLabelModule {}
