import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DynamicRoutingModule } from '../core/routing-utils/dynamic-routing.module';
import { OverlayModule } from '../overlay/overlay.module';
import { DatepickerI18nDirective } from './datepicker-i18n/datepicker-i18n.directive';
import { FilterPipe } from './filter.pipe';
import { ImageLoadDirective } from './image-load/image-load.directive';
import { ImageUrlPipe } from './image-url.pipe';
import { InViewportDirective } from './in-viewport/in-viewport.directive';
import { InterceptLinksDirective } from './intercept-links/intercept-links.directive';
import { LoadInDirective } from './load-in/load-in.directive';
import { padPipe } from './pad.pipe';
import { ProductImagePipe } from './product-image.pipe';
import { SafePipe } from './safe.pipe';
import { SrcSetPipe } from './srcset.pipe';
import { PortalComponent } from '../portal/portal.component';

@NgModule({
    imports: [CommonModule, OverlayModule, DynamicRoutingModule, TranslateModule.forChild(), PortalComponent],
    declarations: [
        DatepickerI18nDirective,
        FilterPipe,
        ImageLoadDirective,
        ImageUrlPipe,
        InterceptLinksDirective,
        InViewportDirective,
        LoadInDirective,
        padPipe,
        ProductImagePipe,
        SafePipe,
        SrcSetPipe,
    ],
    exports: [
        DatepickerI18nDirective,
        FilterPipe,
        ImageLoadDirective,
        ImageUrlPipe,
        InterceptLinksDirective,
        InViewportDirective,
        LoadInDirective,
        padPipe,
        ProductImagePipe,
        SafePipe,
        SrcSetPipe,
        PortalComponent,
    ],
    providers: [ProductImagePipe],
})
export class UtilsModule {}
