import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { IAmountUsedCarsResponse, IFilterItemViewModel, IFilterParams, IFilterSearchResultViewModel } from '@ncg/data';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { SettingsService } from '../core/settings.service';

/**
 * List all allowed query params to be passed with filter
 */
const filterWhiteList = [
    'take',
    'from',
    'q',
    'sort',
    'make.keyword',
    'model.keyword',
    'series.keyword',
    'bodytypes.keyword',
    'year',
    'propellant.keyword',
    'isleasing',
    'iscash',
    'ispremium',
    'price',
    'geartype.keyword',
    'effect',
    'mileage',
    'dealername.keyword',
    'isdemo',
    'haspictures',
] as const;

type FilterKey = (typeof filterWhiteList)[number];

@Injectable({
    providedIn: 'root',
})
export class ProductService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private readonly apiBaseUrl = '/api/product';
    private readonly usedCarsAmount$ = new ReplaySubject<IAmountUsedCarsResponse | undefined>(1);

    private usedCarsAmountValue$ = this.usedCarsAmount$.asObservable();
    private hasUsedCars = false;
    private defaultFilterParams: IFilterParams = {};
    private hasAddedHistoryEntry = false;

    public readonly takeAmount: number = 24;

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly settingsService: SettingsService,
        protected location: Location
    ) {
        this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            this.hasAddedHistoryEntry = false;
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public getUsedCarsAmount(): Observable<IAmountUsedCarsResponse | undefined> {
        if (!this.hasUsedCars) {
            this.http
                .get<IAmountUsedCarsResponse>(`${this.apiBaseUrl}/amount-used-cars`)
                .pipe(
                    catchError(() => of(undefined)),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((amount) => this.usedCarsAmount$.next(amount));
            this.hasUsedCars = true;
        }

        return this.usedCarsAmountValue$.pipe(take(1));
    }

    public filter(model?: IFilterParams, updateUrl = false): Observable<IFilterSearchResultViewModel> {
        if (updateUrl) {
            this.updateQueryParams(model);
        }

        // Only send known/approved parameters
        const whiteListedFilterParams = this.getWhiteListedFilterParams(model);
        return this.settingsService.getCulture().pipe(
            switchMap((culture) => {
                const params = new HttpParams({ fromObject: { ...whiteListedFilterParams, culture } });
                return this.http.get<IFilterSearchResultViewModel>(`${this.apiBaseUrl}/filter`, { params });
            }),
            takeUntil(this.unsubscribe)
        );
    }

    public getQueryParams(): Params {
        return this.router.parseUrl(this.location.path()).queryParams;
    }

    public getRelatedById(id: string): Observable<IFilterItemViewModel[]> {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) => {
                const params = new HttpParams({ fromObject: { culture } });
                return this.http.get<IFilterItemViewModel[]>(`${this.apiBaseUrl}/filter/${id}/related`, { params });
            }),
            takeUntil(this.unsubscribe)
        );
    }

    public getByIds(ids: string[]): Observable<IFilterItemViewModel[]> {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) => {
                const params = new HttpParams({ fromObject: { id: ids, culture } });
                return this.http.get<IFilterItemViewModel[]>(`${this.apiBaseUrl}/filter/cars`, { params });
            }),
            takeUntil(this.unsubscribe)
        );
    }

    public setDefaultFilterParams(params: IFilterParams): void {
        this.defaultFilterParams = params;
    }

    private getWhiteListedFilterParams(model?: IFilterParams): undefined | IFilterParams {
        if (model) {
            const modelCopy: IFilterParams = {};
            for (const key in model) {
                if (model.hasOwnProperty(key) && filterWhiteList.includes(key.toLowerCase() as FilterKey)) {
                    modelCopy[key.toLowerCase()] = model[key];
                }
            }
            return modelCopy;
        }

        return model;
    }

    // Filters should reset to default filters when using the browser back button.
    // This means at most 2 filter page entries will exist. [default, extra-filters]
    // Note: The current setup does not clear duplicate default entries, e.g.
    // when using reset, the history entries will look like [default, default]
    private updateQueryParams(params?: Params) {
        const isCurrentDefaultFilter = this.isDefaultFilterParams(this.getWhiteListedFilterParams(this.getQueryParams()));
        const { navigationId } = this.location.getState() as { navigationId: number };
        const shouldReplace = !isCurrentDefaultFilter || this.hasAddedHistoryEntry;
        const currentUrl = this.location.path();
        const serializedUrl = this.router.serializeUrl(this.router.createUrlTree([], { queryParams: params }));

        // If the filter page is the first page, add an entry in
        // the history, that is just the filterpage with default filterparams
        if (navigationId === 1 && shouldReplace) {
            const baseUrl = this.router.serializeUrl(this.router.createUrlTree([], { queryParams: this.defaultFilterParams }));
            this.location.replaceState(baseUrl, undefined, this.location.getState());
            const nonDefaultParams = this.getNonDefaultParams(params);
            if (Object.keys(nonDefaultParams).length) {
                this.location.go(serializedUrl, undefined, this.location.getState());
                this.hasAddedHistoryEntry = true;
            }
            return;
        }

        // history.back breaks if this check is not here..
        if (currentUrl !== serializedUrl) {
            if (shouldReplace) {
                this.location.replaceState(serializedUrl, undefined, this.location.getState());
            } else {
                this.location.go(serializedUrl, undefined, this.location.getState());
                this.hasAddedHistoryEntry = true;
            }
        }
    }

    private isDefaultFilterParams(params?: IFilterParams): boolean {
        if (!params) {
            return false;
        }
        const keys = Object.keys(params);
        const defaultKeys = Object.keys(this.defaultFilterParams);
        if (keys.length !== defaultKeys.length) {
            return false;
        }
        return keys.every((key) => defaultKeys.includes(key as FilterKey));
    }

    private getNonDefaultParams(params?: IFilterParams): IFilterParams {
        if (!params) {
            return {};
        }
        const nonDefaultParams = { ...params };
        Object.keys(this.defaultFilterParams).forEach((key) => delete nonDefaultParams[key]);
        return nonDefaultParams;
    }
}
