import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, NgModule, OnInit } from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ImpactOverlayRef } from '../../overlay/overlay-ref';
import { IMPACT_OVERLAY_DATA } from '../../overlay/overlay.tokens';
import { TranslateModule } from '@ngx-translate/core';

import { UtilsModule } from '../utils.module';
import { SimpleDialogData } from './dialog.interface';
import { DynamicRoutingModule } from '../../core/routing-utils/dynamic-routing.module';

@Component({
    template: `
        <aside class="dialog {{ data.dialogClassModifier || '' }}" role="dialog" [id]="dialogRef.id">
            <header class="dialog__header">
                <h2 *ngIf="data.header">{{ data.header | translate }}</h2>
                <button class="dialog__close button" (click)="onClose()">
                    <svg-icon-sprite
                        [src]="'close_light'"
                        [viewBox]="'0 0 30 30'"
                        [width]="'30px'"
                        [height]="'30px'"
                        aria-hidden="true"
                        class="is-flex"
                        classes=""
                    ></svg-icon-sprite>
                </button>
            </header>
            <div class="dialog__content" *ngIf="texts?.length">
                <ng-container *ngIf="data.textIsSafe; else unsafeText">
                    <div *ngFor="let value of texts" [innerHTML]="value | safe: 'html'" ncgInterceptLinks [dialogId]="dialogRef.id"></div>
                </ng-container>

                <ng-template #unsafeText>
                    <div *ngFor="let value of texts">{{ value | translate }}</div>
                </ng-template>
            </div>
            <div class="dialog__actions">
                <button *ngIf="!data.disableCloseBtn" class="button is-secondary is-small" (click)="onClose()">
                    {{ data.closeBtn || 'dialog.button_close' | translate }}
                </button>
                <button *ngIf="!data.disableOkBtn" class="button is-primary is-small" (click)="onAccept()">
                    {{ data.okBtn || 'dialog.button_ok' | translate }}
                </button>
            </div>
        </aside>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnInit {
    public texts: string[];

    constructor(
        @Inject(IMPACT_OVERLAY_DATA) public data: SimpleDialogData,
        public dialogRef: ImpactOverlayRef
    ) {}

    ngOnInit() {
        if (this.data && this.data.text) {
            if (typeof this.data.text === 'string') {
                this.texts = [this.data.text];
            } else {
                this.texts = this.data.text;
            }
        }
    }

    @HostListener('document:keydown.esc', ['$event'])
    onClose(): void {
        this.dialogRef.close();
    }

    @HostListener('document:keydown.enter', ['$event'])
    onAccept(): void {
        this.dialogRef.close(true);
    }
}

@NgModule({
    imports: [CommonModule, UtilsModule, DynamicRoutingModule, TranslateModule.forChild(), IconSpriteModule],
    declarations: [DialogComponent],
})
export class DialogComponentModule {}
