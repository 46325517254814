import { Pipe, PipeTransform } from '@angular/core';
import { VariantViewModel } from '@ncg/data';
import { getPriceDifferenceComparedToCheapest } from './by-price.util';

@Pipe({
    name: 'getPriceDifference',
    standalone: true,
    pure: true,
})
export class GetPriceDifferencePipe implements PipeTransform {
    transform(variants?: VariantViewModel[], fieldName?: string | null, variantTotalPrice?: number | string) {
        const variantTotal = Number(variantTotalPrice) || 0;
        if (!variants || !fieldName) {
            return 0;
        }
        return getPriceDifferenceComparedToCheapest(variants, fieldName, variantTotal);
    }
}
