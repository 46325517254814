import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { SettingsService } from '../../core/settings.service';

@Pipe({
    name: 'addCurrency',
    standalone: true,
    pure: true,
})
export class AddCurrencyPipe implements PipeTransform {
    constructor(private readonly settingsService: SettingsService) {}
    transform(fieldName: string): Observable<string> {
        return (this.settingsService.settings$ || of({ currency: 'DKK' })).pipe(map(({ currency }) => `${fieldName}${currency}`));
    }
}
