import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';

import { AppModule } from './app/app.module';

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err: any) => console.error(err));
};

if (document.readyState === 'complete') {
    bootstrap();
} else {
    document.addEventListener('DOMContentLoaded', bootstrap);
}
