import { BehaviorSubject, Observable, Subject, map, takeUntil } from 'rxjs';
import { IProductPageResponse } from '@ncg/data';
import { ProductImagePipe } from '../../utils/product-image.pipe';
import { SidePanelService } from '../../side-panel/side-panel.service';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class ProductPageService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private readonly productPageDataBs$ = new BehaviorSubject<Partial<IProductPageResponse>>({});
    public productPageData$ = this.productPageDataBs$.asObservable();
    public productAdType$: Observable<'cash' | 'leasing'> = this.productPageData$.pipe(
        map(({ product }) => {
            if (!product) {
                return 'cash';
            }
            const isCash = !!product.cashPrice || product.priceType === 'CallForPrice';
            const isLeasing = !!product.leasingPrice || product.priceType === 'CallForLeasing';

            // Cash has priority, because it can be both, but we prioritize cash
            if (isCash) {
                return 'cash';
            } else if (isLeasing) {
                return 'leasing';
            }
            return 'cash';
        }),
        takeUntil(this.unsubscribe)
    );

    constructor(
        private readonly productImagePipe: ProductImagePipe,
        private readonly sidePanelService: SidePanelService
    ) {}

    public setProductPageData(data: IProductPageResponse): void {
        this.productPageDataBs$.next(data);
    }

    public openSendOffer(): void {
        const { product, dealership } = this.productPageDataBs$.value;
        if (!product) {
            return;
        }
        const imageUrl = product.properties.pictures?.length
            ? this.productImagePipe.transform(product?.properties?.pictures?.[0], 'small')
            : undefined;

        this.sidePanelService.openOffer({
            id: product.properties.id?.toString() || '',
            name: product.title,
            subtitle: product.variant,
            isUsedCar: true,
            isCashAllowed: true,
            isLoanAllowed: true,
            isLeasingAllowed: !!product.leasingPrice,
            location: dealership?.location,
            image: imageUrl ? { url: imageUrl, focalPoint: { left: 0.5, top: 0.5 }, extension: 'image' } : undefined,
        });
    }

    public openTestDrive(): void {
        const { product, dealership } = this.productPageDataBs$.value;
        if (!product) {
            return;
        }
        if (dealership?.location?.category?.code !== 'retail') {
            return;
        }

        this.sidePanelService.openTestDrive({
            usedCar: {
                id: product.properties.id?.toString() || '',
                make: product.properties.make ?? '',
                model: product.properties.model ?? '',
                location: dealership?.location,
            },
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
