import { ComponentRef, Directive, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { BaseSpot, IGridIndexes } from '@ncg/data';

import { spotComponents } from './spot-components';

/**
 * Standard directive to dynamically create spot components.
 *
 * Example:
 *
 * <ng-template
 * ncgDynamicSpotComponent
 * [spotType]="spot.alias"
 * [allowNarrowContent]="allowNarrowContent"
 * [spotData]="spot"
 * [isFirstSpot]="isFirstSection && isFirstSpot"
 * [indexes]="{ section: sectionIndex, spot: spotIndex }"
 * ></ng-template>
 */
@Directive({
    selector: '[ncgDynamicSpotComponent]',
})
export class DynamicSpotComponentDirective implements OnChanges {
    @Input()
    spotType?: string;

    @Input()
    spotData: Partial<BaseSpot>;

    @Input()
    indexes: IGridIndexes;

    @Input()
    isFirstSpot?: boolean;

    @Input()
    allowNarrowContent?: boolean;

    constructor(private readonly viewContainerRef: ViewContainerRef) {}

    public ngOnChanges(change: SimpleChanges) {
        if (change) {
            // Start out by clearing the view container
            this.viewContainerRef.clear();

            const componentClass = spotComponents.find((component) => component.ref === this.spotType);

            if (componentClass === undefined) {
                console.warn(`Could not find spot type: ${this.spotType}`);
                return;
            }

            const spotComponent: ComponentRef<any> = this.viewContainerRef.createComponent(componentClass as any);

            spotComponent.instance['data'] = this.spotData;
            spotComponent.instance['indexes'] = this.indexes;
            spotComponent.instance['allowNarrowContent'] = this.allowNarrowContent;
            spotComponent.instance['isFirstSpot'] = this.isFirstSpot;
        }
    }
}
