import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { IMetaLink } from '@ncg/data';

import { SearchDialogService } from '../../search/search-dialog.service';
import { SpotsConfig } from '../../spots/spots-config';

@Component({
    selector: 'ncg-meta-menu-overlay',
    template: `
        <div class="mobile-menu__item mobile-menu__search-wrapper">
            <button
                *ngIf="isSearchEnabled"
                type="button"
                class="mobile-menu__search level is-flex input"
                aria-label="Opens search dialog on mobile"
                (click)="openSearchDialog(true)"
            >
                <span>{{ 'search.button_text' | translate }}</span>
                <span class="mobile-menu__search-icon">
                    <svg-icon-sprite
                        [src]="'search'"
                        [viewBox]="'0 0 30 30'"
                        [width]="'30px'"
                        [height]="'30px'"
                        aria-hidden="true"
                        class="is-flex"
                    ></svg-icon-sprite>
                </span>
            </button>
        </div>
        <div *ngIf="metaLinks && metaLinks?.length" class="mobile-links">
            <aside class="menu">
                <ul class="menu-list">
                    <li *ngFor="let metaItem of metaLinks | filter: 'visibleOnMobile'; trackBy: trackByMethod" class="mobile-links__item">
                        <ng-container *ngIf="metaItem.link?.isExternal; else internalLink">
                            <a [href]="[metaItem.link.url]" target="_blank" rel="noopener">
                                <ng-container *ngTemplateOutlet="iconTmpl"></ng-container>
                                <span>{{ metaItem.name }}</span>
                            </a>
                        </ng-container>
                        <ng-template #internalLink>
                            <a [routerLink]="[metaItem.link.url]">
                                <ng-container *ngTemplateOutlet="iconTmpl"></ng-container>
                                <span>{{ metaItem.name }}</span>
                            </a>
                        </ng-template>
                    </li>
                </ul>
            </aside>
        </div>

        <ng-template #iconTmpl>
            <svg-icon-sprite
                [src]="spotsConfig.iconType === 'light' ? 'chevron-light-right' : 'chevron-right'"
                [viewBox]="'0 0 30 30'"
                [width]="'30px'"
                [height]="'30px'"
                aria-hidden="true"
                class="shortcut__icon"
            ></svg-icon-sprite>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaMenuOverlayComponent {
    @Input() metaLinks: IMetaLink[];
    @Input() isSearchEnabled = false;

    constructor(
        @Inject(SpotsConfig) public spotsConfig: SpotsConfig,
        private readonly searchServiceDialog: SearchDialogService
    ) {}

    openSearchDialog(isMobile = false) {
        this.searchServiceDialog.openSearchDialog(isMobile);
    }

    trackByMethod(index: number, item: any) {
        return item.name || index;
    }
}
