import { Component, Inject, Input, OnInit } from '@angular/core';
import { BannerSpot, IImage } from '@ncg/data';
import { MetaService } from '../../core/meta.service';
import { DesktopBannerAspectRatio, MobileBannerAspectRatio } from '../../utils/helpers/aspect-ratio';
import { breakpointQueries } from '../../utils/helpers/breakpoints';
import { IImageOptions, ImageUrl } from '../../utils/helpers/image-helper';
import { SpotBaseDirective } from '../spot-base.class';
import { SpotsConfig } from '../spots-config';

type BannerBreakpointKeys = 'mobile' | 'mobileLarge' | 'tablet' | 'desktop';
type BannerMediaSizes = { [key in BannerBreakpointKeys]?: string };
type BannerImageSrcSets = { [key in BannerBreakpointKeys]?: string };
interface BannerImageOptions {
    mobile: IImageOptions;
    desktop: IImageOptions;
}

/**
 *
 * ### Note for content editors
 *
 * __The optimal image size:__
 *
 * Width:         `3100px`<br>
 * Height:        `900px`<br>
 * Aspect ratio:  `31:9`<br>
 * Filetype:      `jpg` | `jpeg`
 *
 */
@Component({
    selector: 'ncg-banner-spot',
    template: `
        <div class="container is-full" *ngIf="bannerImage" ncgLoadIn [loadDuration]="loadDuration">
            <div class="container" [ngClass]="spotsConfig.isHeroFullViewport ? 'is-full' : 'is-fullwidth'">
                <picture class="is-ratio-wide">
                    <source [media]="mediaSizes.desktop" [srcset]="imageSrcSets.desktop" />
                    <source [media]="mediaSizes.tablet" [srcset]="imageSrcSets.tablet" />
                    <source [media]="mediaSizes.mobileLarge" [srcset]="imageSrcSets.mobileLarge" />
                    <source [media]="mediaSizes.mobile" [srcset]="imageSrcSets.mobile" />
                    <ng-container *ngIf="!isFirstSpot; else firstSpotImageTmpl">
                        <img [src]="imageUrl" [alt]="bannerImage.altText || ''" loading="lazy" ncgImageLoad />
                    </ng-container>
                    <ng-template #firstSpotImageTmpl>
                        <img [src]="imageUrl" [alt]="bannerImage.altText || ''" />
                    </ng-template>
                </picture>
            </div>
        </div>
    `,
})
export class BannerSpotComponent extends SpotBaseDirective implements OnInit {
    static ref = 'bannerspot';

    @Input()
    public data?: BannerSpot;

    @Input()
    public image?: IImage;

    @Input()
    public isPageBanner?: boolean;

    public bannerImage?: IImage;
    public loadDuration?: number;
    public imageSrcSets: BannerImageSrcSets;
    public mediaSizes: BannerMediaSizes;
    public imageUrl: string;
    public desktopBannerAspectRatio: number = DesktopBannerAspectRatio;
    public mobileBannerAspectRatio: number = MobileBannerAspectRatio;

    constructor(
        @Inject(SpotsConfig)
        public spotsConfig: SpotsConfig,
        private readonly metaService: MetaService
    ) {
        super();
    }

    public ngOnInit() {
        this.bannerImage = this.data?.image || this.image;
        this.loadDuration = this.isPageBanner ? 500 : undefined;
        if (this.bannerImage) {
            this.mediaSizes = breakpointQueries;
            this.imageUrl = this.getImageUrl(this.bannerImage);
            this.imageSrcSets = this.getImageSrcSets(this.bannerImage);

            if (!this.isFirstSpot) {
                return;
            }

            // Preload images if first spot
            Object.keys(this.imageSrcSets).forEach((key) => {
                const breakpoint = key as BannerBreakpointKeys;
                this.metaService.setPreloadImage(this.imageSrcSets[breakpoint] as string, this.mediaSizes[breakpoint]);
            });
            this.metaService.setPreloadImage(this.imageUrl as string, this.mediaSizes.desktop);
        }
    }

    private getDefaultImageOptions(): BannerImageOptions {
        return {
            mobile: { heightratio: this.mobileBannerAspectRatio, mode: 'crop' },
            desktop: { heightratio: this.desktopBannerAspectRatio, mode: 'crop' },
        };
    }

    private getImageSrcSets(image: IImage): BannerImageSrcSets {
        const { mobile: mobileOptions, desktop: desktopOptions } = this.getDefaultImageOptions();
        const { mobile, mobileLarge, tablet, desktop } = this.getBannerImageWidths();

        return {
            mobile: ImageUrl(image, { width: mobile, ...mobileOptions }, 2),
            mobileLarge: ImageUrl(image, { width: mobileLarge, ...mobileOptions }, 2),
            tablet: ImageUrl(image, { width: tablet, ...desktopOptions }, 2),
            desktop: ImageUrl(image, { width: desktop, ...desktopOptions }, 2),
        };
    }

    private getImageUrl(image: IImage): string {
        return ImageUrl(image, { width: 1550, ...this.getDefaultImageOptions().desktop });
    }

    // To ensure best alignment with storybook, widths are defined here as a public method
    public getBannerImageWidths() {
        return {
            mobile: 394,
            mobileLarge: 767,
            tablet: 1024,
            desktop: 1550,
        };
    }
}
