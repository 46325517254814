import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RichTextSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-richtext-spot',
    template: `
        <div class="richtext-spot" [class.is-narrow]="allowNarrowContent" *ngIf="data" ncgLoadIn>
            <div class="columns" [class.is-centered]="!data.isFullWidth">
                <div
                    class="column"
                    [ngClass]="{
                        'is-12': data.isFullWidth || allowNarrowContent,
                        'is-8': !data.isFullWidth && !allowNarrowContent
                    }"
                >
                    <ncg-rich-text *ngIf="data.text" [html]="data.text"></ncg-rich-text>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextSpotComponent extends SpotBaseDirective {
    static ref = 'richtext';

    @Input()
    data: RichTextSpot;
}
