import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AccordionSpot } from '@ncg/data';
import { MetaService } from '../../core/meta.service';
import { firstValueFrom } from 'rxjs';

import { SettingsService } from '../../core/settings.service';
import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-accordion-spot',
    template: `
        <ngx-json-ld *ngIf="schema" [json]="schema"></ngx-json-ld>
        <div class="columns is-centered" *ngIf="data && data.items && data.items.length">
            <div [class.is-8]="!allowNarrowContent" class="accordion-spot column" ncgLoadIn>
                <ncg-expansion-panel class="accordion-spot__item" *ngFor="let item of data.items">
                    <div class="accordion-spot__title" expansion-title>{{ item.title }}</div>
                    <ng-container expansion-icon>
                        <svg-icon-sprite
                            src="chevron-down"
                            [viewBox]="'0 0 30 30'"
                            [width]="'30px'"
                            [height]="'30px'"
                            aria-hidden="true"
                            class="is-flex"
                        ></svg-icon-sprite>
                    </ng-container>
                    <div class="accordion-spot__text">
                        <ncg-rich-text [html]="item.text"></ncg-rich-text>
                    </div>
                </ncg-expansion-panel>
            </div>
        </div>
    `,
})
export class AccordionSpotComponent extends SpotBaseDirective implements OnInit {
    static ref = 'accordion';
    @Input() data?: AccordionSpot;
    schema?: Record<string, any>;

    constructor(
        private readonly metaService: MetaService,
        private readonly settingsService: SettingsService,
        private readonly cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this._setJsonLd();
    }

    private async _setJsonLd() {
        const items = this.data?.items;
        if (!items?.length) {
            return;
        }

        const settings$ = this.settingsService.get();
        const { companyName } = await firstValueFrom(settings$);
        const currentUrl = this.metaService.getAbsoluteUrl();

        this.schema = {
            '@context': 'https://schema.org',
        };

        if (items.length === 1) {
            const item = items[0];
            this.schema['@type'] = 'QAPage';
            this.schema.mainEntity = {
                '@type': 'Question',
                'name': item.title,
                'answerCount': 1,
                'dateCreated': this.data?.dateCreated,
                'author': {
                    '@type': 'Organization',
                    'name': companyName,
                },
                'acceptedAnswer': {
                    '@type': 'Answer',
                    'text': item.text,
                    'dateCreated': this.data?.dateCreated,
                    'upvoteCount': 1,
                    'url': currentUrl,
                    'author': {
                        '@type': 'Organization',
                        'name': companyName,
                    },
                },
            };
        } else {
            const mainEntity = [];

            for (const item of items) {
                mainEntity.push({
                    '@type': 'Question',
                    'name': item.title,
                    'acceptedAnswer': {
                        '@type': 'Answer',
                        'text': item.text,
                    },
                });
            }

            this.schema['@type'] = 'FAQPage';
            this.schema.mainEntity = mainEntity;
        }

        this.cd.markForCheck();
    }
}
