import { Pipe, PipeTransform } from '@angular/core';
import { DataField } from '@ncg/data';
import { fieldById } from './field-by-id.util';

@Pipe({
    name: 'keyById',
    standalone: true,
    pure: true,
})
export class KeyByIdPipe implements PipeTransform {
    transform(fields?: DataField[] | null, id?: DataField['fieldTypeId']): string | null | undefined {
        return fieldById<DataField>(fields, id)?.data?.key;
    }
}
