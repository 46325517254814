import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITimelineItem, TimelineSpot } from '@ncg/data';
import SwiperCore, { EffectCreative, FreeMode, Keyboard, Navigation, Pagination, Swiper, Thumbs } from 'swiper';
import { SpotBaseDirective } from '../spot-base.class';

SwiperCore.use([Pagination, Navigation, Keyboard, EffectCreative, FreeMode, Thumbs]);

@Component({
    selector: 'ncg-timeline-spot',
    template: `
        <div class="timeline is-relative" *ngIf="timelineItems.length">
            <div class="timeline-thumbs" ncgLoadIn>
                <swiper
                    (swiper)="thumbsSwiper = $event"
                    [slidesPerView]="'auto'"
                    [threshold]="10"
                    [freeMode]="{ minimumVelocity: 0.05, momentumRatio: 0.5, momentumVelocityRatio: 0.5 }"
                    [centeredSlides]="true"
                    class="timeline-thumbs-swiper"
                >
                    <ng-template swiperSlide *ngFor="let item of timelineItems; let index = index">
                        <div class="timeline-thumbs__item">
                            <div class="timeline-thumbs__label">{{ item.label }}</div>
                            <div class="timeline-thumbs__bar"></div>
                        </div>
                    </ng-template>
                </swiper>
            </div>

            <swiper
                [slidesPerView]="1"
                [keyboard]="true"
                [spaceBetween]="40"
                [threshold]="10"
                [navigation]="true"
                [thumbs]="{ swiper: thumbsSwiper }"
            >
                <ng-template swiperSlide *ngFor="let item of timelineItems">
                    <ncg-timeline-item [item]="item"></ncg-timeline-item>
                </ng-template>
            </swiper>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineSpotComponent extends SpotBaseDirective {
    static ref = 'timeline';
    @Input() set data(timelineData: TimelineSpot) {
        this.timelineItems = timelineData.items;
    }
    timelineItems: ITimelineItem[];
    thumbsSwiper: Swiper;

    constructor() {
        super();
    }
}
