import { Directive, ElementRef } from '@angular/core';
import { FeatureDetectionService } from '../../core/feature-detection.service';

import { animateTo, fadeIn } from '../animations/easing.animations';

@Directive({
    selector: '[ncgImageLoad]',
})
export class ImageLoadDirective {
    transitionDuration = 600;

    constructor(el: ElementRef, featureDetectionService: FeatureDetectionService) {
        if (featureDetectionService.hasWebAnimation()) {
            const element: HTMLImageElement | HTMLVideoElement = el.nativeElement;

            if (element) {
                element.style.opacity = '0'; // Fixes the onload glitch

                if (element instanceof HTMLImageElement) {
                    element.onload = () => {
                        this._animateIn(element);
                    };
                } else if (element instanceof HTMLVideoElement) {
                    element.onloadeddata = () => {
                        this._animateIn(element);
                    };
                }
            }
        }
    }

    private _animateIn(element: HTMLImageElement | HTMLVideoElement) {
        element.style.opacity = ''; // Fixes the onload glitch
        animateTo(
            element,
            [
                {
                    opacity: '0',
                    offset: 0,
                },
            ],
            {
                duration: this.transitionDuration,
                easing: fadeIn,
            }
        ).finished.then(() => {
            if (element) {
                element.style.opacity = '';
            }
        });
    }
}
