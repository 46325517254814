import { Injectable, OnDestroy } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { PageResponse } from '@ncg/data';
import equal from 'fast-deep-equal';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FeatureDetectionService } from './feature-detection.service';

@Injectable({
    providedIn: 'root',
})
export class GlobalStateService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private readonly defaultStates: GlobalStates = { activeFlow: 'default' };

    private readonly spaStates$ = new BehaviorSubject<GlobalStates>(this.defaultStates);

    constructor(
        private readonly transferState: TransferState,
        private readonly featureDetection: FeatureDetectionService
    ) {
        const globalStatesKey = makeStateKey<GlobalStates>('globalStates');
        if (this.transferState.hasKey(globalStatesKey)) {
            this.setStates(this.transferState.get(globalStatesKey, this.getStatesSynchronous()));
        }

        this.spaStates$.pipe(takeUntil(this.unsubscribe)).subscribe((siteState) => {
            if (this.featureDetection.isServer()) {
                this.transferState.set(globalStatesKey, siteState);
            }
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.spaStates$.complete();
    }

    public setState(state: GlobalStateKey, value: GlobalStateValue): void {
        const currentValue = this.spaStates$.value[state];

        if (!equal(currentValue, value)) {
            const newState = {
                ...this.spaStates$.value,
                [state]: value,
            };
            this.spaStates$.next(newState);
        }
    }

    public setStates(value: Partial<GlobalStates>): void {
        const currentValue = this.spaStates$.value;
        const newValue = { ...currentValue, ...value };

        if (!equal(currentValue, newValue)) {
            this.spaStates$.next(newValue);
        }
    }

    public getState<T extends GlobalStateKey>(state: T): Observable<GlobalStates[T]> {
        return this.getStates().pipe(map((value) => value[state]));
    }

    public getStateSynchronous<T extends GlobalStateKey>(state: T): GlobalStates[T] {
        return this.getStatesSynchronous()[state];
    }

    public getStates(): Observable<GlobalStates> {
        return this.spaStates$.pipe();
    }

    public getStatesSynchronous(): GlobalStates {
        return this.spaStates$.value;
    }
}

// Add global states as needed
export interface GlobalStates {
    activePageResponse?: PageResponse;
    activeFlow: 'default' | 'configurator' | 'service-booking';
}

export type GlobalStateKey = keyof GlobalStates;
export type GlobalStateValue = GlobalStates[GlobalStateKey];
