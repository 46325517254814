import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { SpotsConfig } from '../spots/spots-config';

@Component({
    selector: 'ncg-search-icon',
    styles: [
        `
            :host {
                display: contents;
            }
        `,
    ],
    template: `<svg-icon-sprite
        [src]="searchIconSrc"
        [viewBox]="'0 0 30 30'"
        [width]="'30px'"
        [height]="'30px'"
        aria-hidden="true"
        [classes]="classes"
    ></svg-icon-sprite>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchIconComponent implements OnInit {
    @Input() classes: string = '';
    searchIconSrc = 'search';

    constructor(@Inject(SpotsConfig) public spotsConfig: SpotsConfig) {}

    ngOnInit() {
        switch (this.spotsConfig.iconType) {
            case 'light':
                this.searchIconSrc = 'search-light';
                break;

            case 'outlined':
                this.searchIconSrc = 'search-outlined';
                break;

            default:
                this.searchIconSrc = 'search';
                break;
        }
    }
}
