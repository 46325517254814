import { CdkPortal, DomPortalOutlet, PortalModule, PortalOutlet } from '@angular/cdk/portal';
import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FeatureDetectionService } from '../core/feature-detection.service';

@Component({
    selector: 'ncg-portal',
    template: `
        <ng-template cdkPortal>
            <ng-content></ng-content>
        </ng-template>
    `,
    standalone: true,
    styles: [],
    imports: [PortalModule],
})
export class PortalComponent implements AfterViewInit, OnDestroy {
    @Input() outletId: '#footer-portal' | '#header-portal';
    @ViewChild(CdkPortal) portal: CdkPortal;
    private portalOutlet: PortalOutlet;

    constructor(private readonly featureDetection: FeatureDetectionService) {}

    ngAfterViewInit(): void {
        if (this.featureDetection.isServer()) {
            return;
        }
        const portalHostElement = document.querySelector(this.outletId);
        if (!(portalHostElement && this.portal)) {
            return;
        }
        // Create a portalHost from a DOM element
        this.portalOutlet = new DomPortalOutlet(portalHostElement);

        // Attach portal to host
        this.portalOutlet.attach(this.portal);
    }

    ngOnDestroy(): void {
        if (this.featureDetection.isServer()) {
            return;
        }
        this.portalOutlet?.detach();
    }
}
