import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ncg-ncap-stars',
    template: `
        <ng-container *ngFor="let starClass of starsArr">
            <span [className]="'star ' + starClass">
                <svg-icon-sprite
                    src="star"
                    [viewBox]="starDimension.viewBox"
                    [width]="starDimension.width"
                    [height]="starDimension.height"
                    aria-hidden="true"
                ></svg-icon-sprite>
            </span>
        </ng-container>
    `,
    styles: [
        `
            .star {
                margin: 0 2px;
            }

            .star.is-filled {
                color: #000;
            }

            .star.is-outline {
                color: #bcb3a8;
            }
        `,
    ],

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NcapStarsComponent implements OnInit {
    @Input() stars: string | number = 0;
    @Input() isSmall?: boolean;
    starsArr: string[] = [];
    maxStars = 5;
    starDimension = {
        width: '20px',
        height: '19px',
        viewBox: '0 0 30 30',
    };

    ngOnInit() {
        const starsAmount = Number(this.stars);

        if (this.isSmall) {
            this.starDimension = {
                height: '10px',
                width: '9px',
                viewBox: '0 0 30 30',
            };
        }

        this.starsArr = Array(starsAmount).fill(this.isSmall ? 'is-small is-filled' : 'is-filled');

        if (this.starsArr.length < this.maxStars) {
            const outlinedStars = this.maxStars - this.starsArr.length;

            for (let index = 0; index < outlinedStars; index++) {
                this.starsArr.push(this.isSmall ? 'is-small is-outline' : 'is-outline');
            }
        }
    }
}
