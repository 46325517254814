import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { IMegaMenuVisibilitySettings, INavigationResponse } from '@ncg/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeatureDetectionService } from '../../core/feature-detection.service';

import { menuWrapperAnimation } from '../../utils/animations/mega-menu.animations';
import { NavigationService } from '../navigation.service';

@Component({
    selector: 'ncg-main-menu',
    template: `
        <nav class="main-menu navbar" role="navigation" aria-label="main navigation" (mouseleave)="clearActiveItem()">
            <div class="navbar-menu">
                <div class="navbar-start">
                    <ng-container *ngFor="let item of menuItems; trackBy: trackByMethod">
                        <div *ngIf="item && item.includeInNavigation && !item.hideInMenu">
                            <ng-container *ngIf="item?.pageLink?.isExternal; else internalLink">
                                <a
                                    routerLinkActive="is-active"
                                    [ngClass]="{
                                        'is-active': isActive(item) || item.id === activeItem?.id,
                                        'is-current': isActive(item)
                                    }"
                                    (mouseenter)="setActiveItem(item, dropElement)"
                                    (mouseleave)="onMouseLeave()"
                                    rel="noopener"
                                    [href]="item?.pageLink?.url"
                                    [target]="item?.pageLink?.target"
                                    class="main-menu__item navbar-item"
                                >
                                    <span
                                        >{{ item.name }}
                                        <span aria-hidden="true" class="navbar-item__active-bar"></span>
                                    </span>
                                </a>
                            </ng-container>

                            <ng-template #internalLink>
                                <a
                                    routerLinkActive="is-active"
                                    [ngClass]="{
                                        'is-active': isActive(item) || item.id === activeItem?.id,
                                        'is-current': isActive(item)
                                    }"
                                    (mouseenter)="setActiveItem(item, dropElement)"
                                    (mouseleave)="onMouseLeave()"
                                    [routerLink]="[item?.pageLink?.url ? item?.pageLink?.url : item.url]"
                                    class="main-menu__item navbar-item"
                                >
                                    <span
                                        >{{ item.name }}
                                        <span aria-hidden="true" class="navbar-item__active-bar"></span>
                                    </span>
                                </a>
                            </ng-template>
                            <div
                                class="mega-menu__wrapper"
                                [ngClass]="{ 'is-open': activeItem?.id === item.id && isOpen }"
                                #dropElement
                                [@menuAnimation]="{
                                    value: activeItem?.id === item.id ? animationState : 'hidden',
                                    params: {
                                        prevDropdownHeight: prevDropdownHeight + 'px',
                                        currentDropdownHeight: currentDropdownHeight + 'px'
                                    }
                                }"
                            >
                                <button
                                    type="button"
                                    class="button is-text is-narrow mega-menu__close"
                                    *ngIf="isTouchDevice"
                                    (click)="clearActiveItem()"
                                    aria-label="close dropdown menu"
                                >
                                    <svg-icon-sprite
                                        [src]="'cross-light'"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'30px'"
                                        [height]="'30px'"
                                        aria-hidden="true"
                                        classes=""
                                    ></svg-icon-sprite>
                                </button>
                                <ng-container *ngIf="!item.hideChildrenOnDesktop && item.children?.length">
                                    <ncg-mega-models-menu
                                        *ngIf="!item.megaMenu && item.template === 'modelOverviewPage'"
                                        [children]="item.children"
                                        [allModelsUrl]="item.url"
                                        [isOpen]="isOpen"
                                    ></ncg-mega-models-menu>
                                    <ncg-mega-menu *ngIf="!item.megaMenu && item.template !== 'modelOverviewPage'" [item]="item"></ncg-mega-menu>
                                    <ncg-mega-menu-alternate *ngIf="item.megaMenu" [item]="item"></ncg-mega-menu-alternate>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </nav>
    `,
    animations: [menuWrapperAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuComponent implements OnInit, OnDestroy {
    @Input() isFirstLevelMenu = true;
    @Output() isMegaMenuVisibleSettings: EventEmitter<IMegaMenuVisibilitySettings> = new EventEmitter();
    hoverDelayTimer = 0;
    menuItems: INavigationResponse[] = [];
    activeItem: INavigationResponse | undefined;
    prevDropdownHeight = 0;
    currentDropdownHeight = 0;
    animationState: 'expand' | 'hidden' | undefined = undefined;
    isOpen = false;
    isTouchDevice = false;

    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly router: Router,
        private readonly navigationService: NavigationService,
        private readonly featureDetectionService: FeatureDetectionService,
        private readonly cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.isTouchDevice = this.featureDetectionService.isTouchDevice();

        this.getMenu();

        this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.clearActiveItem();
            }
        });
    }

    private getMenu() {
        if (this.isFirstLevelMenu) {
            this.navigationService
                .getNavigation()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((menu) => {
                    this.menuItems = menu;
                    this.cd.markForCheck();
                });
        } else {
            this.navigationService
                .getNavigationById()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((menu) => {
                    this.menuItems = menu;
                    this.cd.markForCheck();
                });
        }
    }

    setActiveItem(item: INavigationResponse, dropdownElement: HTMLElement) {
        // If user hover same menu link, don't do anything
        if (this.activeItem && this.activeItem.children?.length && this.activeItem.id === item.id) {
            return;
        }

        //  If item has `hideChildrenOnDesktop` set to true
        //  OR
        //  Item dons't have any children
        //  clear active menu and close dropdown
        if (item.hideChildrenOnDesktop || !item.children?.length) {
            this.clearActiveItem();
            this.animationState = 'hidden';
            return;
        }

        this.prevDropdownHeight = this.currentDropdownHeight;

        /**
         * Delay dropdown animation if user switch betweens menu items fast.
         */
        window.clearTimeout(this.hoverDelayTimer);
        this.hoverDelayTimer = window.setTimeout(() => {
            this.activeItem = item;
            this.isOpen = true;
            this.currentDropdownHeight = dropdownElement.offsetHeight;
            this.isMegaMenuVisibleSettings.emit({ isVisible: this.isOpen });
            this.animationState = 'expand';
            this.cd.detectChanges();
        }, 200);
    }

    clearActiveItem() {
        if (this.featureDetectionService.isBrowser()) {
            window.clearTimeout(this.hoverDelayTimer);
            this.activeItem = undefined;
            this.prevDropdownHeight = 0;
            this.currentDropdownHeight = 0;
            this.isOpen = false;
            this.isMegaMenuVisibleSettings.emit({ isVisible: this.isOpen });
            this.animationState = 'hidden';
            this.cd.detectChanges();
        }
    }

    isActive(item: INavigationResponse): boolean {
        return this.router.isActive(item.url, false);
    }

    onMouseLeave() {
        window.clearTimeout(this.hoverDelayTimer);
    }

    ngOnDestroy(): void {
        this.clearActiveItem();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    trackByMethod(index: number, item: any) {
        return item.id || index;
    }
}
