import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IFavoritesPageResponse } from '@ncg/data';
import { LazyContentAliases } from '../../$lazy-content/models/lazy-content.models';

@Component({
    selector: 'ncg-favorites-page',
    template: `
        <div class="favorites-page content">
            <div class="favorites-page__lazy-content">
                <ng-template ncgLazyComponentLoader [alias]="favoritesContainerAlias"></ng-template>
            </div>
            <ncg-umb-grid [grid]="data?.grid"></ncg-umb-grid>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoritesPageComponent {
    static ref = 'favoritesPage';
    @Input() data: IFavoritesPageResponse;
    favoritesContainerAlias = LazyContentAliases.Favorites;
}
