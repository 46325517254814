import { IDropdownState, IFacetList, IFacets, IFilterItemViewModel, IFilterParams, ILayoutSettings, ISelectedFacets } from '@ncg/data';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as ProductsActions from './products.actions';

export const PRODUCTS_FEATURE_KEY = 'products';

export interface ProductsState extends EntityState<IFilterItemViewModel> {
    selectedId?: string | number; // which Products record has been selected
    isFetching: boolean;
    facetList?: IFacetList[];
    error?: string | null; // last none error (if any)
    layout?: ILayoutSettings;
    total?: number;
    filterParams?: IFilterParams;
    facets?: IFacets;
    quickFilterfacets?: IFacets;
    quickFilterfacetsLoaded: boolean;
    quickFilterfacetsError?: any;
    selectedFacets: ISelectedFacets[];
    premiumText?: Record<string, string>;
    selectedPremiumText?: string;
    isPremiumEnabled: boolean;
    dropdownState?: IDropdownState;
}

export interface ProductsPartialState {
    readonly [PRODUCTS_FEATURE_KEY]: ProductsState;
}

export const productsAdapter: EntityAdapter<IFilterItemViewModel> = createEntityAdapter<IFilterItemViewModel>();

export const initialState: ProductsState = productsAdapter.getInitialState({
    // set initial required properties
    isFetching: false,
    quickFilterfacetsLoaded: false,
    selectedFacets: [],
    layout: {
        pageLayout: 'filterVisible',
        view: 'list',
        multiSelectMakes: false,
    },
    dropdownState: {
        activeId: undefined,
        isOpen: false,
    },
    isPremiumEnabled: false,
});

const _productsReducer = createReducer(
    initialState,
    on(ProductsActions.selectedPremiumText, (state, { selectedPremiumText }) => ({ ...state, selectedPremiumText })),
    on(ProductsActions.premiumText, (state, { premiumText }) => ({ ...state, premiumText })),
    on(ProductsActions.isPremiumEnabled, (state, { isPremiumEnabled }) => ({ ...state, isPremiumEnabled })),
    on(ProductsActions.dropdownState, (state, { dropdownState }) => ({ ...state, dropdownState })),
    on(ProductsActions.loadProductsSuccess, (state, { products }) => productsAdapter.setAll(products, { ...state })),
    on(ProductsActions.loadProductsFailure, (state, { error }) => ({ ...state, error })),
    on(ProductsActions.loadMoreProducts, (state, { filterParams }) => ({ ...state, filterParams, isFetching: true })),
    on(ProductsActions.addMoreProducts, (state, { products }) => productsAdapter.addMany(products, { ...state, isFetching: true })),
    on(ProductsActions.clearAllProducts, (state) => productsAdapter.removeAll({ ...state })),
    on(ProductsActions.filterParams, (state, { filterParams, resetFilter, resetKey }) => {
        if (!filterParams) {
            return { ...state, filterParams: undefined };
        }

        const updatedQuery = resetFilter ? filterParams : addRemove(filterParams, state.filterParams, resetKey);
        return { ...state, filterParams: updatedQuery, isFetching: true };
    }),
    on(ProductsActions.layout, (state, { layout }) => ({ ...state, layout: { ...state.layout, ...layout } })),
    on(ProductsActions.isFetching, (state, { isFetching }) => ({ ...state, isFetching })),
    on(ProductsActions.total, (state, { total }) => ({ ...state, total })),
    on(ProductsActions.facets, (state, { facets }) => ({ ...state, facets })),
    on(ProductsActions.facetList, (state, { facetList }) => ({ ...state, facetList })),
    on(ProductsActions.selectedFacets, (state, { selectedFacets }) => ({ ...state, selectedFacets })),

    // Quick filter
    on(ProductsActions.loadQuickFilterFacets, (state) => ({ ...state, quickFilterfacetsLoaded: false })),
    on(ProductsActions.loadQuickFilterfacetsSuccess, (state, { quickFilterfacets }) => ({
        ...state,
        quickFilterfacets,
        quickFilterfacetsLoaded: true,
    })),
    on(ProductsActions.loadQuickFilterFacetsFailure, (state, { error }) => ({
        ...state,
        quickFilterfacetsError: error,
        quickFilterfacetsLoaded: false,
    }))
);

export const productReducer = (state: ProductsState | undefined, action: Action) => _productsReducer(state, action);

const addRemove = (filterParams: IFilterParams, stateParams?: IFilterParams, resetKey?: boolean): IFilterParams => {
    const filterKeyObject: any = {};
    // Remove/add values from object[key] array
    for (const key in filterParams) {
        if (Array.isArray(filterParams[key]) && stateParams?.[key]) {
            const val: string[] = Array.isArray(stateParams?.[key]) ? stateParams?.[key] : [stateParams?.[key]];
            const newArr = filterParams[key] as string[];
            let arr: string[] = [];

            newArr.forEach((value) => {
                if (resetKey) {
                    // E.g. year should only consist of one paramter
                    arr = [filterParams[key]];
                } else if (val.includes(value)) {
                    // Remove query value if already exists
                    arr = val.filter((item) => item !== value);
                } else {
                    // Add value to vurrent state
                    arr = [...val, value];
                }
            });

            filterKeyObject[key] = arr;
        }
    }

    const updatedQuery = Object.assign({}, stateParams, filterParams, filterKeyObject);

    for (const key in updatedQuery) {
        if (updatedQuery[key] === undefined) {
            delete updatedQuery[key];
        }
    }
    return updatedQuery;
};
