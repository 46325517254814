import { Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'ncg-rich-text',
    template: ` <div *ngIf="html" class="rte-content" [innerHTML]="html | safe: 'html'" ncgInterceptLinks [dialogId]="dialogId"></div> `,
})
export class RichTextComponent {
    @Input() dialogId?: string;
    @Input() html?: string | SafeHtml;
}
