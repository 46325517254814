import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SpotBaseDirective } from '../spot-base.class';
import { SpotsConfig } from '../spots-config';
import { HeroSliderSpot } from '@ncg/data';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
    selector: 'ncg-hero-slider-spot',
    template: `
        <div class="hero-slider-spot">
            <div *ngIf="data?.extendedHeroSpots?.length">
                <div>
                    <swiper
                        #swiperRef
                        [slidesPerView]="'auto'"
                        [spaceBetween]="0"
                        [breakpoints]="{ '1024': { spaceBetween: 0 } }"
                        class="image-text-picker-spot-swiper"
                        [grabCursor]="true"
                        [navigation]="{
                            prevEl: prev,
                            nextEl: next
                        }"
                        [pagination]="{
                            clickable: true,
                            el: '.hero-slider-spot__pagination'
                        }"
                        [autoplay]="swiperConfig.autoplay"
                        (swiper)="onSwiper($event)"
                    >
                        <ng-template swiperSlide *ngFor="let item of data.extendedHeroSpots; let i = index">
                            <ncg-extended-hero-spot [data]="item"></ncg-extended-hero-spot>
                        </ng-template>
                    </swiper>
                    <div class="hero-slider-spot__carousel-nav">
                        <div #prev class="swiper-button-prev"></div>
                        <div class="hero-slider-spot__pagination"></div>
                        <div #next class="swiper-button-next"></div>
                    </div>
                </div>
            </div>
        </div>
    `,

    changeDetection: ChangeDetectionStrategy.Default,
})
export class HeroSliderSpotComponent extends SpotBaseDirective implements OnInit {
    static ref = 'heroslider';
    @Input() data: HeroSliderSpot;
    @Input() spotsConfig: SpotsConfig;
    activeHeroIndex = 0;
    swiperConfig: any;
    @ViewChild('swiperRef', { static: false }) swiper: SwiperComponent;

    constructor(readonly cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.swiperConfig = {
            autoplay: {
                enabled: true,
                delay: 4000,
                disableOnInteraction: false,
                rewind: true,
                allowSlidePrev: true,
                allowSlideNext: true,
            },
            pagination: true,
            slidesPerView: 1,
        };
    }
    ngAfterViewInit() {
        if (this.swiper && this.swiper.swiperRef) {
            this.swiper.swiperRef.autoplay.start();
            this.activeHeroIndex = this.swiper.swiperRef.activeIndex || 0;
            this.swiper.swiperRef.update();
            this.swiper.swiperRef.pagination.update();
            this.cdr.detectChanges();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSwiper(swiper: any) {}
}
