import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

export interface ImageObjectType {
    name: string;
    base64File: string;
    sizeExceeded: boolean;
    byteSize: number;
    size?: string;
}

@Component({
    selector: 'ncg-image-upload',
    styleUrls: ['./image-upload.component.scss'],
    template: `
        <div class="image-upload" [ngClass]="{ 'size-exceeded-box': totalSize > maxSizeTotal }">
            <div class="image-upload__spacing">
                <label for="file-upload" class="button is-border">
                    <span class="image-upload__svg-rotate">
                        <svg-icon-sprite
                            [src]="'download'"
                            [viewBox]="'0 0 30 30'"
                            [width]="'30px'"
                            [height]="'30px'"
                            aria-hidden="true"
                            class="is-flex"
                            classes=""
                        >
                        </svg-icon-sprite>
                    </span>
                    {{ 'forms.images_to_upload' | translate }}
                    <input name="imageUpload" #imageInput (change)="uploadImageHandler()" class="input" type="file" accept="image/*" multiple />
                </label>
            </div>
            <div class="image-upload__preview" #preview>
                <p *ngIf="images.length === 0">{{ 'forms.images_not_selected' | translate }}</p>
                <div *ngFor="let image of images; let i = index">
                    <img [ngClass]="{ 'size-exceeded-box': image.sizeExceeded }" [src]="image.base64File" alt="{{ image.name }}" />
                    <p *ngIf="image.sizeExceeded" class="help is-danger">{{ 'forms.image_upload_size' | translate }}</p>
                    <div class="display">
                        <p>{{ image.name }}, {{ image.size }}</p>
                        <button (click)="removeImage(i)" class="button-icon"><img [src]="'assets/icons/search-close.svg'" /></button>
                    </div>
                </div>
            </div>
            <p class="help" *ngIf="images.length">
                <span [ngClass]="{ 'size-exceeded-text': totalSize > maxSizeTotal }">{{ returnImageSize(totalSize) }}</span> /
                {{ returnImageSize(maxSizeTotal) }}
            </p>
        </div>
        <p class="help is-danger mt-2" *ngIf="totalSize > maxSizeTotal">{{ 'forms.image_upload_total_size' | translate }}</p>
    `,
})
export class ImageUploadComponent {
    @ViewChild('imageInput') imageInput: ElementRef;
    @ViewChild('preview') preview: ElementRef;
    @Input() maxSize: number = 1024 * 1024 * 5; // 5MB
    @Input() maxSizeTotal: number = 1024 * 1024 * 15; // 15MB
    public totalSize = 0;

    @Output() fileChange: EventEmitter<ImageObjectType[]> = new EventEmitter<ImageObjectType[]>();

    // accepted image types
    imageTypes = [
        'image/apng',
        'image/bmp',
        'image/gif',
        'image/jpeg',
        'image/jpg',
        'image/pjpeg',
        'image/png',
        'image/pdf',
        'image/svg+xml',
        'image/tiff',
        'image/webp',
        'image/x-icon',
    ];
    images: ImageObjectType[] = [];

    constructor(private readonly cdr: ChangeDetectorRef) {}

    uploadImageHandler() {
        const input = this.imageInput.nativeElement;
        const curImages = input.files;
        if (!curImages || curImages.length === 0) {
            return;
        }

        this.totalSize = 0;
        const promises: Promise<ImageObjectType>[] = [];

        for (const image of curImages) {
            if (this.validFileType(image)) {
                const imageObject: ImageObjectType = {
                    name: image.name,
                    size: this.returnImageSize(image.size),
                    byteSize: image.size,
                    base64File: '',
                    sizeExceeded: false,
                };
                // Create FileReader
                const reader = new FileReader();
                const promise = new Promise<ImageObjectType>((resolve) => {
                    reader.onload = (event) => {
                        // Handle the base64 data here
                        const base64Image = event.target?.result as string;
                        // Create an image element to display the base64File
                        imageObject.base64File = base64Image;
                        imageObject.sizeExceeded = image.size > this.maxSize;
                        resolve(imageObject);
                    };
                    // Read the selected image as Data URL
                    reader.readAsDataURL(image);
                });
                promises.push(promise);
            }
        }
        Promise.allSettled(promises).then((results) => {
            this.images = results
                .filter((result): result is PromiseFulfilledResult<ImageObjectType> => result.status === 'fulfilled')
                .map(({ value }) => value);
            this.verifyImages();
        });
    }

    removeImage(i: number) {
        this.images.splice(i, 1);
        this.verifyImages();
    }
    validFileType(file: { type: string }) {
        return this.imageTypes.includes(file.type);
    }

    private verifyImages(): void {
        const allowedImages = this.images.filter(({ sizeExceeded }) => !sizeExceeded);
        this.totalSize = this.images.reduce((acc, { byteSize }) => acc + byteSize, 0);
        this.cdr.markForCheck();
        if (this.totalSize <= this.maxSizeTotal) {
            this.fileChange.emit(allowedImages);
        }
    }

    public returnImageSize(size: number): string {
        if (size < 1024) {
            return `${size} bytes`;
        } else if (size >= 1024 && size < 1048576) {
            return `${(size / 1024).toFixed(1)} KB`;
        } else {
            return `${(size / 1048576).toFixed(1)} MB`;
        }
    }
}
