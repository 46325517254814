import { IModelCategory } from '../generic/model-category.interface';
import { INavigationResponse } from '../response/navigation-response.interface';
import { IModelsListItem } from '../spots/models-list.model';

/**
 * Remove duplcate modelCategories and sort by Umbraco's sortOrder
 */
export const SortedModelCategories = (items: INavigationResponse[] | IModelsListItem[]): string[] => {
    let modelCategories: IModelCategory[] = [];

    // Add all model categories, to array. Also duplicates
    items.forEach((item) => {
        item.modelCategory?.forEach((t: any) => {
            modelCategories.push({ sortOrder: t.sortOrder, name: t.name });
        });
    });

    // Sort all model categories by umbraco's sortOrder
    modelCategories = modelCategories.sort((a, b) => {
        const modelA = a.sortOrder;
        const modelB = b.sortOrder;

        if (modelA < modelB) {
            return -1;
        }
        if (modelB > modelA) {
            return 1;
        }
        return 0;
    });

    const modelTypesSorted = new Set<string>();

    // Only add unique categories (remove duplicates)
    for (const type of modelCategories) {
        modelTypesSorted.add(type.name);
    }

    // Return sorted modelCategories, without duplicates
    return Array.from(modelTypesSorted);
};
