import { Component, Input } from '@angular/core';
import { ContactFormSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-contact-form-spot',
    template: `
        <div class="columns is-centered" ncgLoadIn>
            <ncg-contact-form class="column" [class.is-8]="!allowNarrowContent" [data]="data"></ncg-contact-form>
        </div>
    `,
})
export class ContactFormSpotComponent extends SpotBaseDirective {
    static ref = 'contactform';

    @Input() data: ContactFormSpot;
}
