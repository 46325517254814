import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FilterSpot } from '@ncg/data';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { SettingsService } from '../../core/settings.service';
import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-filter-spot',
    template: `
        <div class="filter-spot" ncgLoadIn>
            <div class="filter-spot__content" [ngStyle]="{ 'min-height': data.layout === 'tileview' ? '232px' : 'auto' }">
                <ng-container #vc></ng-container>
            </div>
            <div class="filter-spot__actions has-text-centered" *ngIf="usedCarsLink" ncgLoadIn>
                <a [routerLink]="usedCarsLink" class="button is-primary">{{ data.linkText }}</a>
            </div>
        </div>
    `,
})
export class FilterSpotComponent extends SpotBaseDirective implements OnInit, OnDestroy {
    static ref = 'filterspot';

    private readonly unsubscribe = new Subject<void>();

    @Input() data: FilterSpot;
    @ViewChild('vc', { read: ViewContainerRef, static: true }) viewContainer: ViewContainerRef;
    usedCarsLink: string;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly settingsService: SettingsService
    ) {
        super();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    async ngOnInit() {
        const { FilterRootComponent } = await import('../../filter/filter-root/filter-root.component');
        const ref = this.viewContainer.createComponent(FilterRootComponent);
        ref.instance.layout = this.data.layout;
        this.cd.markForCheck();
        this.setLink();
    }

    private setLink() {
        if (!this.data.linkText) {
            return;
        }

        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.usedCarsLink = settings.usedCarsLink.url;
                this.cd.markForCheck();
            });
    }
}
