import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IFacet, IFacetList } from '@ncg/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { filterParams } from '../../+state/products/products.actions';
import { ProductsFacade } from '../../+state/products/products.facade';
import { TrackingService } from '../../core/tracking.service';

@Component({
    selector: 'ncg-filter-facet-toggle-item',
    template: `
        <button
            *ngIf="facetGroup && facetGroup.facetKey && isVisible"
            type="button"
            class="button is-border is-full"
            (click)="updateFilterQuery(facetGroup)"
            [disabled]="isDisabled"
            [ngClass]="{
                'is-border--active': isSelected(facetGroup),
                'is-fetching': currentKey === facetGroup && isFetching
            }"
        >
            <div class="has-text-centered">
                <ng-container [ngSwitch]="facetGroup.name | lowercase">
                    <ng-container *ngSwitchCase="'demo'">{{ 'facet_types.isdemo' | translate }}</ng-container>
                    <ng-container *ngSwitchCase="'notdemo'">{{ 'facet_types.isnotdemo' | translate }}</ng-container>
                    <ng-container *ngSwitchCase="'cash only'">{{ 'facet_types.iscash' | translate }}</ng-container>
                    <ng-container *ngSwitchCase="'leasing only'">{{ 'facet_types.isleasing' | translate }}</ng-container>
                    <ng-container *ngSwitchCase="'premium only'">{{ premiumText }}</ng-container>
                    <ng-container *ngSwitchCase="'with pictures'">{{ 'facet_types.has_pictures' | translate }}</ng-container>
                    <ng-container *ngSwitchDefault>{{ facetGroup.name }} </ng-container>
                </ng-container>
            </div>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFacetToggleItemComponent implements OnInit, OnDestroy {
    @Input() facetGroup?: IFacetList;
    isVisible = true;
    premiumText?: string;
    isFetching = false;
    currentKey?: IFacetList;
    get isDisabled() {
        if (this.facetGroup?.facets && this.facetGroup?.facets.length < 2) {
            const item = this.facetGroup.facets[0];
            if (item?.key === 'false') {
                return true;
            }
        }
        return false;
    }
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly productsFacade: ProductsFacade,
        private readonly cd: ChangeDetectorRef,
        private readonly trackingService: TrackingService
    ) {}

    ngOnInit() {
        this.setPremiumVisibility();
        this.getIsFetching();
    }

    isSelected(facetGroup: IFacetList): boolean {
        const key = facetGroup.reverse ? 'false' : 'true';
        return !!this.facetGroup?.facets?.find((item: IFacet) => item.key === key && item.selected);
    }

    setPremiumVisibility() {
        if (this.facetGroup?.facetKey !== 'ispremium') {
            return;
        }
        this.productsFacade.selectedPremiumText$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.premiumText = data;
            this.isVisible = Boolean(this.premiumText);
            this.cd.markForCheck();
        });
    }

    getIsFetching() {
        this.productsFacade.isFetching$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.isFetching = data;
            if (!this.isFetching) {
                this.currentKey = undefined;
            }
            this.cd.markForCheck();
        });
    }

    updateFilterQuery(group: IFacetList) {
        const selected = this.isSelected(group);
        this.currentKey = group;
        this.cd.markForCheck();
        this.productsFacade.dispatch(
            filterParams({
                filterParams: { [group.facetKey]: selected ? undefined : !group.reverse },
                resetKey: true,
            })
        );

        if (!selected) {
            // track only selected facet
            this.trackingService.trackFilter('true', group.facetKey);
        }
    }

    trackByFn(index: number, item: any) {
        return item.key || index;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
