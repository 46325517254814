import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { getBuildIdQuery } from '@ncg/data';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { Subject, takeUntil } from 'rxjs';

import { filterParams } from '../../+state/products/products.actions';
import { ProductsFacade } from '../../+state/products/products.facade';

@Component({
    selector: 'ncg-product-search',
    template: `<form [formGroup]="form" (submit)="onSearchSubmit()" action="." class="product-search">
        <div class="field">
            <div class="control has-icons-right">
                <input
                    class="input"
                    formControlName="search"
                    name="search"
                    type="search"
                    [placeholder]="'search.placeholder' | translate"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    required
                />
                <button *ngIf="searchField?.value; else submitBtn" type="button" class="icon is-right is-clickable" (click)="updateQuery()">
                    <img [src]="'assets/icons/search-close.svg' + buildIdQuery" alt="" />
                </button>
                <ng-template #submitBtn>
                    <button type="submit" class="icon is-right is-clickable">
                        <img [src]="'assets/icons/search.svg' + buildIdQuery" alt="" />
                    </button>
                </ng-template>
            </div>
        </div>
    </form>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSearchComponent implements OnInit, AfterViewInit, OnDestroy {
    buildIdQuery = getBuildIdQuery();
    form: FormGroup;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly fb: FormBuilder,
        private readonly productsFacade: ProductsFacade
    ) {}

    get searchField() {
        return this.form?.get('search');
    }

    ngOnInit() {
        this.form = this.fb.group({
            search: ['', [Validators.minLength(1), Validators.required]],
        });
    }

    ngAfterViewInit() {
        this.productsFacade.filterParams$.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            this.searchField?.setValue(typeof params?.q === 'string' ? params.q : '');
            this.cd.detectChanges();
        });
    }

    onSearchSubmit() {
        this.updateQuery(this.searchField?.value);
    }

    updateQuery(query?: string) {
        // tread empty string as undefined
        const q = query && query.length > 0 ? query : undefined;

        this.productsFacade.dispatch(
            filterParams({
                filterParams: { q },
            })
        );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, IconSpriteModule, TranslateModule.forChild()],
    declarations: [ProductSearchComponent],
    exports: [ProductSearchComponent],
})
export class ProductSearchModule {}
