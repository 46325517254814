import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IEquipmentPackagesItem } from '@ncg/data';

@Component({
    selector: 'ncg-equipment-packages-item',
    template: `
        <div class="equipment-packages-spot__item" *ngIf="item" ncgLoadIn>
            <div class="equipment-packages-spot__item-title-container">
                <h2 class="equipment-packages-spot__item-title">{{ item.title }}</h2>
                <ncg-info-label *ngIf="item.priceCampaign" [isCampaign]="true"></ncg-info-label>
            </div>
            <div *ngIf="item.priceCampaign" class="equipment-packages-spot__item-price__container">
                <span *ngIf="item.price" class="equipment-packages-spot__item-price--line-through">{{
                    'equipment_spot.price' | translate: { price: item.price }
                }}</span>
                <br *ngIf="item.price" />
                <b class="equipment-packages-spot__item-price equipment-packages-spot__item-price--bold" *ngIf="item.priceCampaign">{{
                    'equipment_spot.price' | translate: { price: item.priceCampaign }
                }}</b>
            </div>
            <b class="equipment-packages-spot__item-price" *ngIf="item.price && !item.priceCampaign">{{
                'equipment_spot.price' | translate: { price: item.price }
            }}</b>
            <ng-container *ngIf="item.description && item.description.length">
                <p class="equipment-packages-spot__item-include">
                    <ng-container *ngIf="item.include; else standard">
                        {{ 'trim_specifications.included_addition' | translate: { trim: item.include } }}
                    </ng-container>
                    <ng-template #standard>{{ 'trim_specifications.included_standard' | translate }}</ng-template>
                </p>
                <ul class="equipment-packages-spot__item-description-list">
                    <li class="equipment-packages-spot__item-description-list-item is-flex" *ngFor="let feature of item.description">
                        <svg-icon-sprite
                            src="checkmark"
                            [viewBox]="'0 0 30 30'"
                            [width]="'30px'"
                            [height]="'30px'"
                            aria-hidden="true"
                        ></svg-icon-sprite>
                        <span>{{ feature }}</span>
                    </li>
                </ul>
            </ng-container>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentPackagesItemComponent {
    @Input() item: IEquipmentPackagesItem;
}
