import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IFileModel, IImage } from '@ncg/data';
import { FeatureDetectionService } from '../core/feature-detection.service';

import { HdAspect } from '../utils/helpers/aspect-ratio';
import { ImageUrl } from '../utils/helpers/image-helper';

@Component({
    selector: 'ncg-video',
    template: `
        <figure [className]="aspectRatioClass">
            <video
                *ngIf="preload === 'auto' && play"
                #videoElement
                loop
                playsinline
                muted
                [preload]="preload"
                [attr.autoplay]="play ? true : null"
                [attr.src]="videoUrl"
                [attr.title]="videoTitle"
                [attr.poster]="videoPoster"
            ></video>
            <video
                *ngIf="preload !== 'auto'"
                ncgImageLoad
                #videoElement
                loop
                playsinline
                muted
                [preload]="preload"
                [attr.autoplay]="play ? true : null"
                [attr.src]="videoUrl"
                [attr.title]="videoTitle"
                [attr.poster]="videoPoster"
            ></video>
        </figure>
    `,
    styles: [
        `
            figure {
                line-height: 0;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent implements AfterViewChecked {
    @ViewChild('videoElement') private videoRef: ElementRef<HTMLVideoElement>;
    @Input() set video(data: IFileModel) {
        if (this.videoRef) {
            // Image load directive, will animate in
            this.videoRef.nativeElement.style.opacity = '0';
        }
        this._generateVideo(data, this.poster);
    }
    @Input() poster?: IImage;
    @Input() calculateAspect = false;
    @Input() play = true;
    @Input() set reset(data: boolean) {
        if (data) {
            this.resetVideo();
        }
    }
    @Input() aspectRatioClass?: string;
    @Input() preload?: 'auto' | 'metadata' | 'none' = 'metadata';
    videoUrl: string;
    videoType: string;
    videoTitle: string;
    videoPoster: string | null;

    constructor(
        private readonly featureDetectionService: FeatureDetectionService,
        private readonly cd: ChangeDetectorRef
    ) {}

    ngAfterViewChecked() {
        // Ensure video is muted - fixes autoplay issues
        if (this.featureDetectionService.isBrowser() && this.videoUrl && this.videoRef?.nativeElement) {
            this.videoRef.nativeElement.muted = true;

            if (this.play) {
                this.videoRef.nativeElement.play();
            }
        }
    }

    resetVideo() {
        if (this.videoRef?.nativeElement) {
            this.videoRef.nativeElement.pause();
            this.videoRef.nativeElement.currentTime = 0;
            this.cd.markForCheck();
        }
    }

    private _generateVideo(video: IFileModel, poster?: IImage) {
        const videoPoster = poster ? ImageUrl(poster, { width: 1550, heightratio: HdAspect, mode: 'crop' }) : null;

        this.videoUrl = video.url;
        this.videoTitle = video.name;
        this.videoType = video.extension ? `video/${video.extension}` : 'video/mp4';
        this.videoPoster = videoPoster;
    }
}
