import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageTransitionService } from './page-transition.service';
import { PageTransitionComponent } from './page-transition.component';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
    declarations: [PageTransitionComponent],
    imports: [CommonModule, RouterModule, SpinnerModule],
    exports: [PageTransitionComponent],
    providers: [PageTransitionService],
})
export class PageTransitionModule {}
