import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { expansionAnimation } from './expansion.animation';

@Component({
    selector: 'ncg-expansion-panel',
    template: `
        <div
            #header
            class="expansion-panel__header level is-mobile"
            [ngClass]="{ 'is-clickable': hasContent && !forceStatic }"
            (click)="hasContent && !forceStatic ? onExpandClick($event) : undefined"
        >
            <ng-content select="[expansion-title]"></ng-content>
            <button
                *ngIf="hasContent && !forceStatic"
                [id]="'button-' + id"
                type="button"
                [attr.aria-label]="headerRef.nativeElement?.childNodes?.[0]?.textContent"
                [attr.aria-controls]="'panel-' + id"
                [attr.aria-expanded]="expanded"
                (click)="onExpandClick($event)"
                class="expansion-panel__icon"
            >
                <div class="is-flex chevron chevron--animate" aria-hidden="true" [ngClass]="{ 'chevron--up': expanded }">
                    <ng-content select="[expansion-icon]"></ng-content>
                </div>
            </button>
        </div>
        <section
            #content
            [id]="'panel-' + id"
            class="expansion-panel__content"
            [attr.aria-hidden]="hasContent && !forceStatic ? (expanded ? 'false' : 'true') : undefined"
            [attr.aria-labelledby]="hasContent && !forceStatic ? 'button-' + id : undefined"
            [@expandAnimation]="forceStatic ? 'expanded' : expandedState"
            [attr.inert]="expanded || forceStatic ? undefined : true"
        >
            <ng-content></ng-content>
        </section>
    `,
    animations: [expansionAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent implements OnInit, AfterViewInit {
    @ViewChild('content') public contentRef: ElementRef<HTMLElement>;
    @ViewChild('header') public headerRef: ElementRef<HTMLElement>;

    @Input() expanded = false;
    @Input() forceStatic = false;

    public readonly id = this.randomId();
    public expandedState?: 'expanded' | 'closed';
    public hasContent: boolean;

    constructor(private readonly cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.expandedState = this.expanded ? 'expanded' : 'closed';
    }

    onExpandClick(event?: Event) {
        this.expanded = !this.expanded;
        this.expandedState = this.expanded ? 'expanded' : 'closed';

        if (event) {
            // Prevent double-click
            event.stopPropagation();
            event.preventDefault();
        }
    }
    ngAfterViewInit(): void {
        this.hasContent = Boolean(this.contentRef.nativeElement?.children.length);
        this.cd.detectChanges();
    }

    private randomId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }
}
