import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModelViewerSpot } from '@ncg/data';

import { FeatureDetectionService } from '../../core/feature-detection.service';
import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-model-viewer-spot',
    template: '<ng-container #vc></ng-container>',
})
export class ModelViewerSpotComponent extends SpotBaseDirective implements OnInit {
    static ref = 'modelviewer';
    @ViewChild('vc', { read: ViewContainerRef, static: true }) viewContainer: ViewContainerRef;
    @Input() data?: ModelViewerSpot;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly featureDetectionService: FeatureDetectionService
    ) {
        super();
    }

    async ngOnInit() {
        if (this.featureDetectionService.isBrowser() && (this.data?.exteriorView || this.data?.interiorView)) {
            this.viewContainer.clear();
            const { ModelViewerContainerComponent } = await import('./model-viewer-container.component');
            const ref = this.viewContainer.createComponent(ModelViewerContainerComponent);
            ref.instance.modelViewer = this.data;
            this.cd.markForCheck();
        }
    }
}
