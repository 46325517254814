import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

import { FeatureDetectionService } from '../../core/feature-detection.service';
import { SettingsService } from '../../core/settings.service';

/* eslint sort-keys: "error" */
const I18N_VALUES: any = {
    da: {
        months: ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'],
        weekdays: ['man.', 'tir.', 'ons.', 'tor.', 'fre.', 'lør.', 'søn.'],
    },
    en: {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    et: {
        months: ['Jaanuar', 'Veebruar', 'Märts', 'Aprill', 'Mai', 'Juuni', 'Juuli', 'August', 'September', 'Oktoober', 'November', 'Detsember'],
        weekdays: ['Esmasp', 'Teisip', 'Kolmap', 'Neljap', 'Reede', 'Laup', 'Pühap'],
    },
    fi: {
        months: [
            'Tammikuu',
            'Helmikuu',
            'Maaliskuu',
            'Huhtikuu',
            'Toukokuu',
            'Kesäkuu',
            'Heinäkuu',
            'Elokuu',
            'Syyskuu',
            'Lokakuu',
            'Marraskuu',
            'Joulukuu',
        ],
        weekdays: ['Ma', 'Ti', 'Ke', 'To', 'Pe', 'La', 'Su'],
    },
    lt: {
        months: ['Sausis', 'Vasaris', 'Kovas', 'Balandis', 'Gegužė', 'Birželis', 'Liepa', 'Rugpjūtis', 'Rugsėjis', 'Spalis', 'Lapkritis', 'Gruodis'],
        weekdays: ['pir', 'ant', 'tre', 'ket', 'pen', 'šeš', 'sek'],
    },
    lv: {
        months: [
            'Janvāris',
            'Februāris',
            'Marts',
            'Aprīlis',
            'Maijs',
            'Jūnijs',
            'Jūlijs',
            'Augusts',
            'Septembris',
            'Oktobris',
            'Novembris',
            'Decembris',
        ],
        weekdays: ['prm', 'otr', 'tre', 'ctr', 'pkt', 'sst', 'svt'],
    },
    nb: {
        months: ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'],
        weekdays: ['man.', 'tir.', 'ons.', 'tor.', 'fre.', 'lør.', 'søn.'],
    },
    sv: {
        months: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'],
        weekdays: ['mån', 'tis', 'ons', 'tors', 'fre', 'lör', 'sön'],
    },
};

@Injectable()
export class I18n {
    languageCode = 'da';

    constructor(private readonly settingsService: SettingsService) {
        this.getLanguageCode();
    }

    async getLanguageCode() {
        const settings$ = this.settingsService.get();
        const settings = await firstValueFrom(settings$);
        if (settings.seoLanguage) {
            this.languageCode = settings.seoLanguage;
        }
    }
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    constructor(
        private readonly i18n: I18n,
        private readonly featureDetectionService: FeatureDetectionService
    ) {
        super();
    }

    getWeekdayLabel(weekday: number): string {
        return I18N_VALUES[this.i18n.languageCode].weekdays[weekday - 1];
    }

    getMonthShortName(month: number): string {
        if (this.featureDetectionService.isBrowser() && window.matchMedia('(max-width: 500px)').matches) {
            return month.toString();
        }

        return I18N_VALUES[this.i18n.languageCode].months[month - 1];
    }

    getMonthFullName(month: number): string {
        return I18N_VALUES[this.i18n.languageCode].months[month - 1];
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}
