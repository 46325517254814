import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { UtilsModule } from '../utils/utils.module';
import { EmbedComponent } from './embed.component';

@NgModule({
    declarations: [EmbedComponent],
    imports: [CommonModule, UtilsModule, IconSpriteModule, TranslateModule.forChild()],
    exports: [EmbedComponent],
})
export class EmbedModule {}
