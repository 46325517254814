import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IModelPageResponse } from '@ncg/data';

import { ImageUrl } from '../../utils/helpers/image-helper';

@Component({
    selector: 'ncg-model-page',
    template: `
        <ncg-breadcrumb [data]="data"></ncg-breadcrumb>
        <ncg-model-page-header [modelPage]="data" *ngIf="!data.hideDefaultModelPageNavigation"></ncg-model-page-header>
        <ng-container *ngIf="schema">
            <ngx-json-ld [json]="schema"></ngx-json-ld>
        </ng-container>
        <div class="model-page content" *ngIf="data">
            <ncg-umb-grid [grid]="data?.grid"></ncg-umb-grid>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelPageComponent implements OnInit {
    static ref = 'modelPage';
    @Input()
    data: IModelPageResponse;
    schema: any = {
        '@context': 'https://schema.org',
        '@type': 'Car',
    };

    ngOnInit() {
        this._setJsonLd();
    }

    private _setJsonLd(): void {
        if (!this.data) {
            return;
        }

        this.schema.name = this.data.name;

        if (this.data.priceRaw) {
            this.schema.offers = {
                '@type': 'Offer',
                'price': this.data.priceRaw,
                'priceCurrency': this.data.priceCurrency,
                'availability': 'InStock',
            };
        } else {
            this.schema.offers = {
                '@type': 'Offer',
                'availability': 'LimitedAvailability',
            };
        }

        if (this.data.meta && this.data.meta.description) {
            this.schema.description = this.data.meta.description;
        }

        if (this.data.image) {
            this.schema.image = this.data.image.url;
        }

        if (this.data.meta.seoImage) {
            this.schema.image = ImageUrl(this.data.meta.seoImage, { width: 92, height: 92, mode: 'crop' });
        }
    }
}
