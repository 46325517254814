import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { ExpansionPanelModule } from '../expansion-panel/expansion-panel.module';
import { LinkModule } from '../link/link.module';
import { UtilsModule } from '../utils/utils.module';
import { FooterNavComponent } from './footer-nav.component';

@NgModule({
    declarations: [FooterNavComponent],
    imports: [CommonModule, IconSpriteModule, RouterModule, UtilsModule, ExpansionPanelModule, LinkModule],
    exports: [FooterNavComponent],
})
export class FooterNavModule {}
