import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { slideInOut } from '../../../../utils/animations/slide.animations';
import { ConfiguratorFacade } from '../../../../+state/configurator/configurator.facade';

@Component({
    selector: 'ncg-configurator-tabs-content',
    template: `
        <div class="content configurator__container">
            <h2
                class="configurator__body-text heading has-text-centered"
                [@inOut]="{ value: configuratorFacade.tabDirection$ | async, params: { distanceIn: '24px', distanceOut: '16px', durationOut: '0s' } }"
            >
                {{ translationBase + (configuratorFacade.tab$ | async) | translate }}
            </h2>
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        `
            .content {
                position: relative;
                z-index: 1;
                grid-row-start: 1;
                grid-column-start: 1;
                background: var(--color-white);
            }
            .heading {
                font-weight: var(--configurator-headline-weight);
                font-size: max(var(--font-size-base), 1.6rem); /* Wack, but font gets weird if too small on BYD */
            }
        `,
    ],
    standalone: true,
    animations: [slideInOut],
    imports: [CommonModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorTabsContentComponent {
    public translationBase = 'configurator.tab_content_';
    constructor(public readonly configuratorFacade: ConfiguratorFacade) {}
}
