import { Pipe, PipeTransform } from '@angular/core';
import { DataEntity, DataEntityWithResources } from '@ncg/data';
import { fieldById } from './field-by-id.util';

@Pipe({
    name: 'filterById',
    standalone: true,
})
export class FilterByIdPipe implements PipeTransform {
    transform(
        ids?: null | string[],
        data?: Record<string, DataEntityWithResources | DataEntity>,
        sortBy?: string | null
    ): (DataEntityWithResources | DataEntity | undefined)[] {
        if (!ids?.length) {
            return [];
        }
        const filteredItems = ids.map((id) => data?.[id]).filter((x) => x);
        if (sortBy) {
            filteredItems.sort((a, b) => {
                const aValue = fieldById(a?.fields, sortBy)?.data?.value ?? 0;
                const bValue = fieldById(b?.fields, sortBy)?.data?.value ?? 0;
                return aValue - bValue;
            });
        }
        return filteredItems;
    }
}
