import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IStandardPageNavResponse } from '@ncg/data';

import { FeatureDetectionService } from '../../core/feature-detection.service';

@Component({
    selector: 'ncg-standard-page-nav',
    template: `
        <ng-container *ngIf="data">
            <ncg-breadcrumb [data]="data"></ncg-breadcrumb>
            <div class="grid__row grid__theme--banner grid__theme--middle" *ngIf="data?.pageBannerImage && data?.pageBannerImage?.url">
                <ncg-banner-spot [isPageBanner]="true" [image]="data?.pageBannerImage"></ncg-banner-spot>
            </div>
            <div class="content standard-page standard-page-nav" [ngClass]="{ 'has-banner': !!(data.pageBannerImage && data.pageBannerImage.url) }">
                <div class="container">
                    <div class="columns">
                        <aside class="column is-3 standard-page-nav__side is-hidden-touch">
                            <div [ngClass]="{ 'sticky-wrap': hasScrolled, 'load-wrap': !hasScrolled }" ncgLoadIn>
                                <nav class="side-menu menu" *ngIf="data?.pageNavigation">
                                    <h3 class="menu-label">
                                        <ng-container *ngIf="data?.pageLink?.isExternal; else internalLink">
                                            <a rel="noopener" [href]="data?.pageLink?.url" [target]="data?.pageLink?.target">{{ headlineName }}</a>
                                        </ng-container>

                                        <ng-template #internalLink>
                                            <ng-container *ngIf="data?.pageLink?.url; else activeTmpl">
                                                <a [routerLink]="[data?.pageLink?.url]">{{ headlineName }}</a>
                                            </ng-container>

                                            <ng-template #activeTmpl>
                                                <a
                                                    routerLinkActive="is-active"
                                                    [routerLinkActiveOptions]="{ exact: true }"
                                                    [routerLink]="[headlineUrl]"
                                                    >{{ headlineName }}</a
                                                >
                                            </ng-template>
                                        </ng-template>
                                    </h3>

                                    <ul class="menu-list">
                                        <li *ngFor="let item of data?.pageNavigation">
                                            <ng-container *ngIf="item?.pageLink?.isExternal; else internalLink">
                                                <a rel="noopener" [href]="item?.pageLink?.url" [target]="item?.pageLink?.target">{{ item.name }}</a>
                                            </ng-container>

                                            <ng-template #internalLink>
                                                <a routerLinkActive="is-active" [routerLink]="[item?.pageLink?.url ? item?.pageLink?.url : item.url]">
                                                    {{ item.name }}
                                                </a>
                                            </ng-template>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </aside>
                        <div class="column is-12 is-9-desktop standard-page-nav__content">
                            <ncg-umb-grid [allowNarrowContent]="true" [grid]="data?.grid"></ncg-umb-grid>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardPageNavComponent implements OnInit, OnDestroy {
    static ref = 'standardPageNav';

    @Input() data: IStandardPageNavResponse;
    hasScrolled = false;
    headlineUrl?: string;
    headlineName?: string;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly featureDetection: FeatureDetectionService
    ) {}

    ngOnInit() {
        if (this.data) {
            this.headlineUrl = this.data.modelParentUrl ? this.data.modelParentUrl : this.data.url;
            this.headlineName = this.data.modelParentName ? this.data.modelParentName : this.data.name;
        }

        if (this.featureDetection.isBrowser()) {
            window.addEventListener('scroll', this.stickyPosition, { passive: true });
        }
    }

    ngOnDestroy() {
        this.removeScrollListener();
    }

    stickyPosition = () => {
        this.cd.markForCheck();
        this.hasScrolled = true;
        this.removeScrollListener();
    };

    private removeScrollListener(): void {
        if (this.featureDetection.isBrowser()) {
            window.removeEventListener('scroll', this.stickyPosition);
        }
    }
}
