import { Action, createReducer, on } from '@ngrx/store';

import { ConfiguratorState, ConfiguratorStep, ConfiguratorTab, ConfiguratorTabDirection } from '../../configurator/configurator';
import { configuratorSteps, configuratorTabs } from '../../configurator/configurator-settings';
import { navigateTab, reset, setCategories, setModel, setPageData, setStep, setTab, setTabState, softReset } from './configurator.actions';

const initialState: ConfiguratorState = {
    step: configuratorSteps[0],
    tab: configuratorTabs[0],
    loading: false,
    tabDirection: 'still',
    currency: 'DKK',
    enabledTabs: [],
    tabStates: {
        trim: 'pristine',
        powertrain: 'pristine',
        exterior: 'pristine',
        interior: 'pristine',
        optionals: 'pristine',
        accessories: 'pristine',
        partner_products: 'pristine',
    },
};

const _configuratorReducer = createReducer(
    initialState,
    on(setPageData, (state, { pageData }) => ({ ...state, pageData })),
    on(setCategories, (state, { categories }) => ({ ...state, categories })),
    on(setModel, (state, { model }) => {
        const enabledTabs: ConfiguratorTab[] = ['trim', 'powertrain', 'exterior', 'interior'];
        if (Object.keys(model.optionalOptions ?? {}).length) {
            enabledTabs.push('optionals');
        }
        if (Object.keys(model.accessories ?? {}).length) {
            enabledTabs.push('accessories');
        }
        if (Object.keys(model.partnerProducts ?? {}).length) {
            enabledTabs.push('partner_products');
        }
        return { ...state, model, enabledTabs };
    }),
    on(setTab, (state, { tab }) => {
        const tabDirection: ConfiguratorTabDirection = state.enabledTabs.indexOf(state.tab) > state.enabledTabs.indexOf(tab) ? 'left' : 'right';
        const step: ConfiguratorStep = 'configuration';
        // Set the previous tab to visited
        const tabStates = { ...state.tabStates, [state.tab]: 'visited' };
        return { ...state, tab, tabDirection, step, tabStates };
    }),
    on(navigateTab, (state, { direction }) => {
        const currentTabIndex = state.enabledTabs.indexOf(state.tab);
        const tab =
            direction === 'left'
                ? state.enabledTabs[Math.max(0, currentTabIndex - 1)]
                : state.enabledTabs[Math.min(currentTabIndex + 1, state.enabledTabs.length - 1)];
        const step: ConfiguratorStep = direction === 'right' && currentTabIndex === state.enabledTabs.length - 1 ? 'summary' : 'configuration';
        // Set the previous tab to visited
        const tabStates = { ...state.tabStates, [state.tab]: 'visited' };
        return { ...state, tab, tabDirection: direction, step, tabStates };
    }),
    on(setStep, (state, { step }) => ({ ...state, step })),
    on(setTabState, (state, { tab, tabState }) => ({
        ...state,
        tabStates: {
            ...state.tabStates,
            [tab]: tabState,
        },
    })),
    on(softReset, (state) => ({
        categories: state.categories,
        ...initialState,
        model: state.model,
        tab: state.enabledTabs[0],
        enabledTabs: state.enabledTabs,
    })),
    on(reset, (state) => ({
        categories: state.categories,
        ...initialState,
        tab: state.enabledTabs[0],
    }))
);

export const configuratorReducer = (state: ConfiguratorState | undefined, action: Action) => _configuratorReducer(state, action);
