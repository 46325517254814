export const HdAspect = 0.5625; // Equals an aspect ratio of 16/9
export const CinemaAspect = 0.4285703125; // Equals an aspect ratio of 21/9
export const MobileAspectRatio = 1.2487309645; // Equals an aspect ratio of 4/5
export const DesktopAspectRatio = CinemaAspect;
export const MobileBannerAspectRatio = HdAspect;
export const DesktopBannerAspectRatio = 0.290322581; // 31/9
export const ModelsAspectRatio = 0.5586956522;
export const MenuImageAspectRatio = 0.6995412844;
export const ProductItem = 0.66;
export const NineSixteen = 16 / 9;
