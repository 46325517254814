import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { IModelsListItem } from '@ncg/data';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { SettingsService } from '../../../core/settings.service';
import { ModelsAspectRatio } from '../../../utils/helpers/aspect-ratio';
import { ImageUrl } from '../../../utils/helpers/image-helper';

@Component({
    selector: 'ncg-models-list-item',
    template: `
        <ng-container *ngIf="model?.pageLink?.url && model?.pageLink?.isExternal; else internalLink">
            <a class="models-list__item animate-scale__link" [href]="model.pageLink?.url" [target]="model.pageLink?.target">
                <ng-container *ngTemplateOutlet="modelTmpl"></ng-container>
            </a>
        </ng-container>

        <ng-template #internalLink>
            <a *ngIf="model?.url" class="models-list__item animate-scale__link" [routerLink]="[model.url]" [attr.title]="model.name">
                <ng-container *ngTemplateOutlet="modelTmpl"></ng-container>
            </a>
        </ng-template>

        <ng-template #modelTmpl>
            <div class="is-ratio-model animate-scale is-top-scale is-small-scale">
                <img
                    class="models-list__image"
                    [alt]="pictureAlt"
                    [srcset]="pictureSrcset"
                    [src]="pictureSrc"
                    sizes="405px"
                    loading="lazy"
                    *ngIf="model.image && pictureSrc && pictureSrcset"
                    ncgImageLoad
                />
            </div>
            <header class="models-list__header">
                <ng-container *ngIf="isNew || model.isCampaign">
                    <ncg-info-label [isCampaign]="showCampaignLabels" [isNew]="isNew"></ncg-info-label>
                </ng-container>
                <h2 class="models-list__header--title" *ngIf="model.name">{{ model.name }}</h2>
                <h3 class="models-list__header--price" *ngIf="model.price">
                    <ng-container *ngIf="model.price"> {{ 'models.pricefrom' | translate: { price: model?.price } }} </ng-container>
                </h3>
            </header>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelsListItemComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    model: IModelsListItem;
    private readonly unsubscribe = new Subject<void>();
    isNew = false;
    showCampaignLabels = true;
    pictureSrcset: string = '';
    pictureSrc = '';
    pictureAlt = '';

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly settings: SettingsService
    ) {}

    ngOnInit(): void {
        this.settings
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((setting) => {
                this.showCampaignLabels = Boolean(setting.showCampaignLabels && this.model?.isCampaign);
                this.cd.markForCheck();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model && changes.model.currentValue !== changes.model.previousValue) {
            this.model = changes.model.currentValue;
            this.isNew = !!(this.model.modelProperties && this.model.modelProperties.includes('New'));
            this.addImageSizes();
            this.cd.detectChanges();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    addImageSizes() {
        if (this.model.image) {
            const image = this.model.image;
            this.pictureSrcset = `
                ${ImageUrl(image, { width: 405, heightratio: ModelsAspectRatio, mode: 'crop' })} 1x,
                ${ImageUrl(image, { width: 810, heightratio: ModelsAspectRatio, mode: 'crop' })} 2x
                `;
            this.pictureSrc = ImageUrl(image, { width: 810, heightratio: ModelsAspectRatio, mode: 'crop' });
            this.pictureAlt = this.model.name || this.model.image.altText || '';
        }
    }
}
