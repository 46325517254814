import { DealerPageComponent } from './dealer-page/dealer-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FilterPageComponent } from './filter-page/filter-page.component';
import { RootPageComponent } from './home/root-page.component';
import { ModelOverviewPageComponent } from './model-overview-page/model-overview-page.component';
import { ModelPageComponent } from './model-page/model-page.component';
import { ModelSubpageComponent } from './model-subpage/model-subpage.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { StandardPageNavComponent } from './standard-page-nav/standard-page-nav.component';
import { StandardPageComponent } from './standard-page/standard-page.component';
import { ConfiguratorPageComponent } from './configurator-page/configurator-page.component';
import { ConfiguratorModelPageComponent } from './configurator-model-page/configurator-model-page.component';
import { WidePageComponent } from './wide-page/wide-page.component';
import { FavoritesPageComponent } from './favorites-page/favorites-page.component';
import { ServiceBookingPageComponent } from './service-booking-page/service-booking-page.component';

export const pageComponents = [
    RootPageComponent,
    ErrorPageComponent,
    ModelPageComponent,
    ModelSubpageComponent,
    ModelOverviewPageComponent,
    StandardPageComponent,
    StandardPageNavComponent,
    FilterPageComponent,
    ProductPageComponent,
    DealerPageComponent,
    ConfiguratorPageComponent,
    ConfiguratorModelPageComponent,
    WidePageComponent,
    FavoritesPageComponent,
    ServiceBookingPageComponent,
];
