import { Inject, PLATFORM_ID, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { PageTransitionService } from './page-transition.service';

@Injectable()
export class PageTransitionResolve implements Resolve<string> {
    firstLoad = true;
    constructor(
        // NOTE: currently the @Inject decorator is needed on a argument of the constructor
        // for injection of services in this resolver to work. Please do not remove @Inject
        // without checking that it doesn't break the PageTransitionService injection
        @Inject(PLATFORM_ID)
        private platformId: any,
        private pageTransitionService: PageTransitionService
    ) {}

    resolve() {
        if (isPlatformBrowser(this.platformId)) {
            if (!this.firstLoad) {
                return this.pageTransitionService.startPre();
            }
            this.firstLoad = false;
        }
        return of('');
    }
}
