import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'ncg-tab',
    exportAs: 'ncgTab',
    template: `
        <div
            #tabContent
            class="tab-content"
            role="tabpanel"
            [attr.id]="id ? id : null"
            [ngClass]="{
                'is-custom-hidden': !isActive
            }"
        >
            <ng-content></ng-content>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
    @Input() label?: string;
    @Input() isActive = false;
    id: string;
    @ViewChild('tabContent') _tabContent: ElementRef<HTMLDivElement>;

    constructor(private readonly cd: ChangeDetectorRef) {}

    setActive(active: boolean) {
        this.isActive = active;
        this.cd.markForCheck();
    }

    setId(newId: string) {
        this.id = `panel-${newId}`;
        this.cd.detectChanges();
    }
}
