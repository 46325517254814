import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pad',
})
export class padPipe implements PipeTransform {
    transform(value: string | number, amount: number, padCharacter: string, direction: 'front' | 'end' = 'front'): string {
        const stringValue = value.toString();
        return direction === 'front' ? stringValue.padStart(amount, padCharacter) : stringValue.padEnd(amount, padCharacter);
    }
}
