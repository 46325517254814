import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EquipmentPackages } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-equipment-packages-spot',
    template: `
        <div class="equipment-packages-spot" *ngIf="data && data.packages && data.packages.length">
            <div class="columns is-desktop is-mobile is-multiline">
                <div class="column is-one-third-desktop is-half-mobile" *ngFor="let item of data.packages">
                    <ncg-equipment-packages-item [item]="item"></ncg-equipment-packages-item>
                </div>
            </div>

            <div class="equipment-packages-spot__text" *ngIf="data.text" ncgLoadIn>
                <ncg-rich-text [html]="data.text"></ncg-rich-text>
            </div>

            <div class="equipment-packages-spot__button" *ngIf="data.link && data.link?.url" ncgLoadIn>
                <ng-container *ngIf="data.link.isExternal; else internalLink">
                    <a class="button is-primary" rel="noopener" [href]="data.link.url" [target]="data.link.target">{{ data.link.name }}</a>
                </ng-container>
                <ng-template #internalLink>
                    <a class="button is-primary" [routerLink]="data.link.url">{{ data.link.name }}</a>
                </ng-template>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentPackagesSpotComponent extends SpotBaseDirective {
    static ref = 'equipmentpackages';

    @Input() data: EquipmentPackages;
}
