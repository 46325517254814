import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request as ExpressRequest } from 'express';
import { BehaviorSubject } from 'rxjs';

export interface IPreviewContext {
    token: string;
    culture: string;
}

export interface IPreviewPageContext extends IPreviewContext {
    pageId: number;
    published: boolean;
    url: string;
}

@Injectable({ providedIn: 'root' })
export class PreviewService {
    private _previewContext: IPreviewContext | null = null;
    private _previewObs$ = new BehaviorSubject<IPreviewPageContext | null>(null);

    constructor(
        @Optional()
        @Inject(REQUEST)
        private readonly request?: ExpressRequest
    ) {}

    public setPreviewFromContext(route: ActivatedRouteSnapshot): void {
        // Just stay in preview mode if entered once
        if (this.isPreview()) {
            return;
        }

        const host = this.request?.headers.host ?? location.host;
        if (route.queryParams['preview'] && !host.startsWith('www.')) {
            this._previewContext = {
                token: route.queryParams['preview'],
                culture: route.queryParams['previewCulture'] || '',
            };
        } else {
            this.exitPreview();
        }
    }

    public exitPreview(): void {
        this._previewContext = null;
        this._previewObs$.next(null);
    }

    public isPreview(): boolean {
        return !!this._previewContext?.token;
    }

    public getPreviewContextSnapshot() {
        return this._previewContext;
    }

    public getPreviewPageContext() {
        return this._previewObs$.asObservable();
    }

    public getPreviewQueryParams(): Params {
        return {
            preview: this._previewContext?.token,
            previewCulture: this._previewContext?.culture,
        };
    }

    public setPreviewPageContext(pageId: number, published: boolean, url: string) {
        if (this._previewContext) {
            this._previewObs$.next({
                pageId,
                published,
                url,
                ...this._previewContext,
            });
        }
    }
}
