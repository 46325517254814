import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { IDealerDetailsViewModel, IGetLocationsDto, NcgApiLocationResponse, NcgLocationCategory, NgcApiLocation } from '@ncg/data';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SettingsService } from '../core/settings.service';

@Injectable({
    providedIn: 'root',
})
export class LocationService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly http: HttpClient,
        private readonly settingsService: SettingsService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    /**
     * Gets Umbraco dealer page based on "store_code".
     * This doesn't use the location API or Elastic, but Umbraco
     *
     * @param id - The "store_code" of the location.
     */
    public getLocationById(id: string): Observable<IDealerDetailsViewModel> {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) => this.http.get<IDealerDetailsViewModel>(`api/page/dealer/${id}`, { params: { culture } })),
            takeUntil(this.unsubscribe)
        );
    }

    /**
     * Gets all locations for the current site context (businessId).
     * Uses "getLocationsByBusiness" in BFF->elastic-locations.service.ts.
     *
     * @param category - OPTIONAL: Specify location category (or not). If specified (only the that category will be returned).
     */
    public getLocationsBySiteContext(category: NcgLocationCategory = ''): Observable<NcgApiLocationResponse> {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) => {
                const model: IGetLocationsDto = {
                    category,
                    culture,
                };
                return this.http.get<NcgApiLocationResponse>('api/elastic-locations/contextual', { params: model as any });
            }),
            takeUntil(this.unsubscribe)
        );
    }

    /**
     * Gets all locations for a specific brand (e.g. "hyundai") or all brands (default).
     * Uses "getLocationsByBrand" in BFF->elastic-locations.service.ts.
     *
     * @param brand - OPTIONAL: Specify brand name. Defaults to "all".
     * @param category - OPTIONAL: Specify location category (or not). If specified (only the that category will be returned).
     */
    public getLocationsByBrandName(brand: string = 'all', category: NcgLocationCategory = ''): Observable<NcgApiLocationResponse> {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) => {
                const model: IGetLocationsDto = {
                    category,
                    culture,
                };
                return this.http.get<NcgApiLocationResponse>(`api/elastic-locations/brand/${brand}`, { params: model as any });
            }),
            takeUntil(this.unsubscribe)
        );
    }

    /**
     * Gets all locations based on "store_code" (e.g. "LVMAUPE-W").
     * Uses "getLocationByStoreCode" in BFF->elastic-locations.service.ts.
     */
    public getLocationByStoreCode(storeCode: string): Observable<NgcApiLocation> {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) => {
                const model: IGetLocationsDto = {
                    category: '',
                    culture,
                };
                return this.http.get<NgcApiLocation>(`api/elastic-locations/store/${storeCode}`, { params: model as any });
            }),
            takeUntil(this.unsubscribe)
        );
    }

    /**
     * Gets locations based on "used_car_id" (e.g. "637401754").
     * Uses "getLocationByUsedCarId" in BFF->elastic-locations.service.ts.
     *
     * @param carId - The used_car_id.
     */
    public getLocationByUsedCarId(carId: string): Observable<NgcApiLocation> {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) => {
                const model: IGetLocationsDto = {
                    category: '',
                    culture,
                };
                return this.http.get<NgcApiLocation>(`api/elastic-locations/car/${carId}`, { params: model as any });
            }),
            takeUntil(this.unsubscribe)
        );
    }
}
