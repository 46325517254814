import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PageResponse } from '@ncg/data';

@Component({
    selector: 'ncg-root-page',
    template: `<div class="content">
        <ncg-umb-grid [grid]="data?.grid"></ncg-umb-grid>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootPageComponent {
    public static ref = 'rootPage';

    @Input()
    data?: PageResponse;
}
