import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IModelPageResponse } from '@ncg/data';

@Component({
    selector: 'ncg-model-subpage',
    template: `
        <ncg-breadcrumb [data]="data"></ncg-breadcrumb>
        <ncg-model-page-header [modelPage]="data" *ngIf="!data.hideDefaultModelPageNavigation"></ncg-model-page-header>
        <div class="model-subpage" *ngIf="data">
            <ncg-umb-grid [grid]="data?.grid"></ncg-umb-grid>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelSubpageComponent {
    static ref = 'modelSubpage';
    @Input()
    data: IModelPageResponse;
}
