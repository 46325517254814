import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
    selector: 'ncg-card',
    template: `
        <div class="card card--{{ variant }}" [ngClass]="{ 'card--selected': selected }">
            <div class="card-radio scale-in" *ngIf="variant === 'radio' && selected">
                <svg-icon-sprite
                    [src]="'checkmark'"
                    [viewBox]="'0 0 30 30'"
                    [width]="'18px'"
                    [height]="'18px'"
                    aria-hidden="true"
                    class="icon"
                ></svg-icon-sprite>
            </div>
            <div class="content" [ngClass]="contentClass">
                <ng-content select="[card-image]"></ng-content>

                <div class="card-checkbox-wrapper" *ngIf="variant === 'checkbox'">
                    <div class="card-checkbox" [ngClass]="{ 'card-checkbox--selected': selected }">
                        <svg-icon-sprite
                            *ngIf="selected"
                            [src]="'checkmark'"
                            [viewBox]="'0 0 30 30'"
                            [width]="'24px'"
                            [height]="'24px'"
                            aria-hidden="true"
                            class="icon scale-in"
                        ></svg-icon-sprite>
                    </div>
                    <ng-content select="[card-label]"></ng-content>
                </div>

                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./card.component.scss'],
    standalone: true,
    imports: [CommonModule, IconSpriteModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
    @Input() selected?: boolean;
    @Input() variant?: 'radio' | 'checkbox' | 'plain' = 'radio'; // These are currently not real form elements, just visuals.
    @Input() contentClass?: string = '';
}
