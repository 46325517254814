import { Pipe, PipeTransform } from '@angular/core';
import { breakpoints } from '../../utils/helpers/breakpoints';

@Pipe({
    name: 'resourceSrcset',
    standalone: true,
    pure: true,
})
export class ResourceSrcsetPipe implements PipeTransform {
    transform(url: string): string | undefined {
        // NCG media does not have a worker, so this will currently have no effect
        return Object.values(breakpoints)
            .map((value) => `${url}?w=${value} ${value}w`)
            .join(', ');
    }
}
