import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISearchResult } from '@ncg/data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SettingsService } from '../core/settings.service';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    constructor(
        private readonly http: HttpClient,
        private readonly settingsService: SettingsService
    ) {}

    search(query: string): Observable<ISearchResult> {
        return this.settingsService.get().pipe(
            switchMap((settings) => {
                const { culture, searchTake } = settings;
                return this.http.get<ISearchResult>('api/search', { params: { query, take: Number(searchTake), culture } });
            })
        );
    }
}
