import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router, Scroll } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { FeatureDetectionService } from './feature-detection.service';

export interface IScrollContext {
    windowHeight: number;
    bodyHeight: number;
}

@Injectable({
    providedIn: 'root',
})
export class ScrollService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private readonly isBrowser: boolean = this.featureDetection.isBrowser();
    private readonly scrollElement: Element = this.doc.scrollingElement || this.doc.documentElement;
    private readonly defaultScrollBehavior: ScrollBehavior = 'smooth';
    private readonly defaultScrollIntoViewOptions: ScrollIntoViewOptions = {
        behavior: this.defaultScrollBehavior,
        block: 'start',
        inline: 'nearest',
    };

    private hasInit: boolean = false;
    private anchorRetryTimer?: number;
    private restorationCancelInterval?: number;
    private enableDefaultScroll = true;

    constructor(
        @Inject(DOCUMENT)
        private readonly doc: Document,
        private readonly router: Router,
        private readonly location: Location,
        private readonly featureDetection: FeatureDetectionService
    ) {}

    public ngOnDestroy(): void {
        this.clearTimers();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    /**
     * Initialize Scroll Restoration.
     */
    public initScrollRestoration(): void {
        if (!this.hasInit && this.isBrowser) {
            this.hasInit = true;
            let scrollPath: string;
            this.router.events
                .pipe(
                    filter((e) => e instanceof NavigationStart || e instanceof Scroll),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((e) => {
                    if (e instanceof Scroll) {
                        if (e.anchor && scrollPath === this.location.path()) {
                            this.scrollToAnchorContinuous(e.anchor, 0, { behavior: 'auto' });
                        } else if (e.position) {
                            this.scrollToPositionContinuous(e.position[1]);
                        } else if (e.anchor) {
                            this.scrollToAnchorContinuous(e.anchor, 0, { behavior: 'auto' });
                        } else if (this.enableDefaultScroll) {
                            this.scrollToPosition({ top: 0, left: 0, behavior: 'auto' });
                        }
                        scrollPath = this.location.path();
                    } else {
                        this.clearTimers();
                    }
                });
        }
    }

    /**
     * Scroll to a specific position.
     * Pass scrollElement if scrolling inside e.g. a <div>.
     */
    public scrollToPosition(options?: ScrollToOptions, scrollElement?: HTMLElement) {
        if (this.isBrowser) {
            (scrollElement || this.scrollElement).scroll({ behavior: this.defaultScrollBehavior, ...options });
        }
    }

    /**
     * Scroll specific element into view.
     * Pass scrollElement if scrolling inside e.g. a <div>.
     */
    public scrollToElement(element?: HTMLElement, options?: ScrollIntoViewOptions, scrollElement?: HTMLElement) {
        if (this.isBrowser) {
            if (element) {
                if (element.scrollIntoView) {
                    element.scrollIntoView({
                        ...this.defaultScrollIntoViewOptions,
                        ...options,
                    });
                } else {
                    this.scrollToPosition(
                        {
                            top: element.getBoundingClientRect().top + element.scrollTop,
                            behavior: options?.behavior || this.defaultScrollBehavior,
                        },
                        scrollElement
                    );
                }
            }
        }
    }

    /**
     * Scroll to anchor/id.
     * Pass scrollElement if scrolling inside e.g. a <div>.
     *
     * @returns true if element was found and false if not found.
     */
    public scrollToAnchor(anchor: string, options?: ScrollIntoViewOptions, scrollElement?: HTMLElement): boolean {
        if (this.isBrowser) {
            const element = this.doc.getElementById(anchor);
            if (element) {
                this.scrollToElement(element, options, scrollElement);
                // Push anchor navigation to state (for browser-back compatability).
                if (this.doc.location.hash !== '#' + anchor) {
                    let state = this.location.getState() as NgLocationState;
                    if (!state) {
                        state = { navigationId: 0 };
                    }
                    state.navigationId = state.navigationId ? state.navigationId++ : 1;

                    this.location.go(`${this.doc.location.pathname + this.doc.location.search}#${anchor}`, undefined, state);
                }
                return true;
            }
        }
        return false;
    }

    public toggleEnableDefaultScroll(enable?: boolean): void {
        if (enable !== undefined) {
            this.enableDefaultScroll = enable;
        } else {
            this.enableDefaultScroll = !this.enableDefaultScroll;
        }
    }

    public get enableDefaultScrollValue(): boolean {
        return this.enableDefaultScroll;
    }

    public get scrollPosition(): number {
        return this.scrollElement.scrollTop;
    }

    private scrollToPositionContinuous(targetPosition: number): void {
        this.clearTimers();
        let retries = 0;
        this.restorationCancelInterval = window.setInterval(() => {
            if (retries > 500) {
                this.clearTimers();
            }
            const currentPosition = this.scrollPosition;
            const currentHeight = this.getPageHeight();
            if (currentHeight >= targetPosition) {
                this.scrollToPosition({ top: targetPosition, behavior: 'auto' });
            }
            if (currentPosition === targetPosition) {
                this.clearTimers();
            }
            retries++;
        }, 10);
    }

    private scrollToAnchorContinuous(anchor: string, activeRetries: number = 0, options?: ScrollIntoViewOptions): void {
        this.clearTimers();
        const element = this.doc.getElementById(anchor);

        if (element) {
            this.scrollToElement(element, options);
        } else if (activeRetries < 500) {
            this.anchorRetryTimer = window.setTimeout(() => {
                activeRetries++;
                this.scrollToAnchorContinuous(anchor, activeRetries, options);
            }, 10);
        }
    }

    private getPageHeight(): number {
        return Math.max(
            this.doc.documentElement.clientHeight,
            this.doc.body.clientHeight,
            this.doc.documentElement.offsetHeight,
            this.doc.body.offsetHeight,
            this.doc.documentElement.scrollHeight,
            this.doc.body.scrollHeight
        );
    }

    private clearTimers(): void {
        clearTimeout(this.anchorRetryTimer);
        clearInterval(this.restorationCancelInterval);
    }
}

interface NgLocationState {
    navigationId?: number;
}
