import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IGalleryItem } from '@ncg/data';

import { HdAspect } from '../../utils/helpers/aspect-ratio';
import { ImageUrl } from '../../utils/helpers/image-helper';

@Component({
    selector: 'ncg-gallery-spot-item',
    template: `
        <div class="gallery-spot__item" *ngIf="item">
            <img
                class="gallery-spot__item--image"
                *ngIf="src && srcset"
                [src]="src"
                [srcset]="srcset"
                [attr.alt]="alt"
                sizes="(max-width: 1024px) 100vw, 1220px"
            />
            <div class="gallery-spot__item--text rte-content" *ngIf="item.text">
                <ncg-rich-text [html]="item.text"></ncg-rich-text>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GallerySpotItemComponent implements OnInit {
    @Input() item: IGalleryItem;

    alt = '';
    src = '';
    srcset: string = '';

    ngOnInit() {
        if (this.item && this.item.image) {
            this.alt = this.item.title || this.item.image.altText || '';
            this.src = ImageUrl(this.item.image, { width: 1220, heightratio: HdAspect, mode: 'crop' });
            this.srcset = `
                ${ImageUrl(this.item.image, { width: 345, heightratio: HdAspect, mode: 'crop' })} 345w,
                ${ImageUrl(this.item.image, { width: 790, heightratio: HdAspect, mode: 'crop' })} 790w,
                ${ImageUrl(this.item.image, { width: 1220, heightratio: HdAspect, mode: 'crop' })} 1220w,
                ${ImageUrl(this.item.image, { width: 2440, heightratio: HdAspect, mode: 'crop' })} 2440w
            `;
        }
    }
}
