import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CarModel, IConfiguratorModelPageResponse, getBuildIdQuery } from '@ncg/data';
import { Observable, Subject, combineLatest, forkJoin, from, of, take, takeUntil } from 'rxjs';

import { setError, setCurrency, setModel, setPageData } from '../../+state/configurator/configurator.actions';
import { ConfiguratorFacade } from '../../+state/configurator/configurator.facade';
import { ConfiguratorService } from '../../configurator/configurator.service';
import { FeatureDetectionService } from '../../core/feature-detection.service';
import { productListAnimation } from '../../utils/animations/filter.animation';
import { SettingsService } from '../../core/settings.service';

@Component({
    selector: 'ncg-configurator-model-page',
    animations: [productListAnimation],
    template: `
        <div class="content configurator-page">
            <ng-container [ngSwitch]="state">
                <div *ngSwitchCase="'loading'" class="configurator-loader">
                    <ncg-spinner-car class="car-spinner"></ncg-spinner-car>
                </div>
                <ng-container *ngSwitchCase="'success'">
                    <ng-container #vcConfigurator></ng-container>
                </ng-container>
            </ng-container>
        </div>
    `,
    styles: [
        `
            .car-spinner {
                color: var(--configurator-accent-color);
                width: clamp(10rem, 12vw + 4rem, 20rem);
            }
            .configurator-loader {
                position: fixed;
                z-index: 1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: var(--color-white);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorModelPageComponent implements OnInit, OnDestroy {
    static ref = 'configuratorModelPage';
    private readonly unsubscribe = new Subject<void>();
    private rootReuseStrategy: (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) => boolean;
    public buildIdQuery = getBuildIdQuery();
    public state: 'loading' | 'success' | 'error' = 'loading';

    @ViewChild('vcConfigurator', { read: ViewContainerRef })
    viewContainerConfigurator: ViewContainerRef;

    @Input() data: IConfiguratorModelPageResponse;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly router: Router,
        private readonly settingsService: SettingsService,
        private readonly configuratorService: ConfiguratorService,
        private readonly featureDetection: FeatureDetectionService,
        public readonly configuratorFacade: ConfiguratorFacade,
        public readonly route: ActivatedRoute
    ) {}

    ngOnInit() {
        if (this.featureDetection.isServer()) {
            return;
        }

        // Save the root reuseStrategy defined in app-routing
        this.rootReuseStrategy = this.router.routeReuseStrategy.shouldReuseRoute;
        // Always reuse routes, when navigating on this component, since we stay on the same route, and only change queryParams.
        // This stops unnecessary rerendering of content inside the router-outlet
        this.router.routeReuseStrategy.shouldReuseRoute = (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) => {
            // If the route component is PageComponent, template is on data,
            // else we must find the template from the child
            const futureTemplate = future.data.content?.template || future.firstChild?.data.content?.template;
            const currTemplate = curr.data.content?.template || curr.firstChild?.data.content?.template;
            const hasSameTemplate = currTemplate === futureTemplate;
            return hasSameTemplate && futureTemplate === ConfiguratorModelPageComponent.ref;
        };

        combineLatest([this.settingsService.settings$, forkJoin([from(import('../../configurator/configurator.component')), this._getModelData()])])
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe({
                next: ([settings, [{ ConfiguratorComponent }, model]]) => {
                    if (!model.title) {
                        this._handleError();
                        return;
                    }
                    this.state = 'success';
                    this.configuratorFacade.dispatch(setModel({ model }));
                    this.configuratorFacade.dispatch(setPageData({ pageData: this.data }));
                    this.configuratorFacade.dispatch(setCurrency({ currency: settings.currency }));
                    this.cd.markForCheck();

                    window.requestAnimationFrame(() => {
                        this.viewContainerConfigurator.clear();
                        this.viewContainerConfigurator.createComponent(ConfiguratorComponent);
                        this.cd.markForCheck();
                    });
                },
                error: () => this._handleError(),
            });
    }

    private _handleError(): void {
        this.state = 'error';
        this.cd.markForCheck();
        this.configuratorFacade.dispatch(setError({ error: { type: 'incomplete-data' } }));
    }

    private _getModelData(): Observable<CarModel> {
        const segments = this.route.snapshot.url;
        const modelName = segments[segments.length - 1]?.path;

        if (!modelName) {
            return of({});
        }

        return this.configuratorService.getConfiguratorModelByName(modelName).pipe(take(1), takeUntil(this.unsubscribe));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.router.routeReuseStrategy.shouldReuseRoute = this.rootReuseStrategy;
    }
}
