import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { IFacetList, IFilterPageLayout } from '@ncg/data';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProductsFacade } from '../../+state/products/products.facade';

@Component({
    selector: 'ncg-filter-facets',
    templateUrl: './filter-facets.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFacetsComponent implements OnInit, OnDestroy {
    facetList?: IFacetList[] = [];
    filterPageLayout?: IFilterPageLayout;
    showPremiumFacet = false;
    showUsedFacet = false;
    premiumText = '';
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly productsFacade: ProductsFacade,
        private readonly cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        combineLatest([this.productsFacade.layout$, this.productsFacade.facetList$, this.productsFacade.selectedPremiumText$])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([layout, list, premiumText]) => {
                this.showPremiumFacet = layout?.showPremiumFacet || false;
                this.showUsedFacet = layout?.showUsedFacet || false;
                this.premiumText = premiumText || '';

                this.transformFilterFacetsList(list);

                this.cd.markForCheck();
            });
    }

    transformFilterFacetsList(list?: IFacetList[]) {
        let isAnyMakeSelected = false;
        let isBmwSelected = false;
        let makeIsVisible = true;
        const newList: IFacetList[] = [];

        if (list) {
            for (const item of list) {
                if (item.facetKey === 'make.keyword') {
                    const makeFacets = item.facets || [];
                    for (const facet of makeFacets) {
                        if (facet.selected) {
                            isAnyMakeSelected = true;
                        }

                        if (facet.key?.toLowerCase().trim() === 'bmw' && facet.selected) {
                            isBmwSelected = true;
                        }
                    }

                    // Remove 'make' if there is less than 2 choices
                    if (makeFacets.length < 2 && !isAnyMakeSelected) {
                        makeIsVisible = false;
                        continue;
                    }
                }

                // Remove model and series if there isn't chosen a make. Only show series if BMW is selected
                if (
                    makeIsVisible &&
                    ((!isAnyMakeSelected && item.facetKey === 'model.keyword') || (!isBmwSelected && item.facetKey === 'series.keyword'))
                ) {
                    continue;
                }

                // expand `model` if any make is selected
                // or `series` if BMW is selected
                if (isAnyMakeSelected) {
                    if ((isAnyMakeSelected && item.facetKey === 'model.keyword') || (isBmwSelected && item.facetKey === 'series.keyword')) {
                        newList.push({ ...item, isCollapsed: false });
                        continue;
                    }
                }

                if (item.facetKey === 'only') {
                    newList.push({
                        ...item,
                        facetGroups: item.facetGroups?.filter((group) => {
                            if (group.facetKey === 'isdemo') {
                                return this.showUsedFacet;
                            } else if (group.facetKey === 'ispremium') {
                                return this.showPremiumFacet && Boolean(this.premiumText);
                            }

                            return true;
                        }),
                    });
                    continue;
                }

                newList.push(item);
            }
        }

        this.facetList = newList.map((facetList) => {
            if (facetList.facetGroups) {
                let selected = 0;
                facetList.facetGroups.forEach((facetGroup) => {
                    facetGroup.facets?.filter((x) => x.selected).forEach(() => selected++);
                });
                return {
                    ...facetList,
                    selectedCount: selected > 0 ? selected : undefined,
                };
            } else {
                const selected = facetList.facets?.filter((facet) => facet.selected).length || 0;
                return {
                    ...facetList,
                    selectedCount: selected > 0 ? selected : undefined,
                };
            }
        });

        this.cd.markForCheck();
    }

    trackByFn(index: number, item: any) {
        return item.facetKey || index;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
