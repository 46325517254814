import { ElementRef } from '@angular/core';

export const easeSmoothOut = 'cubic-bezier(0.23, 0, 0.13, 1)'; // Equals scss var $SmoothOut
export const smoothIn = 'cubic-bezier(0.885, 0, 0.68, 1)'; // Equals scss var $SmoothIn
export const smoothInOut = 'cubic-bezier(0.165, 0.84, 0.44, 1)'; // Equals scss var $SmoothInOut
export const ease = 'cubic-bezier(0.6, 1.5, 0.6, 1)';
export const fadeIn = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)';
export const staggeFadeIn = 'cubic-bezier(.22,.78,0,.98)';
export const fadeOut = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)';
export const ANIMATION_TIMINGS = '300ms cubic-bezier(0.35, 0, 0.25, 1)';

export const timingFast = 200;
export const timingNormal = 300;
export const timingSlow = 500;

const logarithmicTime = (length: number, baseTime: number): number => {
    const start = length <= 0 ? 1 : length;
    const value = Math.floor((Math.log(start) + 1) * baseTime);
    return value;
};

export const animateTo = (element: HTMLElement, keyframes: Keyframe[], options?: KeyframeAnimationOptions): Animation => {
    const animate = element.animate(keyframes, { fill: 'both', ...options });
    animate.addEventListener('finish', () => {
        animate.cancel();
    });
    return animate;
};

export const animateOut = async (arr: ElementRef<HTMLDivElement>[]) => {
    await Promise.all(
        arr.map(async (item) => {
            await animateTo(
                item.nativeElement,
                [
                    { transform: 'translate3d(0, 0, 0)' },
                    { transform: 'scale3d(1, 1, 1)', transformOrigin: 'bottom' },
                    { opacity: '0', transform: 'translate3d(0, 40px, 0) scale3d(0.92, 0.92, 1)' },
                ],
                {
                    duration: 400,
                    easing: fadeOut,
                    fill: 'both',
                }
            ).finished;
        })
    );
};

export const animateIn = async (arr: ElementRef<HTMLDivElement>[]) => {
    await Promise.all(
        arr.map(async (item, index) => {
            item.nativeElement.style.opacity = '0'; // Safari fix, to prevent blink
            const startDelay = 80;

            let delay = 0;
            if (index > 0) {
                delay = logarithmicTime(index, startDelay);
            }

            item.nativeElement.style.opacity = ''; // Safari fix, to prevent blink

            await animateTo(
                item.nativeElement,
                [{ opacity: '0', transform: 'translate3d(0, -40px, 0)' }, { opacity: '0.2' }, { transform: 'translate3d(0, 0, 0)' }],
                {
                    duration: 1000,
                    easing: staggeFadeIn,
                    fill: 'both',
                    delay,
                }
            ).finished;
        })
    );
};
