import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CarouselSpot } from '@ncg/data';
import SwiperCore, { Navigation, Pagination } from 'swiper';

SwiperCore.use([Navigation, Pagination]);

/**
 * ### Note for content editors
 *
 * __The optimal image size and format:__
 *
 * Width:         `3100px`<br>
 * Height:        `1744px`<br>
 * Aspect ratio:  `16:9`<br>
 * Filetype:      `jpg` | `jpeg`
 *
 */
@Component({
    selector: 'ncg-carousel-spot',
    template: `
        <div class="carousel">
            <div *ngIf="data?.items?.length" class="container">
                <swiper
                    class="carousel-spot__container"
                    [slidesPerView]="'auto'"
                    [spaceBetween]="15"
                    [breakpoints]="{ '1024': { spaceBetween: 30 } }"
                    [grabCursor]="true"
                    [navigation]="{
                        prevEl: prev,
                        nextEl: next
                    }"
                >
                    <ng-template swiperSlide *ngFor="let item of data.items">
                        <ncg-carousel-item [item]="item"></ncg-carousel-item>
                    </ng-template>
                </swiper>
                <div class="carousel__arrows carousel__arrows-container">
                    <div #prev class="swiper-button-prev carousel__prev"></div>
                    <div #next class="swiper-button-next carousel__next"></div>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselSpotComponent {
    static ref = 'carousel';
    @Input() data: CarouselSpot;
}
