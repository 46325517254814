import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { SwiperModule } from 'swiper/angular';
import { ButtonModule } from '../button/button.module';
import { ConfiguratorModule } from '../configurator/configurator.module';
import { EmbedModule } from '../embed/embed.module';
import { EnergyLabelModule } from '../energy-labels/energy-labels.module';
import { ExpansionPanelModule } from '../expansion-panel/expansion-panel.module';
import { FormModule } from '../form/form.module';
import { InfoLabelModule } from '../label/info-label.module';
import { LegalModule } from '../legal/legal.module';
import { LinkModule } from '../link/link.module';
import { LogosModule } from '../logos/logos.module';
import { NcapStarsModule } from '../ncap-stars/ncap-stars.module';
import { ProductsModule } from '../products/products.module';
import { RichTextModule } from '../rich-text/rich-text.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { TabsModule } from '../tabs/tabs.module';
import { TextSpriteIconModule } from '../text-sprite-icon/text-sprite-icon.module';
import { UsedCarsTagsPipe } from '../used-cars/used-cars-tags.pipe';
import { UtilsModule } from '../utils/utils.module';
import { VideoModule } from '../video/video.module';
import { BannerSpotComponent } from './banner-spot/banner-spot.component';
import { CampaignsSpotItemComponent } from './campaigns-spot/campaigns-spot-item.component';
import { CarouselSpotItemComponent } from './carousel-spot/carousel-spot-item.component';
import { DynamicSpotComponentDirective } from './dynamic-spot-component.directive';
import { EquipmentPackagesItemComponent } from './equipment-packages-spot/equipment-packages-item.component';
import { GallerySpotItemComponent } from './gallery-spot/gallery-spot-item.component';
import { ImageTextItemComponent } from './image-text-picker-spot/image-text-item/image-text-item.component';
import { ModelPageNavigationSpotService } from './model-page-navigation-spot/model-page-navigation-spot.service';
import { ModelsListItemComponent } from './models-spot/models-list-item/models-list-item.component';
import { MotorsizeItemIconsComponent } from './motorsize-spot/motorsize-item-icons.component';
import { MotorsizeItemComponent } from './motorsize-spot/motorsize-item.component';
import { RecentlyViewedCarsSpotService } from './recently-viewed-cars-spot/recently-viewed-cars-spot.service';
import { spotComponents } from './spot-components';
import { SpotContentComponent } from './spot-content/spot-content.component';
import { SpotHeadlineComponent } from './spot-headline/spot-headline.component';
import { SpotsConfig } from './spots-config';
import { SubnavigationItemComponent } from './subnavigation-spot/subnavigation-item.component';
import { TimelineItemComponent } from './timeline-spot/timeline-item.component';
import { VideoItemComponent } from './video-spot/video-item.component';

@NgModule({
    providers: [ModelPageNavigationSpotService, RecentlyViewedCarsSpotService],
    declarations: [
        ...spotComponents,
        DynamicSpotComponentDirective,
        SpotContentComponent,
        SpotHeadlineComponent,
        ModelsListItemComponent,
        ImageTextItemComponent,
        GallerySpotItemComponent,
        MotorsizeItemComponent,
        EquipmentPackagesItemComponent,
        MotorsizeItemIconsComponent,
        SubnavigationItemComponent,
        CarouselSpotItemComponent,
        CampaignsSpotItemComponent,
        UsedCarsTagsPipe,
        VideoItemComponent,
        TimelineItemComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        RouterModule,
        ButtonModule,
        EmbedModule,
        EnergyLabelModule,
        LegalModule,
        ExpansionPanelModule,
        TabsModule,
        FormModule,
        UtilsModule,
        NcapStarsModule,
        InfoLabelModule,
        ProductsModule,
        ConfiguratorModule,
        SpinnerModule,
        RichTextModule,
        LogosModule,
        SwiperModule,
        HttpClientJsonpModule,
        VideoModule,
        LinkModule,
        NgxJsonLdModule,
        IconSpriteModule,
        TextSpriteIconModule,
    ],
    exports: [BannerSpotComponent, SpotContentComponent, ...spotComponents, DynamicSpotComponentDirective],
})
export class SpotsUiModule {
    static forRoot(config: SpotsConfig): ModuleWithProviders<SpotsUiModule> {
        return {
            ngModule: SpotsUiModule,
            providers: [
                {
                    provide: SpotsConfig,
                    useValue: config,
                },
            ],
        };
    }
}
