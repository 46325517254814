import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { CampaignsSpot, HeadlineSpot, INavigationResponse, ICampaignSpotTag } from '@ncg/data';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ScrollService } from '../../core/scroll.service';

import { NavigationService } from '../../navigation/navigation.service';
import { menuBarAnimation } from '../../utils/animations/filter.animation';
import flatten from '../../utils/helpers/flatten';
import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-campaigns-spot',
    template: `
        <ncg-headline-spot *ngIf="headline" [data]="headline"></ncg-headline-spot>
        <div class="campaigns-spot columns is-mobile is-multiline" ncgLoadIn [loadOptions]="{ threshold: 0.02 }">
            <div class="column is-full all-models" *ngIf="tags.length > 1">
                <nav class="navbar">
                    <div class="navbar-start all-models-filter" #navRef>
                        <button
                            class="button is-filter"
                            #allBtnRef
                            [ngClass]="{ 'is-active-bar': !activeTag }"
                            (click)="setTag(undefined, allBtnRef)"
                        >
                            <span *ngIf="allTagsText">{{ allTagsText }}</span>
                            <span *ngIf="!allTagsText">{{ 'model_types.all' | translate }}</span>
                        </button>
                        <button
                            #btnRef
                            *ngFor="let tag of tags; trackBy: trackByTagFn"
                            class="button is-filter"
                            [ngClass]="{ 'is-active-bar': activeTag === tag }"
                            (click)="setTag(tag, btnRef)"
                        >
                            <span>{{ tag.name }}</span>
                        </button>
                        <div
                            [@menuBar]="{
                                value: state,
                                params: {
                                    scaleToPercentage: scaleToPercentage,
                                    scalePercentage: scalePercentage,
                                    fromX: fromX + 'px',
                                    toX: toX + 'px'
                                }
                            }"
                            #activeBarRef
                            class="active-bar"
                        ></div>
                    </div>
                </nav>
            </div>
            <div class="column is-12">
                <div class="campaigns columns is-mobile is-multiline" *ngIf="activeCampaigns.length; else emptyCampaigns">
                    <div class="column is-full-mobile is-half-tablet" *ngFor="let campaign of activeCampaigns; trackBy: trackByFn">
                        <ncg-campaigns-spot-item [campaign]="campaign"></ncg-campaigns-spot-item>
                    </div>
                </div>
                <ng-template #emptyCampaigns>
                    <ng-container *ngIf="activeTag">{{ 'campaigns.no_campaigns_tag' | translate: { tag: activeTag?.name } }}</ng-container>
                    <ng-container *ngIf="!activeTag">{{ 'campaigns.no_campaigns' | translate }}</ng-container>
                </ng-template>
            </div>
            <div class="column is-12" *ngIf="pagination && !states.loadAll">
                <div class="has-text-centered">
                    <ncg-button [disabled]="states.loadMore" (click)="loadMore()">{{ 'campaigns.load_more_button' | translate }}</ncg-button>
                </div>
            </div>
            <div class="column is-12" *ngIf="seeAllCampaignsUrl && rootLinkText">
                <div class="has-text-centered">
                    <a class="button is-link" [routerLink]="seeAllCampaignsUrl">{{ rootLinkText }}</a>
                </div>
            </div>
        </div>
    `,
    animations: [menuBarAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignsSpotComponent extends SpotBaseDirective implements AfterViewInit, OnDestroy {
    static ref = 'campaigns';

    @Input() set data(value: CampaignsSpot) {
        this.amount = value.amount;
        this.visibleAmount = value.amount;
        this.rootId = value.rootId;
        this.pagination = value.pagination;
        this.tags = value.tags;
        this.campaigns = value.campaigns;
        this.seeAllCampaignsUrl = value.seeAllCampaignsUrl;
        this.rootLinkText = value.rootLinkText;
        this.allTagsText = value.allTagsText;
        if (value.title || value.subtitle) {
            this.headline = {
                title: value.title,
                subtitle: value?.subtitle,
                alias: 'headline',
            };
        }
        this.setTag();
    }

    @ViewChild('navRef') navRef: ElementRef;
    @ViewChild('allBtnRef') allBtnRef: ElementRef;
    @ViewChild('activeBarRef') activeBarRef: ElementRef;

    states = {
        loadMore: false,
        loadAll: false,
    };
    rootLinkText?: string;
    allTagsText?: string;
    headline?: HeadlineSpot;
    seeAllCampaignsUrl?: string;
    amount: number;
    visibleAmount: number;
    tags: ICampaignSpotTag[] = [];
    activeTag?: ICampaignSpotTag;
    campaigns: INavigationResponse[] = [];
    activeCampaigns: INavigationResponse[] = [];
    state: 'initial' | 'move' = 'initial';
    scaleToPercentage = 0; // Size of the active button in percentage
    scalePercentage = 0; // The scaling size between active and previous button
    prevButton?: HTMLButtonElement;
    toButton: HTMLButtonElement;
    fromX = 0;
    toX = 0;
    direction: 'leftToRight' | 'rightToLeft' = 'leftToRight';

    pagination = false;
    page = 1;
    private rootId: number;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly navigationService: NavigationService,
        private readonly cd: ChangeDetectorRef,
        private readonly scrollService: ScrollService
    ) {
        super();
    }

    ngAfterViewInit() {
        this.initializeFilterButton();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    initializeFilterButton() {
        if (this.allBtnRef && this.allBtnRef.nativeElement.clientWidth > 0) {
            this.toButton = this.allBtnRef.nativeElement;
            this.setTag(this.activeTag, this.toButton);
        }
    }

    setTag(tag?: ICampaignSpotTag, btnElement?: HTMLButtonElement): void {
        this.activeTag = tag;
        this.activeCampaigns = tag ? this.campaigns.filter((x) => (x.tags ? x.tags.includes(tag.key) : false)) : this.campaigns;
        this.activeCampaigns = this.activeCampaigns.slice(0, this.visibleAmount);
        this.cd.markForCheck();

        if (this.navRef && btnElement && this.tags.length) {
            this.prevButton = this.toButton;
            this.toButton = btnElement;
            this.scrollToButtonElement();
            this.animateActiveBar();
        }
    }

    scrollToButtonElement() {
        let scrollNumber;
        const itemOffsetLeft = this.toButton.offsetLeft;
        const itemWidth = this.toButton.clientWidth;
        const nav: HTMLElement = this.navRef.nativeElement;
        const navWidth = nav.offsetWidth;
        const navOffsetLeft = nav.scrollLeft;
        const btnPadding = 40; // used for to show some of the next menu item

        if (itemWidth + itemOffsetLeft > navWidth + navOffsetLeft) {
            scrollNumber = itemWidth + itemOffsetLeft - navWidth;
            scrollNumber = scrollNumber + btnPadding;
        } else if (navOffsetLeft > itemOffsetLeft) {
            scrollNumber = itemOffsetLeft;
            scrollNumber = scrollNumber - btnPadding;
        } else {
            return;
        }

        this.scrollService.scrollToPosition({ left: scrollNumber }, nav);
    }

    animateActiveBar(isResize?: boolean) {
        if (this.navRef && this.navRef.nativeElement && this.activeBarRef.nativeElement) {
            const nav: HTMLElement = this.navRef.nativeElement;
            const btnStyle = window.getComputedStyle(this.toButton);
            const btnPl = btnStyle.paddingLeft ? Number(btnStyle.paddingLeft.replace('px', '')) : 0;
            const btnPr = btnStyle.paddingRight ? Number(btnStyle.paddingRight.replace('px', '')) : 0;
            const padding = btnPl + btnPr;
            const width = this.toButton.clientWidth - padding;
            this.scaleToPercentage = width / nav.offsetWidth;
            this.toX = this.toButton.offsetLeft + btnPl;

            // Only set new width an X coordinates when resizing window
            if (isResize) {
                return;
            }

            if (this.prevButton) {
                const prevBtnStyle = window.getComputedStyle(this.prevButton);
                const prevBtnPl = prevBtnStyle.paddingLeft ? Number(prevBtnStyle.paddingLeft.replace('px', '')) : 0;
                const prevBtnPr = prevBtnStyle.paddingRight ? Number(prevBtnStyle.paddingRight.replace('px', '')) : 0;
                const prevPadding = prevBtnPl + prevBtnPr;
                const prevWidth = this.prevButton.clientWidth - prevPadding;

                this.direction = this.prevButton.offsetLeft < this.toButton.offsetLeft ? 'leftToRight' : 'rightToLeft';

                if (this.direction === 'leftToRight') {
                    this.fromX = this.prevButton.offsetLeft + prevBtnPl;
                    this.scalePercentage = (this.toButton.offsetLeft + btnPl + width - (this.prevButton.offsetLeft + prevBtnPl)) / nav.offsetWidth;
                } else {
                    this.fromX = this.toButton.offsetLeft + btnPl;
                    this.scalePercentage =
                        (this.prevButton.offsetLeft + prevBtnPl + prevWidth - (this.toButton.offsetLeft + btnPl)) / nav.offsetWidth;
                }
            } else {
                this.fromX = 0;
                this.scalePercentage = this.toX / nav.offsetWidth + this.scaleToPercentage;
            }

            this.state = this.state === 'initial' ? 'move' : 'initial';
            this.cd.detectChanges();
        }
    }

    loadMore(): void {
        this.page++;
        this.states.loadMore = true;
        this.cd.markForCheck();
        forkJoin(this.tags.map((tagName) => this.navigationService.getDescendantsByTag(this.rootId, tagName.key, this.page, this.amount)))
            .pipe(
                finalize(() => {
                    this.states.loadMore = false;
                    this.cd.markForCheck();
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((campaigns$) => {
                const campaigns: INavigationResponse[] = [...flatten(campaigns$, 1)];

                if (!campaigns.length) {
                    this.states.loadAll = true;
                    return;
                }

                campaigns.forEach((campaign) => {
                    // Check for duplicates
                    if (!this.campaigns.find((x) => x.id === campaign.id)) {
                        this.campaigns.push(campaign);
                    }
                });
                this.visibleAmount = this.amount * this.page;
                this.setTag(this.activeTag);
            });
    }

    trackByTagFn(index: number, tag: ICampaignSpotTag) {
        return tag.key || index;
    }

    trackByFn(index: number, item: INavigationResponse) {
        return item.id || index;
    }
}
