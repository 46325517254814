import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ncg-info-label',
    template: `
        <div class="models-list__header--tag">
            <span *ngIf="isNew" class="info-label">{{ 'models.is_new' | translate }}</span>
            <span *ngIf="isCampaign" class="info-label info-label-campaign">{{ 'models.is_campaign' | translate }}</span>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoLabelComponent {
    @Input() isCampaign?: boolean;
    @Input() isNew?: boolean;
}
