import { animate, animateChild, group, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

export const searchAnimation = [
    trigger('searchList', [
        transition('void => *', [
            group([
                query(
                    ':enter',
                    [
                        style({
                            opacity: '0',
                            transform: 'translateY(40px)',
                        }),
                        stagger(
                            '40ms',
                            animate(
                                '400ms cubic-bezier(0.390, 0.575, 0.565, 1.000)',
                                style({
                                    opacity: '1',
                                    transform: 'translateY(0)',
                                })
                            )
                        ),
                    ],
                    { optional: true }
                ),
                query(
                    '.search-section__link',
                    [
                        style({
                            opacity: '0',
                            transform: 'translateY(40px)',
                        }),
                        animate(
                            '560ms cubic-bezier(0.390, 0.575, 0.565, 1.000)',
                            style({
                                opacity: '1',
                                transform: 'translateY(0)',
                            })
                        ),
                    ],
                    { optional: true }
                ),
            ]),
        ]),
    ]),
];

export const tileAnimation = [
    trigger('tileList', [
        transition('* => make', [
            query(
                '.filter-tile',
                [
                    style({
                        opacity: '0',
                        transform: 'translateY(40px)',
                        zIndex: '1',
                    }),
                    stagger(
                        '80ms',
                        animate(
                            '400ms cubic-bezier(0.390, 0.575, 0.565, 1.000)',
                            style({
                                opacity: '1',
                                transform: 'translateY(0)',
                                zIndex: '1',
                            })
                        )
                    ),
                ],
                { optional: true }
            ),
        ]),
    ]),
];

export const productListAnimation = [
    trigger('listAnimation', [
        transition('* <=> *', [
            query(
                ':enter',
                [
                    style({
                        opacity: 0,
                        transform: 'translateY(40px)',
                        transformOrigin: '50% 50%',
                    }),
                    stagger(
                        '60ms',
                        animate(
                            '500ms cubic-bezier(0.390, 0.575, 0.565, 1.000)',
                            style({
                                opacity: 1,
                                transform: 'translateY(0)',
                                transformOrigin: '50% 50%',
                            })
                        )
                    ),
                ],
                { optional: true, limit: 9 }
            ),
            query(':leave', animate('200ms ease-out', style({ opacity: 0 })), { optional: true }),
        ]),
    ]),
];

export const moveInAndfadeIAnimation = [
    trigger('moveFadeIn', [transition(':enter', [query('@item', stagger(100, animateChild()), { optional: true, delay: 200 })])]),
    trigger('item', [
        transition(':enter', [
            style({ opacity: 0, transform: 'translateY(100%)' }),
            animate(
                '400ms 100ms cubic-bezier(0.22, 1, 0.36, 1)',
                style({
                    opacity: 1,
                    transform: 'translateY(0)',
                })
            ),
        ]),
    ]),
];

export const menuBarAnimation = [
    trigger('menuBar', [
        state(
            '*',
            style({
                transform: 'translate3d({{toX}}, 0, 0) scale3d({{scaleToPercentage}}, 1, 1)',
                transformOrigin: 'left bottom',
            }),
            { params: { scalePercentage: '0', scaleToPercentage: '0', toX: '0px', fromX: '0px' } }
        ),
        transition('move <=> initial', [
            sequence([
                animate(
                    '300ms ease',
                    style({
                        transform: 'translate3d({{fromX}}, 0, 0) scale3d({{scalePercentage}}, 1, 1)',
                        transformOrigin: 'left bottom',
                    })
                ),
                animate(
                    '280ms ease',
                    style({
                        transform: 'translate3d({{toX}}, 0, 0) scale3d({{scaleToPercentage}}, 1, 1)',
                        transformOrigin: 'left bottom',
                    })
                ),
            ]),
        ]),
    ]),
];

export const facetItemAnimation = [
    trigger('facetAnimations', [
        transition(':enter', [
            group([
                style({
                    width: '0px',
                }),
                animate(
                    '400ms ease-in',
                    style({
                        width: '*',
                    })
                ),
                query('@item', stagger(100, animateChild()), {
                    optional: true,
                }),
            ]),
        ]),
        transition(':leave', [
            group([
                style({
                    width: '*',
                }),
                animate(
                    '2600ms ease-in',
                    style({
                        width: '0px',
                    })
                ),
                query('@item', stagger(100, animateChild()), {
                    optional: true,
                }),
            ]),
        ]),
    ]),
    trigger('item', [
        transition(':enter', [
            style({
                opacity: 0,
                width: '0px',
                transform: 'translate3d(-160px, 0, 0) scale3d(0.2, 0, 1)',
                zIndex: '1',
                transformOrigin: 'left',
            }),
            group([
                animate(
                    '300ms ease',
                    style({
                        opacity: 1,
                    })
                ),
                animate(
                    '500ms ease-in',
                    style({
                        transformOrigin: 'left',
                        transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1)',
                        width: '*',
                    })
                ),
            ]),
        ]),
        transition(':leave', [
            style({
                opacity: 1,
                transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1)',
                transformOrigin: 'left',
                zIndex: 1,
                width: '*',
            }),
            group([
                animate(
                    '200ms cubic-bezier(0.5, 0, 0.75, 0)',
                    style({
                        opacity: 0,
                        transform: 'translate3d(-160px, 0, 0) scale3d(0.1, 0.1, 1)',
                        transformOrigin: 'left',
                        zIndex: 1,
                    })
                ),
                animate(
                    '300ms 100ms cubic-bezier(0.5, 0, 0.75, 0)',
                    style({
                        width: '0px',
                    })
                ),
            ]),
        ]),
    ]),
];
