import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ncg-legal',
    template: `
        <div class="legal" *ngIf="legalText || energyLabels">
            <ncg-energy-label [energyLabels]="energyLabels" *ngIf="energyLabels && energyLabels?.length"></ncg-energy-label>
            <ncg-rich-text class="legal__text" *ngIf="legalText" [innerHTML]="legalText"></ncg-rich-text>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalComponent {
    @Input()
    energyLabels?: string[];

    @Input()
    legalText?: string;
}
