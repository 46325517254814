import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { UtilsModule } from '../../../utils/utils.module';
import { DataField, DataListField } from '@ncg/data';
import { FieldsToResourcePipe } from '../../utils/fields-to-resource.pipe';
import { ResourceSrcsetPipe } from '../../utils/resource-srcset.pipe';
import { SettingsService } from '../../../core/settings.service';
import { configuratorImageAspectRatio } from '../../configurator-settings';
import { fieldsToResource } from '../../utils/fields-to-resource.util';
import { ConfiguratorImageAspectRatio } from '../../configurator';

@Component({
    selector: 'ncg-configurator-image',
    template: `
        <ng-container *ngIf="fields | fieldsToResource as resource">
            <ng-container *ngIf="(settingsService.settings$ | async)?.mediaUrlNCG as baseUrl">
                <img
                    *ngIf="resource.ResourceCdnUrlOriginal"
                    [src]="baseUrl + '/' + resource.ResourceCdnUrlOriginal"
                    [alt]="resource.ResourceFilename"
                    [srcset]="baseUrl + '/' + resource.ResourceCdnUrlOriginal | resourceSrcset"
                    [sizes]="sizes"
                    loading="lazy"
                    ncgImageLoad
                />
            </ng-container>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styles: [
        `
            :host {
                display: grid; /* For aspect ratio */
            }
            img {
                vertical-align: top; /* To remove descender */
            }
        `,
    ],
    imports: [CommonModule, UtilsModule, FieldsToResourcePipe, ResourceSrcsetPipe],
})
export class ConfiguratorImageComponent {
    @Input() type: ConfiguratorImageAspectRatio = 'other';
    @Input() fields: (DataField | DataListField)[] | null | undefined;
    @Input() sizes: string;

    // Make sure we avoid CLS by having aspect ratio on host
    @HostBinding('style') get style() {
        if (!fieldsToResource(this.fields)?.ResourceCdnUrlOriginal) {
            return {
                display: 'none',
            };
        }
        return {
            aspectRatio: `1 / ${configuratorImageAspectRatio[this.type]}`,
        };
    }

    constructor(public readonly settingsService: SettingsService) {}
}
