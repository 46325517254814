import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProductService } from '../products/product.service';

@Pipe({
    name: 'usedCarsTags',
    pure: false,
})
export class UsedCarsTagsPipe implements PipeTransform {
    private originalValue: string;

    constructor(private readonly productService: ProductService) {}

    transform(value: string) {
        this.originalValue = value;
        const pattern = /\[used-cars:?(.*?)\]/g;
        const tags = [...value.matchAll(pattern)];

        if (!tags?.length) {
            return of(value);
        }

        return this.productService.getUsedCarsAmount().pipe(
            map((usedCarsAmount) => {
                if (!usedCarsAmount) {
                    return value.replace(pattern, '');
                }

                for (const tag of tags) {
                    const [match, make] = tag;

                    if (make) {
                        if (make in usedCarsAmount.makes) {
                            this.originalValue = this.originalValue.replace(match, usedCarsAmount.makes[make].toString());
                        } else {
                            this.originalValue = this.originalValue.replace(match, '0');
                        }
                    } else {
                        this.originalValue = this.originalValue.replace(match, usedCarsAmount.amount.toString());
                    }
                }

                return this.originalValue;
            })
        );
    }
}
