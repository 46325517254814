import { Pipe, PipeTransform } from '@angular/core';
import { formatWithCurrency } from '@ncg/data';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'currency',
    standalone: true,
    pure: true,
})
export class CurrencyFormatterPipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}
    transform(value: number | string = 0, currencyCode: string = 'DKK', isMonthly: boolean = false): string {
        return `${formatWithCurrency(parseInt(value?.toString()), currencyCode)}${
            isMonthly ? this.translateService.instant('configurator.currency_monthly') : ''
        }`;
    }
}
