import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TestDriveFormSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-test-drive-form-spot',
    template: `
        <div class="columns is-centered" ncgLoadIn *ngIf="data">
            <ncg-test-drive-form
                class="column"
                [class.is-8]="!allowNarrowContent"
                [isModelPage]="data.isModelPage"
                [data]="data"
                [successMessage]="data.successMessage"
                [redirect]="data.successRedirect"
                [testDriveStartDate]="data.testDriveStartDate"
                [campaign]="data.campaign"
                [listId]="data.listId"
                [collectMarketingConsent]="data.collectMarketingConsent !== false"
            >
            </ncg-test-drive-form>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestDriveFormSpotComponent extends SpotBaseDirective {
    static ref = 'testdriveform';

    @Input() data: TestDriveFormSpot;
}
