import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UnsubscribeNewsletterFormSpot } from '@ncg/data';
import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-unsubscribe-newsletter-spot',
    template: `
        <div class="columns is-centered">
            <ncg-unsubscribe-newsletter class="column" [class.is-8]="!allowNarrowContent" [data]="data"></ncg-unsubscribe-newsletter>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsubscribeNewsletterSpotComponent extends SpotBaseDirective {
    public static ref = 'unsubscribenewsletter';

    @Input() data: UnsubscribeNewsletterFormSpot;
}
