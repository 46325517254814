/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Directive } from '@angular/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Directive({
    selector: '[ncgDatepickerI18n]',
})
export class DatepickerI18nDirective {
    private nextMonth = '';
    private prevMonth = '';
    private selectMonth = '';
    private selectYear = '';

    constructor(
        private readonly datepicker: NgbInputDatepicker,
        private readonly translateService: TranslateService
    ) {
        this.getTranslations().then(() => {
            const previousOpen = this.datepicker.open;
            this.datepicker.open = () => {
                previousOpen.bind(this.datepicker)();
                if (this.datepicker.isOpen()) {
                    this.localize();
                }
            };
        });
    }

    async getTranslations() {
        this.nextMonth = await firstValueFrom(this.translateService.get('forms.datepicker_next_month'));
        this.prevMonth = await firstValueFrom(this.translateService.get('forms.datepicker_prev_month'));
        this.selectMonth = await firstValueFrom(this.translateService.get('forms.datepicker_select_month'));
        this.selectYear = await firstValueFrom(this.translateService.get('forms.datepicker_select_year'));
    }

    localize() {
        // @ts-ignore
        const calendarEl = this.datepicker._cRef.location.nativeElement;

        const nextMonthEl = calendarEl.querySelector('button[title="Next month"]');
        const prevMonthEl = calendarEl.querySelector('button[title="Previous month"]');
        const selectMonthEl = calendarEl.querySelector('select[title="Select month"]');
        const selectYearEl = calendarEl.querySelector('select[title="Select year"]');

        if (nextMonthEl) {
            nextMonthEl.setAttribute('title', this.nextMonth);
            nextMonthEl.setAttribute('aria-label', this.nextMonth);
        }

        if (prevMonthEl) {
            prevMonthEl.setAttribute('title', this.prevMonth);
            prevMonthEl.setAttribute('aria-label', this.prevMonth);
        }

        if (selectMonthEl) {
            selectMonthEl.setAttribute('title', this.selectMonth);
            selectMonthEl.setAttribute('aria-label', this.selectMonth);
        }

        if (selectYearEl) {
            selectYearEl.setAttribute('title', this.selectYear);
            selectYearEl.setAttribute('aria-label', this.selectYear);
        }
    }
}
