import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { getBuildIdQuery, IImage } from '@ncg/data';
import { Subject, takeUntil } from 'rxjs';

import { MetaService } from '../core/meta.service';
import { TrackingService } from '../core/tracking.service';

@Component({
    selector: 'ncg-embed',
    template: `
        <figure class="embed figure" *ngIf="embedUrl" ncgLoadIn>
            <div class="embed__wrapper" [ngClass]="{ 'embed__wrapper--empty': !isVideoAllowed }">
                <div #embedded class="embed__markup" [innerHTML]="embedMarkup | safe: 'html'"></div>
                <div class="embed__overlay--image" *ngIf="overlayStyle" [ngStyle]="overlayStyle">
                    <div class="embed__overlay--color"></div>
                    <div class="embed__overlay--play" aria-label="Play video" (click)="togglePlay()" *ngIf="isVideoAllowed">
                        <svg-icon-sprite
                            [src]="'assets/images/sprite-multi.svg' + buildIdQuery + '#play'"
                            [viewBox]="'0 0 72 72'"
                            [width]="'72px'"
                            [height]="'72px'"
                            aria-hidden="true"
                        ></svg-icon-sprite>
                    </div>
                </div>
                <div *ngIf="!isVideoAllowed" class="embed__blocked">
                    <p>{{ 'embed_spot.video_blocked_placeholder' | translate }}</p>
                    <button class="button is-primary" (click)="showCookieBanner()" type="button">
                        {{ 'embed_spot.open_cookie' | translate }}
                    </button>
                </div>
            </div>
            <figcaption class="figcaption" *ngIf="embedCaption">{{ embedCaption }}</figcaption>
        </figure>
    `,
    styleUrls: ['./embed.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbedComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    public readonly buildIdQuery = getBuildIdQuery();

    public overlayStyle?: Partial<CSSStyleDeclaration>;
    public isVideoAllowed = false;
    public embedMarkup = '';

    @Input()
    public embedUrl: string;

    @Input()
    public embedCaption?: string;

    @Input()
    public thumbnail?: IImage;

    @Input()
    public autoplay: boolean;

    @ViewChild('embedded')
    public embedded?: ElementRef<HTMLDivElement>;

    constructor(
        private readonly metaService: MetaService,
        private readonly trackingService: TrackingService,
        private readonly cd: ChangeDetectorRef
    ) {}

    public ngOnDestroy() {
        // Remove any potential references
        if (this.embedded) {
            this.embedded = undefined;
        }
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public ngOnInit() {
        this.setOverlay();
        this.trackingService
            .marketingConsentGiven()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((consent) => {
                this.isVideoAllowed = consent;
                if (this.isVideoAllowed) {
                    if (this.autoplay || !this.thumbnail) {
                        this.updateIframe();
                    }
                } else {
                    this.setOverlay();
                    this.embedMarkup = '';
                }
                this.cd.markForCheck();
            });
    }

    public showCookieBanner(): void {
        this.trackingService.showCookieBanner();
    }

    public togglePlay(): void {
        // Remove any overlays before trying to start a video beneath
        this.removeOverlay();

        this.autoplay = true;
        this.updateIframe();
    }

    private updateIframe() {
        if (this.embedUrl?.startsWith('http')) {
            const embedUrlObject = new URL(this.embedUrl);

            // Enable Message API (Youtube)
            embedUrlObject.searchParams.set('enablejsapi', '1');

            // Set origin address so that the Message API is not accessible from outside (Youtube)
            embedUrlObject.searchParams.set('origin', this.metaService.getBaseUrl());

            // Set level of branding to a modest setting (Youtube)
            embedUrlObject.searchParams.set('modestbranding', '1');

            // Show only related videos from same channel as source video (Youtube)
            embedUrlObject.searchParams.set('rel', '0');

            // Set autoplay & loop
            if (this.autoplay) {
                embedUrlObject.searchParams.set('autoplay', '1');
                embedUrlObject.searchParams.set('loop', '1');
                // Chrome requires mute to be set if autoplay is set.
                embedUrlObject.searchParams.set('mute', '1'); // Youtube
                embedUrlObject.searchParams.set('muted', '1'); // Vimeo
                // Vimeo requires autopause to be set to 0 if autoplay is set for multiple videos on the same page.
                embedUrlObject.searchParams.set('autopause', '0');
            }

            this.embedMarkup = `
                <iframe
                    src="${embedUrlObject.href}"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture;fullscreen"
                    title="Video player">
                </iframe>`;
            this.cd.markForCheck();
        }
    }

    private setOverlay(): void {
        if (this.thumbnail && !this.autoplay) {
            this.overlayStyle = {};
            this.overlayStyle.backgroundImage = `url('${this.thumbnail.url}')`;
            if (this.thumbnail.focalPoint) {
                const top = this.thumbnail.focalPoint.top * 100;
                const left = this.thumbnail.focalPoint.left * 100;
                this.overlayStyle.backgroundPosition = `${top}% ${left}%`;
            }
        }
    }

    private removeOverlay(): void {
        if (this.overlayStyle) {
            this.overlayStyle = undefined;
        }
    }
}
