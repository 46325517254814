/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, HostBinding, HostListener, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';

import { ImpactOverlayRef } from './overlay-ref';
import { OverlayService } from './overlay.service';

@Directive({
    selector: 'button[impDialogClose], button[imp-dialog-close]',
    exportAs: 'impDialogClose',
})
export class ImpDialogCloseDirective implements OnInit, OnChanges {
    @HostBinding('attr.aria-label')
    @Input('aria-label')
    ariaLabel = 'Close dialog';

    @HostBinding('type') buttonType = 'button';

    @Input('imp-dialog-close') dialogResult?: any;

    @Input('impDialogClose') _impDialogClose?: any;

    constructor(
        @Optional() public dialogRef: ImpactOverlayRef,
        private readonly elementRef: ElementRef,
        private readonly overlay: OverlayService
    ) {}

    ngOnInit() {
        if (!this.dialogRef) {
            const ref = getClosestDialog(this.elementRef, this.overlay.refs);
            if (ref) {
                this.dialogRef = ref;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes._impDialogClose) {
            this.dialogResult = changes._impDialogClose.currentValue;
        }
    }

    @HostListener('click')
    onClick(): void {
        this.dialogRef.close(this.dialogResult);
    }
}

/**
 * Finds the closest ImpactOverlayRef to an element by looking at the DOM.
 *
 * @param element Element relative to which to look for a dialog.
 * @param openDialogs References to the currently-open dialogs.
 */
function getClosestDialog(element: ElementRef, openDialogs: ImpactOverlayRef[]) {
    let parent: HTMLElement | null = element.nativeElement.parentElement;

    while (parent && !parent.classList.contains('impact-dialog-container')) {
        parent = parent.parentElement;
    }

    return parent ? openDialogs.find((dialog) => dialog.id === parent?.id) : null;
}
