import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
})
export class FilterPipe implements PipeTransform {
    transform<T>(items?: any[], property?: keyof T, searchValue: string | boolean = true): any[] | undefined {
        if (!searchValue || searchValue === 'all') {
            return items;
        }

        if (Array.isArray(items) && property !== undefined) {
            return items.filter((item) => {
                if (!(property in item)) {
                    return false;
                }

                if (Array.isArray(item[property])) {
                    if (item && (item as Record<string, unknown>).hasOwnProperty(property) && item[property]) {
                        const arr = item[property] as unknown as any[];
                        return arr.includes(searchValue);
                    }
                } else {
                    return item[property] === searchValue;
                }
            });
        }

        return items || [];
    }
}
