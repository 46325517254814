import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getBuildIdQuery, IFacet } from '@ncg/data';

@Component({
    selector: 'ncg-filter-type-icons',
    template: `
        <span class="facet-car-icon" *ngIf="imgSrc">
            <img
                [src]="'assets/images/' + imgSrc + '.svg' + buildIdQuery"
                [attr.alt]="'facet_types.facet_body_' + facetValue | translate"
                width="80"
                height="44"
                loading="lazy"
            />
        </span>

        <div class="filter-type-icons__text">
            <span>{{ 'facet_types.facet_body_' + facetValue | translate }}</span>
            <span class="has-text-grey-dark">&nbsp;({{ facetCount }})</span>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTypeIconsComponent {
    buildIdQuery = getBuildIdQuery();

    @Input() set facet(value: IFacet) {
        this.facetCount = value.count || 0;

        switch (value.key?.toLowerCase()) {
            case 'cabriolet':
                this.imgSrc = 'cabriolet';
                this.facetValue = 'cabriolet';
                break;

            case 'coupé':
                this.imgSrc = 'coupe';
                this.facetValue = 'coupe';
                break;

            case 'hatchback':
                this.imgSrc = 'hatchback';
                this.facetValue = 'hatchback';
                break;

            case 'hybrid':
                this.imgSrc = 'hybrid';
                this.facetValue = 'hybrid';
                break;

            case 'el':
                this.imgSrc = 'el';
                this.facetValue = 'el';
                break;

            case 'lille bil':
                this.imgSrc = 'lille';
                this.facetValue = 'smallcar';
                break;

            case 'sedan':
                this.imgSrc = 'sedan';
                this.facetValue = 'sedan';
                break;

            case 'stationcar':
                this.imgSrc = 'stationcar';
                this.facetValue = 'stationcar';
                break;

            case 'suv':
                this.imgSrc = 'suv';
                this.facetValue = 'suv';
                break;

            case 'mpv':
                this.imgSrc = 'suv';
                this.facetValue = 'mpv';
                break;

            case 'cuv':
                this.imgSrc = 'suv';
                this.facetValue = 'cuv';
                break;

            case 'van':
                this.imgSrc = 'van';
                this.facetValue = 'van';
                break;

            case 'varevogn':
                this.imgSrc = 'van';
                this.facetValue = 'varevogn';
                break;

            default:
                this.imgSrc = 'sedan';
                this.facetValue = value.key?.toLowerCase() ?? '';
        }
    }

    imgSrc = '';
    facetValue = '';
    facetCount = 0;
}
