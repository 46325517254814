import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';

import { ConfiguratorRouterState } from './configurator-route-serializer';
import {
    categories,
    model,
    price,
    queryParams,
    step,
    tab,
    trim,
    tabDirection,
    variant,
    exterior,
    interior,
    optionals,
    powertrain,
    partnerProducts,
    pageData,
    accessories,
    url,
    error,
    tabStates,
    enabledTabs,
    trackingStep,
} from './configurator.selectors';
import { ConfiguratorState } from '../../configurator/configurator';

@Injectable({ providedIn: 'root' })
export class ConfiguratorFacade {
    step$ = this.store$.select(step);
    tab$ = this.store$.select(tab);
    categories$ = this.store$.select(categories);
    model$ = this.store$.select(model);
    trim$ = this.store$.select(trim);
    variant$ = this.store$.select(variant);
    powertrain$ = this.store$.select(powertrain);
    exterior$ = this.store$.select(exterior);
    interior$ = this.store$.select(interior);
    optionals$ = this.store$.select(optionals);
    partnerProducts$ = this.store$.select(partnerProducts);
    routerQueryParams$ = this.store$.select(queryParams);
    price$ = this.store$.select(price);
    error$ = this.store$.select(error);
    tabDirection$ = this.store$.select(tabDirection);
    pageData$ = this.store$.select(pageData);
    accessories$ = this.store$.select(accessories);
    url$ = this.store$.select(url);
    enabledTabs$ = this.store$.select(enabledTabs);
    tabStates$ = this.store$.select(tabStates);
    trackingStep$ = this.store$.select(trackingStep);

    constructor(private readonly store$: Store<{ configurator: ConfiguratorState; router: ConfiguratorRouterState }>) {}

    dispatch(action: Action) {
        this.store$.dispatch(action);
    }
}
