import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { IFilterPageResponse } from '@ncg/data';
import { FilterService } from '../../filter/filter.service';

@Component({
    selector: 'ncg-filter-page',
    template: `
        <div class="content filter-page" [ngClass]="data.layout === 'filterVisible' ? 'filter-page--full' : 'filter-page--panel'">
            <ncg-breadcrumb *ngIf="isLoaded" [data]="data"></ncg-breadcrumb>
            <ng-container #vc></ng-container>
            <ncg-umb-grid *ngIf="isLoaded" [grid]="data?.grid"></ncg-umb-grid>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPageComponent implements OnInit {
    static ref = 'filterPage';

    @ViewChild('vc', { read: ViewContainerRef, static: true }) viewContainer: ViewContainerRef;

    @Input() data: IFilterPageResponse;
    isLoaded = false;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly filterService: FilterService
    ) {}

    ngOnInit() {
        this.filterService.filterSettings = this.data.filterPriorities || {};
        this.getProductListRootLazy();
    }

    async getProductListRootLazy() {
        this.viewContainer.clear();
        const { FilterProductListComponent } = await import('../../products/filter-product-list/filter-product-list.component');
        const ref = this.viewContainer.createComponent(FilterProductListComponent);
        ref.instance.data = this.data;
        this.isLoaded = true;
        this.cd.markForCheck();
    }
}
