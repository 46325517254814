import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarModel } from '@ncg/data';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConfiguratorService {
    constructor(private readonly httpClient: HttpClient) {}

    public getConfiguratorModelById(id: string): Observable<CarModel> {
        return this.httpClient.get<CarModel>(`/api/configurator/id/${id}`);
    }

    public getConfiguratorModelByName(name: string): Observable<CarModel> {
        return this.httpClient.get<CarModel>(`/api/configurator/name/${name}`);
    }
}
