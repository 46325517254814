import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProductsEffects } from '../+state/products/products.effects';
import { ProductsFacade } from '../+state/products/products.facade';
import * as fromProducts from '../+state/products/products.reducer';
import { ProductDetailsRootComponent } from './product-details/product-details-root.component';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromProducts.PRODUCTS_FEATURE_KEY, fromProducts.productReducer),
        EffectsModule.forFeature([ProductsEffects]),
    ],
    declarations: [ProductDetailsRootComponent],
    exports: [ProductDetailsRootComponent],
    providers: [ProductsFacade],
})
export class ProductsModule {}
