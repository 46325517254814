import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request as ExpressRequest } from 'express';

@Injectable({
    providedIn: 'root',
})
export class MetaService {
    public previousPageRef?: string;
    public currentPageRef?: string;

    constructor(
        private readonly title: Title,
        private readonly meta: Meta,
        @Inject(PLATFORM_ID)
        private readonly platformId: Record<string, unknown>,
        @Inject(DOCUMENT)
        private readonly doc: Document,
        @Optional()
        @Inject(REQUEST)
        private readonly request?: ExpressRequest
    ) {}

    public changePage(ref: string): void {
        this.previousPageRef = this.currentPageRef;
        this.currentPageRef = ref;
    }

    public setTitle(title?: string): void {
        if (title) {
            this.title.setTitle(title);
        }
    }

    public getTitle(): string {
        return this.title.getTitle();
    }

    public setDescription(content?: string): void {
        content = content ?? '';

        this.meta.updateTag({
            name: 'description',
            content,
        });

        this.meta.updateTag({
            property: 'og:description',
            content,
        });
    }

    public setKeywords(content?: string): void {
        content = content ?? '';

        this.meta.updateTag({
            name: 'keywords',
            content,
        });
    }

    public setNoIndex(shouldBlock: boolean): void {
        if (shouldBlock) {
            this.meta.updateTag({
                name: 'robots',
                content: 'noindex',
            });
        } else {
            this.meta.removeTag('name="robots"');
        }
    }

    public setCanonical(content?: string): void {
        this.doc.querySelectorAll('[rel="canonical"]').forEach((x) => x.remove());

        if (this.doc.head) {
            if (content) {
                if (!content.startsWith('http')) {
                    content = this.getBaseUrl() + content;
                }
            } else {
                content = this.getAbsoluteUrlWithoutQueryString();
            }

            content = this.stripTrailingSlash(content);

            const canonicalTag = this.doc.createElement('link');
            this.doc.head.appendChild(canonicalTag);

            canonicalTag.setAttribute('rel', 'canonical');
            canonicalTag.setAttribute('href', content);
        }
    }

    public setRelPrevNext(prevUrl?: string, nextUrl?: string): void {
        this.doc.querySelectorAll('[rel="prev"], [rel="next"]').forEach((x) => x.remove());
        const createLink = (rel: 'prev' | 'next', link?: string) => {
            if (this.doc.head && link) {
                const canonicalTag = this.doc.createElement('link');
                this.doc.head.appendChild(canonicalTag);
                canonicalTag.setAttribute('rel', rel);

                link = this.stripTrailingSlash(link);
                canonicalTag.setAttribute('href', link);
            }
        };
        createLink('prev', prevUrl);
        createLink('next', nextUrl);
    }

    public setLanguageVariants(variants: LanguageVariants): void {
        this.doc.querySelectorAll('[rel="alternate"]').forEach((x) => x.remove());

        if (this.doc.head) {
            const languages = Object.keys(variants);
            if (languages.length > 1) {
                // Add hreflang
                languages.forEach((iso) => {
                    const canonicalTag = this.doc.createElement('link');
                    this.doc.head.appendChild(canonicalTag);
                    canonicalTag.setAttribute('rel', 'alternate');
                    canonicalTag.setAttribute('hreflang', iso);

                    let url = variants[iso];
                    url = this.stripTrailingSlash(url);

                    if (!url.startsWith('http')) {
                        url = `${this.getBaseUrl()}${url}`;
                    }

                    canonicalTag.setAttribute('href', url);
                });
            }
        }
    }

    public setOpenGraph(data: OpenGraph): void {
        // Title
        this.meta.updateTag({
            property: 'og:title',
            content: data.title,
        });

        // Type
        this.meta.updateTag({
            property: 'og:type',
            content: data.type,
        });

        // Url
        this.meta.updateTag({
            property: 'og:url',
            content: this.getAbsoluteUrlWithoutQueryString(),
        });

        // Author
        this.meta.updateTag({
            property: 'author',
            content: data.author,
        });

        // Copyright
        this.meta.updateTag({
            property: 'copyright',
            content: data.author,
        });

        // Image
        if (data.image) {
            this.meta.updateTag({
                property: 'og:image',
                content: data.image.url,
            });
            this.meta.updateTag({
                property: 'og:image:width',
                content: data.image.width || '',
            });
            this.meta.updateTag({
                property: 'og:image:height',
                content: data.image.height || '',
            });
            this.meta.updateTag({
                property: 'og:image:alt',
                content: data.image.altText || '',
            });
        } else {
            this.meta.removeTag('property="og:image"');
            this.meta.removeTag('property="og:image:width"');
            this.meta.removeTag('property="og:image:height"');
            this.meta.removeTag('property="og:image:alt"');
        }
    }

    public setImpactHook(pageId: any): void {
        if (this.doc && this.isBrowser()) {
            this.doc.documentElement.dataset.impactHookCmsId = pageId;
        }
    }

    public getBaseUrl(): string {
        return this.request ? `${this.request.protocol}://${this.request.hostname}` : window.location.origin;
    }

    public getAbsoluteUrl(): string {
        return this.request ? this.getBaseUrl() + this.request.path : window.location.href;
    }

    public getAbsoluteUrlWithoutQueryString(): string {
        const url = this.getAbsoluteUrl();
        return url.split('?')[0];
    }

    public setPreloadResource(src: string, as: string): void {
        if (this.isBrowser() || !src) {
            return;
        }
        const link = this.doc.createElement('link');
        link.setAttribute('href', src);
        link.setAttribute('as', as);
        link.rel = 'preload';
        this.doc.getElementsByTagName('head')[0].appendChild(link);
    }

    /**
     * Useful to increase LCP
     * Adds a preload link to head, to make sure the image request happens early
     */
    public setPreloadImage(srcSet: string, media?: string, sizes?: string, href?: string, highFetchPriority: boolean = true): void {
        if (this.isBrowser() || !srcSet) {
            return;
        }
        const link = this.doc.createElement('link');
        link.setAttribute('imagesrcset', srcSet);
        link.setAttribute('as', 'image');
        if (media) {
            link.setAttribute('media', media);
        }
        if (sizes) {
            link.setAttribute('imagesizes', sizes);
        }
        if (href) {
            link.setAttribute('href', href);
        }
        link.rel = 'preload';
        if (highFetchPriority) {
            // medium & auto are the defaults, we only care about high, low is irrelevant
            link.setAttribute('fetchPriority', 'high');
        }

        this.doc.getElementsByTagName('head')[0].appendChild(link);
    }

    private stripTrailingSlash(url: string): string {
        return url.replace(/^(.*)\/$/s, '$1');
    }

    private isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }
}

export interface OpenGraph {
    title: string;
    image?: {
        url: string;
        width?: string;
        height?: string;
        altText?: string;
    };
    type: string;
    author: string;
}

export type LanguageVariants = Record<string, string>;
