import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IModelPageResponse } from '@ncg/data';

@Component({
    selector: 'ncg-model-page-header-inner',
    template: `
        <div class="model-header__inner" *ngIf="modelPage">
            <div class="container is-fullwidth">
                <div class="model-header__content" ncgLoadIn [loadDirection]="'down'" [ngClass]="{ 'has-price': modelPage.price }">
                    <div class="model-header__title" [ngClass]="{ 'has-price': modelPage.price }">
                        <h3 *ngIf="modelPage.modelParentName">{{ modelPage.modelParentName }}</h3>
                    </div>
                    <ng-content></ng-content>
                    <div class="model-header__cta" *ngIf="modelPage.price">
                        <p>{{ 'models.pricefrom' | translate: { price: modelPage.price } }}</p>
                    </div>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelPageHeaderInnerComponent {
    @Input()
    modelPage: IModelPageResponse;
}
