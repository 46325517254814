import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ModelsListSpot, IModelsListItem } from '@ncg/data';
import { FeatureDetectionService } from '../../core/feature-detection.service';
import { ScrollService } from '../../core/scroll.service';
import SwiperCore, { Navigation, Pagination, Keyboard } from 'swiper';

import { animateIn, animateOut } from '../../utils/animations/easing.animations';
import { menuBarAnimation } from '../../utils/animations/filter.animation';

SwiperCore.use([Pagination, Navigation, Keyboard]);

@Component({
    selector: 'ncg-models-spot',
    template: `
        <div class="models-list" *ngIf="data && data.models && data.models.length" ncgLoadIn>
            <div class="column is-full all-models" *ngIf="data.showFilter && filterFacets?.length">
                <nav
                    class="navbar"
                    [ngClass]="{
                        'all-models__scroll': useScrollIndicator,
                        'all-models__scroll--both': useScrollIndicator && !isMaxLeft && !isMaxRight,
                        'all-models__scroll--right': useScrollIndicator && isMaxLeft,
                        'all-models__scroll--left': useScrollIndicator && isMaxRight
                    }"
                >
                    <div class="navbar-start all-models-filter" (scroll)="handleScroll()" #navRef>
                        <button
                            class="button is-filter "
                            #allBtnRef
                            [ngClass]="{ 'is-active-bar': currentType === 'all' }"
                            (click)="onFilterClick('all', allBtnRef)"
                        >
                            <span>{{ 'model_types.all' | translate }}</span>
                        </button>
                        <button
                            #btnRef
                            *ngFor="let facet of filterFacets"
                            class="button is-filter"
                            [ngClass]="{ 'is-active-bar': currentType === facet }"
                            (click)="onFilterClick(facet, btnRef)"
                        >
                            <span>{{ facet }}</span>
                        </button>
                        <div
                            [@menuBar]="{
                                value: state,
                                params: {
                                    scaleToPercentage: scaleToPercentage,
                                    scalePercentage: scalePercentage,
                                    fromX: fromX + 'px',
                                    toX: toX + 'px'
                                }
                            }"
                            #activeBarRef
                            class="active-bar"
                        ></div>
                    </div>
                </nav>
            </div>

            <div class="column" [ngClass]="{ 'models-list--with-slides': data.enableSlide }">
                <div class="container" *ngIf="data.enableSlide; else noSlider">
                    <swiper
                        class="models-list--swiper"
                        [slidesPerView]="'auto'"
                        [grabCursor]="true"
                        [keyboard]="true"
                        [simulateTouch]="models.length > data.amountVisibleSlides"
                        [breakpoints]="{
                            '1024': { slidesPerView: this.data.amountVisibleSlides + 0.1 },
                            '1600': { slidesPerView: this.data.amountVisibleSlides }
                        }"
                        [navigation]="{
                            prevEl: prev,
                            nextEl: next
                        }"
                    >
                        <ng-template swiperSlide *ngFor="let model of models; trackBy: trackByMethod">
                            <div>
                                <ncg-models-list-item [model]="model"></ncg-models-list-item>
                            </div>
                        </ng-template>
                    </swiper>
                    <div>
                        <div #prev class="swiper-button-prev is-small"></div>
                        <div #next class="swiper-button-next is-small"></div>
                    </div>
                </div>
                <ng-template #noSlider>
                    <div class="columns is-mobile is-centered is-multiline">
                        <div #modelItem class="column is-one-third-tablet is-full-mobile" *ngFor="let model of models; trackBy: trackByMethod">
                            <ncg-models-list-item [model]="model"></ncg-models-list-item>
                        </div>
                    </div>
                </ng-template>
            </div>

            <div class="column is-12 has-text-centered" *ngIf="data.link && data.link?.url">
                <div class="models-list__footer has-text-centered">
                    <a class="button is-primary" [routerLink]="[data.link?.url]">{{ data.link?.name }}</a>
                </div>
            </div>
        </div>
    `,
    animations: [menuBarAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelsSpotComponent implements OnInit, AfterViewInit {
    static ref = 'modelslist';

    @Input() data: ModelsListSpot;
    @ViewChild('navRef') navRef: ElementRef;
    @ViewChild('allBtnRef') allBtnRef: ElementRef;
    @ViewChild('activeBarRef') activeBarRef: ElementRef;
    @ViewChildren('modelItem') modelItems: QueryList<ElementRef<HTMLDivElement>>;
    models: IModelsListItem[] = [];
    filterFacets: string[];
    currentType = 'all';
    state: 'initial' | 'move' = 'initial';
    scaleToPercentage = 0; // Size of the active button in percentage
    scalePercentage = 0; // The scaling size between active and previous button
    prevButton?: HTMLButtonElement;
    toButton: HTMLButtonElement;
    fromX = 0;
    toX = 0;
    direction: 'leftToRight' | 'rightToLeft' = 'leftToRight';

    useScrollIndicator = false;
    isMaxLeft = false;
    isMaxRight = false;

    constructor(
        private readonly featureDetectionService: FeatureDetectionService,
        private readonly cd: ChangeDetectorRef,
        private readonly scrollService: ScrollService
    ) {}

    ngOnInit() {
        this.models = this.data.models;

        if (this.models.length) {
            this.filterFacets = this.data.sortedModelCategories;
        }
    }

    ngAfterViewInit() {
        if (this.featureDetectionService.isBrowser() && this.data.showFilter && this.allBtnRef && this.allBtnRef.nativeElement) {
            this.toButton = this.allBtnRef.nativeElement;
            this.onFilterClick(this.currentType, this.toButton, true);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.data.showFilter) {
            this.animateActiveBar(true);
            this.handleScroll();
        }
    }

    async onFilterClick(type: string, btnElement?: HTMLButtonElement, preventAnimation = false) {
        if (!preventAnimation && this.featureDetectionService.hasWebAnimation()) {
            await animateOut(this.modelItems.toArray());
        }

        this.currentType = type;

        if (this.currentType === 'all') {
            this.models = this.data.models;
        } else {
            const arr = [];
            for (const model of this.data.models) {
                const foundItem = model.modelCategory?.find((category) => category.name === this.currentType);
                if (foundItem) {
                    arr.push(model);
                }
            }

            this.models = arr;
        }

        if (this.navRef && btnElement && this.data.showFilter) {
            this.prevButton = this.toButton;
            this.toButton = btnElement;
            this.scrollToButtonElement();
            this.animateActiveBar();
        }

        if (!preventAnimation && this.featureDetectionService.hasWebAnimation()) {
            await animateIn(this.modelItems.toArray());
        }
        this.cd.markForCheck();
    }

    scrollToButtonElement() {
        let scrollNumber;
        const itemOffsetLeft = this.toButton.offsetLeft;
        const itemWidth = this.toButton.clientWidth;
        const nav: HTMLElement = this.navRef.nativeElement;
        const navWidth = nav.offsetWidth;
        const navOffsetLeft = nav.scrollLeft;
        const btnPadding = 40; // used for to show some of the next menu item

        if (itemWidth + itemOffsetLeft > navWidth + navOffsetLeft) {
            scrollNumber = itemWidth + itemOffsetLeft - navWidth;
            scrollNumber = scrollNumber + btnPadding;
        } else if (navOffsetLeft > itemOffsetLeft) {
            scrollNumber = itemOffsetLeft;
            scrollNumber = scrollNumber - btnPadding;
        } else {
            return;
        }

        this.scrollService.scrollToPosition({ left: scrollNumber }, nav);
    }

    animateActiveBar(isResize?: boolean) {
        if (this.navRef && this.navRef.nativeElement && this.activeBarRef.nativeElement) {
            const nav: HTMLElement = this.navRef.nativeElement;
            const btnStyle = window.getComputedStyle(this.toButton);
            const btnPl = btnStyle.paddingLeft ? Number(btnStyle.paddingLeft.replace('px', '')) : 0;
            const btnPr = btnStyle.paddingRight ? Number(btnStyle.paddingRight.replace('px', '')) : 0;
            const padding = btnPl + btnPr;
            const width = this.toButton.clientWidth - padding;
            this.scaleToPercentage = width / nav.offsetWidth;
            this.toX = this.toButton.offsetLeft + btnPl;

            // Only set new width an X coordinates when resizing window
            if (isResize) {
                return;
            }

            if (this.prevButton) {
                const prevBtnStyle = window.getComputedStyle(this.prevButton);
                const prevBtnPl = prevBtnStyle.paddingLeft ? Number(prevBtnStyle.paddingLeft.replace('px', '')) : 0;
                const prevBtnPr = prevBtnStyle.paddingRight ? Number(prevBtnStyle.paddingRight.replace('px', '')) : 0;
                const prevPadding = prevBtnPl + prevBtnPr;
                const prevWidth = this.prevButton.clientWidth - prevPadding;

                this.direction = this.prevButton.offsetLeft < this.toButton.offsetLeft ? 'leftToRight' : 'rightToLeft';

                if (this.direction === 'leftToRight') {
                    this.fromX = this.prevButton.offsetLeft + prevBtnPl;
                    this.scalePercentage = (this.toButton.offsetLeft + btnPl + width - (this.prevButton.offsetLeft + prevBtnPl)) / nav.offsetWidth;
                } else {
                    this.fromX = this.toButton.offsetLeft + btnPl;
                    this.scalePercentage =
                        (this.prevButton.offsetLeft + prevBtnPl + prevWidth - (this.toButton.offsetLeft + btnPl)) / nav.offsetWidth;
                }
            } else {
                this.fromX = 0;
                this.scalePercentage = this.toX / nav.offsetWidth + this.scaleToPercentage;
            }

            this.state = this.state === 'initial' ? 'move' : 'initial';
            this.cd.detectChanges();
        }
    }

    trackByMethod(index: number, item: any) {
        return item.id || index;
    }

    handleScroll(): void {
        const scrollElement = this.navRef?.nativeElement;
        if (!scrollElement) {
            return;
        }

        const { scrollLeft, scrollWidth, clientWidth } = scrollElement as HTMLElement;

        if (scrollWidth <= clientWidth) {
            this.useScrollIndicator = false;
            return;
        }

        this.useScrollIndicator = true;
        const maxScrollLeft = scrollWidth - clientWidth;

        this.isMaxLeft = scrollLeft === 0;
        this.isMaxRight = Math.round(scrollLeft) >= maxScrollLeft;
    }
}
