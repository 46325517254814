import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { IProductPageResponse } from '@ncg/data';

@Component({
    selector: 'ncg-product-details-root',
    template: '<ng-container #vc></ng-container>',
})
export class ProductDetailsRootComponent implements OnInit {
    @Input() data: IProductPageResponse;
    @ViewChild('vc', { read: ViewContainerRef, static: true }) viewContainer: ViewContainerRef;

    constructor(private readonly cd: ChangeDetectorRef) {}

    async ngOnInit() {
        const { ProductDetailsComponent } = await import('./product-details.component');
        const componentRef = this.viewContainer.createComponent(ProductDetailsComponent);
        componentRef.instance.data = this.data;
        this.cd.markForCheck();
    }
}
