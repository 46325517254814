import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UnsubscribeNewsletterFormSpot } from '@ncg/data';
import { firstValueFrom } from 'rxjs';

import { AppHttpErrorResponse } from '../core/app-http-error.response';
import { DialogService } from '../utils/dialog/dialog.service';
import { FormService } from './form.service';

@Component({
    selector: 'ncg-unsubscribe-newsletter',
    template: `
        <div class="unsubscribe-newsletter" *ngIf="form">
            <form [formGroup]="form" (submit)="submit()" *ngIf="!states.submitted; else success">
                <div class="unsubscribe-newsletter__fieldset field is-grouped is-compact">
                    <div class="control is-expanded">
                        <input
                            formControlName="email"
                            [class.is-danger]="errors('email')"
                            type="email"
                            name="email"
                            class="input"
                            [attr.placeholder]="'forms.email' | translate"
                            required
                        />
                        <p class="help is-danger" *ngIf="errors('email')">{{ 'forms.error_email' | translate }}</p>
                    </div>
                    <div class="control has-text-centered-mobile">
                        <button class="button is-primary" [class.is-loading]="states.processing" [disabled]="states.processing || form?.invalid">
                            {{ 'forms.submit_newsletter' | translate }}
                        </button>
                    </div>
                </div>
            </form>

            <ng-template #success> <ncg-rich-text [html]="data.successMessage"></ncg-rich-text> </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsubscribeNewsletterComponent implements OnInit {
    @Input() data: UnsubscribeNewsletterFormSpot;

    form?: UntypedFormGroup;

    states = {
        submitted: false,
        processing: false,
    };

    constructor(
        private fb: UntypedFormBuilder,
        private formService: FormService,
        private readonly cd: ChangeDetectorRef,
        private dialogService: DialogService,
        private readonly router: Router
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            email: this.fb.control('', [Validators.required, Validators.email, FormService.emailValidator()]),
        });
    }

    submit(): void {
        if (!this.form || this.form.invalid) {
            return;
        }

        this.states.processing = true;
        this.cd.markForCheck();

        const value = this.form.value;

        firstValueFrom(this.formService.submitUnsubscribeNewsletter({ email: value.email.toLowerCase() }))
            .then(() => {
                this.onSuccess();
            })
            .catch((err: AppHttpErrorResponse) => {
                this.states.processing = false;
                this.dialogService.showMessage('forms.error_field', 'forms.dialog_validation_errors');

                if (err.validationErrors.length && this.form) {
                    FormService.markValidationErrors(err.validationErrors, this.form);
                }

                this.cd.markForCheck();
            });
    }

    private onSuccess() {
        const url = this.data.successRedirect?.url;
        if (url) {
            this.router.navigateByUrl(url);
            return;
        }

        this.states.processing = false;
        this.states.submitted = true;
        this.cd.markForCheck();
    }

    errors = (controlName: string) => FormService.errors(controlName, this.form);
}
