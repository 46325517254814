import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModelPricesSpot, ModelPriceTableRow } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-model-prices-spot',
    template: `
        <div class="model-prices__table-container" ncgLoadIn *ngIf="data">
            <div class="model-prices__table" *ngIf="data.hasCampaign">
                <h2 *ngIf="!data.hideModelsHeader" class="model-prices-spot__section-header">
                    {{ 'model_prices.section_header_campaign_models' | translate }}
                </h2>
                <ng-container *ngFor="let fuelType of campaignTypes">
                    <ncg-model-prices-spot-table
                        [hideFuelTypeHeader]="data.hideFuelTypeHeader"
                        [currentFuelType]="fuelType"
                        [models]="campaignModels"
                        [isCampaign]="true"
                    ></ncg-model-prices-spot-table>
                </ng-container>
            </div>
            <div *ngIf="nonCampaignModels.length > 0" class="model-prices__table">
                <h2 *ngIf="!data.hideModelsHeader" class="model-prices-spot__section-header">
                    {{ 'model_prices.section_header_model_program' | translate }}
                </h2>
                <ng-container *ngFor="let fuelType of nonCampaignTypes">
                    <ncg-model-prices-spot-table
                        [hideFuelTypeHeader]="data.hideFuelTypeHeader"
                        [currentFuelType]="fuelType"
                        [models]="nonCampaignModels"
                        [isCampaign]="false"
                    ></ncg-model-prices-spot-table>
                </ng-container>
            </div>
        </div>

        <ncg-rich-text class="model-prices-spot__text" [html]="data.text" *ngIf="data.text"></ncg-rich-text>
        <div class="model-prices-spot__button" *ngIf="data.link && data.link.url">
            <ng-container *ngIf="data?.link?.isExternal; else internalLink">
                <a class="button is-primary" rel="noopener" [href]="data.link.url" [target]="data.link.target">
                    {{ data.link.name }}
                </a>
            </ng-container>
            <ng-template #internalLink>
                <a class="button is-primary" [routerLink]="data.link.url">{{ data.link.name }}</a>
            </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelPricesComponent extends SpotBaseDirective implements OnInit {
    static ref = 'modelprices';
    @Input() data: ModelPricesSpot;
    campaignTypes: string[];
    nonCampaignTypes: string[];
    campaignModels: ModelPriceTableRow[] = [];
    nonCampaignModels: ModelPriceTableRow[] = [];

    constructor(private readonly cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.sortTables();
    }

    sortTables() {
        const campaignArray: string[] = [];
        const nonCampaignArray: string[] = [];

        this.data.tableData.forEach((model: ModelPriceTableRow) => {
            if (model.fuelTypeCode) {
                if (model.priceDiscount) {
                    campaignArray.push(model.fuelTypeCode);
                    this.campaignModels.push(model);
                } else {
                    nonCampaignArray.push(model.fuelTypeCode);
                    this.nonCampaignModels.push(model);
                }
            }
        });

        this.campaignTypes = [...new Set(campaignArray).values()];
        this.nonCampaignTypes = [...new Set(nonCampaignArray).values()];
        this.cd.detectChanges();
    }
}
