import { ConfiguratorImageAspectRatio, ConfiguratorStep, ConfiguratorTab } from './configurator';

export const CONFIGURATOR_FEATURE_KEY = 'configurator';
export const configuratorTabs = ['trim', 'powertrain', 'exterior', 'interior', 'optionals', 'accessories', 'partner_products'] as const;
//
export const configuratorSteps = ['configuration', 'summary', 'mail', 'dealer', 'mail_receipt', 'dealer_receipt'] as const;
// TODO: Consider prettifying, like e.g. 'configurator-trim' → ct, cp, ce etc., for smaller URL size. Or just the one letter 🤷‍♀️
export const configuratorParamsWhiteList = [...configuratorTabs, 'finalized'] as const;

export const multiParamSeparator = '|';
export const configuratorImageAspectRatio: Record<ConfiguratorImageAspectRatio, number> = {
    car: 0.5625, // 16:9
    other: 0.4, // 5:2
};

export const stepTrackingKeyLookup: Record<ConfiguratorTab | Extract<ConfiguratorStep, 'summary' | 'dealer' | 'mail'>, string> = {
    trim: 'Variant',
    powertrain: 'Motor',
    exterior: 'Exterior',
    interior: 'Interior',
    optionals: 'Factory options',
    accessories: 'Accessories',
    partner_products: 'Other options',
    summary: 'Summary',
    dealer: 'Send to dealer',
    mail: 'Send to me',
};
