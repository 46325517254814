import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { SocialLinksComponent } from './social-links.component';

@NgModule({
    declarations: [SocialLinksComponent],
    imports: [CommonModule, IconSpriteModule],
    exports: [SocialLinksComponent],
})
export class SocialLinksModule {}
