import { Injectable } from '@angular/core';
import stringify from 'fast-safe-stringify';
import { fromEvent, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { FeatureDetectionService } from './feature-detection.service';

/**
 * Provides "safe" usage of browser localStorage API with options for expiration.
 */
@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private readonly storageEvent$: Observable<StorageEvent> | undefined;

    /**
     * Is localStorage is supported and enabled.
     * NOTE: You don't need to check support before using this API. Support is checked internally to avoid errors.
     */
    public readonly isStorageSupported: boolean = false;

    constructor(private readonly featureDetection: FeatureDetectionService) {
        if (featureDetection.isBrowser()) {
            if ((typeof localStorage as any) !== 'undefined') {
                const testKey = 'key';
                const testVal = 'value';
                try {
                    localStorage.setItem(testKey, testVal);
                    localStorage.removeItem(testKey);
                    this.isStorageSupported = true;
                } catch (error) {
                    this.isStorageSupported = false;
                }
            }
            if (!this.isStorageSupported) {
                console.warn('This browser does not support localStorage. Some features will be limited.');
            } else {
                this.storageEvent$ = fromEvent<StorageEvent>(window, 'storage');
            }
            this.storageEvent$ = fromEvent<StorageEvent>(window, 'storage');
        }
    }

    /**
     * Store an object in session- or localStorage safely. If storage is not supported it will do nothing.
     *
     * @param key - Key used to store the value.
     * @param value - The value to store. If value is "falsy" it won't be stored.
     * @param daysToLive - OPTIONALLY: Set number of full days the entry should be valid.
     */
    public set(key: string, value: any, daysToLive?: number): void {
        if (this.isStorageSupported && value) {
            const storageObject: StorageObject = {
                value,
            };
            if (daysToLive) {
                const ttlDate: Date = new Date();
                storageObject.timestamp = ttlDate.setDate(ttlDate.getDate() + Math.round(daysToLive));
            }
            localStorage.setItem(key, stringify(storageObject));
        }
    }

    /**
     * Get an object from storage.
     *
     * @param key - Key used to store the value.
     */
    public get<T = any>(key: string): T | undefined {
        if (this.isStorageSupported) {
            const stored = localStorage.getItem(key);

            if (stored) {
                const errMsg = `"${key}" was not stored using the StorageService.`;
                try {
                    const storageObject: StorageObject<T> = JSON.parse(stored);
                    if (storageObject.timestamp && storageObject.timestamp < Date.now()) {
                        this.remove(key);
                        return undefined;
                    }
                    if (storageObject.value !== undefined) {
                        return storageObject.value;
                    }
                    console.warn(errMsg);
                    return storageObject as any;
                } catch (error) {
                    console.warn(errMsg, error);
                    return stored as any;
                }
            }
        }
    }

    /**
     * Remove specific object.
     *
     * @param key - Key used to store the value.
     */
    public remove(key: string): void {
        if (this.isStorageSupported) {
            localStorage.removeItem(key);
        }
    }

    /**
     * Clears entire storage.
     */
    public clear(): void {
        if (this.isStorageSupported) {
            localStorage.clear();
        }
    }

    /**
     * Subscribe to localStorage changes for a specific key e.g. if data is changed from a different tab.
     *
     * @param key - Key used to store the value.
     */
    public observe<T = any>(storageKey: string): Observable<T | undefined> {
        if (!this.storageEvent$) {
            return of(undefined);
        }
        return this.storageEvent$.pipe(
            filter((e) => e.key === null || e.key === storageKey),
            distinctUntilChanged((previous, current) => {
                const prevValue = previous?.newValue ? JSON.parse(previous.newValue).value : undefined;
                const newValue = current?.newValue ? JSON.parse(current.newValue).value : undefined;
                return stringify(prevValue) === stringify(newValue);
            }),
            map(() => this.get<T>(storageKey))
        );
    }
}

interface StorageObject<T = any> {
    /**
     * Stored value - does NOT need to be a string.
     */
    value: T;
    /**
     * Expiration date of value (in "milliseconds since 1970" format).
     */
    timestamp?: number;
}
