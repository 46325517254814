import { Pipe, PipeTransform } from '@angular/core';

interface IProductImageSizes {
    xSmall: number;
    small: number;
    medium: number;
    large: number;
    xLarge: number;
}

@Pipe({
    name: 'productImage',
})
export class ProductImagePipe implements PipeTransform {
    // Known bilinfo cdn image sizes
    private readonly bilInfoImageSizes: IProductImageSizes = {
        xSmall: 200,
        small: 400,
        medium: 960,
        large: 1200,
        xLarge: 1600,
    };

    // Known bilbasen cdn image sizes
    private readonly bilBasenImageSizes: IProductImageSizes = {
        xSmall: 180,
        small: 400,
        medium: 800,
        large: 1200,
        xLarge: 1600,
    };

    // Known finn.no cdn image sizes
    private readonly finnNoImageSizes: IProductImageSizes = {
        xSmall: 320,
        small: 640,
        medium: 960,
        large: 1280,
        xLarge: 1600,
    };

    transform(url: string, width: keyof IProductImageSizes): string {
        const isBilInfo = url.includes('.bilinfo.net');
        if (isBilInfo) {
            return this.replaceBilInfo(url, width);
        }

        const isBilBasen = url.includes('i.ebayimg.com');
        if (isBilBasen) {
            return this.replaceBilbasen(url, width);
        }

        const isFinnNo = url.includes('images.finncdn.no');
        if (isFinnNo) {
            return this.replaceFinn(url, width);
        }

        const isBilinfo = url.indexOf('billeder.bilinfo.net') !== -1;
        if (isBilinfo) {
            return this.replaceBilinfo(url, width);
        }

        return url;
    }

    private replaceBilInfo(url: string, sizeKey: keyof IProductImageSizes): string {
        const imageSize = this.bilInfoImageSizes[sizeKey];
        return `${url.split('?')[0]}?class=S${imageSize}X${imageSize}`;
    }

    private replaceBilbasen(url: string, sizeKey: keyof IProductImageSizes): string {
        const imageSize = this.bilBasenImageSizes[sizeKey];
        return url.replace('s-l1600', `s-l${imageSize}`);
    }

    private replaceFinn(url: string, sizeKey: keyof IProductImageSizes): string {
        const imageSize = this.finnNoImageSizes[sizeKey];
        return url.replace('default', `${imageSize}w`);
    }

    private replaceBilinfo(url: string, sizeKey: keyof IProductImageSizes): string {
        // Bilinfo image URL example: https://billeder.bilinfo.net/bilinfo/3d649aa5-8df9-4451-8524-466c21603079.jpeg?class=S1600X1600
        // removing the class parameter will return a 400px image
        if (sizeKey === 'xSmall' || sizeKey === 'small') {
            return url.replace('?class=S1600X1600', '');
        }

        // return the original image for medium, large and xLarge
        return url;
    }
}
