import { Injectable, OnDestroy } from '@angular/core';
import { CarPriceType, CarVatType, IProductPageResponse } from '@ncg/data';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from '../../core/storage.service';

@Injectable()
export class RecentlyViewedCarsSpotService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private readonly lastViewedVehicles$ = new ReplaySubject<ILastViewedVehicle[]>(1);
    private readonly lastViewStorageKey = 'lastViewed';
    private readonly lastViewMaxCount = 10;
    private readonly lastViewedTtlDays = 30;

    constructor(private readonly storage: StorageService) {
        const data = this.getDataFromStorage();
        if (data?.length) {
            this.lastViewedVehicles$.next(data);
        }
        this.initStorageEvent();
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public lastViewedVehicles(): Observable<ILastViewedVehicle[]> {
        return this.lastViewedVehicles$.asObservable();
    }

    public trackLastViewedVehicle(data?: IProductPageResponse): void {
        if (!data?.product) {
            return;
        }

        const { url, product } = data;
        const { cashPrice, leasingPrice } = product;
        const { id, priceType, vatType, pictures } = product.properties;
        const { title, variant } = product;

        const trackedVehicle = {
            url,
            id,
            picture: pictures?.[0],
            title,
            variant,
            cashPrice,
            leasingPrice,
            priceType,
            vatType,
        };

        const vehicles = this.getDataFromStorage();
        if (!vehicles.length) {
            // No stored cars exist, set it to an array with the current vehicle
            this.storage.set(this.lastViewStorageKey, [trackedVehicle], this.lastViewedTtlDays);
            this.lastViewedVehicles$.next([trackedVehicle]);
        } else {
            // Cars already exist
            // Try finding the one being tracked
            const index = vehicles.findIndex((x) => x.id === id);
            if (index > -1) {
                // Vehicle already in array, remove it
                vehicles.splice(index, 1);
            }

            // Append vehicle to the end of the array
            vehicles.push(trackedVehicle);

            // Don't allow array to exceed max count
            if (vehicles.length > this.lastViewMaxCount) {
                vehicles.splice(0, vehicles.length - this.lastViewMaxCount);
            }

            // Save new array to storage (updates ttl)
            this.storage.set(this.lastViewStorageKey, vehicles, this.lastViewedTtlDays);
            this.lastViewedVehicles$.next(vehicles);
        }
    }

    private getDataFromStorage(): ILastViewedVehicle[] {
        return this.storage.get<ILastViewedVehicle[]>(this.lastViewStorageKey) || [];
    }

    private initStorageEvent() {
        this.storage
            .observe<ILastViewedVehicle[]>(this.lastViewStorageKey)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                this.lastViewedVehicles$.next(value || []);
            });
    }
}

export interface ILastViewedVehicle {
    url: string;
    picture: string | null | undefined;
    id: string | null | undefined;
    title: string;
    variant: string;
    cashPrice: string | undefined;
    leasingPrice: string | undefined;
    priceType: CarPriceType | undefined;
    vatType: CarVatType | undefined;
}
