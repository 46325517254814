import { LazyContentAliases, LazyContentGenericComponent } from '../models/lazy-content.models';

export const getLazyComponent: (ref?: string) => Promise<LazyContentGenericComponent | null> = async (ref?: string) => {
    switch (ref) {
        case LazyContentAliases.AltMenuHighlight: {
            const { AltMenuHighlightComponent } = await import('../components/alt-menu-highlight/alt-menu-highlight.component');
            return AltMenuHighlightComponent;
        }
        case LazyContentAliases.AltMenuIconLinks: {
            const { AltMenuIconLinksComponent } = await import('../components/alt-menu-icon-links/alt-menu-icon-links.component');
            return AltMenuIconLinksComponent;
        }
        case LazyContentAliases.MenuSpot: {
            const { MenuSpotComponent } = await import('../components/menu-spot/menu-spot.component');
            return MenuSpotComponent;
        }
        case LazyContentAliases.PreviewOverlay: {
            const { PreviewOverlayComponent } = await import('../components/preview-overlay/preview-overlay.component');
            return PreviewOverlayComponent;
        }
        case LazyContentAliases.ActionBar: {
            const { ActionBarComponent } = await import('../components/action-bar/action-bar.component');
            return ActionBarComponent;
        }
        case LazyContentAliases.Favorites: {
            const { FavoritesContainerComponent } = await import('../components/favorites/favorites-container/favorites-container.component');
            return FavoritesContainerComponent;
        }
        case LazyContentAliases.FindLeasingCalculator: {
            const { FindLeasingCalculatorComponent } = await import('../components/find-leasing-calculator/find-leasing-calculator.component');
            return FindLeasingCalculatorComponent;
        }
        case LazyContentAliases.ServiceBookingServiceDialog: {
            const { ServiceDialogComponent } = await import('../components/service-booking/service-dialog.component');
            return ServiceDialogComponent;
        }
        case LazyContentAliases.AutoProff: {
            const { AutoProffComponent } = await import('../components/auto-proff/auto-proff.component');
            return AutoProffComponent;
        }
        default:
            return null;
    }
};
