import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ncg-spot-headline',
    template: `
        <ng-template #content>
            <ng-content></ng-content>
        </ng-template>

        <ng-container [ngSwitch]="level">
            <h1 *ngSwitchCase="0" [class]="headlineClass" [ngClass]="headlineNgClass">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </h1>
            <h1 *ngSwitchCase="1" [class]="headlineClass" [ngClass]="headlineNgClass">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </h1>
            <h2 *ngSwitchCase="2" [class]="headlineClass" [ngClass]="headlineNgClass">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </h2>
            <h3 *ngSwitchCase="3" [class]="headlineClass" [ngClass]="headlineNgClass">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </h3>
            <h4 *ngSwitchCase="4" [class]="headlineClass" [ngClass]="headlineNgClass">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </h4>
            <h5 *ngSwitchCase="5" [class]="headlineClass" [ngClass]="headlineNgClass">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </h5>
            <h6 *ngSwitchCase="6" [class]="headlineClass" [ngClass]="headlineNgClass">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </h6>
            <h2 *ngSwitchDefault [class]="headlineClass" [ngClass]="headlineNgClass">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </h2>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpotHeadlineComponent {
    @Input()
    public headlineClass: string;

    @Input()
    public headlineNgClass: string | string[] | Set<string> | { [klass: string]: any };

    @Input()
    public level: '1' | '2' | '3' | '4' | '5' | '6' = '2';
}
