import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { IVehicleLmsResponse, NcgAuthorityDetails } from '@ncg/data';
import { SettingsService } from './settings.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VehicleService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly httpClient: HttpClient,
        private readonly settingsService: SettingsService
    ) {}

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public getAuthorityDetails(regNr: string) {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) => this.httpClient.get<NcgAuthorityDetails>(`/api/vehicle/authoritydetails`, { params: { regNr, culture } })),
            takeUntil(this.unsubscribe)
        );
    }

    public getVehicleLms(pimId: string) {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) =>
                this.httpClient.get<IVehicleLmsResponse>(`/api/vehicle/${pimId}/lms`, {
                    params: {
                        culture,
                    },
                })
            ),
            takeUntil(this.unsubscribe)
        );
    }
}
