import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GallerySpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-gallery-spot',
    template: `
        <div class="gallery-spot" *ngIf="data && data.items" ncgLoadIn>
            <ncg-tab-group>
                <ncg-tab *ngFor="let item of data.items; let isFirst = first" [label]="item.title" [isActive]="isFirst">
                    <ncg-gallery-spot-item [item]="item"></ncg-gallery-spot-item>
                </ncg-tab>
            </ncg-tab-group>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GallerySpotComponent extends SpotBaseDirective {
    static ref = 'gallery';

    @Input() data: GallerySpot;
}
