import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyComponentLoaderDirective } from './directives/lazy-component-loader.directive';

@NgModule({
    imports: [CommonModule],
    exports: [LazyComponentLoaderDirective],
    declarations: [LazyComponentLoaderDirective],
})
export class LazyContentModule {}
