import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ScrollService } from '../../core/scroll.service';
import { SidePanelService } from '../../side-panel/side-panel.service';

@Directive({
    selector: '[ncgInterceptLinks]',
})
export class InterceptLinksDirective {
    @Input()
    public stopPropagation: boolean = false;
    private excludedPathRegex?: RegExp;
    private activeDialogId?: string;

    constructor(
        @Inject(DOCUMENT)
        private readonly doc: Document,
        private readonly element: ElementRef,
        private readonly router: Router,
        private readonly viewportScroller: ViewportScroller,
        private readonly sidePanelService: SidePanelService,
        private readonly scrollService: ScrollService
    ) {}

    /**
     * Support scroll in dialog/overlay
     * Pass in the dialog element id, if used to scroll to and anchor in dialog/overlay
     */
    @Input()
    public set dialogId(id: string | undefined) {
        this.activeDialogId = id;
    }

    /**
     * Set the paths either by a string or a regex that should not be considered when parsing clicks
     */
    @Input()
    public set exclude(paths: string | string[] | RegExp) {
        if (paths instanceof RegExp) {
            this.excludedPathRegex = paths;
        } else if (typeof paths === 'string') {
            this.excludedPathRegex = new RegExp(paths);
        } else {
            this.excludedPathRegex = new RegExp(paths.join('|'));
        }
    }

    /**
     * Event handler to intercept link clicks.
     * "Return true" here means that navigation will skip to normal browser navigation.
     * "Return false" means that further handling is done either this directive or in the browser.
     */
    @HostListener('click', ['$event'])
    onClick($event: MouseEvent): boolean {
        if (this.stopPropagation) {
            $event.stopPropagation();
        }
        // Find the linkElement that was clicked.
        const linkElement: HTMLAnchorElement | undefined = this.findClickedLinkElement($event.target as HTMLAnchorElement);

        // Eject if linkElement wasn't found.
        if (!linkElement) {
            return true;
        }

        // If using hash to open page in side panel, use sidePanelService instead and stop further processing (Custom NIC logic).
        if (linkElement.hash.includes('#sidepanel')) {
            this.sidePanelService.openContent(linkElement.pathname);
            return false;
        }

        // Eject if not able to be handled by router.
        if (!this.isLinkIsValidForRouter(linkElement)) {
            return true;
        }

        // If you are still here, the router will take over and do the navigation.
        return this.navigate(new URL(linkElement.href));
    }

    /**
     * Navigate to any internal url that is not excluded by the [exclude] input.
     */
    private navigate(url: URL): boolean {
        // Remove # from url hash.
        const strippedUrlHash: string | undefined = url.hash ? url.hash.replace('#', '') : undefined;

        if (strippedUrlHash) {
            // If we need to scroll inside passed dialog.
            if (this.activeDialogId) {
                return this.scrollToAnchorInDialog(strippedUrlHash, this.activeDialogId);
            }

            // When anchor linking on the same page with the same params, we handle it manually for best experience.
            if ((!url.search || url.search === this.doc.location.search) && (url.pathname === '/' || url.pathname === this.doc.location.pathname)) {
                if (this.scrollService.scrollToAnchor(strippedUrlHash)) {
                    return false;
                }
            }
        }

        // Do regular angular navigation using router.
        this.router
            .navigate([url.pathname], {
                fragment: strippedUrlHash,
                queryParams: this.mapURLSearchParamsToParams(url.searchParams),
            })
            .catch((e) => {
                console.error(
                    `INTERCEPT-LINKS: Failed to navigate from: "${this.doc.location.href}" to: "${url.origin}${url.pathname}${url.search}${url.hash}".`,
                    e
                );
            });

        return false;
    }

    /**
     * Recursively find anchor element of whatever was clicked (e.g. if the link has a child that was clicked).
     * Stops looking when reaching directive element.
     */
    private findClickedLinkElement(linkElement?: HTMLElement | null): HTMLAnchorElement | undefined {
        // If linkElement is falsy -> Return undefined.
        if (!linkElement) {
            return;
        }

        // Found link -> Return it.
        if (linkElement instanceof HTMLAnchorElement) {
            return linkElement;
        }

        // Reached directive element (and not an anchor) -> Return undefined.
        if (linkElement === this.element.nativeElement) {
            return;
        }

        // Try with parent element recursively.
        return this.findClickedLinkElement(linkElement.parentElement);
    }

    /**
     * Determine if link can be handled by angular router or should be ejected to browser.
     */
    private isLinkIsValidForRouter(linkElement: HTMLAnchorElement): boolean {
        // Eject if link doesn't have an href.
        if (!linkElement.href) {
            return false;
        }

        // Eject if link has download attribute.
        if (linkElement.download) {
            return false;
        }

        // Eject if links have target _blank or _parent (_self, _top and undefined/'' are not ejected).
        if (linkElement.target && (linkElement.target === '_blank' || linkElement.target === '_parent')) {
            return false;
        }

        // Eject if link points to another domain (external link).
        if (linkElement.hostname !== this.doc.location.hostname) {
            return false;
        }

        // Eject if current path matches excludedPathRegex.
        if (this.excludedPathRegex && this.excludedPathRegex.test(linkElement.pathname)) {
            return false;
        }

        return true;
    }

    /**
     * Perform anchor scrolling within an open dialog.
     */
    private scrollToAnchorInDialog(anchorId: string, dialogId: string): boolean {
        const dialogElement: HTMLElement | undefined = document.getElementById(dialogId) as HTMLElement;
        if (dialogElement) {
            if (this.scrollService.scrollToAnchor(anchorId, undefined, dialogElement)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Maps searchParams of the URL object to Angular Params object.
     */
    private mapURLSearchParamsToParams(urlParams: URLSearchParams): Params {
        const params: Params = {};
        urlParams.forEach((value, key) => (params[key] = value));
        return params;
    }
}
