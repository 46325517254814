import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getBuildIdQuery } from '@ncg/data';

@Component({
    selector: 'ncg-energy-label',
    template: `
        <ng-container *ngFor="let label of energyLabels" [ngSwitch]="label">
            <ng-container *ngSwitchCase="'G'">
                <img
                    height="18"
                    width="53"
                    class="energy-image"
                    [ngClass]="imageClassModifier"
                    loading="lazy"
                    [src]="'assets/images/energy-g.png' + buildIdQuery"
                    alt="Euro emission rating - G"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'F'">
                <img
                    height="18"
                    width="53"
                    class="energy-image"
                    [ngClass]="imageClassModifier"
                    loading="lazy"
                    [src]="'assets/images/energy-f.png' + buildIdQuery"
                    alt="Euro emission rating - F"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'E'">
                <img
                    height="18"
                    width="53"
                    class="energy-image"
                    [ngClass]="imageClassModifier"
                    loading="lazy"
                    [src]="'assets/images/energy-e.png' + buildIdQuery"
                    alt="Euro emission rating - E"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'D'">
                <img
                    height="18"
                    width="53"
                    class="energy-image"
                    [ngClass]="imageClassModifier"
                    loading="lazy"
                    [src]="'assets/images/energy-d.png' + buildIdQuery"
                    alt="Euro emission rating - D"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'C'">
                <img
                    height="18"
                    width="53"
                    class="energy-image"
                    [ngClass]="imageClassModifier"
                    loading="lazy"
                    [src]="'assets/images/energy-c.png' + buildIdQuery"
                    alt="Euro emission rating - C"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'B'">
                <img
                    height="18"
                    width="53"
                    class="energy-image"
                    [ngClass]="imageClassModifier"
                    loading="lazy"
                    [src]="'assets/images/energy-b.png' + buildIdQuery"
                    alt="Euro emission rating - B"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'A'">
                <img
                    height="18"
                    width="53"
                    class="energy-image"
                    [ngClass]="imageClassModifier"
                    loading="lazy"
                    [src]="'assets/images/energy-a.png' + buildIdQuery"
                    alt="Euro emission rating - A"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'A+'">
                <img
                    height="18"
                    width="53"
                    class="energy-image"
                    [ngClass]="imageClassModifier"
                    loading="lazy"
                    [src]="'assets/images/energy-aa.png' + buildIdQuery"
                    alt="Euro emission rating - A+"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'A++'">
                <img
                    height="18"
                    width="53"
                    class="energy-image"
                    [ngClass]="imageClassModifier"
                    loading="lazy"
                    [src]="'assets/images/energy-aaa.png' + buildIdQuery"
                    alt="Euro emission rating - A++"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'A+++'">
                <img
                    height="18"
                    width="53"
                    class="energy-image"
                    [ngClass]="imageClassModifier"
                    loading="lazy"
                    [src]="'assets/images/energy-aaaa.png' + buildIdQuery"
                    alt="Euro emission rating - A+++"
                />
            </ng-container>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyLabelComponent {
    buildIdQuery = getBuildIdQuery();

    @Input() energyLabels: string[];
    @Input() imageClassModifier = '';
}
