import { Pipe, PipeTransform } from '@angular/core';
import { CarModel, DataEntityWithResources, TrimViewModel } from '@ncg/data';
import { catchError, map, Observable, of } from 'rxjs';
import { SettingsService } from '../../core/settings.service';
import { getIdByByPrice } from './by-price.util';
import { ConfiguratorQueryParams } from '../configurator';

/**
 * Returns cheapest exterior color for variant/powertrain combo.
 */
@Pipe({
    name: 'exteriorColorByVariant',
    standalone: true,
    pure: true,
})
export class ExteriorColorByVariantPipe implements PipeTransform {
    constructor(private readonly settingsService: SettingsService) {}
    transform(model: CarModel, trim: TrimViewModel, params: ConfiguratorQueryParams): Observable<DataEntityWithResources | undefined> {
        const variant = trim?.variants?.find((v) => v.powerTrainId === params.powertrain) ?? trim?.variants?.[0];

        return this.settingsService.settings$.pipe(
            map(({ currency }) => {
                const cheapestExteriorIdInVariant = getIdByByPrice(
                    variant?.commercialColourOptionExteriorIds,
                    model?.commercialColourOptionsExterior,
                    `CommercialColourOptionRetailSellingPrice`,
                    currency,
                    'lowest'
                );
                const cheapestExteriorInVariant = model?.commercialColourOptionsExterior?.[cheapestExteriorIdInVariant];
                if (cheapestExteriorInVariant) {
                    return cheapestExteriorInVariant;
                }
                return undefined;
            }),
            catchError((e) => {
                console.warn('WARNING: Could not find exterior', e);
                return of(undefined);
            })
        );
    }
}
