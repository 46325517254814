import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMotorSizeItem } from '@ncg/data';

@Component({
    selector: 'ncg-motorsize-item',
    template: `
        <div class="motorsize-item has-text-centered">
            <div class="container">
                <div class="columns is-centered">
                    <div class="column">
                        <div class="motorsize-item__title" *ngIf="item.title">{{ item.title }}</div>
                        <div class="motorsize-item__description" *ngIf="item.description">{{ item.description }}</div>
                    </div>
                </div>
                <div class="columns is-multiline is-mobile">
                    <div class="column is-one-fifth-tablet is-one-third-mobile motorsize-item__data" *ngFor="let group of item.values">
                        <ncg-motorsize-item-icons [icon]="group.code"></ncg-motorsize-item-icons>
                        <div class="motorsize-item__label">{{ group.label }}</div>
                        <div class="motorsize-item__value" [ngSwitch]="group.code">
                            <ng-container *ngSwitchCase="'vehicle_ncap'">
                                <ncg-ncap-stars [stars]="group.value"></ncg-ncap-stars>
                            </ng-container>
                            <ng-container *ngSwitchDefault>{{ group.value }}</ng-container>
                        </div>
                        <div class="motorsize-item__sublabel motorsize-item__label" *ngIf="group.sublabel">
                            {{ group.sublabel }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MotorsizeItemComponent {
    @Input() item: IMotorSizeItem;
}
