import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { ScriptSpot } from '@ncg/data';
import { FeatureDetectionService } from '../../core/feature-detection.service';
import { TrackingService } from '../../core/tracking.service';

@Component({
    selector: 'ncg-script-spot',
    template: ` <div *ngIf="data" class="script-spot" #placeholder [innerHTML]="data?.html | safe: 'html'"></div> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScriptSpotComponent implements AfterViewInit {
    static ref = 'script';

    @ViewChild('placeholder') placeholderRef?: ElementRef<HTMLDivElement>;

    @Input() data?: ScriptSpot;

    constructor(
        @Inject(DOCUMENT) private doc: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly trackingService: TrackingService,
        private readonly featureDetection: FeatureDetectionService
    ) {}

    ngAfterViewInit() {
        if (!this.featureDetection.isAudit() && this.data?.html && this.placeholderRef && isPlatformBrowser(this.platformId)) {
            // Take all scripts and reappend them in order to execute in browser
            [].forEach.call(this.placeholderRef.nativeElement.querySelectorAll('script'), (script: HTMLScriptElement) => {
                const s = this.doc.createElement('script');
                if (script.innerText) {
                    s.innerText = script.innerText.replace(/[\r\n]+/g, '');
                }
                if (script.src) {
                    s.src = script.src;
                }
                if (script.async) {
                    s.async = script.async;
                }
                if (script.defer) {
                    s.defer = script.defer;
                }
                if (script.crossOrigin) {
                    s.crossOrigin = script.crossOrigin;
                }
                if (script.integrity) {
                    s.integrity = script.integrity;
                }
                if (script.id) {
                    s.id = script.id;
                }
                if (script.className) {
                    s.className = script.className;
                }
                if (script.type) {
                    s.type = script.type;
                }
                this.placeholderRef?.nativeElement.insertBefore(s, script);
                if (typeof script.remove === 'function') {
                    script.remove();
                } else if (script.parentNode) {
                    // Internet Explorer is a bastard
                    script.parentNode.removeChild(script);
                } else {
                    // If everything else fails, at least remove identifiers from original script
                    script.id = '';
                    script.className = '';
                }
                (script as any) = null;
            });

            this.trackingService.reloadCookieInformation();
        }
    }
}
