import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { ISearchNode } from '@ncg/data';

import { searchAnimation } from '../utils/animations/filter.animation';

@Component({
    selector: 'ncg-search-section',
    template: `
        <div class="search-section" *ngIf="results?.length" [@searchList]>
            <header class="search-section__header" *ngIf="title">
                <h5 class="search-section__header--title">{{ title }} ({{ results?.length }})</h5>
            </header>

            <a
                #searchItem
                [routerLink]="result.url"
                class="animate-scale__link search-item has-image"
                *ngFor="let result of results"
                [ngClass]="{ 'is-active': currentItem && currentItem === result.url }"
            >
                <div class="search-item__image" *ngIf="result?.image && isModel">
                    <ncg-search-image class="is-ratio-model animate-scale is-small-scale" [image]="result?.image"></ncg-search-image>
                </div>
                <div class="search-item__text">
                    <div class="search-item__text--title" *ngIf="result.title">{{ result.title }}</div>
                    <div class="search-item__text--description" *ngIf="isModel && result.priceFrom">
                        {{ 'search.model_price' | translate: { price: result.priceFrom } }}
                    </div>
                    <div class="search-item__text--description" *ngIf="!isModel && result.description">{{ result.description }}</div>
                </div>
            </a>
        </div>
    `,
    animations: [searchAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchSectionComponent implements OnChanges {
    @Input() title: string;
    @Input() results?: readonly ISearchNode[];
    @Input() currentItem?: string;
    @Input() isModel = true;
    @ViewChildren('searchItem') searchItems: QueryList<ElementRef>;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentItem) {
            this.setFocus();
        }
    }
    setFocus() {
        if (this.searchItems) {
            this.searchItems.forEach((anchor: ElementRef) => {
                if (this.currentItem) {
                    if (this.currentItem === anchor.nativeElement.getAttribute('href')) {
                        anchor.nativeElement.focus();
                    }
                }
            });
        }
    }
}
