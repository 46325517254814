import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { LegalModule } from '../legal/legal.module';
import { LogosModule } from '../logos/logos.module';
import { NgBootstrapModule } from '../ng-bootstrap/ng-bootstrap.module';
import { RichTextModule } from '../rich-text/rich-text.module';
import { NgbCustomDateFormatterService } from '../utils/helpers/dateformatter';
import { CustomDatepickerI18n, I18n } from '../utils/helpers/datepicker-i18n';
import { UtilsModule } from '../utils/utils.module';
import { BookServiceFormComponent } from './book-service-form.component';
import { ConsentComponent } from './consent.component';
import { ContactFormComponent } from './contact-form.component';
import { LocationSelectComponent } from './location-select.component';
import { ModelsSelectComponent } from './models-select.component';
import { NewsletterComponent } from './newsletter.component';
import { OfferFormComponent } from './offer-form.component';
import { TestDriveFormComponent } from './test-drive-form.component';
import { UnsubscribeNewsletterComponent } from './unsubscribe-newsletter.component';
import { ImageUploadModule } from '../image-upload/image-upload.module';

@NgModule({
    declarations: [
        ConsentComponent,
        ContactFormComponent,
        NewsletterComponent,
        BookServiceFormComponent,
        TestDriveFormComponent,
        OfferFormComponent,
        LocationSelectComponent,
        ModelsSelectComponent,
        UnsubscribeNewsletterComponent,
    ],
    providers: [
        I18n,
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbDateParserFormatter, useClass: NgbCustomDateFormatterService },
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        ContactFormComponent,
        NewsletterComponent,
        BookServiceFormComponent,
        OfferFormComponent,
        TestDriveFormComponent,
        UnsubscribeNewsletterComponent,
        LocationSelectComponent,
        ConsentComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        RichTextModule,
        NgBootstrapModule,
        LegalModule,
        LogosModule,
        IconSpriteModule,
        UtilsModule,
        ImageUploadModule,
    ],
})
export class FormModule {}
