import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IModelOverviewPageResponse } from '@ncg/data';

@Component({
    selector: 'ncg-model-overview-page',
    template: `
        <div class="content">
            <ncg-breadcrumb [data]="data"></ncg-breadcrumb>
            <ncg-umb-grid [grid]="data?.grid"></ncg-umb-grid>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelOverviewPageComponent {
    static ref = 'modelOverviewPage';

    @Input()
    data: IModelOverviewPageResponse;
}
