import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CarModel, TrimViewModel } from '@ncg/data';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { CardComponent } from '../../../card/card.component';
import { SettingsService } from '../../../core/settings.service';
import { SidePanelService } from '../../../side-panel/side-panel.service';
import { listAnimation } from '../../../utils/animations/list.animations';
import { CurrencyFormatterPipe } from '../../../utils/currency.pipe';
import { DialogService } from '../../../utils/dialog/dialog.service';
import { HdAspect } from '../../../utils/helpers/aspect-ratio';
import { UtilsModule } from '../../../utils/utils.module';
import { ConfiguratorImageComponent } from '../../components/configurator-image/configurator-image.component';
import { ClassInterpolationPipe } from '../../utils/class-interpolation.pipe';
import { DataByIdPipe } from '../../utils/data-by-id.pipe';
import { ExteriorColorByVariantPipe } from '../../utils/exterior-color-by-variant.pipe';
import { ListItemDirective } from '../../../list-item/list-item.directive';
import { ConfiguratorTabStates } from '../../configurator';

@Component({
    selector: 'ncg-configurator-trims',
    animations: [listAnimation],
    template: ` <ng-container *ngIf="configuratorFacade.routerQueryParams$ | async as params">
        <div class="container" *ngIf="configuratorFacade.model$ | async as model" [@listAnimation]="model.trims?.length">
            <div class="configurator__cards">
                <ng-container *ngFor="let trim of model.trims">
                    <ncg-card class="configurator__card" contentClass="configurator__card-content" [selected]="trim.id === params.trim">
                        <ncg-configurator-image
                            *ngIf="model | exteriorColorByVariant: trim : params | async as exteriorColor"
                            [fields]="exteriorColor?.resourcePackShot?.[0]?.fields"
                            type="car"
                            sizes="300px"
                            card-image
                        ></ncg-configurator-image>

                        <h2 class="title configurator__headline" *ngIf="configuratorFacade.tabStates$ | async as tabStates">
                            <button
                                class="configurator__font-weight--emphasis trim-button configurator__link-cover"
                                (click)="handleTrimChange(tabStates, trim.id)"
                            >
                                {{ model.title }} <br />
                                <span class="trim-name">{{ trim.fields | dataById: 'CommercialTrimName' }}</span>
                            </button>
                        </h2>
                        <p class="price">
                            <span
                                *ngIf="
                                    getLowestVariantPrice(trim, 'VariantRetailSellingPrice', (settingsService.settings$ | async)?.currency)
                                        | currency as price
                                "
                                [innerHTML]="
                                    'configurator.price_cash_from' | translate: { price: '<span class=configurator__text--bold>' + price + '</span>' }
                                "
                            ></span>
                            <ng-container *ngIf="(settingsService.settings$ | async)?.currency as currency">
                                <span
                                    *ngIf="getLowestVariantPrice(trim, 'VariantLeasingSellingPricePrivateMonthly', currency) as leasingPrice"
                                    [innerHTML]="
                                        'configurator.price_leasing_from'
                                            | translate
                                                : {
                                                      price:
                                                          '<span class=configurator__text--bold>' +
                                                          (leasingPrice | currency: currency : true) +
                                                          '</span>'
                                                  }
                                    "
                                >
                                </span>
                            </ng-container>
                        </p>

                        <ng-container *ngIf="trim.standardOptionsHighlightsIds as highlightIds">
                            <h3 class="configurator__sub-headline highlights-title">{{ 'configurator.highlights_title' | translate }}</h3>
                            <ul class="configurator__list">
                                <ng-container *ngFor="let id of highlightIds">
                                    <li ncgListItem *ngIf="model.standardOptions?.[id]?.fields | dataById: 'OptionMarketingTitle' as value">
                                        {{ value }}
                                    </li>
                                </ng-container>
                            </ul>
                            <button type="button" class="highlights-button" (click)="openTrimsEquipmentPanel(model, trim.id)">
                                {{ 'configurator.highlights_see_all' | translate }}
                            </button>
                        </ng-container>
                    </ncg-card>
                </ng-container>
            </div>
            <div class="configurator__legal">
                <p *ngFor="let trim of model.trims">
                    <strong>{{ trim.fields | dataById: 'CommercialTrimMarketingTitle' }}.</strong>
                    {{ trim.fields | dataById: 'CommercialTrimLegalText' }}<br />
                    {{ trim.fields | dataById: 'CommercialTrimLegalTextConfigurator' }}
                </p>
            </div>
        </div>
    </ng-container>`,
    styleUrls: ['./configurator-trims.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        UtilsModule,
        CardComponent,
        TranslateModule,
        DataByIdPipe,
        ClassInterpolationPipe,
        CurrencyFormatterPipe,
        ConfiguratorImageComponent,
        ExteriorColorByVariantPipe,
        ListItemDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorTrimsComponent implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    public hdAspect: number = HdAspect;

    constructor(
        private readonly sidePanelService: SidePanelService,
        private readonly router: Router,
        private readonly dialogService: DialogService,
        public readonly configuratorFacade: ConfiguratorFacade,
        public readonly settingsService: SettingsService
    ) {}

    public getLowestVariantPrice(trim: TrimViewModel, id: string, currency?: string): string | undefined {
        const prices = trim.variants
            ?.map((variant) => variant.fields?.find((field) => field.fieldTypeId === `${id}${currency || 'DKK'}`)?.data?.value)
            .filter((x) => !!x);
        if (prices?.length) {
            return Math.min(...prices).toString();
        }
        return undefined;
    }

    public openTrimsEquipmentPanel(model: CarModel, selectedTrim: TrimViewModel['id']): void {
        this.sidePanelService.openTrimsEquipmentPanel(model, selectedTrim);
    }

    public handleTrimChange(tabStates: ConfiguratorTabStates, id?: string | null): void {
        const doTrimChange = () => {
            this.router.navigate([], { queryParams: { trim: id }, queryParamsHandling: 'merge', replaceUrl: true });
        };

        const isStateDirty = Object.entries(tabStates)
            .filter(([tab]) => tab === 'trim')
            .some(([, state]) => state !== 'pristine');

        if (!isStateDirty) {
            doTrimChange();
            return;
        }

        this.dialogService
            .openDialog({
                data: {
                    header: 'configurator.tab_state_dialog_heading',
                    text: 'configurator.tab_state_dialog_body',
                    okBtn: 'configurator.tab_state_dialog_ok_button',
                    closeBtn: 'configurator.tab_state_dialog_close_button',
                },
            })
            .then((dialogRef) => {
                if (!dialogRef) {
                    return;
                }
                dialogRef
                    .afterClose()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((confirmed) => {
                        if (confirmed) {
                            doTrimChange();
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
