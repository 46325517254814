export default function* flatten(array: any[][], depth: number): any {
    if (depth === undefined) {
        depth = 1;
    }
    for (const item of array) {
        if (Array.isArray(item) && depth > 0) {
            yield* flatten(item, depth - 1);
        } else {
            yield item;
        }
    }
}
