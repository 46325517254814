import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    override parse(url: string): UrlTree {
        const segments = url.split('?');
        let newUrl = segments[0].toLowerCase();
        if (segments.length > 1) {
            newUrl += `?${segments[1]}`;
        }
        return super.parse(newUrl);
    }
}
