import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { setTab } from '../../../../+state/configurator/configurator.actions';
import { ConfiguratorFacade } from '../../../../+state/configurator/configurator.facade';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { slideDownInWithWidthAnimation } from '../../../../utils/animations/slide.animations';
import { ConfiguratorTab } from '../../../configurator';

@Component({
    selector: 'ncg-configurator-tabs-nav',
    template: `
        <div class="nav-wrapper">
            <nav #vcNav *ngIf="configuratorFacade.tab$ | async as activeTab">
                <div class="tab" *ngFor="let tab of configuratorFacade.enabledTabs$ | async">
                    <button (click)="this.handletabChange(tab)" [ngClass]="{ active: activeTab === tab }">
                        <span class="button-text">{{ translationBase + tab | translate }}</span>
                        <svg-icon-sprite
                            *ngIf="['affected', 'touched'].includes((configuratorFacade.tabStates$ | async)?.[tab] || '')"
                            [width]="'24px'"
                            [height]="'24px'"
                            [src]="'attention'"
                            [viewBox]="'0 0 24 24'"
                            aria-hidden="true"
                            class="icon"
                            [@slideDownInWithWidthAnimation]
                        ></svg-icon-sprite>
                    </button>
                </div>
            </nav>
        </div>
    `,
    styleUrls: ['./configurator-tabs-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    animations: [slideDownInWithWidthAnimation],
    imports: [CommonModule, TranslateModule, IconSpriteModule],
})
export class ConfiguratorTabsNavComponent implements AfterViewInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    public translationBase = 'configurator.tab_header_';

    @ViewChild('vcNav', { read: ViewContainerRef })
    vcNav: ViewContainerRef;

    constructor(
        private readonly cd: ChangeDetectorRef,
        public readonly configuratorFacade: ConfiguratorFacade
    ) {}

    ngAfterViewInit(): void {
        // Center instantly once view is ready
        this.vcNav.element.nativeElement.querySelector('.active')?.scrollIntoView({ behavior: 'instant', inline: 'center' });
        this.configuratorFacade.tab$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            // Center when tab is changed by state, and view is updated (hence timeout)
            window.setTimeout(() => {
                this.vcNav.element.nativeElement.querySelector('.active')?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
                this.cd.markForCheck(); // Makes sure tabStates$ isn't stale.
            }, 0);
        });
    }

    public handletabChange(tab: ConfiguratorTab): void {
        this.configuratorFacade.dispatch(setTab({ tab }));
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
