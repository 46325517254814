import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MotorSizeSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-motorsize-spot',
    template: `
        <div class="motorsize-spot container is-full" *ngIf="data && data.items && data.items.length" ncgLoadIn>
            <div class="container is-fullwidth">
                <ng-container *ngIf="data.items.length > 1; else singleEngine">
                    <ncg-tab-group>
                        <ng-container *ngFor="let item of data.items; let isFirst = first">
                            <ncg-tab *ngIf="item.title" [label]="item.title" [isActive]="isFirst">
                                <ncg-motorsize-item [item]="item"></ncg-motorsize-item>
                            </ncg-tab>
                        </ng-container>
                    </ncg-tab-group>
                </ng-container>

                <ng-template #singleEngine>
                    <ncg-motorsize-item [item]="data.items[0]"></ncg-motorsize-item>
                </ng-template>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MotorsizeComponent extends SpotBaseDirective {
    static ref = 'motorsize';

    @Input() data: MotorSizeSpot;
}
