import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TabGroupComponent } from './tab-group.component';
import { TabComponent } from './tab.component';

@NgModule({
    imports: [CommonModule],
    declarations: [TabGroupComponent, TabComponent],
    exports: [TabGroupComponent, TabComponent],
})
export class TabsModule {}
