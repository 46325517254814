import { Overlay } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { IFilterSettings } from '@ncg/data';

import { DialogService } from '../utils/dialog/dialog.service';
import { FilterSidepanelComponent } from './filter-sidepanel/filter-sidepanel.component';

@Injectable({
    providedIn: 'root',
})
export class FilterService {
    private _filterSettings: IFilterSettings = {};
    constructor(
        private readonly dialogService: DialogService,
        private readonly overlay: Overlay
    ) {}

    public openFilterPanel() {
        this.dialogService.openDialogWithComponent(FilterSidepanelComponent, {
            fullHeight: true,
            blockScroll: true,
            hasBackdrop: true,
            data: {},
            positionHorizontal: {
                placement: 'right',
            },
        });
    }

    public get filterSettings() {
        return this._filterSettings;
    }

    public set filterSettings(value: IFilterSettings) {
        this._filterSettings = value;
    }
}
