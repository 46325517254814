import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DealerInfoComponent } from './dealer-info.component';

@NgModule({
    imports: [CommonModule, TranslateModule.forRoot()],
    declarations: [DealerInfoComponent],
    exports: [DealerInfoComponent],
})
export class DealerModule {}
