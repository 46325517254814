import { DOCUMENT, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IActionBar, IImage, IMenuSpot, ISettingsResponse } from '@ncg/data';
import { Observable, of, ReplaySubject, Subject, switchMap } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { PreviewService } from './preview.service';
import { BUILD_TOKEN } from './tokens';
import { TrackingService } from './tracking.service';

@Injectable({
    providedIn: 'root',
})
export class SettingsService implements OnDestroy {
    private readonly _cacheBustingId: string;
    private readonly unsubscribe = new Subject<void>();

    private _currentCulture?: string;
    private readonly settingsRs$ = new ReplaySubject<ISettingsResponse>(1);
    public settings$ = this.settingsRs$.asObservable();

    constructor(
        public readonly trackingService: TrackingService,
        private readonly http: HttpClient,
        private readonly preview: PreviewService,
        private readonly meta: Meta,
        private readonly router: Router,
        private readonly location: Location,
        @Inject(DOCUMENT) private readonly _document: Document,
        @Inject(BUILD_TOKEN)
        @Optional()
        private readonly buildToken?: string
    ) {
        this._cacheBustingId = this.buildToken || 'dev-build';

        this.get()
            .pipe(
                take(1),
                switchMap((settings) => {
                    this.setBrowserLanguage(`${settings.seoLanguage}-${settings.seoCountry}`);
                    this.setDocumentFavicon(settings.favicons);
                    this.setDocumentSiteTheme(settings.siteTheme);
                    this.setDocumentBuildId();
                    this.setFacebookMetaTag(settings.facebookMetaTag);
                    if (settings.loadCookieInfoScript) {
                        return this.trackingService
                            .initCookieInformation(settings.seoLanguage)
                            .pipe(map((isLoaded) => (isLoaded ? settings.gtmId : undefined)));
                    }
                    return of(settings.gtmId);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((gtmId) => {
                if (gtmId) {
                    this.trackingService.initGtm(gtmId);
                }
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public getCulture(): Observable<string> {
        return this.settingsRs$.pipe(
            map((settings) => settings.culture),
            takeUntil(this.unsubscribe)
        );
    }

    public initSettings(culture: string, preview = false): void {
        const previewContext = preview ? this.preview.getPreviewContextSnapshot() : null;
        if (this._currentCulture === culture && !previewContext) {
            return;
        }

        if (previewContext) {
            culture = previewContext.culture;
            this._currentCulture = undefined;
        } else {
            this._currentCulture = culture;
        }

        const params: any = { culture };
        const headers: any = {};
        let withCredentials = false;

        if (previewContext) {
            params.preview = '1';

            headers['Authorization'] = `${previewContext?.token}`;
            withCredentials = true;
        }

        this.http
            .get<ISettingsResponse>('/api/settings', { params, headers, withCredentials })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                this.settingsRs$.next(res);
            });
    }

    get() {
        return this.settingsRs$.asObservable();
    }

    getMenuSpot(): Observable<IMenuSpot | undefined> {
        return this.get().pipe(
            map((settings) => settings.menuSpot),
            take(1)
        );
    }

    getActionBar(): Observable<IActionBar | undefined> {
        return this.get().pipe(
            map((settings) => settings.actionBar),
            take(1)
        );
    }

    private setBrowserLanguage(languageKey: string): void {
        if (this._document?.documentElement) {
            this._document.documentElement.lang = languageKey;
        }
    }

    private setDocumentSiteTheme(themeName: string): void {
        if (themeName && this._document?.body?.className.indexOf(themeName) === -1) {
            this._document.body.classList.add(themeName);
            if (this._document.head) {
                const link = this._document.createElement('link');
                link.rel = 'stylesheet';
                link.href = `${themeName}.css?cb=${this._cacheBustingId}`;
                this._document.head.appendChild(link);
            }
        }
    }

    private setDocumentBuildId(): void {
        this.meta.addTag({ name: 'x-build-id', content: this._cacheBustingId });
    }

    private setDocumentFavicon(favicons: IImage[]): void {
        if (favicons.length && this._document?.head) {
            for (const favicon of favicons) {
                const link = this._document.createElement('link');

                switch (favicon.extension) {
                    case 'svg':
                        link.rel = 'icon';
                        link.type = 'image/svg+xml';
                        break;

                    default:
                        link.rel = favicons.length > 1 ? 'alternate' : 'icon';
                        link.type = 'image/x-icon';
                        break;
                }

                link.href = favicon.url;
                this._document.head.appendChild(link);
            }
        }
    }

    private setFacebookMetaTag(content?: string): void {
        if (content) {
            this.meta.addTag({
                name: 'facebook-domain-verification',
                content,
            });
        }
    }
}
