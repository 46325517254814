import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DocModifiedSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-doc-modified-spot',
    template: `
        <div class="doc-modified-spot" [class.is-narrow]="allowNarrowContent" *ngIf="data" ncgLoadIn>
            <div class="columns is-centered">
                <div
                    class="column"
                    [ngClass]="{
                        'is-12': allowNarrowContent,
                        'is-8': !allowNarrowContent,
                        'has-text-left': data.alignment === 'Left',
                        'has-text-centered': data.alignment === 'Center',
                        'has-text-right': data.alignment === 'Right'
                    }"
                >
                    <time [attr.datetime]="data.date">
                        {{ 'doc_modified_spot.lastmodified' | translate }}
                        {{ data.date | date: 'MM.LL.yyyy' }}
                    </time>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocModifiedSpotComponent extends SpotBaseDirective {
    static ref = 'docmodified';

    @Input()
    data: DocModifiedSpot;
}
