import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, QueryParamsHandling, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { getIdByByPrice } from '../../configurator/utils/by-price.util';

import { ScrollService } from '../../core/scroll.service';
import { SettingsService } from '../../core/settings.service';
import { DialogService } from '../../utils/dialog/dialog.service';
import { navigateTab, setError, setModel, setStep, setTab, setTabState, softReset } from './configurator.actions';
import { ConfiguratorFacade } from './configurator.facade';
import { ConfiguratorTab, ConfiguratorQueryParams } from '../../configurator/configurator';
import { configuratorParamsWhiteList, multiParamSeparator } from '../../configurator/configurator-settings';

@Injectable()
export class ConfiguratorEffects implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly actions$: Actions,
        private readonly configuratorFacade: ConfiguratorFacade,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly scrollService: ScrollService,
        private readonly dialogService: DialogService,
        private readonly settingsService: SettingsService
    ) {
        // Listen for variant change, to validate the state.
        // Filter on model, so we only do it when a model is selected.
        this.configuratorFacade.variant$
            .pipe(
                concatLatestFrom(() => [this.configuratorFacade.model$, this.configuratorFacade.routerQueryParams$]),
                filter(([, model]) => !!model),
                map(([variant, , queryParams]) => ({ variant, queryParams })),
                takeUntil(this.unsubscribe)
            )
            .subscribe(({ variant, queryParams }) => {
                if (queryParams.finalized) {
                    return;
                }
                // If variant becomes undefined, its because trim and powertrain no longer matches.
                // Set variant by combining trim and powertrain. After setting is done, this method will fire again and validate the rest
                if (!variant) {
                    this.validateState({ tab: 'trim' });
                    window.requestAnimationFrame(() => {
                        this.validateState({ tab: 'powertrain' });
                    });
                } else {
                    // Grab all params but trim and powertrain
                    const { exterior, interior, partner_products, accessories, optionals } = queryParams;
                    const paramKeys = Object.keys({ exterior, interior, partner_products, accessories, optionals });
                    // Validate all other states that rely on variant
                    configuratorParamsWhiteList
                        .filter((param) => paramKeys.includes(param))
                        .forEach((param) => {
                            if (param === 'finalized') {
                                return;
                            }
                            // Use RAF to add sequentially to the event loop. URL change might experience race conditions if not.
                            window.requestAnimationFrame(() => {
                                this.validateState({ tab: param, selectDefault: param === 'exterior' });
                            });
                        });
                }
            });
    }

    // The first effect. Happens once a model from elastic is found
    onModelSet$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(setModel),
                concatLatestFrom(() => [this.configuratorFacade.routerQueryParams$]),
                map(([, queryParams]) => {
                    if (queryParams.finalized) {
                        this.configuratorFacade.dispatch(setStep({ step: 'summary' }));
                        return;
                    }

                    // Sequentially set trim and powertrain
                    // Trim + powertrain = variant, which is needed for everything.
                    // After variant exists, the variant$ subscription handles further validation
                    this.validateState({ tab: 'trim' });
                    window.requestAnimationFrame(() => {
                        this.validateState({ tab: 'powertrain' });
                    });
                })
            ),
        { dispatch: false }
    );

    onSetTab$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(setTab),
                map(({ tab }) => {
                    this.handleStickyScroll();
                    this.validateState({ tab });
                })
            ),
        { dispatch: false }
    );

    onNavigateTab$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(navigateTab),
                concatLatestFrom(() => [this.configuratorFacade.tab$, this.configuratorFacade.step$, this.configuratorFacade.routerQueryParams$]),
                map(([, tab, step, queryParams]) => {
                    if (step === 'configuration') {
                        this.handleStickyScroll();
                        this.validateState({ tab });
                        return;
                    }
                    // Validate params
                    if (step === 'summary') {
                        this.validateAll(!!queryParams.finalized);
                    }
                    window.requestAnimationFrame(() => {
                        this.scrollService.scrollToPosition({ top: 0, behavior: 'auto' });
                    });
                })
            ),
        { dispatch: false }
    );

    onSetStep$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(setStep),
                concatLatestFrom(() => [this.configuratorFacade.routerQueryParams$]),
                map(([{ step }, queryParams]) => {
                    // Validate params
                    if (step === 'summary') {
                        this.validateAll(!!queryParams.finalized);
                    }
                    window.requestAnimationFrame(() => {
                        this.scrollService.scrollToPosition({ top: 0, behavior: 'auto' });
                    });
                })
            ),
        { dispatch: false }
    );

    onSoftReset$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(softReset),
                concatLatestFrom(() => [this.configuratorFacade.model$]),
                map(([, model]) => {
                    const firstTrim = model?.trims?.[0];
                    this.addRouterParam(
                        {
                            trim: firstTrim?.id || undefined,
                            powertrain: firstTrim?.variants?.[0].powerTrainId || undefined,
                            exterior: firstTrim?.variants?.[0].commercialColourOptionExteriorIds?.[0],
                        },
                        ''
                    );
                })
            ),
        { dispatch: false }
    );

    onError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(setError),
                map(({ error }) => error?.type),
                distinctUntilChanged((prev, curr) => prev === curr),
                map((type) => {
                    switch (type) {
                        case 'incomplete-data': {
                            this.dialogService
                                .openDialog({
                                    data: {
                                        text: 'configurator.general_error',
                                        textIsSafe: false,
                                        disableCloseBtn: true,
                                    },
                                })
                                .then((ref) => {
                                    combineLatest([this.configuratorFacade.url$, ref.afterClose()])
                                        .pipe(take(1), takeUntil(this.unsubscribe))
                                        .subscribe(([url]) => {
                                            const tree = this.router.parseUrl(url);
                                            const segmentGroup = tree.root.children[PRIMARY_OUTLET];
                                            const segments = segmentGroup.segments;
                                            const parentSegment = segments[segments.length - 2];
                                            // Navigate back to the overview of models.
                                            // ActivatedPath just shows root, so we get path from store.
                                            this.router.navigate([`/${parentSegment.path}`], { relativeTo: this.route });
                                            this.configuratorFacade.dispatch(setError({}));
                                        });
                                })
                                .catch(console.error);
                            break;
                        }
                        case 'configuration-not-available': {
                            this.dialogService
                                .openDialog({
                                    data: {
                                        text: 'configurator.error_unavailable_config',
                                        textIsSafe: false,
                                        disableCloseBtn: true,
                                    },
                                })
                                .then((ref) => {
                                    ref.afterClose()
                                        .pipe(take(1), takeUntil(this.unsubscribe))
                                        .subscribe(() => {
                                            this.configuratorFacade.dispatch(setError({}));
                                        });
                                })
                                .catch(console.error);
                            break;
                        }
                    }
                })
            ),
        { dispatch: false }
    );

    private validateAll(showConfigError: boolean): void {
        configuratorParamsWhiteList.forEach((param) => {
            // Use RAF to add sequentially to the event loop. URL change might experience race conditions if not.
            window.requestAnimationFrame(() => {
                // Remove finalized from params
                if (param === 'finalized') {
                    this.addRouterParam({ finalized: undefined });
                    return;
                }
                this.validateState({ tab: param, showConfigError });
            });
        });
    }

    private validateState({
        tab,
        selectDefault = true,
        showConfigError = false,
    }: {
        tab: ConfiguratorTab;
        selectDefault?: boolean;
        showConfigError?: boolean;
    }): void {
        combineLatest([
            this.configuratorFacade.routerQueryParams$,
            this.configuratorFacade.model$,
            this.configuratorFacade.trim$,
            this.configuratorFacade.variant$,
            this.configuratorFacade.tabStates$,
            this.settingsService.get().pipe(map((s) => s?.currency ?? 'DKK')),
        ])
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe(([params, model, selectedTrim, selectedVariant, tabStates, currency]) => {
                const informOfTabChange = () => {
                    if (tabStates[tab] === 'visited') {
                        this.configuratorFacade.dispatch(setTabState({ tab, tabState: 'affected' }));
                    }
                };
                const informOfChangesToConfig = () => {
                    if (showConfigError) {
                        this.configuratorFacade.dispatch(setError({ error: { type: 'configuration-not-available' } }));
                    }
                };

                switch (tab) {
                    case 'trim': {
                        // To enable a non-step navigation, we must always combine trim and powertrain to get a variant.
                        if (!model?.trims?.length) {
                            this.configuratorFacade.dispatch(setError({ error: { type: 'incomplete-data' } }));
                            break;
                        }
                        const validTrim = model.trims?.find((trim) => trim.id === params.trim);
                        if (!validTrim || (!params.trim && selectDefault)) {
                            const firstTrimId = model.trims[0].id;
                            if (firstTrimId) {
                                this.addRouterParam({ trim: firstTrimId });
                            }
                            informOfChangesToConfig();
                        }
                        break;
                    }
                    case 'powertrain': {
                        const firstPowerTrainIdInTrim = selectedTrim?.variants?.[0].powerTrainId;
                        if (!model?.powerTrains || !firstPowerTrainIdInTrim) {
                            this.configuratorFacade.dispatch(setError({ error: { type: 'incomplete-data' } }));
                            break;
                        }
                        const validPowerTrain = selectedTrim.variants?.find(({ powerTrainId }) => powerTrainId === params.powertrain);
                        if (!validPowerTrain) {
                            if (selectDefault) {
                                this.addRouterParam({ powertrain: firstPowerTrainIdInTrim });
                                informOfChangesToConfig();
                            }
                            informOfTabChange();
                        }
                        break;
                    }
                    case 'exterior': {
                        const cheapestExteriorInVariant = getIdByByPrice(
                            selectedVariant?.commercialColourOptionExteriorIds,
                            model?.commercialColourOptionsExterior,
                            `CommercialColourOptionRetailSellingPrice`,
                            currency,
                            'lowest'
                        );

                        if (!model?.commercialColourOptionsExterior || !cheapestExteriorInVariant) {
                            this.configuratorFacade.dispatch(setError({ error: { type: 'incomplete-data' } }));
                            break;
                        }

                        const validExterior = selectedVariant?.commercialColourOptionExteriorIds?.find((id) => id === params.exterior);
                        if (!validExterior) {
                            if (selectDefault) {
                                this.addRouterParam({ exterior: cheapestExteriorInVariant });
                                informOfChangesToConfig();
                            }
                            informOfTabChange();
                        }
                        break;
                    }
                    case 'interior': {
                        const cheapestInteriorInVariant = getIdByByPrice(
                            selectedVariant?.commercialColourOptionInteriorIds,
                            model?.commercialColourOptionsInterior,
                            `CommercialColourOptionRetailSellingPrice`,
                            currency,
                            'lowest'
                        );

                        if (!model?.commercialColourOptionsInterior || !cheapestInteriorInVariant) {
                            this.configuratorFacade.dispatch(setError({ error: { type: 'incomplete-data' } }));
                            break;
                        }

                        const validInterior = selectedVariant?.commercialColourOptionInteriorIds?.find((id) => id === params.interior);
                        if (!validInterior) {
                            if (selectDefault) {
                                this.addRouterParam({ interior: cheapestInteriorInVariant });
                                informOfChangesToConfig();
                            }
                            informOfTabChange();
                        }
                        break;
                    }
                    case 'optionals': {
                        if (!params.optionals) {
                            break;
                        }
                        const optionArray = params.optionals.split(multiParamSeparator).filter((x) => x);
                        const validOptions = selectedVariant?.optionalOptionIds?.filter((id) => optionArray.includes(id)) ?? [];
                        if (validOptions.length < optionArray.length) {
                            this.addRouterParam({ optionals: validOptions.join(multiParamSeparator) || undefined });
                            informOfTabChange();
                            informOfChangesToConfig();
                        }
                        break;
                    }
                    case 'accessories': {
                        if (!params.accessories) {
                            break;
                        }
                        const accessoryArray = params.accessories.split(multiParamSeparator).filter((x) => x);
                        const ids = selectedVariant?.accessoryIds ?? selectedTrim?.accessoryIds ?? model?.accessoryIds;
                        const validAccessories = ids?.filter((id) => accessoryArray.includes(id)) ?? [];
                        if (validAccessories.length < accessoryArray.length) {
                            this.addRouterParam({ accessories: validAccessories.join(multiParamSeparator) || undefined });
                            informOfTabChange();
                            informOfChangesToConfig();
                        }
                        break;
                    }
                    case 'partner_products': {
                        if (!params.partner_products) {
                            break;
                        }
                        const partnerProductArray = params.partner_products.split(multiParamSeparator).filter((x) => x);
                        const validPartnerProducts = selectedTrim?.partnerProductIds?.filter((id) => partnerProductArray.includes(id)) ?? [];
                        if (validPartnerProducts.length < partnerProductArray.length) {
                            this.addRouterParam({ partner_products: validPartnerProducts.join(multiParamSeparator) || undefined });
                            informOfTabChange();
                            informOfChangesToConfig();
                        }
                        break;
                    }
                }
            });
    }

    // If the user has scrolled below the navigation bar, then restore scroll to that point.
    // The offset is given by the 360 viewer
    private handleStickyScroll(): void {
        window.requestAnimationFrame(() => {
            const imageHeight = document.querySelector<HTMLElement>('ncg-configurator-360')?.offsetHeight;
            const currentScroll = this.scrollService.scrollPosition;
            if (imageHeight && currentScroll > imageHeight) {
                this.scrollService.scrollToPosition({ top: imageHeight, behavior: 'auto' });
            }
        });
    }

    private addRouterParam(params: ConfiguratorQueryParams, paramHandling: QueryParamsHandling = 'merge'): void {
        this.router.navigate([], { relativeTo: this.route, queryParams: params, replaceUrl: true, queryParamsHandling: paramHandling });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
