import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { ICarSearchNode, IImage } from '@ncg/data';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { SettingsService } from '../core/settings.service';
import { searchAnimation } from '../utils/animations/filter.animation';
import { ImageUrl } from '../utils/helpers/image-helper';

@Component({
    selector: 'ncg-search-section-used',
    template: `
        <div [@searchList]>
            <div class="search-section">
                <header class="search-section__header">
                    <h5 class="search-section__header--title">
                        <ng-container *ngIf="isAllMakes">{{ 'search.section_title_cars' | translate: { count: total } }}</ng-container>
                        <ng-container *ngIf="!isAllMakes">{{ 'search.section_title' | translate: { make: make, count: total } }}</ng-container>
                    </h5>
                </header>

                <a
                    #searchItem
                    [routerLink]="result.url"
                    class="animate-scale__link search-item has-image"
                    *ngFor="let result of results"
                    [ngClass]="{ 'is-active': currentItem && currentItem === result.url }"
                >
                    <div class="search-item__image is-ratio-model" *ngIf="result?.image || fallbackPictureSrc">
                        <ng-container *ngIf="result && result.image; else fallbackImageTmpl">
                            <img class="is-ratio-model animate-scale is-small-scale" [src]="result.image | productImage: 'xSmall'" />
                        </ng-container>
                        <ng-template #fallbackImageTmpl>
                            <img class="is-ratio-model animate-scale is-small-scale" [src]="fallbackPictureSrc" />
                        </ng-template>
                    </div>
                    <div class="search-item__text">
                        <div class="search-item__text--title" *ngIf="result.title">{{ result.title }}</div>
                        <div class="search-item__text--description">
                            <ng-container *ngIf="result.cashPrice; else noPriceTmpl">{{ result.cashPrice }}</ng-container>
                            <ng-template #noPriceTmpl>{{ 'search.no_price_text' | translate }}</ng-template>
                        </div>
                    </div>
                </a>
            </div>
            <a *ngIf="linkToUsedCars" class="search-section__link is-block" [routerLink]="linkToUsedCars" [queryParams]="queryParams">
                <ng-container *ngIf="!isAllMakes">{{ 'search.see_all_used' | translate }} {{ make }}</ng-container>
                <ng-container *ngIf="isAllMakes">{{ 'search.see_all_used_cars' | translate }}</ng-container>
            </a>
        </div>
    `,
    animations: [searchAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchSectionUsedComponent implements OnInit, OnChanges, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    @ViewChildren('searchItem') searchItems: QueryList<ElementRef>;
    @Input() get make() {
        return this._make;
    }
    set make(value: string) {
        if (value.trim().toLowerCase() !== 'all') {
            this.queryParams = { ...this.queryParams, 'make.keyword': value };
        } else {
            this.isAllMakes = true;
        }
        this._make = value;
    }
    private _make: string;
    @Input() count: string | number;
    @Input() total: number;
    @Input() currentItem?: string | number;
    @Input() results: readonly ICarSearchNode[];
    @Input() get query() {
        return this._query;
    }
    set query(query: string) {
        this.queryParams = { ...this.queryParams, q: query };
        this._query = query;
    }
    private _query: string;
    queryParams = {};
    isAllMakes = false;
    fallbackPictureSrc?: string;
    linkToUsedCars?: string;

    constructor(private readonly settingsService: SettingsService) {}

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit(): void {
        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.linkToUsedCars = settings.usedCarsLink.url;
                this.setFallbackImage(settings.productFallbackImage);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentItem) {
            this.setFocus();
        }
    }

    setFallbackImage(image?: IImage) {
        if (image) {
            this.fallbackPictureSrc = ImageUrl(image, { width: 180, height: 120, mode: 'crop' });
        }
    }

    setFocus() {
        if (this.searchItems) {
            this.searchItems.forEach((anchor: ElementRef) => {
                if (this.currentItem) {
                    if (this.currentItem === anchor.nativeElement.getAttribute('href')) {
                        anchor.nativeElement.focus();
                    }
                }
            });
        }
    }
}
