import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IWidePageResponse } from '@ncg/data';

@Component({
    selector: 'ncg-wide-page',
    styles: [
        `
            .wide-page ::ng-deep .section {
                padding: 0;
            }

            .wide-page ::ng-deep .container {
                max-width: none;
            }
        `,
    ],
    template: `<div class="content wide-page">
        <ncg-breadcrumb [data]="data"></ncg-breadcrumb>
        <ncg-umb-grid [grid]="data?.grid"></ncg-umb-grid>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidePageComponent {
    static ref = 'widePage';
    @Input() data: IWidePageResponse;
}
