import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, switchMap } from 'rxjs/operators';

import { SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root',
})
export class TranslateLoaderService implements TranslateLoader {
    constructor(
        private readonly http: HttpClient,
        private readonly settingsService: SettingsService
    ) {}

    public getTranslation(): Observable<any> {
        return this.settingsService.get().pipe(
            distinctUntilKeyChanged('culture'),
            switchMap((settings) => {
                const culture = settings.culture;
                return this.http.get('/api/translations', { params: { culture } });
            })
        );
    }
}
