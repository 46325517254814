import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OfferFormSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-offer-form-spot',
    template: `
        <div class="columns is-centered" ncgLoadIn *ngIf="data.model || data.models?.length">
            <ncg-offer-form
                *ngIf="data"
                class="column"
                [class.is-8]="!allowNarrowContent"
                [model]="data.model"
                [models]="data.models"
                [redirect]="data.successRedirect"
                [isCashAllowed]="data.isCashAllowed"
                [isLoanAllowed]="data.isLoanAllowed"
                [isLeasingAllowed]="data.isLeasingAllowed"
                [campaign]="data.campaign"
                [listId]="data.listId"
                [successMessage]="data.successMessage"
            ></ncg-offer-form>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferFormSpotComponent extends SpotBaseDirective {
    static ref = 'offerform';

    @Input() data: OfferFormSpot;
}
