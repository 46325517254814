import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { TranslateModule } from '@ngx-translate/core';
import { LazyContentModule } from '../$lazy-content/lazy-content.module';
import { AccordionComponent } from '../accordion/accordion.component';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { ButtonModule } from '../button/button.module';
import { ConfiguratorHeaderComponent } from '../configurator';
import { DynamicRoutingModule } from '../core/routing-utils/dynamic-routing.module';
import { DealerModule } from '../dealer/dealer.module';
import { ProductsModule } from '../products/products.module';
import { SpinnerCarComponent } from '../spinner-car/spinner-car.component';
import { SpotsUiModule } from '../spots/spots-ui.module';
import { UmbracoGridModule } from '../umbraco-grid/umbraco-grid.module';
import { UtilsModule } from '../utils/utils.module';
import { ModelPageHeaderInnerComponent } from './model-page/model-page-header-inner.component';
import { ModelPageHeaderComponent } from './model-page/model-page-header.component';
import { ModelPageNavComponent } from './model-page/model-page-nav.component';
import { pageComponents } from './page-components';
import { PageComponent } from './page.component';

@NgModule({
    declarations: [...pageComponents, PageComponent, ModelPageNavComponent, ModelPageHeaderComponent, ModelPageHeaderInnerComponent],
    exports: [PageComponent],
    imports: [
        BreadcrumbModule,
        CommonModule,
        DynamicRoutingModule,
        UmbracoGridModule,
        TranslateModule.forChild(),
        RouterModule,
        NgxJsonLdModule,
        SpotsUiModule,
        UtilsModule,
        ProductsModule,
        DealerModule,
        ButtonModule,
        ConfiguratorHeaderComponent,
        LazyContentModule,
        AccordionComponent,
        SpinnerCarComponent,
    ],
})
export class PageModule {
    static forRoot(): ModuleWithProviders<PageModule> {
        return {
            ngModule: PageModule,
        };
    }
}
