import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { BreadcrumbComponent } from './breadcrumb.component';

@NgModule({
    imports: [CommonModule, IconSpriteModule, NgxJsonLdModule, RouterModule],
    declarations: [BreadcrumbComponent],
    exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
