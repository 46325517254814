import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ILink, IMetaLink, ISiteLanguage } from '@ncg/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SettingsService } from '../../core/settings.service';
import { SpotsConfig } from '../../spots/spots-config';
import { NavigationService } from '../navigation.service';

@Component({
    selector: 'ncg-meta-menu',
    template: `
        <!-- Meta menu in mobile navigation overlay -->
        <ncg-meta-menu-overlay
            *ngIf="metaLinks.length && isInMobile"
            [metaLinks]="metaLinks"
            [isSearchEnabled]="isSearchEnabled"
        ></ncg-meta-menu-overlay>

        <!-- Meta menu navigation-->
        <ncg-meta-menu-links
            *ngIf="!isInMobile && (metaLinks.length || isSearchEnabled || hasUsedCars || dealerLink?.url)"
            [ngClass]="{ 'is-hidden-touch': !spotsConfig.isMetaLinksVisibleMobile }"
            class="meta-menu level-right"
            [metaLinks]="metaLinks"
            [isSearchEnabled]="isSearchEnabled"
            [hasUsedCars]="hasUsedCars"
            [dealerLink]="dealerLink"
        ></ncg-meta-menu-links>

        <!-- Touch - outside mobile navigation overlay -->
        <div *ngIf="!isInMobile && !spotsConfig.isMetaLinksVisibleMobile && dealerLink?.url" class="navbar is-hidden-desktop" role="navigation">
            <ncg-dealer [dealerLink]="dealerLink" [isNameVisible]="true"></ncg-dealer>
        </div>

        <ncg-language-selector
            *ngIf="showLanguageSelector && languageLinks"
            [languageLinks]="languageLinks"
            [siteLanguages]="siteLanguages"
        ></ncg-language-selector>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaMenuComponent implements OnInit, OnDestroy {
    @Input() isInMobile = false;

    metaLinks: IMetaLink[] = [];
    isSearchEnabled = false;
    hasUsedCars = false;
    languageLinks?: Record<string, string>;
    siteLanguages: ISiteLanguage[];
    showLanguageSelector = false;
    dealerLink?: ILink;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly settingsService: SettingsService,
        private readonly navigationService: NavigationService,
        private readonly cd: ChangeDetectorRef,
        @Inject(SpotsConfig) public spotsConfig: SpotsConfig
    ) {}

    ngOnInit() {
        this.navigationService.languageLinks$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.languageLinks = data;
            this.cd.markForCheck();
        });

        this.settingsService
            .get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.metaLinks = settings.metaLinks;
                this.showLanguageSelector = settings.showLanguageSelector;
                this.hasUsedCars = settings.featureToggles.hasUsedCars && settings.metaMenu.showUsedCarsLink;
                this.isSearchEnabled = settings.featureToggles.isSearchEnabled;
                this.dealerLink = settings.metaMenu.showDealershipsLink && settings.dealershipsLink ? settings.dealershipsLink : undefined;
                this.siteLanguages = settings.siteLanguages;
                this.cd.markForCheck();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
