import { Component, Input } from '@angular/core';
import { DualImageSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-dual-image',
    template: `
        <div class="dual-image-spot" *ngIf="data.primary && data.secondary" [ngClass]="{ 'dual-image-spot--top-left': data.altLayout }" ncgLoadIn>
            <div class="dual-image-spot__wrapper">
                <div class="dual-image-spot__primary is-ratio-hd">
                    <img
                        [alt]="data.primary.altText || ''"
                        [src]="data.primary | srcset: { retina: false, imageOptions: { width: 1120 } }"
                        [srcset]="data.primary | srcset: { widths: [270, 570, 800, 1120, 1550] }"
                        sizes="(min-width: 1024px) 80vw, (min-width: 768px) 75vw, 100vw"
                        loading="lazy"
                        ncgImageLoad
                    />
                </div>
                <div class="dual-image-spot__secondary is-ratio-4-3">
                    <img
                        [alt]="data.secondary.altText || ''"
                        [src]="data.secondary | imageUrl: { width: 540, heightratio: smallAspectRatio, mode: 'crop' }"
                        [srcset]="data.secondary | srcset: { widths: [190, 290, 380, 540, 950], imageOptions: { heightratio: smallAspectRatio } }"
                        sizes="(min-width: 768px) 40vw, 70vw"
                        loading="lazy"
                        ncgImageLoad
                    />
                </div>
            </div>
        </div>
    `,
})
export class DualImageSpotComponent extends SpotBaseDirective {
    static ref = 'dualimage';
    @Input() data: DualImageSpot;
    smallAspectRatio = 0.75; // 4:3
}
