import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FilteredCarsSpot } from '@ncg/data';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { SettingsService } from '../../core/settings.service';
import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-featured-cars-spot',
    template: `
        <div class="featured-cars" ncgLoadIn>
            <ng-container #vc></ng-container>
            <br />
            <div class="featured-cars__actions has-text-centered">
                <ng-container *ngIf="noItemsFound; else cta">
                    <p>{{ 'filtered_cars_spot.empty_text' | translate }}</p>
                    <a *ngIf="usedCarsLink" [routerLink]="usedCarsLink" class="button is-primary">
                        {{ 'filtered_cars_spot.empty_button_text' | translate }}
                    </a>
                </ng-container>
                <ng-template #cta>
                    <a *ngIf="usedCarsLink" [routerLink]="usedCarsLink" [queryParams]="usedCarsLinkQueryParams" class="button is-primary">
                        {{ data.linkText }}
                    </a>
                </ng-template>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturedCarsSpotComponent extends SpotBaseDirective implements OnInit, OnDestroy {
    static ref = 'filteredcars';

    private readonly unsubscribe = new Subject<void>();

    @Input() data: FilteredCarsSpot;
    @ViewChild('vc', { read: ViewContainerRef, static: true }) viewContainer: ViewContainerRef;
    usedCarsLink: string;
    usedCarsLinkQueryParams: Record<string, any>;
    noItemsFound = false;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly settingsService: SettingsService
    ) {
        super();
    }

    async ngOnInit() {
        const { ProductListComponent } = await import('../../products/product-list/product-list.component');
        const componentRef = this.viewContainer.createComponent(ProductListComponent);
        componentRef.instance.filterQuery = this.data.filterQuery;
        componentRef.instance.takeAmount = this.data.amount;
        componentRef.instance.skeletonsAmount = this.data.amount;
        componentRef.instance.layout = {
            view: 'grid',
            pageLayout: 'filterInSidepanel',
        };
        componentRef.instance.shouldGetItems = true;
        this.cd.markForCheck();

        this.setLink();

        componentRef.instance.stateChanged
            .pipe(
                filter((x) => x.key === 'noItemsFound'),
                takeUntil(this.unsubscribe)
            )
            .subscribe((noItemsFound) => {
                this.noItemsFound = noItemsFound.value;
                this.usedCarsLinkQueryParams = this.data.filterQuery;
                this.cd.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private setLink() {
        if (!this.data.linkText) {
            return;
        }

        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.usedCarsLink = settings.usedCarsLink.url;
                this.cd.markForCheck();
            });
    }
}
