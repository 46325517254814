import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnchorSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-anchor-spot',
    styleUrls: ['./anchor-spot.component.scss'],
    template: `<a *ngIf="data.anchorId" class="anchor-spot">
        <span [id]="safeId(data.anchorId)"></span>
    </a>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorSpotComponent extends SpotBaseDirective {
    static ref = 'anchor';
    @Input() data: AnchorSpot;

    safeId = (id: string) => id.replace(/\s/g, '');
}
