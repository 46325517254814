import { AnimationEvent } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { getBuildIdQuery } from '@ncg/data';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { Subject } from 'rxjs';
import { ConfiguratorFacade } from '../../../../+state/configurator/configurator.facade';
import { ImpactOverlayRef } from '../../../../overlay/overlay-ref';
import { slideInBottom } from '../../../../utils/animations/slide.animations';
import { ConfiguratorSummaryComponent } from '../../../summary/configurator-summary/configurator-summary.component';

@Component({
    selector: 'ncg-configurator-footer-summary',
    template: `
        <aside
            class="footer-summary"
            [@slideInBottom]="animationState"
            (@slideInBottom.start)="onAnimationStart($event)"
            (@slideInBottom.done)="onAnimationDone($event)"
        >
            <div class="footer-content">
                <button class="close-summary" (click)="closeDialog()">
                    <svg-icon-sprite
                        [width]="'30px'"
                        [height]="'30px'"
                        [src]="'chevron-down'"
                        [viewBox]="'0 0 30 30'"
                        aria-hidden="true"
                        class="icon"
                    ></svg-icon-sprite>
                </button>
                <ncg-configurator-summary (editClick)="closeDialog()"></ncg-configurator-summary>
            </div>
        </aside>
    `,
    styleUrls: ['./configurator-footer-summary.component.scss'],
    standalone: true,
    imports: [CommonModule, IconSpriteModule, TranslateModule, ConfiguratorSummaryComponent],
    animations: [slideInBottom],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorFooterSummaryComponent implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    public animationStateChanged = new EventEmitter<AnimationEvent>();
    public animationState: 'void' | 'enter' | 'leave' = 'enter';
    public buildIdQuery = getBuildIdQuery();

    constructor(
        private readonly dialogRef: ImpactOverlayRef,
        private readonly cd: ChangeDetectorRef,
        public readonly configuratorFacade: ConfiguratorFacade
    ) {}

    @HostListener('window:keyup.esc', ['$event'])
    public closeDialog() {
        this.dialogRef.close();
    }

    public onAnimationStart(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    public onAnimationDone(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    public startExitAnimation() {
        this.animationState = 'leave';
        this.cd.detectChanges();
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
