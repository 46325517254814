import { Input, Directive } from '@angular/core';
import { IGridIndexes } from '@ncg/data';

@Directive()
export class SpotBaseDirective {
    @Input()
    indexes: IGridIndexes;

    @Input()
    columns: number;

    @Input()
    allowNarrowContent = false;

    @Input()
    isFirstSpot = false;
}
