import { DataEntityWithResources, DataField, DataListDataListEntityWithResources, DataListField, VariantViewModel } from '@ncg/data';
import { fieldById } from './field-by-id.util';
import { isDataList, isDataListEntityWithResources } from './is-data-list.util';

// Get the cheapest interior/exterior in the variant. Needed because the first one is not the cheapest. Really exposes the challenges of the data model.
export const getIdByByPrice = (
    validIds: string[] | null = [],
    dataForIds: Record<string, DataEntityWithResources> | Record<string, DataListDataListEntityWithResources> = {},
    pricePropertyWithoutCurrency: PriceProperty = 'CommercialColourOptionRetailSellingPrice',
    currency: string = 'DKK',
    getBy: 'lowest' | 'highest' = 'lowest'
) => getIdsSortedByPrice(validIds, dataForIds, pricePropertyWithoutCurrency, currency, getBy === 'lowest' ? 'asc' : 'desc')[0]?.id;

export const getIdsSortedByPrice = (
    validIds: string[] | null = [],
    dataForIds: Record<string, DataEntityWithResources> | Record<string, DataListDataListEntityWithResources> = {},
    pricePropertyWithoutCurrency: PriceProperty = 'CommercialColourOptionRetailSellingPrice',
    currency: string = 'DKK',
    orderBy: 'asc' | 'desc' = 'asc'
) => {
    const idsWithData = Object.entries(dataForIds).filter(([id]) => validIds?.includes(id));
    const idsWithPrice: IdWithPrice[] = idsWithData.map(([id, option]) => {
        const priceProperty = option.fields?.find(
            (field: DataField | DataListField) => field.fieldTypeId === `${pricePropertyWithoutCurrency}${currency}`
        )?.data;
        // If there are multiple prices, we take the first one.
        return {
            id,
            price: priceProperty ? priceProperty.value ?? priceProperty[0]?.value : 0,
        };
    });

    if (orderBy === 'desc') {
        return idsWithPrice.sort((a, b) => b.price - a.price);
    }
    return idsWithPrice.sort((a, b) => a.price - b.price);
};

export const getEntitiesSortedByPrice = <T extends DataEntities>(
    entities: T,
    pricePropertyWithoutCurrency: PriceProperty = 'CommercialColourOptionRetailSellingPrice',
    currency: string = 'DKK',
    orderBy: 'asc' | 'desc' = 'asc'
) =>
    entities.sort((a, b) => {
        const priceProperty = `${pricePropertyWithoutCurrency}${currency}`;
        const priceFieldA = isDataListEntityWithResources(a)
            ? fieldById<DataListField>(a.fields, priceProperty)
            : fieldById<DataField>(a.fields, priceProperty);
        const priceFieldB = isDataListEntityWithResources(b)
            ? fieldById<DataListField>(b.fields, priceProperty)
            : fieldById<DataField>(b.fields, priceProperty);

        // If there are multiple prices, we take the first one.
        const priceA = (isDataList(priceFieldA) ? priceFieldA.data?.[0].value : priceFieldA?.data?.value) ?? 0;
        const priceB = (isDataList(priceFieldB) ? priceFieldB.data?.[0].value : priceFieldB?.data?.value) ?? 0;

        if (orderBy === 'desc') {
            return priceB - priceA;
        }
        return priceA - priceB;
    });

export const getPriceDifferenceComparedToCheapest = (variants: VariantViewModel[], fieldName: string, variantTotalPrice: number): number => {
    const allPrices = variants.map((variant) => fieldById<DataField>(variant.fields, fieldName)?.data?.value);
    const cheapestVariantPrice = Math.min(...allPrices);
    return variantTotalPrice - cheapestVariantPrice;
};

interface IdWithPrice {
    price: number;
    id: string;
}

export type DataEntities = (DataEntityWithResources | DataListDataListEntityWithResources)[];

export type PriceProperty =
    | 'CommercialColourOptionRetailSellingPrice'
    | 'OptionRetailSellingPrice'
    | 'AccessoryRetailSellingPrice'
    | 'PartnerProductRetailSellingPrice';
