import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IStandardPageResponse } from '@ncg/data';
import { MetaService } from '../../core/meta.service';
import { firstValueFrom } from 'rxjs';

import { SettingsService } from '../../core/settings.service';

@Component({
    selector: 'ncg-standard-page',
    template: `
        <ncg-breadcrumb [data]="data"></ncg-breadcrumb>
        <ngx-json-ld *ngIf="schema" [json]="schema"></ngx-json-ld>
        <div class="content">
            <ncg-umb-grid [grid]="data?.grid"></ncg-umb-grid>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardPageComponent implements OnInit {
    static ref = 'standardPage';
    @Input() data: IStandardPageResponse;
    schema?: Record<string, unknown>;

    constructor(
        private readonly metaService: MetaService,
        private readonly settingsService: SettingsService
    ) {}

    ngOnInit(): void {
        this._setJsonLd();
    }

    private async _setJsonLd() {
        if (this.data.template !== 'standardCampaignPage') {
            return;
        }

        const settings$ = this.settingsService.get();
        const { companyName, logoImage } = await firstValueFrom(settings$);
        const currentUrl = this.metaService.getAbsoluteUrl();

        this.schema = {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            'headline': this.data.name,
            'datePublished': this.data.createDate,
            'dateModified': this.data.updateDate,
        };

        if (currentUrl) {
            this.schema.mainEntityOfPage = {
                '@type': 'WebPage',
                '@id': currentUrl,
            };
        }

        if (companyName) {
            this.schema.author = {
                '@type': 'Organization',
                'name': companyName,
            };

            if (logoImage?.url) {
                this.schema.publisher = {
                    '@type': 'Organization',
                    'name': companyName,
                    'logo': logoImage?.url,
                };
            }
        }

        if (this.data.image?.url) {
            this.schema.image = [this.data.image?.url];
        }
    }
}
