// Finds the video ID. Output is in [1].
const idRegex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;

export const getVideoId = (url: string): string | undefined => url.match(idRegex)?.[1];

export const createEmbedUrl = (videoUrl: string): string | undefined => {
    const videoId = getVideoId(videoUrl);
    if (!videoId) {
        return undefined;
    }
    const url = new URL(videoUrl);
    const params = new URLSearchParams(url.search);
    params.delete('v'); // Delete id from url if exists
    params.delete('vi'); // Delete id from url if exists
    return `${url.origin}/embed/${videoId}${params.toString() ? `?${params.toString()}` : ''}`;
};
