import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IGridSection } from '@ncg/data';

@Component({
    selector: 'ncg-umb-grid',
    template: `
        <ng-container *ngIf="grid?.length">
            <ncg-umb-section
                *ngFor="let section of grid; let i = index; let first = first"
                [section]="section"
                [sectionIndex]="i"
                [isFirstSection]="first"
                [allowNarrowContent]="allowNarrowContent"
            ></ncg-umb-section>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UmbGridComponent {
    @Input()
    grid?: IGridSection[];

    @Input()
    allowNarrowContent?: boolean;
}
