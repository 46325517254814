import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { InfoLabelComponent } from './info-label.component';

@NgModule({
    imports: [CommonModule, TranslateModule.forChild()],
    declarations: [InfoLabelComponent],
    exports: [InfoLabelComponent],
})
export class InfoLabelModule {}
