import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ncg-spinner',
    template: `
        <div
            *ngIf="active"
            [ngClass]="{ 'spinner-search': isSearch, 'is-small': isSmall, 'is-centered': isCentered }"
            class="spinner"
            @spinnerEnterLeave
        ></div>
    `,
    animations: [
        trigger('spinnerEnterLeave', [
            transition(':enter', [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))]),
            transition(':leave', [animate('0.2s', style({ opacity: 0 }))]),
        ]),
    ],
})
export class SpinnerComponent {
    @Input() active: boolean;
    @Input() isSearch?: boolean;
    @Input() isSmall?: boolean;
    @Input() set isCentered(data: boolean) {
        this.addClass = data ?? false;
    }
    @HostBinding('class.is-centered') addClass?: boolean;
}
