import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IImage } from '@ncg/data';

import { MenuImageAspectRatio, ModelsAspectRatio } from '../../utils/helpers/aspect-ratio';
import { ImageUrl } from '../../utils/helpers/image-helper';

@Component({
    selector: 'ncg-mega-menu-image',
    template: `
        <div class="mega-menu-image" *ngIf="image" [ngClass]="imageClass">
            <img
                class="mega-menu-image--image"
                [alt]="pictureAlt"
                [srcset]="pictureSrcset"
                [src]="pictureSrc"
                loading="lazy"
                *ngIf="pictureSrc && pictureSrcset"
            />
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegaMenuImageComponent implements OnChanges {
    @Input() image?: IImage;
    @Input() isModelsMenu = false;
    @Input() title = '';
    pictureSrcset: string = '';
    pictureSrc = '';
    pictureAlt = '';
    aspectRatio: number;
    imageClass = '';

    ngOnChanges(changes: SimpleChanges) {
        if (changes.image && changes.image.currentValue !== changes.image.previousValue && this.image) {
            this.aspectRatio = this.isModelsMenu ? ModelsAspectRatio : MenuImageAspectRatio;
            this.imageClass = this.isModelsMenu ? 'is-ratio-model animate-scale is-small-scale' : 'is-ratio-menu animate-mask is-small-mask';
            const image = this.image;
            this.pictureSrcset = `
                            ${ImageUrl(image, { width: 270, heightratio: this.aspectRatio, mode: 'crop' })} 1x,
                            ${ImageUrl(image, { width: 540, heightratio: this.aspectRatio, mode: 'crop' })} 2x
                        `;
            this.pictureSrc = ImageUrl(image, { width: 540, heightratio: this.aspectRatio, mode: 'crop' });
            this.pictureAlt = this.image.altText || this.title;
        }
    }
}
