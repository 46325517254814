import { Pipe, PipeTransform } from '@angular/core';
import { DataListField } from '@ncg/data';
import { fieldById } from './field-by-id.util';

@Pipe({
    name: 'datasById',
    standalone: true,
    pure: true,
})
export class DatasByIdPipe implements PipeTransform {
    transform(fields?: DataListField[] | null, id?: DataListField['fieldTypeId']): (string | number)[] | undefined {
        return fieldById<DataListField>(fields, id)?.data?.map((data) => data.value);
    }
}
