export class SpotsConfig {
    iconType: 'filled' | 'light' | 'outlined' = 'filled';
    isMetaLinksVisibleMobile: boolean;
    isMobileBackMultiLevel: boolean;
    mobileOverlayOffsetTop = '65px';
    invertModelPageHeader = true;
    isModelPageHeaderAnimating = true;
    isHeroFullViewport = false;
    mobileNavigationLogo = true;
    mobileAnimationXAxis = '-100%';
    mobileAnimationYAxis = '0';
    /**
     * @description Layout setting for the map spot.
     * If set to true, there will be shown a list of logos foreach brand, in the list view and window marker.
     * E.g. BAG has 'Mini' and 'BMW'
     */
    isBrandLayout: boolean;
    imageTextPickerItemSpaceBetween = 20;
}
