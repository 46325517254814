import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getBuildIdQuery } from '@ncg/data';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { HeaderMinimalComponent } from '../../../header-minimal/header-minimal.component';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsService } from '../../../core/settings.service';

@Component({
    selector: 'ncg-configurator-header',
    template: `
        <ncg-header-minimal>
            <div class="configurator-header">
                <a
                    class="logo-link"
                    id="logo"
                    [attr.aria-label]="(settingsService.settings$ | async)?.brandId + 'logo'"
                    [routerLink]="'/'"
                    [ngSwitch]="(settingsService.settings$ | async)?.brandId"
                >
                    <ng-container *ngSwitchCase="'hyundai'">
                        <svg-icon-sprite
                            class="is-hidden-desktop"
                            [src]="'assets/images/sprite.svg' + buildIdQuery + '#hyundai-logo-symbol'"
                            [width]="'48px'"
                            [height]="'25px'"
                            [viewBox]="'0 0 98 50'"
                            [attribute]="[['aria-labelledby', 'logo']]"
                        ></svg-icon-sprite>
                        <svg-icon-sprite
                            class="is-hidden-touch"
                            [src]="'assets/images/sprite.svg' + buildIdQuery + '#hyundai-logo-symbol'"
                            [width]="'80px'"
                            [height]="'40px'"
                            [viewBox]="'0 0 98 50'"
                            [attribute]="[['aria-labelledby', 'logo']]"
                        ></svg-icon-sprite>
                    </ng-container>
                    <ng-container *ngSwitchCase="'byd'">
                        <svg-icon-sprite src="byd-logo" [viewBox]="'0 0 94 18'" [attribute]="[['aria-labelledby', 'logo']]"></svg-icon-sprite>
                    </ng-container>
                </a>
                <h2 class="heading">{{ 'configurator.header_headline' | translate }}</h2>
                <a
                    *ngIf="showLink"
                    class="go-back-link"
                    [routerLink]="'../'"
                    [relativeTo]="route"
                    [attr.title]="'configurator.header_close' | translate"
                >
                    <svg-icon-sprite
                        src="cross-light"
                        [viewBox]="'0 0 30 30'"
                        [width]="'30px'"
                        [height]="'30px'"
                        aria-hidden="true"
                    ></svg-icon-sprite>
                </a>
            </div>
        </ncg-header-minimal>
    `,
    styleUrls: ['./configurator-header.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, IconSpriteModule, HeaderMinimalComponent, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfiguratorHeaderComponent {
    constructor(
        public readonly settingsService: SettingsService,
        public readonly router: Router,
        public readonly route: ActivatedRoute,
        public readonly location: Location
    ) {}
    public buildIdQuery = getBuildIdQuery();

    @Input() showLink = true;
}
