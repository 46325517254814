import { animate, group, query, stagger, state, style, transition, trigger } from '@angular/animations';

import { easeSmoothOut } from './easing.animations';

export const menuWrapperAnimation = [
    trigger('menuAnimation', [
        state(
            'hidden',
            style({
                opacity: 0,
                visibility: 'hidden',
            })
        ),
        state(
            'expand',
            style({
                opacity: 1,
                visibility: 'visible',
            })
        ),
        transition('expand => hidden', [
            group([
                style({
                    height: '*',
                    zIndex: 1,
                    opacity: 1,
                    visibility: 'visible',
                }),
                animate(
                    `400ms 0ms ${easeSmoothOut}`,
                    style({
                        height: '0',
                        zIndex: 1,
                        opacity: 1,
                        visibility: 'visible',
                    })
                ),
            ]),
        ]),
        transition(
            'expand => hidden, hidden => expand',
            [
                group([
                    style({
                        height: '{{ prevDropdownHeight }}',
                        zIndex: 2,
                        opacity: 1,
                        visibility: 'visible',
                    }),
                    animate(
                        `300ms 0ms ${easeSmoothOut}`,
                        style({
                            height: '{{ currentDropdownHeight }}',
                            zIndex: 2,
                            opacity: 1,
                            visibility: 'visible',
                        })
                    ),
                    // Animation for mega menu image
                    query(
                        '.mega-menu__item--animate',
                        [
                            style({
                                transform: 'translateY(-4rem)',
                                opacity: 0,
                            }),
                            stagger(20, [
                                group([
                                    animate(
                                        `600ms 0ms ${easeSmoothOut}`,
                                        style({
                                            transform: 'translateY(0rem)',
                                        })
                                    ),
                                    animate(
                                        '400ms 0ms cubic-bezier(0.390, 0.575, 0.565, 1.000)',
                                        style({
                                            opacity: 1,
                                        })
                                    ),
                                ]),
                            ]),
                        ],
                        { optional: true }
                    ),
                    // Animation for mega menu sidebar menu
                    query(
                        '.mega-sidemenu__label--animate',
                        [
                            style({
                                opacity: 0,
                                transform: 'translateY(-200%)',
                            }),
                            animate(
                                `300ms 0ms ${easeSmoothOut}`,
                                style({
                                    opacity: 1,
                                    transform: 'translate(0)',
                                })
                            ),
                        ],
                        { optional: true }
                    ),
                    query(
                        '.mega-sidemenu__item--animate',
                        [
                            style({
                                opacity: 0,
                                transform: 'translate(0%, -180%)',
                            }),
                            stagger(40, [
                                animate(
                                    `400ms 0ms ${easeSmoothOut}`,
                                    style({
                                        opacity: 1,
                                        transform: 'translate(0, 0)',
                                    })
                                ),
                            ]),
                        ],
                        { optional: true }
                    ),
                    // Animation for mega models nav
                    query(
                        '.mega-models__navbar--animate',
                        [
                            style({
                                opacity: 0,
                                transform: 'translate(0%, -180%)',
                            }),
                            animate(
                                `400ms 0ms ${easeSmoothOut}`,
                                style({
                                    opacity: 1,
                                    transform: 'translate(0, 0)',
                                })
                            ),
                        ],
                        { optional: true }
                    ),
                ]),
            ],
            {
                params: {
                    prevDropdownHeight: '0px',
                    currentDropdownHeight: '*',
                },
            }
        ),
    ]),
];
