import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { UpComponent } from './up.component';

@NgModule({
    imports: [CommonModule, RouterModule, IconSpriteModule],
    declarations: [UpComponent],
    exports: [UpComponent],
})
export class UpModule {}
