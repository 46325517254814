import { ComponentRef, Directive, EventEmitter, Input, OnChanges, OnDestroy, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * Standard directive to dynamically create page components.
 *
 * Example:
 *
 * <ng-template ncgDynamicPageComponent [component]="component" [inputs]="inputs"></ng-template>
 */
@Directive({
    selector: '[ncgDynamicPageComponent]',
})
export class DynamicPageComponentDirective implements OnChanges, OnDestroy {
    private readonly subscriptions: { [name: string]: Subscription } = {};

    private _component: ComponentRef<any>;

    @Input()
    public component: any;

    @Input()
    public inputs: { [name: string]: any } = {};

    @Input()
    public outputs?: { [name: string]: any };

    constructor(private readonly viewContainerRef: ViewContainerRef) {}

    public ngOnDestroy() {
        Object.keys(this.subscriptions).forEach((key) => this.subscriptions[key].unsubscribe());
    }

    public ngOnChanges(changes: any) {
        // Clear & create component on change.
        if (changes['component'].currentValue) {
            this.createComponent();
        }

        // Set inputs on change.
        if (changes['inputs'].currentValue || changes['component'].currentValue) {
            this.updateInputs();
        }

        // Set outputs on change.
        if (changes['outputs']?.currentValue || changes['component']?.currentValue) {
            this.updateOutputs();
        }
    }

    private createComponent(): void {
        this.viewContainerRef.clear();
        this._component = this.viewContainerRef.createComponent(this.component);
    }

    private updateInputs(): void {
        Object.keys(this.inputs).forEach((key) => {
            this._component.instance[key] = this.inputs[key];
        });
    }

    private updateOutputs(): void {
        if (this._component && this.outputs) {
            const newOutputs = Object.keys(this.outputs).filter((key) => !this.subscriptions[key]);
            const removedOutputs = Object.keys(this.subscriptions).filter((key) => !this.outputs?.[key]);

            newOutputs.forEach((key: string) => {
                if (this._component.instance.hasOwnProperty(key) && this._component.instance[key] instanceof EventEmitter) {
                    this.subscriptions[key] = this._component.instance[key].subscribe((data: any) => {
                        if (this.outputs?.key) {
                            (this.outputs[key] as (data: any) => void)(data);
                        }
                    });
                }
            });

            removedOutputs.forEach((key) => {
                this.subscriptions[key].unsubscribe();
                delete this.subscriptions[key];
            });
        }
    }
}
