import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IImage } from '@ncg/data';

import { ModelsAspectRatio } from '../utils/helpers/aspect-ratio';
import { ImageUrl } from '../utils/helpers/image-helper';

@Component({
    selector: 'ncg-search-image',
    template: ` <img loading="lazy" [srcset]="pictureSrcset" [src]="pictureSrc" sizes="270px" *ngIf="pictureSrc && pictureSrcset" /> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchImageComponent implements OnInit {
    @Input() image?: IImage;
    @Input() showImage = false;
    pictureSrc = '';
    pictureSrcset: string = '';

    ngOnInit() {
        if (this.image) {
            this.pictureSrc = ImageUrl(this.image, { width: 540, heightratio: ModelsAspectRatio, mode: 'crop' });
            this.pictureSrcset = `
                    ${ImageUrl(this.image, { width: 270, heightratio: ModelsAspectRatio, mode: 'crop' })} 270w,
                    ${ImageUrl(this.image, { width: 540, heightratio: ModelsAspectRatio, mode: 'crop' })} 540w
                `;
        }
    }
}
