import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { SettingsService } from '../../core/settings.service';

@Injectable({
    providedIn: 'root',
})
export class MapService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private isScriptsLoaded = false;

    constructor(
        private readonly http: HttpClient,
        private readonly settingsService: SettingsService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public initGoogleMapsScripts(): Observable<boolean> {
        if (this.isScriptsLoaded) {
            return of(true);
        }

        return this.settingsService.get().pipe(
            take(1),
            switchMap((settings) => {
                const apiKey = settings.googleMapsApiKey;

                if (!apiKey) {
                    console.warn('Missing google maps key');
                    return of((this.isScriptsLoaded = false));
                }

                const mapsApiScript = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=&libraries=geometry`;
                return this.http.jsonp(mapsApiScript, 'callback').pipe(
                    map(() => (this.isScriptsLoaded = true)),
                    catchError(() => of((this.isScriptsLoaded = false)))
                );
            }),
            takeUntil(this.unsubscribe)
        );
    }
}
