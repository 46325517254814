import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { CTASpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';
import { SpotsConfig } from '../spots-config';

@Component({
    selector: 'ncg-cta-spot',
    template: `
        <ul class="cta-spot columns is-multiline is-variable is-8-tablet is-centered" ncgLoadIn>
            <li
                *ngFor="let item of data.ctaItems"
                class="cta-spot__item column is-3 "
                [ngClass]="{ 'has-text-centered-tablet': data.alignment === 'Center' }"
            >
                <div class="cta-spot__item-content" *ngIf="item.iconId || item.title">
                    <div class="cta-spot__theme-color-wrapper">
                        <svg-icon-sprite
                            *ngIf="item.iconId"
                            class="cta-spot__icon"
                            [src]="item.iconId"
                            [viewBox]="'0 0 30 30'"
                            [width]="'75px'"
                            [height]="'75px'"
                            aria-hidden="true"
                        ></svg-icon-sprite>
                        <h3 class="cta-spot__title" *ngIf="item.title">
                            <ncg-link
                                [cover]="true"
                                styleClass="cta-spot__link"
                                [link]="item.link"
                                [title]="item.linkHoverTitle"
                                [attr.aria-label]="item.linkHoverTitle"
                            >
                                <span class="cta-spot__link-content {{ 'cta-spot__link-content_' + data.alignment | lowercase }}">
                                    <span *ngIf="data.showChevron" class="cta-spot__chevron" aria-hidden="true">
                                        <svg-icon-sprite
                                            src="chevron-right"
                                            [viewBox]="'0 0 30 30'"
                                            [width]="'30px'"
                                            [height]="'30px'"
                                            aria-hidden="true"
                                        ></svg-icon-sprite>
                                    </span>
                                    <span class="cta-spot__link-text">{{ item.title }}</span>
                                </span>
                            </ncg-link>
                        </h3>
                    </div>
                    <p class="is-hidden-mobile" *ngIf="item.subtitle">{{ item.subtitle }}</p>
                </div>
                <div [class.is-hidden-mobile]="item.title" *ngIf="item.showButton">
                    <ncg-button [link]="item.link" [title]="item.link.name" [buttonClass]="item.buttonStyle"></ncg-button>
                </div>
            </li>
        </ul>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CTASpotComponent extends SpotBaseDirective {
    static ref = 'cta';
    @Input() data: CTASpot;

    constructor(@Inject(SpotsConfig) public readonly spotsConfig: SpotsConfig) {
        super();
    }
}
