import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isEmptyObject',
    standalone: true,
    pure: true,
})
export class IsEmptyObjectPipe implements PipeTransform {
    transform(obj = {}): boolean {
        return !Object.keys(obj)?.length;
    }
}
