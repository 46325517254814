import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'ncg-header-minimal',
    template: `
        <header class="container">
            <ng-content></ng-content>
        </header>
    `,
    styleUrls: ['./header-minimal.component.scss'],
    standalone: true,
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMinimalComponent {}
